import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import React from 'react';
import Iconify from 'src/components/Iconify';

export default function ChannelActionModal({ open, onClose, action, actionTitle, message }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Stack width={1} direction="row" justifyContent="space-between" mb={3}>
          <Stack>
            <Typography fontWeight="bold" variant="h5">
              Alert
            </Typography>
          </Stack>
          <Stack>
            <IconButton onClick={onClose}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <DialogContentText whiteSpace="pre-line" textAlign="center">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="contained" color="error" onClick={action}>
          {actionTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
