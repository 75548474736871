import { fr as localFr } from 'date-fns/locale';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography
} from '@mui/material';
import { format } from 'date-fns';
import { isNumber, isString } from 'lodash';
import React, { useState } from 'react';
import CIconButton from 'src/components/CIconButton';
import EllipsisText from 'src/components/EllipsisText';
import Iconify from 'src/components/Iconify';
import { toDateTime } from 'src/utils/unixDateTime';
import { MuiCustomInput } from 'src/components/CustomInput';
import { dispatch } from 'src/redux/store';
import { updateChannel } from 'src/redux/slices/communication/channel';
import { ChannelType } from 'src/models/communication_types';
import { CANAL_INFO_TAB, CHANNEL_FIELD, CONFIRM_MESSAGE } from 'src/constants/community';
import { useCommunityContext } from 'src/contexts/CommunityContext';

import { MAvatar } from 'src/components/@material-extend';
import createAvatar from 'src/utils/createAvatar';
import { nameFilter } from 'src/utils/nameFilter';

/**
 *
 * @param {{open: boolean, onClose: function, channel: ChannelType, user: firebase.default.UserInfo}} props
 * @returns
 */
const ChannelInfos = ({ open, onClose, channel, user }) => {
  const [tab, setTab] = React.useState(CANAL_INFO_TAB.ABOUT);
  const [field, setField] = React.useState(null);
  const { handleOpenConfim } = useCommunityContext();
  const [currentChannel, setCurrentChannel] = useState(channel);
  const [favorite, setFavorite] = useState(channel?.favorite || {});
  const [notifications, setNotifications] = useState(channel?.notifications || {});

  const handleClose = () => {
    setField(null);
  };

  const handleOpen = (_field) => () => {
    setField(_field);
  };

  const handleChange = (_, val) => {
    setTab(val);
  };

  const handleChannelChange = (name, value) => {
    setCurrentChannel((val) => ({ ...val, [name]: value }));
  };

  const handleFavorite = () => {
    let _favorite = { ...favorite };
    const userId = user.uid;
    const val = _favorite[userId];
    _favorite[userId] = !val;

    dispatch(
      updateChannel({
        channel: { ...channel, favorite: _favorite },
        oldChannel: channel,
        callback: () => {
          setFavorite(_favorite);
        }
      })
    );
  };

  const handleNotifications = () => {
    let _notifications = { ...notifications };
    const userId = user.uid;
    const val = _notifications[userId];
    _notifications[userId] = !val;

    dispatch(
      updateChannel({
        channel: { ...channel, notifications: _notifications },
        oldChannel: channel,
        callback: () => {
          setNotifications(_notifications);
        }
      })
    );
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle>
        <Stack width={1} direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2}>
            <Iconify icon="material-symbols:tag-rounded" sx={{ color: 'grey.600' }} />
            <EllipsisText
              style={{ color: 'grey.900', variant: 'body2', fontWeight: 'bold' }}
              text={channel.title}
            ></EllipsisText>
          </Stack>
          <Stack>
            <IconButton onClick={onClose}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack width={1} direction="row" spacing={3} mb={3} mt={2}>
          <ToggleButtonGroup size="small" value={tab} exclusive onChange={handleChange} aria-label="Platform">
            <ToggleButton value={CANAL_INFO_TAB.ABOUT}> {CANAL_INFO_TAB.ABOUT} </ToggleButton>
            <ToggleButton value={CANAL_INFO_TAB.MEMBERS}>
              {CANAL_INFO_TAB.MEMBERS} ({channel?.members?.length || 0})
            </ToggleButton>
          </ToggleButtonGroup>

          <CIconButton sx={{ fontSize: 13 }} onClick={handleFavorite}>
            <Iconify icon={favorite[user.uid] ? 'emojione:star' : 'ic:round-star-outline'} sx={{ mr: 1 }} />
            {favorite[user.uid] ? 'Retirer des favoris' : 'Ajouter aux favoris'}
          </CIconButton>
          <CIconButton sx={{ fontSize: 12 }} onClick={handleNotifications}>
            <Iconify
              icon={
                notifications[user.uid] === undefined
                  ? 'lucide:bell-ring'
                  : notifications[user.uid]
                  ? 'lucide:bell-ring'
                  : 'carbon:notification-off-filled'
              }
              sx={{ mr: 1 }}
            />
            {notifications[user.uid] === undefined
              ? 'Désactiver les notifications'
              : notifications[user.uid]
              ? 'Désactiver les notifications'
              : 'Activer les notifications'}
          </CIconButton>
        </Stack>

        <TabContext value={tab}>
          <TabPanel value={CANAL_INFO_TAB.ABOUT}>
            <AboutPanel
              channel={currentChannel}
              user={user}
              handleOpen={handleOpen}
              handleOpenConfim={handleOpenConfim}
            />
          </TabPanel>
          <TabPanel value={CANAL_INFO_TAB.MEMBERS}>
            <MemberPanel channel={channel} user={user} />
          </TabPanel>
        </TabContext>
      </DialogContent>
      {field && (
        <EditChannel
          open={Boolean(field)}
          onClose={handleClose}
          channel={channel}
          field={field}
          handleChannelChange={handleChannelChange}
          editTitle={field === CHANNEL_FIELD.TITLE ? 'Modifier le titre' : 'Modifier la description'}
        />
      )}
    </Dialog>
  );
};

export default ChannelInfos;

/**
 *
 * @param {{channel: ChannelType, user: firebase.default.UserInfo, handleOpenConfim: function}} pros
 */
const AboutPanel = ({ channel, user, handleOpen, handleOpenConfim }) => {
  const [values, setValues] = useState({ title: channel?.title, description: channel?.description });

  const handleChange = (name, value) => {
    setValues((old) => ({ ...old, [name]: value }));
  };

  return (
    <Stack>
      <CanalInfosLine
        title="Titre du canal"
        onChange={handleChange}
        value={channel?.title}
        edit={handleOpen(CHANNEL_FIELD.TITLE)}
        addHash
      />
      <Divider />
      <CanalInfosLine
        title="Description du canal"
        value={channel?.description}
        onChange={handleChange}
        edit={handleOpen(CHANNEL_FIELD.DESC)}
      />
      <Divider />
      <CanalInfosLine
        title="Créé par"
        value={`${channel?.created_by?.name} le ${format(
          isString(channel.created_at) || isNumber(channel.created_at)
            ? toDateTime(Number(channel.created_at) / 1000)
            : new Date(),
          'dd MMMM yyyy HH:mm',
          {
            locale: localFr
          }
        )}`}
      />
      {!channel?.isClosed && <Divider sx={{ mb: 1 }} />}
      {!channel?.isClosed && (
        <CanalInfosLine title="Quitter le canal" color="#E01E5A" asButton={handleOpenConfim(CONFIRM_MESSAGE.LEAVE)} />
      )}
      <Divider />
      {!channel?.isClosed && channel?.created_by?.id === user.uid && (
        <CanalInfosLine
          title="Cloturer le canal pour tout le monde"
          color="#E01E5A"
          asButton={handleOpenConfim(CONFIRM_MESSAGE.CLOSE)}
        />
      )}
      {channel?.isClosed && channel?.created_by?.id === user.uid && (
        <CanalInfosLine
          title="Rouvrir le canal pour tout le monde"
          color="#E01E5A"
          asButton={handleOpenConfim(CONFIRM_MESSAGE.OPEN)}
        />
      )}
      {!channel?.isClosed && channel?.created_by?.id === user.uid && (
        <CanalInfosLine
          title="Supprimer le canal"
          color="#E01E5A"
          asButton={handleOpenConfim(CONFIRM_MESSAGE.DELETE_CANAL)}
        />
      )}
    </Stack>
  );
};

const CanalInfosLine = ({ title, value, color, edit = null, addHash = false, asButton = null }) => {
  return (
    <Stack width={1} {...(asButton && { component: MenuItem, disableGutters: true, onClick: asButton })} py={1}>
      <Stack width={1} direction="row" justifyContent="space-between">
        <Stack>
          <Typography fontWeight={value ? 'bold' : '600'} fontSize={15} {...(color && { color: color })}>
            {title}
          </Typography>
        </Stack>
        {edit && !asButton && (
          <Stack>
            <Button onClick={edit} size="small">
              Modifier
            </Button>
          </Stack>
        )}
      </Stack>
      <Stack direction="row" spacing={1}>
        {addHash && <Iconify icon="material-symbols:tag-rounded" sx={{ color: 'grey.800' }} />}
        <Typography fontSize={14} color="grey.800">
          {value}
        </Typography>
      </Stack>
    </Stack>
  );
};

const EditChannel = ({ open, onClose, handleChannelChange, field, channel, editTitle }) => {
  const [value, setValue] = React.useState(channel[field]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };

  const handleSave = () => {
    dispatch(updateChannel({ channel: { ...channel, [field]: value }, oldChannel: channel }));
    handleChannelChange(field, value);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Stack width={1} direction="row" justifyContent="space-between">
          <Stack>
            <Typography fontWeight="bold" variant="h5">
              {editTitle}
            </Typography>
          </Stack>
          <Stack>
            <IconButton onClick={onClose}>
              <Iconify icon="eva:close-fill" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack width={500} pt={2}>
          <MuiCustomInput
            name={field}
            fullWidth
            {...(field === CHANNEL_FIELD.DESC && { multiline: true })}
            value={value}
            onChange={handleChange}
            {...(field === CHANNEL_FIELD.TITLE && { startAdornment: <span style={{ marginRight: 10 }}>#</span> })}
          />
          {field === CHANNEL_FIELD.TITLE ? (
            <Typography color="text.disabled" variant="caption">
              Le titre de ce canal ne doit pas dépasser 80 caractères. caractères utilisés : (
              {channel?.title?.length || 0})
            </Typography>
          ) : (
            <Typography color="text.disabled" variant="caption">
              Quel est l’objectif de ce canal ?
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <Stack width={1} spacing={3} direction="row" justifyContent="flex-end" pb={2} px={2}>
        <Button variant="outlined" color="inherit" onClick={onClose}>
          Annuler
        </Button>
        <Button variant="contained" onClick={handleSave}>
          Enregistrer
        </Button>
      </Stack>
    </Dialog>
  );
};

/** @param {{channel: ChannelType, user: firebase.default.UserInfo}} */
const MemberPanel = ({ channel, user }) => {
  const [value, setValue] = useState('');

  return (
    <Stack width={1} spacing={1}>
      <MuiCustomInput
        fullWidth
        startAdornment={<Iconify icon="ic:baseline-search" sx={{ mr: 2, height: 25, width: 25 }} />}
        placeholder="rechercher"
        size="small"
        value={value}
        onChange={(e) => setValue(e.currentTarget.value)}
        sx={{ py: 0.5 }}
      />
      <Stack>
        {nameFilter(channel.members, 'name', value).map((chan, index) => (
          <MenuItem key={chan?.id + index} disableGutters>
            <MAvatar
              variant="square"
              color={createAvatar(chan.name).color}
              sx={{ borderRadius: 1, mr: 2, height: 30, width: 30 }}
            >
              {createAvatar(chan.name).name}
            </MAvatar>
            <Typography fontWeight="bold">
              {chan.name} {user.uid === chan.id ? '(vous)' : ''}
            </Typography>
          </MenuItem>
        ))}
      </Stack>
    </Stack>
  );
};
