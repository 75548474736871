import PropTypes from 'prop-types';
// @mui
import { Dialog, DialogTitle, TextField, DialogActions, Button, Stack } from '@mui/material';
import DatePicker from '@mui/lab/StaticDatePicker';
import { LocalizationProvider } from '@mui/lab';
import frLocale from 'date-fns/locale/fr';
import AdapterDayjs from '@mui/lab/AdapterDateFns';

// ----------------------------------------------------------------------

DatePickerDialog.propTypes = {
  open: PropTypes.bool,
  startTime: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
  endTime: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(Date)]),
  onChangeEndTime: PropTypes.func,
  onChangeStartTime: PropTypes.func,
  onClose: PropTypes.func
};

export default function DatePickerDialog({
  startTime,
  endTime,
  onChangeStartTime,
  onChangeEndTime,
  open,
  onClose,
  onSave
}) {
  const handleOnSave = () => {
    onClose();
    onSave();
  };

  const cancel = () => {
    onChangeEndTime(null);
    onChangeStartTime(null);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={onClose}>
      <DialogTitle> Date d'échéance </DialogTitle>

      <Stack width={1} justifyContent="center" direction="row" spacing={2} sx={{ px: 3, mt: 3 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs} locale={frLocale}>
          <DatePicker
            label="Date début"
            value={startTime}
            onChange={onChangeStartTime}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} locale={frLocale}>
          <DatePicker
            label="Date fin"
            value={endTime}
            onChange={onChangeEndTime}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </Stack>

      <DialogActions>
        <Button variant="outlined" onClick={cancel}>
          Annuler
        </Button>
        <Button variant="contained" onClick={handleOnSave}>
          Appliquer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
