import {useState, useEffect, useMemo} from "react";
import {isEmpty, isLoaded, useFirestoreConnect} from "react-redux-firebase";
import {useDispatch, useSelector} from "src/redux/store";
import {firestore} from 'src/contexts/FirebaseContext'


export default function useAudiencePeople() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const getUsers = useSelector(state => state.firestore.ordered.audPeo);
    const users = useMemo(() => {
        return isEmpty(getUsers) ? [] : getUsers
    }, [getUsers]);

    useFirestoreConnect(() => [
        {collection: 'audience-people', storeAs: 'audPeo'}
    ]);

    const register = async (user) => {
        const {id, ...rest} = user
        await firestore.collection("audience-people").doc(id).set(rest, {merge: true})
    }

    useEffect(() => {
        if (!isLoaded(getUsers)) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [dispatch, getUsers, users]);

    return {users, loading, register}
}
