import React from 'react';
import { Box, Button, Dialog, DialogContent, Paper, Stack, Typography } from '@mui/material';
import EllipsisText from './EllipsisText';

/**
 * @typedef {{
 * title: string,
 * titleSize?: number,
 * icon:any,
 * subTitle: string,
 * color: string,
 * action?: React.ReactNode,
 * moreAction?: React.ReactNode
 * desc:string,
 * open: boolean,
 * onClose: Function,
 * maxWidth: string,
 * closeText: string,
 * removeZIndex?: boolean
 * }}
 */
var ActionModalType;

/**
 *
 * @param {ActionModalType} props
 *
 */
const ActionModal = (props) => {
  const {
    title,
    titleSize,
    subTitle,
    color = 'primary',
    body = null,
    moreAction = null,
    action = null,
    maxWidth,
    desc,
    open,
    icon,
    onClose,
    closeText = 'Annuler',
    removeZIndex = false,
    removeCancel = false
  } = props;

  const handleCanClose = (_, raison) => {
    if (raison !== 'backdropClick') {
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth={maxWidth || 'sm'}
      open={open}
      onClose={handleCanClose}
      sx={{ ...(!removeZIndex && { zIndex: (t) => t.zIndex.modal + 3 }) }}
    >
      <Stack width={1} direction="row">
        <Box pl={3} sx={{ position: 'relative', minHeight: 50 }}>
          <Paper
            elevation={4}
            square
            sx={{
              left: 15,
              minHeight: 40,
              minWidth: 100,
              position: 'absolute',
              transform: 'skewX(25deg)',
              borderRadius: '0 0 10px 10px',
              bgcolor: (t) => t.palette[`${color}`].main
            }}
          />
          <Paper
            elevation={4}
            square
            sx={{
              pt: 1,
              px: 1,
              left: 35,
              minHeight: 40,
              minWidth: 150,
              maxWidth: 'max-content',
              position: 'absolute',
              transform: 'skewX(-25deg)',
              borderRadius: '0 0 10px 10px',
              bgcolor: (t) => t.palette[`${color}`].main
            }}
          >
            <Stack width={1} direction="row">
              <EllipsisText
                text={title}
                style={{
                  align: 'center',
                  fontWeight: 'bold',
                  ...(titleSize && { fontSize: titleSize }),
                  color: 'white'
                }}
                sx={{ transform: 'skewX(25deg)', pr: 2 }}
              />
              {subTitle && (
                <Stack width={1} direction="row" alignItems="center">
                  {icon && (
                    <Stack width={30} sx={{ transform: 'skewX(25deg)' }}>
                      {icon}
                    </Stack>
                  )}
                  <EllipsisText
                    text={subTitle}
                    style={{
                      align: 'center',
                      fontWeight: 'bold',
                      fontSize: 12,
                      color: 'white'
                    }}
                    sx={{ transform: 'skewX(25deg)', pr: 2 }}
                  />
                </Stack>
              )}
            </Stack>
          </Paper>
        </Box>
      </Stack>
      <DialogContent>
        <Typography color="grey.500" mb={2} align="center">
          {desc}
        </Typography>

        {body && (
          <Stack mt={2} ml={5}>
            {body}
          </Stack>
        )}

        {moreAction && <Stack mt={2}>{moreAction}</Stack>}

        <Stack mt={3} direction="row" width={1} spacing={3} justifyContent="flex-end">
          {!removeCancel && (
            <Button color={color} variant="outlined" onClick={handleCanClose}>
              {closeText}
            </Button>
          )}
          {action}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default ActionModal;
