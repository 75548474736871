import React, { useRef, useState } from 'react'
import { orderBy } from 'lodash';
import { Icon } from '@iconify/react'
import { CircularProgress, IconButton, MenuItem, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import EllipsisText from 'src/components/EllipsisText'
import useAuth from 'src/hooks/useAuth';
import { useDashbord } from 'src/layouts/dashboard';
import { useToggleV2 } from 'src/hooks/useToggle';
import MenuPopover from 'src/components/MenuPopover';
import useProject from 'src/hooks/useProject';
import { orderProject } from 'src/pages/dashboard/TaskProjectMyProjects';
import { useDispatch } from 'src/redux/store';
import { getCurrentProject, projectActuSelected, selectProject, updateProjectLatestOpen } from 'src/redux/slices/kanban';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Scrollbar from 'src/components/Scrollbar';
import { MuiCustomInput } from 'src/components/CustomInput';
import { nameFilter } from 'src/utils/nameFilter';
import { baseColors } from 'src/constants/color';
import { useProjectContext } from 'src/contexts/ProjectContext';

export default function ChangeCurrentProjectBox() {
    const anchorEl = useRef();
    const { user } = useAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { title, setTitle } = useDashbord();
    const [open, onOpen, onClose] = useToggleV2();
    const { loading, projects } = useProject({ id: user.id, status: ['open'] });
    const [popoverSearch, setPopoverSearch] = useState('');
    const { setProjetChanging } = useProjectContext();

    const popoverFilterFunction = (_projects) => {
        const sort_project = orderBy(_projects || [], 'name');
        return nameFilter(sort_project, 'name', popoverSearch);
    }

    const handleSelecteProject = (project) =>{
        onClose();
        setProjetChanging(true);
        navigate(`${PATH_DASHBOARD.general.tasks}/${project?.id}`, 
            { state: { projectName: project.name, projectState: project?.state } }
        );
        setTitle(project?.name);
        dispatch(selectProject(project?.id));
        dispatch(projectActuSelected(project));
        dispatch(getCurrentProject(project));
        dispatch(updateProjectLatestOpen(project.id));
        setPopoverSearch('');
        setTimeout(() => setProjetChanging(false) , 1000);
    }

    


  return (
    <>
   {title &&
    <Stack width={1} spacing={1} onClick={onOpen} direction='row' alignItems='center' bgcolor={baseColors.GREYLIGHTER} py={.5} px={1} borderRadius={1}>
       <Stack maxWidth={1}>
            <EllipsisText text={title} 
                style={{ color: 'grey.600', fontWeight: 'bold',  }} 
            />
       </Stack>
       <Stack>
            <IconButton ref={anchorEl} size='small'>
                <Icon icon='fa6-solid:chevron-down' height={15} width={15} />
            </IconButton>
       </Stack>
    </Stack>}
    {open && 
    <MenuPopover width={400} open={open} height={420} onClose={onClose} anchorEl={anchorEl.current} disabledArrow horizontal='right'  transformOrigin={{ vertical: 'top', horizontal: 'left'}}>
        {loading ? <CircularProgress /> : 
        <Stack height={400} py={1} px={.5} spacing={2}>
            <MuiCustomInput
                size='small'
                fullWidth
                placeholder="Filtrer "
                value={popoverSearch}
                onChange={(e) => setPopoverSearch(e.currentTarget.value)}/>
            <Scrollbar>
                { projects?.length ?
                orderProject(popoverFilterFunction((projects)), user.id).map((_proj, idx) =>(
                    <MenuItem key={_proj?.id+idx} onClick={() => handleSelecteProject(_proj)}>
                        <EllipsisText material text={_proj?.name} />
                    </MenuItem>
                )) : null }
            </Scrollbar>
        </Stack>
        }
    </MenuPopover>
    }
    </>

  )
}
