export const INVALID_EMAIL = "auth/invalid-email";
export const WEAK_PASSWORD = "auth/weak-password";
export const TOO_MANY_REQUEST = "auth/too-many-requests";
export const EMAIL_ALREADY_USE ="auth/email-already-in-use";
export const WRONG_PASSWORD  = "auth/wrong-password";
export const USER_NOT_FOUND= "auth/user-not-found";
export const PASSWORDS_DONT_MATCH = "passwordsDontMatch";
export const USERNAME_EMPTY ="usernameEmpty";
export const COMPTE_DISABLED ="auth/user-disabled";
export const EMAIL_ALREADY_EXIST ="auth/email-already-exists";



export const errorMessage = (code) =>{
    if(code === EMAIL_ALREADY_EXIST){
        return "Un compte avec cet email est existe déja";
    }
    if(code === INVALID_EMAIL){
        return "Cmail invalide";
    }
    if(code === WEAK_PASSWORD){
        return "Mot de passe non conforme";
    }
    if(code === TOO_MANY_REQUEST){
        return "Trop de tentative successive";
    }
    if(code === EMAIL_ALREADY_USE){
        return "Cet email est déja en utilisation";
    }
    if(code === WRONG_PASSWORD){
        return "Mot de passe erroné";
    }
    if(code === USER_NOT_FOUND){
        return "Compte non trouvé";
    }
    if(code === PASSWORDS_DONT_MATCH){
        return "Le mot de passe ne correspond pas";
    }
    if(code === USERNAME_EMPTY){
        return "Nom d'utilisateur vide";
    }
    if(code === COMPTE_DISABLED){
        return "Ce compte est désactivé";
    }
}