import { Button, Dialog, DialogContent, DialogTitle, Divider, Stack, useTheme } from '@mui/material';
import React from 'react';
import LabelStyle from 'src/components/LabelStyle';
import useAuth from 'src/hooks/useAuth';
import { TaskType } from 'src/models/task_m';
import { updateTaskWithoutProject } from 'src/redux/slices/task';
import AddProjectToTask from '../components/AddProjectToTask';
import TaskAssigne from '../components/TaskAssigne';
import TaskDescriptionInput from '../components/TaskDescriptionInput';
import TaskDueDate from '../components/TaskDueDate';
import TaskFileView from '../components/TaskFileView';
import TaskSubTask from '../components/TaskSubTask';
import TaskTitleInput from '../components/TaskTitleInput';
import TaskItemDetailToolbar from './TaskItemDetailToolbar';

/**
 *
 * @param {{ open: boolean, onClose: function, task: TaskType }} props
 * @returns
 */
export default function TaskItemDetail({ open, onClose, task }) {
  const { user } = useAuth();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth="md"
      fullWidth
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <Stack>
        <DialogTitle id="scroll-dialog-title">
          <TaskItemDetailToolbar user={user} task={task} onUpdate={updateTaskWithoutProject} onClose={onClose} />
        </DialogTitle>
        <Divider sx={{ pt: 2 }} />
        <DialogContent>
          <Stack spacing={3} width={1} px={4}>
            <TaskTitleInput
              task={task}
              value={{ name: task?.name, editor_mentions: task?.editor_mentions }}
              onUpdate={updateTaskWithoutProject}
            />
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <LabelStyle>Projet</LabelStyle>
              <Stack width={1} direction="row">
                <AddProjectToTask task={task} user={user} onUpdate={updateTaskWithoutProject} />
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <LabelStyle>Échéance</LabelStyle>
              <Stack width={1} direction="row">
                <TaskDueDate task={task} onUpdate={updateTaskWithoutProject} />
              </Stack>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <LabelStyle>Intervenants</LabelStyle>
              <TaskAssigne task={task} onUpdate={updateTaskWithoutProject} />
            </Stack>
            <Stack width={1} spacing={2}>
              <LabelStyle>Description</LabelStyle>
              <TaskDescriptionInput task={task} onUpdate={updateTaskWithoutProject} />
            </Stack>

            <Stack width={1}>
              <TaskSubTask task={task} />
            </Stack>

            <Stack width={1}>
              <TaskFileView task={task} user={user} onUpdate={updateTaskWithoutProject} />
            </Stack>
          </Stack>
        </DialogContent>
      </Stack>
    </Dialog>
  );
}
