import firebase from 'firebase/app';
import { UserType } from 'src/helpers/types';


/** 
 * @typedef {{ 
 * name: string 
 * id: string?
 * parentId: string?
 * favorite: boolean
 * path: Array<{name:string, id:string, path:Array}> 
 * canAccessId: Array<string>
 * shareWithMe: Array<UserType> 
 * shareWithMeId: Array<string> 
 * myShare: Array<UserType> 
 * shareWith: Array,
 * sharedById: Array,
 * sharedBy: {}
 * sharedAt: firebase.firestore.Timestamp? 
 * createdAt: firebase.firestore.Timestamp? 
 * updatedAt: firebase.firestore.Timestamp? 
 * createdBy: UserType?
 * lastUpdatedBy: UserType?
 * }} 
 * 
*/
export var FolderType;



/** 
 * @typedef {{ 
 * name: string 
 * id: string? 
 * file:{url:string, name:string, type: string, id:string?}
 * parentId:string?
 * favorite: boolean
 * canAccessId: Array<string>
 * shareWithMe: Array<string> 
 * myShare: Array<UserType> 
 * shareWith: Array,
 * sharedById: Array,
 * sharedBy: {}
 * sharedAt: firebase.firestore.Timestamp? 
 * createdAt: firebase.firestore.Timestamp? 
 * updatedAt: firebase.firestore.Timestamp? 
 * createdBy: UserType?
 * lastUpdatedBy: UserType?
 * }} 
 * 
 * 
*/
export var FileType;

/** @type {FolderType} */
export var Folder=null;



export const docDriverCollectionPaths = {
    myFolders : 'docDriver',
    shareFile: 'shareFile'
};

/**
 * @typedef {{
 *  by: string,
 *  gues: Array,
 *  link: string,
 *  members: Array,
 *  shareLink: string,
 *  createdAt: firebase.firestore.Timestamp? 
 * }}
 */
export var ShareType;

