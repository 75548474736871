import { Icon } from '@iconify/react';
import React from 'react';
import { IconButtonAnimate } from 'src/components/animate';
import { themeColor } from 'src/constants/color';
import { dateConverter } from 'src/helpers/dueDateConverter';
import useDateRangePicker from 'src/hooks/useDateRangePicker';
import { TaskType } from 'src/models/task_m';
import { dispatch } from 'src/redux/store';
import DatePickerDialog from 'src/components/DatePickerDialog';
import DisplayTime from 'src/components/DisplayTime';

/**
 *
 * @param {{task : TaskType, onUpdate: function}} props
 * @returns
 */
export default function TaskDueDate({ task, onUpdate }) {
  const {
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    openPicker,
    onOpenPicker,
    onClosePicker,
    onChangeEndTime,
    onChangeStartTime
  } = useDateRangePicker(dateConverter(task?.due));

  const onSave = () => {
    dispatch(onUpdate(task, { due: [startTime, endTime] }, () => {}));
  };

  return (
    <>
      {startTime && endTime ? (
        <DisplayTime
          startTime={startTime}
          endTime={endTime}
          isSameDays={isSameDays}
          isSameMonths={isSameMonths}
          onOpenPicker={onOpenPicker}
          sx={{ typography: 'body2' }}
        />
      ) : (
        <IconButtonAnimate onClick={onOpenPicker} sx={{ p: 0.5 }}>
          <Icon icon="clarity:calendar-line" color={themeColor.CARROT_ORANGE} />
        </IconButtonAnimate>
      )}

      {openPicker && (
        <DatePickerDialog
          open={openPicker}
          startTime={startTime}
          endTime={endTime}
          onChangeEndTime={onChangeEndTime}
          onChangeStartTime={onChangeStartTime}
          onClose={onClosePicker}
          onSave={onSave}
        />
      )}
    </>
  );
}
