import { uniq } from "lodash";

export function extractUserIdsFromAffectation(data) {
    const userIds = [...(data?.toDoIds || []), ...(data?.coReceiver || []), ...(data?.toReviewIds || [])];

    // Parcourez les différentes sections d'assignation
    data?.assignationOrganigramme?.forEach((section) => {
        // Parcourez les utilisateurs de chaque section
        section.users.forEach((userId) => {
            // Vérifiez si l'ID de l'utilisateur n'est pas déjà dans la liste
            if (!userIds.includes(userId)) {
                userIds.push(userId);
            }
        });
    });

    // Vous pouvez également ajouter d'autres emplacements où les ID d'utilisateur peuvent être extraits ici, si nécessaire.

    return uniq(userIds);
}