export const downloadFile = (url, name) =>{
    fetch(url).then(res => res.blob())
    .then( file =>{
        let tempUrl = URL.createObjectURL(file);
        let a = document.createElement('a');
        a.href = tempUrl;
        a.download = name;
        document.body.appendChild(a);
        a.click();
        a.remove();
        URL.revokeObjectURL(tempUrl);

    }).catch(() => {
        console.log('erreur de telechargement du fichier');
    })
}