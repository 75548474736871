import _ from 'lodash';
import { isNotEmpty } from 'src/utils/type_check';

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  const changes = (object, base) => _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
    }
  });

  return changes(object, base);
}

/**
 * Based on [difference] to detect if there is a change between two object
 * @param object
 * @param base
 * @returns {boolean}
 */
export const hasChanged = (object, base) => {
  return isNotEmpty(_.keys(difference(object, base)));
};
