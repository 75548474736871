import React from 'react';
import { Box, IconButton, Paper, Tooltip } from '@mui/material';

/** @type {IconButton} props  */
const CIconButton = React.forwardRef(function (props, ref) {
  const { children, title = null, noBorder, noBackground, sx, ...other } = props;

  return title ? (
    <Tooltip title={title} arrow>
      <Box>
        <Paper
          component={IconButton}
          {...other}
          variant={noBorder ? 'elevation' : 'outlined'}
          size="small"
          sx={{ ...sx, ...(noBackground && { bgcolor: 'transparent' }) }}
        >
          {children}
        </Paper>
      </Box>
    </Tooltip>
  ) : (
    <Paper
      component={IconButton}
      {...other}
      variant={noBorder ? 'elevation' : 'outlined'}
      size="small"
      sx={{ ...sx, ...(noBackground && { bgcolor: 'transparent' }) }}
    >
      {children}
    </Paper>
  );
});

export default CIconButton;
