import {useState } from 'react';
// material

import {Stack, Paper, Button } from '@mui/material';
//
import useAuth from 'src/hooks/useAuth';
import QuillEditor from './editor/quill/index';
import {useTheme} from '@mui/material/styles';
import {serverTime} from 'src/utils/serverTime';
import { blue, grey } from 'src/components/CustomInput';

// ----------------------------------------------------------------------

export default function CommentInput({idQ, onChange, type=null, voice=true }) {
    const theme = useTheme();
    const [comment, setComment] = useState('');
    const [mentions, setMentions] = useState([]);
    const {user: {id, displayName, photoURL, email}} = useAuth();


    const handleSubmit = () => {
        if (comment.trim().length > 0) {

            onChange({
                userId: id,
                avatar: photoURL,
                name: displayName,
                email: email,
                messageType: 'text',
                createdAt: serverTime(),
                message: comment,
                mentions
            });
            setComment('');
        }

    }

    const handleAudioRecording = (file, {commentObject, cleanup}) => {
        onChange && onChange(commentObject)
        cleanup && cleanup()
    }

    const handleChange = (e, d, s, editor) => {
        setComment(e);
    }


    return (
        <Stack direction="row"  width={1}>

            <Paper variant="outlined"
                   sx={{
                       p: 1, flexGrow: 1,
                       backgroundColor: theme.palette.mode === 'dark' ? grey[900] : grey[10],
                       color: theme.palette.mode === 'dark' ? grey[300] : grey[900],
                       border: 'none',
                       borderRadius: '5px',
                       padding: '6px 12px',
                       transition: 'all 200ms ease',
                       "&:hover": {
                           backgroundColor: theme.palette.mode === 'dark' ? null : grey[50],
                           borderColor: theme.palette.mode === 'dark' ? grey[700] : grey[400],
                       },
                       "&:focus": {
                           outline: `2px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]}`,
                           outlineOffset: 0
                       }
                   }}
            >

                <QuillEditor
                    simple
                    type={type}
                    id={`${idQ}-comment-compo-id`}
                    placeholder="Taper votre message"
                    value={comment}
                    canMention={true}
                    onChange={handleChange}
                    setMensions={setMentions}
                    sx={{border: 'none'}}
                    onAudioRecord={voice ? handleAudioRecording : undefined}
                />


                <Stack direction="row" justifyContent="flex-end" alignItems="center">
                    <Button variant="contained" onClick={handleSubmit}>Commenter</Button>
                </Stack>
            </Paper>
        </Stack>
    );
}
