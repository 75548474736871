import axios from 'axios'
import {firestore} from 'src/contexts/FirebaseContext'


export function setMSCredentials({userId, data, onResolve, onReject}) {
    return async () => {
        try {
            // console.log('init', data)
            const {accessToken, expiresOn, tenantId, uniqueId, scopes = []} = data;

            // const snap = await axios.get("https://graph.microsoft.com/v1.0/me/calendars", {
            //     headers: {
            //         Authorization: `Bearer ${accessToken}`
            //     }
            // })
            await firestore.collection('users').doc(userId).set({
                microsoftCredential: {
                    accessToken,
                    expiresOn,
                    tenantId,
                    uniqueId,
                    scopes
                }
            }, {merge: true,})

            onResolve()

        } catch (e) {
            console.log(e)
            onReject && onReject(e)
        }
    }
}


export function setMSUnlinkCredentials({userId, onResolve, onReject}) {
    return async () => {
        try {

            await firestore.collection('users').doc(userId).set({microsoftCredential: null}, {merge: true,})

            onResolve()

        } catch (e) {
            console.log(e)
            onReject && onReject(e)
        }
    }
}


export function getMSAllCalendar({accessToken, onResolve, onReject}) {
    return async () => {
        try {
            if(!accessToken) return  onReject && onReject(`accessToken == $accessToken`)

            const snap = await axios.get("https://graph.microsoft.com/v1.0/me/calendars", {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            onResolve(snap.data?.value || [])

        } catch (e) {
            console.log(e)
            onReject && onReject(e)
        }
    }
}

export function getMSAllCalendarEvents({accessToken, onResolve, onReject}) {
    return async () => {
        try {

            if(!accessToken) return  onReject && onReject(`accessToken == $accessToken`)

            const snap = await axios.get("https://graph.microsoft.com/v1.0/me/events?$select=subject,body,bodyPreview,organizer,attendees,start,end,location", {
                headers: {
                    Authorization: `Bearer ${accessToken}`
                }
            })

            onResolve(snap.data?.value || [])

        } catch (e) {
            console.log(e)
            onReject && onReject(e)
        }
    }
}

export function createMSEvent({accessToken, calendarId, event, onResolve, onReject}) {
    return async () => {
        try {
            const snap = await axios.post(`https://graph.microsoft.com/v1.0/me/calendars/${calendarId}/events`,
                event,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-type": "application/json",
                    },
                })

            onResolve(snap.data?.value)

        } catch (e) {
            console.log(e)
            onReject && onReject(e)
        }
    }
}

export function editMSEvent({accessToken, calendarId, event, onResolve, onReject}) {
    return async () => {
        try {
            const {id, ...rest} = event;

            const snap = await axios.patch(`https://graph.microsoft.com/v1.0/me/calendars/${calendarId}/events/${id}`,
                rest,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-type": "application/json",
                    },
                })
            console.log(snap.data)

            onResolve(snap.data?.value)

        } catch (e) {
            console.log(e)
            onReject && onReject(e)
        }
    }
}

export function deleteMSEvent({accessToken, calendarId, eventId, onResolve, onReject}) {
    return async () => {
        try {

            const snap = await axios.delete(`https://graph.microsoft.com/v1.0/me/calendars/${calendarId}/events/${eventId}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-type": "application/json",
                    },
                })
            console.log(snap.data)

            onResolve(snap.data?.value)

        } catch (e) {
            console.log(e)
            onReject && onReject(e)
        }
    }
}