// i18n
import './locales/i18n';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// map
import 'mapbox-gl/dist/mapbox-gl.css';

// lightbox
import 'react-image-lightbox/style.css';

// editor
import 'quill-mention';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'quill-mention/dist/quill.mention.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import 'antd/dist/antd.css';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {Provider as ReduxProvider} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
// material
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
// redux
import {store, persistor} from './redux/store';
// contexts
import {SettingsProvider} from './contexts/SettingsContext';
import {CollapseDrawerProvider} from './contexts/CollapseDrawerContext';
// components
import LoadingScreen from './components/LoadingScreen';

// import { AuthProvider } from './contexts/JWTContext';
import {AuthProvider} from './contexts/FirebaseContext';
// import { AuthProvider } from './contexts/AwsCognitoContext';
// import { AuthProvider } from './contexts/Auth0Context';

//
import App from './App';

// import MoodProvider from 'src/contexts/MoodContext';

import {QueryClient, QueryClientProvider} from 'react-query';
// Create a client
import {registerLicense, L10n} from '@syncfusion/ej2-base';
import {defautL10nLocale} from './constants/defaultL10nLocale';
import MicrosoftProvider from 'src/contexts/MicrosoftContext';

registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFac1pJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkBhUH5YdHxRR2ddUUE=');

const queryClient = new QueryClient();

L10n.load({
    fr: defautL10nLocale
});

// ----------------------------------------------------------------------

ReactDOM.render(
    <HelmetProvider>
        <ReduxProvider store={store}>
            <PersistGate loading={<LoadingScreen/>} persistor={persistor}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <SettingsProvider>
                        <CollapseDrawerProvider>
                            <BrowserRouter>
                                <AuthProvider>
                                    <MicrosoftProvider>
                                        <QueryClientProvider client={queryClient}>
                                            <App/>
                                        </QueryClientProvider>
                                    </MicrosoftProvider>
                                </AuthProvider>
                            </BrowserRouter>
                        </CollapseDrawerProvider>
                    </SettingsProvider>
                </LocalizationProvider>
            </PersistGate>
        </ReduxProvider>
    </HelmetProvider>,
    document.getElementById('root')
);
