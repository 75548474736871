import React from 'react';
import {
  Dialog,
  DialogTitle,
  Button,
  Stack,
  Typography,
  IconButton,
  DialogContent,
  DialogActions
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MuiCustomInput } from '../../../components/CustomInput';
import { isFunction, uniqBy } from 'lodash';
import { alpha } from '@mui/material/styles';
import { UserList } from 'src/section/project/ProjectCardOPtion';
import { auth } from 'src/contexts/FirebaseContext';
import { useSelector } from 'src/redux/store';

export default function KanbanTaskManager({ managers, users, open, onClose }) {
  const [selected, setSelected] = React.useState([...managers]);
  const { users: LUsers } = useSelector((state) => state.user);

  const canValidates = React.useMemo(() => {
    const _users = users || LUsers || [];
    const _managers = managers || [];

    return uniqBy([..._managers, ..._users], 'id');
  }, [managers, users, LUsers]);

  const handleChange = (newVal) => {
    setSelected(newVal);
  };

  const handleClose = (reason) => {
    if (reason !== 'backdropClick') {
      const valid = selected.map((one) => {
        return { ...one, validate: false };
      });
      if (!selected.length) {
        const currentUser = LUsers.find((_one) => _one.id === auth.currentUser.uid);
        if (currentUser) {
          valid.push(currentUser);
        }
      }
      isFunction(onClose) && onClose(valid, true);
    }
  };

  const handleCancel = (reason) => {
    if (reason !== 'backdropClick') {
      isFunction(onClose) && onClose(null, false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={(_, reason) => handleCancel(reason)}
      disableEscapeKeyDown
      sx={{ zIndex: (t) => t.zIndex.modal + 3 }}
    >
      <DialogTitle>
        <Stack direction="row" width={1} justifyContent="space-between">
          <Typography> Demande de validation </Typography>
          <IconButton color="error" size="small" onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack spacing={1} sx={{ mt: 0.5 }}>
          <MuiCustomInput fullWidth />
          <UserList
            title="Gestionnaires"
            current={managers}
            onChange={handleChange}
            collaborateurs={canValidates}
            canSelect={true}
            canRemove={false}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" width={1} justifyContent="flex-end" spacing={2}>
          <Button color="inherit" onClick={handleCancel}>
            Ne pas envoyer
          </Button>
          <Button
            color="info"
            onClick={handleClose}
            sx={{
              bgcolor: (t) => alpha(t.palette.info.main, t.palette.action.hoverOpacity)
            }}
          >
            {!selected.length ? 'Auto valider' : 'Envoyer'}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
