import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, Stack, IconButton, DialogActions, Button } from '@mui/material';
import { alpha } from '@mui/material/styles';

export default function ShowModal({open, onClose, desc}) {
  return( <div>
      <Dialog open={open} onClose={onClose}>
          <DialogTitle>
                <Stack width={1} direction="row" justifyContent='flex-end'>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
          </DialogTitle>
          <DialogContent sx={{mt: 2}}> 
               {desc} 
          </DialogContent>
          <DialogActions>
              <Stack  width={1} direction="row" justifyContent='flex-end'>
                    <Button color="info" onClick={onClose}
                        sx={{bgcolor: (t) => alpha(t.palette.info.main, t.palette.action.hoverOpacity) }}
                    > OK </Button>
              </Stack>
          </DialogActions>
      </Dialog>
  </div>);
}
