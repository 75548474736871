import React,  {useRef, useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import saveFill from '@iconify/icons-eva/save-fill';
import downloadFill from '@iconify/icons-eva/download-fill';
import { useSnackbar } from 'notistack';
import { DocumentEditorContainerComponent, Toolbar, Inject } from '@syncfusion/ej2-react-documenteditor';
import { Stack, IconButton, Backdrop, CircularProgress, Tooltip } from '@mui/material';
import { saveFile } from 'src/redux/slices/document';
import { L10n } from '@syncfusion/ej2-base';
import { defautL10nLocale } from './../constants/defaultL10nLocale';
import CircularProgressWithLabel from './CircularProgressWithLabel';

L10n.load({
    fr: defautL10nLocale
});



const serviceUrl = 'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/Import';

export default function WordFileEditor({pathUrl, type, fileName, fileId, email, handleUpdate, onClose }) {
    const docContainerRef = useRef();
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [uploading, setUploading] = useState(false);


    const handlePasseFile = (file) =>{

        if (file.name.substr(file.name.lastIndexOf('.')) === '.sfdt') {
            let fileReader = new FileReader();
            fileReader.onload = (e) => {
                let contents = e.target.result;
                docContainerRef.current.documentEditor.open(contents);
                docContainerRef.current.documentEditor.documentName = fileName;
                docContainerRef.current.documentEditor.acceptTab = true;
                docContainerRef.current.documentEditor.pageOutline = '#E0E0E0';
                setLoading(false);
            };
            fileReader.readAsText(file);
            return;
        }

        let ajax = new XMLHttpRequest();
        ajax.open('POST', serviceUrl, true);


        ajax.onreadystatechange = () => {
            
            if (ajax.readyState === 4) {
                if (ajax.status === 200 || ajax.status === 304) {
                     docContainerRef.current?.documentEditor.open(ajax.responseText);
                     docContainerRef.current.documentEditor.documentName = fileName;
                     docContainerRef.current.documentEditor.acceptTab = true;
                }
                setLoading(false);
            }

        };

        let formData = new FormData();
        formData.append('files', file);
        ajax.send(formData);
    }

    useEffect(()=>{
        if(docContainerRef.current){
            setLoading(true);
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = (event) => {
                var blob = xhr.response;
                const file = new File([blob], fileName, { type: type });
                handlePasseFile(file);
              
            };
            xhr.onerror = (e) =>{
                setLoading(false);
            };

            xhr.open('GET', pathUrl);
            xhr.send();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    },[docContainerRef.current]);
    
    const onDocumentChange = () =>{
        if(docContainerRef.current){
            let isMobileDevice = /Android|Windows Phone|webOS/i.test(navigator.userAgent);
            if(isMobileDevice){
                docContainerRef.current.restrictEditing = false;
                setTimeout(() => {
                    docContainerRef.current.documentEditor.fitPage("FitPageWidth");
                  }, 50);
                return;
            } 
            docContainerRef.current.restrictEditing = false;

        }
    }

    const handleSave = ()=>{
        if(docContainerRef.current.documentEditor){
            setLoading(true);
            docContainerRef.current.documentEditor.saveAsBlob('Docx').then((blob)=>{
                let exportedDoc = blob;
                const file = new File([exportedDoc], fileName, { type: type });
                saveFile(file, handleUpdate, ()=>{
                                                setLoading(false); 
                                                onClose();
                                                enqueueSnackbar('Document enrégistré avec succès', { variant: 'success' });
                                            }, setUploading, fileId);
            })
        }
    }

    const handleDownload = ()=>{
        if(docContainerRef.current.documentEditor){
            docContainerRef.current.documentEditor.save(fileName, 'Docx');
        }

    }

    return (
        <div>
            <Backdrop open={loading} sx={{ color:"#fff", zIndex: (theme) => theme.zIndex.drawer + 150 }}>
               {!uploading ? <CircularProgress color="inherit" />
               : 
               <CircularProgressWithLabel size={70} color='inherit' value={uploading?.progress || 0} />}
            </Backdrop>

            <Stack sx={{bgcolor:'#0A3D7A' }} justifyContent="flex-end" direction="row" width={1}>

                <Stack direction="row" spacing={3}  sx={{ py: 0.5, px: 2 }}>   

                    <Stack direction="row" height={30} width={25}>
                    <Tooltip title="Télécharger">
                            <IconButton size="small" color="inherit" onClick={handleDownload}>
                                <Icon color="white" icon={downloadFill} width={22} height={22} />
                            </IconButton>
                    </Tooltip>
                    </Stack>

                    <Stack direction="row" height={30} width={25}>
                        <Tooltip title="Enregistrer">
                            <IconButton size="small" color="default" onClick={handleSave}>
                                <Icon color="white" icon={saveFill} width={22} height={22} />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    
                    <Stack direction="row" height={30} width={25}>
                        <Tooltip title="Fermer">
                            <IconButton size="small" color="inherit" onClick={onClose}>
                                <Icon color="white" icon={closeFill} width={22} height={22} />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                </Stack>
                
            </Stack>

            <DocumentEditorContainerComponent 
                locale='fr'
                ref={docContainerRef}
                height={`${window.innerHeight - 50}px`}
                id="doceditcontainer"
                style={{display:'block'}}
                currentUser={email}
                enableToolbar={true}
                enableComment={false}
                documentChange={onDocumentChange}
            >
                    <Inject services={[Toolbar]} />
            </DocumentEditorContainerComponent>
        </div>
    )
}
