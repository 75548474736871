import {Dialog, DialogContent, Stack,} from "@mui/material";
import {Close} from "@mui/icons-material";
import Scrollbar from "src/components/Scrollbar";
import TaskDetailsHeaderScrumb from "src/section/tasks/TaskDetailsHeaderScrumb";
import {PATH_DASHBOARD} from "src/routes/paths";

export default function Modal({children, open = false, onClose, maxWidth = 'md', height, title, secondaryAction}) {

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick")
            return;
        onClose && onClose();
    }

    return (
        <>
            {
                open && (
                    <Dialog
                        open onClose={handleClose}
                        maxWidth={maxWidth} fullWidth
                        sx={{padding: 0, bgcolor: 'transparent'}}
                    >
                        <DialogContent sx={{height, p: 0, m: 0, overflow: 'hidden'}}>
                            <Stack
                                direction='row'
                                justifyContent='space-between' width={1}
                                bgcolor={"#EDF0F1"}

                            >
                                <Stack justifyContent={'center'} ml={3}>
                                    <TaskDetailsHeaderScrumb path={[{name: title, href: '#', path: []}]}/>

                                    {/*<PDFStack direction={'row'} justifyContent={'center'}>*/}
                                    {/*    <PDFStack bgcolor={'white'} borderRadius={8} height={25}>*/}
                                    {/*        <Typography fontSize={14}>*/}
                                    {/*            Detail du compte*/}
                                    {/*        </Typography>*/}
                                    {/*    </PDFStack>*/}
                                    {/*</PDFStack>*/}
                                </Stack>

                                <Stack direction='row' py={1} spacing={1}>

                                    {secondaryAction}

                                    <Stack justifyContent='center' px={2}>
                                        <Stack bgcolor={'white'} width={30} height={30} borderRadius={1}
                                               justifyContent={'center'} onClick={onClose} sx={{cursor: 'pointer'}}>
                                            <Stack direction={'row'} justifyContent={'center'}>
                                                <Close/>
                                            </Stack>
                                        </Stack>

                                    </Stack>
                                </Stack>
                            </Stack>

                            <Scrollbar sx={{overflow: 'normal'}}>
                                {children}
                            </Scrollbar>


                        </DialogContent>
                    </Dialog>

                )
            }
        </>
    )
}