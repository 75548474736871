import { useState, createContext, useContext } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, Typography } from '@mui/material';

// hooks
import useCollapseDrawer from './../hooks/useCollapseDrawer';
import { HideOnScroll } from './../components/HideOnScroll';
import Forward from './dashboard/Forward';
import { isEmpty } from 'lodash';
import NotificationsPopover from './dashboard/NotificationsPopover';
import AccountPopover from './dashboard/AccountPopover';
//


// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  backgroundRepeat: 'no-repeat',
  backgroundSize: "cover",
  backgroundAttachment: "fixed"
}));

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(1),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));



const APPBAR_MOBILE = 50;
const APPBAR_DESKTOP = 50;

const NavRootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    [theme.breakpoints.up('lg')]: {
      minHeight: APPBAR_DESKTOP,
      padding: theme.spacing(0, 5)
    }
}));
  

// ----------------------------------------------------------------------

const DashbordContext = createContext({
  title: '',
  isTask: false,
  setIsTask: (state) => {},
  setTitle: (title) => {}
});



export const useDashbord2 = () =>{
  return useContext(DashbordContext);
}

export default function GuestLayout() {
  const theme = useTheme();
  const [title, setTitle] = useState('');
  const [isTask, setIsTask] = useState(false);
  const { collapseClick } = useCollapseDrawer();

  return (
    <DashbordContext.Provider value={{title, setTitle, isTask, setIsTask}}>
      <RootStyle>
        <GuestdNavbar />
        <MainStyle
          sx={{
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.complex
            }),
            ...(collapseClick && {
              ml: '102px'
            })
          }}
        >
          <Outlet />
        </MainStyle>
      </RootStyle>
    </DashbordContext.Provider>
  );
}


function GuestdNavbar() {
    const { title } = useDashbord2();

  
    return (
      <HideOnScroll>
        <NavRootStyle >
          <ToolbarStyle>
  
            <Forward hasTitle={isEmpty(title)} />
  
            <Box sx={{ml: 2}}>
                <Typography sx={{color: (theme) => theme.palette.primary.main}} fontSize={{ md: 20}} fontWeight="bold" fontFamily="sans-serif"> {title}  </Typography>
            </Box>
  
            <Box sx={{ flexGrow: 1 }} />
  
            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              <NotificationsPopover hasTitle={isEmpty(title)} />
              <AccountPopover />
            </Stack>
          </ToolbarStyle>
        </NavRootStyle>
      </HideOnScroll>
    );
  }
  
