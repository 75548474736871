import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import flagFill from '@iconify/icons-eva/flag-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import flagOutline from '@iconify/icons-eva/flag-outline';
import personAdd from '@iconify/icons-eva/person-add-fill';
import calendarFill from '@iconify/icons-eva/calendar-outline';
import { format } from 'date-fns';
import { gDate } from 'src/utils/formatTime';
import { fr as LocalFr } from 'date-fns/locale';
import {
  Box,
  Stack,
  Button,
  IconButton,
  Typography,
  Paper,
  ClickAwayListener,
  InputBase,
  Checkbox,
  Tooltip as MTooltip,
  Grid,
  Skeleton,
  alpha
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { defaultTask, DisplayTime, useDatePicker } from './kanban/KanbanTaskAdd';
import MenuPopover from 'src/components/MenuPopover';
import EllipsisText from 'src/components/EllipsisText';
import { SelectCollaborates } from '../project/ProjectCardOPtion';
import { isEmpty, isEqual, values, uniqBy, flattenDeep } from 'lodash';
import { priorityColor } from 'src/helpers/priorityColor';
import { useTheme } from '@mui/material/styles';
import PriorityFlags from './PriorityFlags';
import { useProjectContext } from 'src/contexts/ProjectContext';
import { Avatar, Badge, Tooltip } from 'antd';
import createAvatar from 'src/utils/createAvatar';
import { isTaskAccepted } from 'src/helpers/projectStateChecker';
import { TASK_STATE_VALIDATION, TASK_VISIBILITY } from 'src/constants';
import { dateConverter, diffDaysWithToDay, diffDaysWithToHours } from 'src/helpers/dueDateConverter';
import { baseColors } from 'src/constants/color';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';
import Iconify from 'src/components/Iconify';
import ColorPicker from 'src/components/ColorPicker';
import useAuth from 'src/hooks/useAuth';
import KanbanTaskCardValidation from './kanban/KanbanTaskCardValidation';
import KanbanTaskManager from './kanban/KanbanTaskManager';
import ActionModal from 'src/components/ActionModal';
import Label from 'src/components/Label';
import useAudioRecorder from 'src/hooks/useAudioRecorder';
import { addCommentToTask } from 'src/redux/slices/kanban';
import { useDispatch } from 'src/redux/store';
import { nanoid } from '@reduxjs/toolkit';
import { serverTime } from 'src/utils/serverTime';
import { useSnackbar } from 'notistack';
import numGenerate from 'src/utils/numGenerate';
import MusicPlayerSlider from 'src/components/MusicPlayerSlider';
import { timerDisplay } from './kanban/KanbanTaskCard';
import { saveFile } from 'src/redux/slices/document';
import UploadingOneFileProgress from 'src/components/UploadingOneFileProgress';
import DateTineRangeSelector from 'src/components/DateTineRangeSelector';
import { getTaskRights } from '../../utils/task_rights';
import MoreTaskOPtion from './MoreTaskOPtion';
import { MenuLine } from 'src/components/MenuLine';
import DependencyChoice from './dependency/DependencyChoice';
import DependencyRenderListOnDelete from './dependency/DependencyRenderListOnDelete';
import { DEPS_TYPES } from 'src/constants/deps';
import CIconButton from 'src/components/CIconButton';
import { TaskDueDateGuardPrompt, useTaskDueDateGuard } from './due_date/TaskDueDateGuard';
import UserPicker from 'src/components/UserPicker';
import { BudgetModal, VisibilityPrivateModal } from 'src/section/tasks/kanban/KanbanTaskDetailsInfo';
import { showEditorTaskTitleWithMention } from './editor/helpers';
import { EditorMentionEnter } from 'src/components/CustomInput';
import { useTaskV4Context } from 'src/contexts/TaskContext';

export const BaseLine = ({
  subTitle = null,
  icon = false,
  title,
  isSub = false,
  isCollapse,
  grids = [],
  spacing = 0.2
}) => {
  return (
    <Stack direction="row" spacing={spacing} width={1}>
      {!isSub && (
        <Box height={18} width={35} mr={0.5}>
          {' '}
          {icon}{' '}
        </Box>
      )}
      <Stack width={1} maxWidth={1}>
        {' '}
        {title}{' '}
      </Stack>
      {!isCollapse &&
        grids.map((_grid, idx) => (
          <Stack width={_grid?.width || 100} minWidth={100} key={idx}>
            {' '}
            {_grid?.element}{' '}
          </Stack>
        ))}
    </Stack>
  );
};

export const SubTaskOneSibLine = ({ isBotton, isUnderSub = false, line }) => {
  return (
    <Stack width={1} direction="row">
      <Box height={18} width={isUnderSub ? 10 : 37} mr={0.5} />
      <Stack
        height={isBotton ? 17.5 : 1}
        width="1.5px"
        sx={{ borderRadius: '0 0 0 5px' }}
        bgcolor={baseColors.GREYLIGHT}
      />
      <Stack width={30} mt="16px" height="1.5px" bgcolor={baseColors.GREYLIGHT} />
      <Stack width={1} direction="row" alignItems="center">
        {line}
      </Stack>
    </Stack>
  );
};

export const TaskLineSkeleton = (
  <>
    {[...Array(3)].map((_, index) => (
      <Stack width={1} key={index} mb={4}>
        {[...Array(5)].map((_, udx) => (
          <Grid container spacing={1} key={`${udx}-${index}`}>
            <Grid item xs={6} md={6}>
              <Skeleton
                variant="rectangular"
                width={udx === 0 ? '75%' : '100%'}
                sx={{
                  height: 32,
                  mb: 0.5,
                  ...(udx === 0 && { borderRadius: '10px 10px 0 0' })
                }}
              />
            </Grid>
            <Grid item xs={2} md={2}>
              <Skeleton variant="rectangular" width="100%" sx={{ height: 32, mb: 0.5 }} />
            </Grid>
            <Grid item xs={2} md={2}>
              <Skeleton variant="rectangular" width="100%" sx={{ height: 32, mb: 0.5 }} />
            </Grid>
            <Grid item xs={2} md={2}>
              <Skeleton variant="rectangular" width="100%" sx={{ height: 32, mb: 0.5 }} />
            </Grid>
          </Grid>
        ))}
      </Stack>
    ))}
  </>
);

export const SubTaskLineSkeleton = (
  <Stack width={1} spacing={0.1}>
    {[...Array(2)].map((_, index) => (
      <Grid container key={index}>
        <Grid item xs={12}>
          <SubTaskOneSibLine
            isBotton={index === 1}
            line={<Skeleton variant="rectangular" width="100%" sx={{ height: 35, mb: 0.5 }} />}
          />
        </Grid>
      </Grid>
    ))}
  </Stack>
);

export const TaskListNewTask = ({ columnId, parent = null, isSub = false, selectedProject = null }) => {
  const theme = useTheme();
  const [title, setTitle] = useState('');
  const [mentions, setMentions] = useState([]);
  const [priority, setPriority] = useState('');
  const [selected, setSelected] = useState(false);
  const [assigne, setAssigne] = useState([]);

  const anchorEl = useRef();
  const anchorRef = useRef(null);
  const anchorPRef = useRef(null);
  const { open, handleOpen, handleClose } = useToggle();
  const { open: openP, handleOpen: handleOpenP, handleClose: handleCloseP } = useToggle();

  const {
    user: { role }
  } = useAuth();
  const canCreate = role?.task?.create;

  const { addTask, addSubTask } = useProjectContext();

  const {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker
  } = useDatePicker({
    date: dateConverter([null, null])
  });

  const handleDueDeteChange = (value) => {
    onChangeDueDate(value);
  };

  const handeChange = (_, value) => {
    const val = value.map((newValue) => {
      return {
        id: newValue.id,
        name: newValue?.displayName || newValue?.name || null,
        email: newValue.email,
        avatar: newValue?.photoURL || newValue?.photoUrl || newValue?.avatar || null
      };
    });
    setAssigne(val);
  };

  const onCancel = () => {
    setSelected(false);
    setTitle('');
    setPriority('');
    setAssigne([]);
    onChangeDueDate([null, null]);
  };

  const onClose = () => {
    setSelected(false);
    if (!isEmpty(title)) {
      if (isSub) {
        addSubTask(
          {
            ...defaultTask,
            name: title,
            editor_mentions: mentions,
            assignee: assigne,
            priority,
            parentId: parent?.id,
            due: dueDate
          },
          parent,
          () => {
            onCancel();
            setSelected(true);
          }
        );
        return;
      }

      addTask(
        {
          ...defaultTask,
          name: title,
          editor_mentions: mentions,
          assignee: assigne,
          priority,
          due: dueDate
        },
        columnId,
        () => {
          onCancel();
          setSelected(true);
        }
      );
    }
  };

  const handleKeyUpAddTask = (event) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      onClose();
    }
  };

  const handleToolbarKeyDown = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  const takeOpenOnClick = () => {
    setSelected(true);
    selectedProject && selectedProject();
  };

  const handleTitleChange = ({ newValue, mentions }) => {
    setTitle(newValue);
    setMentions(mentions);
  };

  return (
    <Stack direction="row">
      <Box height={18} width={35} mr={0.5} />

      <ClickAwayListener onClickAway={onClose}>
        <Stack width={1}>
          {!selected ? (
            <Stack
              component={Button}
              direction="row"
              spacing={1}
              width={1}
              height={1}
              justifyContent="start"
              alignItems="end"
              pt={1}
              color="grey.500"
              sx={{
                cursor: 'pointer',
                borderBottom: (t) => `1px dashed ${t.palette.divider}`
              }}
              onClick={takeOpenOnClick}
              disabled={!canCreate}
            >
              <Icon icon={plusFill} />
              <Typography fontSize={10} variant="overline">
                Nouvelle {isSub ? 'sous-' : ''}tâche
              </Typography>
            </Stack>
          ) : (
            <Stack
              direction="row"
              px={1}
              pt={0.5}
              pb={0.2}
              mt={1}
              spacing={2}
              alignItems="center"
              sx={{ border: (t) => `1px solid ${t.palette.primary.main}`, borderRadius: 1 }}
              width={1}
            >
              <Icon icon="tabler:subtask" color={baseColors.GREY} height={20} width={20} />
              <Stack width={1}>
                <EditorMentionEnter
                  value={title}
                  placeholder={`Nouvelle ${isSub ? 'sous tâche' : 'tâche'}`}
                  onKeyDown={handleToolbarKeyDown}
                  onKeyUp={handleKeyUpAddTask}
                  onChange={handleTitleChange}
                />
              </Stack>
              <Stack spacing={1} direction="row">
                <Stack justifyContent="center" ref={anchorEl}>
                  {startTime && endTime ? (
                    <Stack justifyContent="center" sx={{ width: 'max-content' }}>
                      <DisplayTime
                        noYear
                        endTime={endTime}
                        startTime={startTime}
                        isSameDays={isSameDays}
                        isSameMonths={isSameMonths}
                        onOpenPicker={onOpenPicker}
                        sx={{ fontSize: 10, color: 'grey.500' }}
                      />
                    </Stack>
                  ) : (
                    <IconButton
                      size="small"
                      onClick={onOpenPicker}
                      sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
                    >
                      <Icon icon={calendarFill} />
                    </IconButton>
                  )}
                </Stack>
                <Stack
                  justifyContent="center"
                  onClick={handleOpen}
                  ref={anchorRef}
                  sx={{ width: 'max-content', cursor: 'pointer' }}
                >
                  {assigne.length ? (
                    <Avatar.Group size="small" maxPopoverPlacement="top" maxCount={1}>
                      {assigne.map((part) => (
                        <Avatar
                          key={part?.id}
                          size="small"
                          srcSet={part?.avatar}
                          style={{ backgroundColor: createAvatar(part?.name).color2 }}
                        >
                          {createAvatar(part?.name).name}
                        </Avatar>
                      ))}
                    </Avatar.Group>
                  ) : (
                    <IconButton
                      onClick={handleOpen}
                      size="small"
                      sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
                    >
                      <Icon icon={personAdd} />
                    </IconButton>
                  )}
                </Stack>

                <IconButton
                  ref={anchorPRef}
                  size="small"
                  onClick={handleOpenP}
                  sx={{ border: (t) => `1px dashed ${t.palette.divider}` }}
                >
                  <Box
                    component={Icon}
                    icon={isEmpty(priority) ? flagOutline : flagFill}
                    color={priorityColor(priority)}
                  />
                </IconButton>

                <Stack justifyContent="center">
                  <Stack
                    py={0.3}
                    px={1}
                    color="white"
                    bgcolor={(t) => t.palette.primary.main}
                    fontSize={10}
                    fontWeight="bold"
                    sx={{ cursor: 'pointer' }}
                    onClick={onClose}
                  >
                    ENREGISTRER
                  </Stack>
                </Stack>

                <IconButton size="small" onClick={onCancel}>
                  <Box component={Icon} icon={closeFill} />
                </IconButton>
              </Stack>
            </Stack>
          )}

          {openPicker && (
            <DateTineRangeSelector
              open={openPicker}
              anchorEl={anchorEl.current}
              onClose={onClosePicker}
              onChange={handleDueDeteChange}
              deb={startTime}
              end={endTime}
            />
          )}
          {open && (
            <MenuPopover
              zIndex={(theme) => theme?.zIndex?.modal + 5}
              width={400}
              sx={{ height: { xs: 1, md: 550, bgcolor: 'transparent', border: 'none' } }}
              open={open}
              onClose={handleClose}
              anchorEl={anchorRef.current}
              arrow="right-center"
            >
              <SelectCollaborates
                title="intervenants"
                field="assignee"
                currents={assigne}
                onClose={handleClose}
                onChange={handeChange}
              />
            </MenuPopover>
          )}
          {openP && (
            <PriorityFlags
              open={openP}
              onClose={handleCloseP}
              onChange={setPriority}
              anchorRef={anchorPRef}
              value={priority}
            />
          )}
        </Stack>
      </ClickAwayListener>
    </Stack>
  );
};

export const TaskListOneRow = ({ row, isFirst, height, elevation = 5, borderRadius = 0 }) => {
  return (
    <Paper elevation={elevation} sx={{ minHeight: height || 35, borderRadius, width: '100%' }}>
      <Stack height={1} width={1} justifyContent="center" alignItems={isFirst ? 'start' : 'center'}>
        {row}
      </Stack>
    </Paper>
  );
};

export const TaskListRowSection = ({
  title,
  textColor,
  icon,
  isHeader,
  col,
  isColName = false,
  isFirst,
  style = null,
  row
}) => {
  const [selected, setSelected] = useState(false);
  const [color, setColor] = useState(col?.color || baseColors?.WARINIG);
  const { updateColumn, deleteCol } = useProjectContext();
  const { open, handleClose, handleOpen } = useToggle();
  const [openH, onOpenH, onCloseH] = useToggleV2();
  const [openD, onOpenD, onCloseD] = useToggleV2();
  const [deleting, setDeleting] = useState(false);
  const [deleteOk, setDeleteOK] = useState(false);

  const colorRef = useRef(null);

  const onClosePicker = () => {
    handleClose();
    if (col?.color !== color) {
      updateColumn(col?.id, col?.name, color);
    }
  };

  const handleDeletion = () => {
    setDeleting(true);
    deleteCol(col?.id);
    setDeleting(false);
  };

  return (
    <Stack width={1}>
      {isHeader && (
        <Stack
          width={1}
          alignItems={isFirst ? 'start' : 'center'}
          sx={{ cursor: 'pointer' }}
          onMouseEnter={onOpenH}
          onMouseLeave={onCloseH}
        >
          <Paper elevation={5} sx={{ ...(!isFirst && { width: '100%', borderRadius: 0 }) }}>
            <Stack
              direction="row"
              width={isColName ? { xs: 150, md: 250, lg: 400 } : 1}
              px={1}
              height={35}
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
              {...(isFirst && { bgcolor: color, borderRadius: '10px 10px 0 0' })}
            >
              {!isFirst && (
                <Stack
                  direction="row"
                  alignItems="center"
                  width={1}
                  justifyContent="space-between"
                  sx={{ cursor: 'pointer' }}
                >
                  <EllipsisText
                    key={title}
                    text={title}
                    style={{
                      fontSize: 15,
                      // color: textColor || 'text.secondary',
                      ...(!isFirst && { fontSize: 10, variant: 'overline', textAlign: 'center' }),
                      ...style,
                      color: 'inherit'
                    }}
                  />
                  {icon && icon}
                </Stack>
              )}

              {isFirst &&
                (!selected ? (
                  <Stack width={0.8} maxWidth={0.8} onClick={() => setSelected(true)}>
                    <EllipsisText
                      key={title}
                      text={title}
                      style={{
                        fontSize: 15,
                        color: 'text.secondary',
                        ...(isFirst && { pt: 0.35, fontWeight: 'bold' }),
                        ...style
                      }}
                    />
                  </Stack>
                ) : (
                  <TaskListEditOperation operation={col} onClose={() => setSelected(false)} />
                ))}

              {isFirst && (
                <Stack direction="row" spacing={1}>
                  {openH && (
                    <IconButton onClick={handleOpen} size="small" sx={{ p: 0.2 }} ref={colorRef}>
                      <Iconify icon={'eva:color-picker-fill'} color="white" />
                    </IconButton>
                  )}
                  {openH && (
                    <IconButton onClick={onOpenD} size="small" sx={{ p: 0.2 }}>
                      <Iconify icon={'eva:trash-fill'} color={baseColors.RED} />
                    </IconButton>
                  )}
                </Stack>
              )}
              {open && (
                <ColorPicker anchor={colorRef.current} open={open} onClose={onClosePicker} onChange={setColor} />
              )}
              {openD && (
                <ActionModal
                  title="Supprimer l'opération ?"
                  desc="Cette action ne peut pas être annulée"
                  color="error"
                  open={openD}
                  onClose={onCloseD}
                  moreAction={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Checkbox
                        size="small"
                        color="error"
                        checked={deleteOk}
                        onChange={(e) => setDeleteOK(e.currentTarget.checked)}
                      />
                      <Typography fontSize={14} fontWeight={120}>
                        Je comprends qu'une fois supprimé, cette opération ne peut pas être récupérée.
                      </Typography>
                    </Stack>
                  }
                  action={
                    <LoadingButton
                      loading={deleting}
                      color="error"
                      variant="contained"
                      disabled={!deleteOk}
                      onClick={handleDeletion}
                    >
                      Supprimer
                    </LoadingButton>
                  }
                />
              )}
            </Stack>
          </Paper>
        </Stack>
      )}

      <Stack width={1}>{!isHeader && <TaskListOneRow isFirst={isFirst} row={row} />}</Stack>
    </Stack>
  );
};

export const TaskLineTitle = ({ task, isSub, parent, width, swichSubTask, openSubTask }) => {
  const theme = useTheme();
  const { onOpen, deleteTask, updateTask } = useProjectContext();
  const { openTaskDetails: v4OpenTaskDetail } = useTaskV4Context();
  const { open, handleClose, handleOpen } = useToggle();
  const [openH, onOpenH, onCloseH] = useToggleV2();
  const [openD, onOpenD, onCloseD] = useToggleV2();
  const [deleting, setDeleting] = useState(false);
  const [deleteOk, setDeleteOK] = useState(false);
  const [isRecording, setRecording] = useState(false);
  const [openM, onMenuOpen, onCloseMenu] = useToggleV2();
  const [openDep, onOpenDep, onCloseDep] = useToggleV2();
  const [visible, display, hide] = useToggleV2();

  const [openBudget, onBudgetOpen, onCloseBudget] = useToggleV2();

  const deps = useMemo(() => {
    return uniqBy(flattenDeep(values(task?.dependences || {}) || []), 'id');
  }, [task?.dependences]);

  const anchorEl = useRef();
  const ref = useRef();

  const rights = getTaskRights(task);

  const handleDeletion = () => {
    setDeleting(true);
    if (isSub) {
      deleteTask(task?.id, parent?.id, task);
    } else {
      deleteTask(task?.id, null, task);
    }
    setDeleting(false);
  };

  const openTaskDetails = () => {
    v4OpenTaskDetail(task);
  };

  const closeHoverState = () => {
    if (!isRecording) {
      onCloseH();
      onCloseMenu();
    }
  };

  const handleToggleVisibility = () => {
    const isPrivate = task?.visibility === TASK_VISIBILITY.PRIVATE;
    if (isPrivate) {
      handleChange({ visibility: TASK_VISIBILITY.PUBLIC });
    } else {
      display();
    }
  };
  const handlePrivate = () => {
    handleChange({ visibility: TASK_VISIBILITY.PRIVATE });
  };

  const handleChange = (change) => {
    updateTask({ ...task, ...change }, task);
  };

  const handleBudgetChange = (budget) => {
    handleChange({ budget });
  };
  return (
    <Stack width={1} position="relative">
      <Stack
        alignItems="center"
        direction="row"
        sx={{ cursor: 'pointer' }}
        width={1}
        onMouseEnter={onOpenH}
        onMouseLeave={closeHoverState}
        justifyContent="space-between"
      >
        {!open ? (
          <Stack direction="row" alignItems="center" spacing={2} width={1}>
            <Stack alignItems="center" direction="row">
              <Stack width={25} sx={{ mr: 1 }}>
                {task?.subTasksCount ? (
                  <IconButton onClick={swichSubTask} size="small" sx={{ px: 0.5, py: 0.4 }}>
                    <Icon icon="eva:arrow-right-fill" style={{ ...(!openSubTask && { transform: 'rotate(90deg)' }) }} />
                  </IconButton>
                ) : null}
              </Stack>
              <Stack mr={1} hidden={task?.visibility !== TASK_VISIBILITY.PRIVATE}>
                <Iconify icon={'bi:shield-lock-fill'} color={theme.palette.warning.main} width={15} height={15} />
              </Stack>

              <Stack
                alignItems="center"
                direction="row"
                spacing={1}
                maxWidth={{ xs: 200, md: width }}
                minWidth={100}
                onClick={openTaskDetails}
              >
                <EllipsisText
                  isHTML
                  key={`task${task?.id}-line-name`}
                  text={showEditorTaskTitleWithMention(task?.name || '', task?.editor_mentions || [], false)}
                  tooltip={showEditorTaskTitleWithMention(task?.name || '', task?.editor_mentions || [], true)}
                  style={{ fontSize: 14, fontWeight: 'bold' }}
                  sx={{ color: (t) => (t.mode === 'light' ? 'grey.800' : 'inherit') }}
                />
              </Stack>
              <Stack direction="row" spacing={1} pl={1}>
                <TaskDependences task={task} type={DEPS_TYPES.LOCK} isList />
                <TaskDependences task={task} type={DEPS_TYPES.PENDING} isList />
                <TaskDependences task={task} type={DEPS_TYPES.TASK} isList />
              </Stack>
            </Stack>
            <VisibilityPrivateModal open={visible} onClose={hide} onAccept={handlePrivate} />

            {openH && (
              <Stack direction="row" spacing={1}>
                {rights.update && (
                  <Stack>
                    <IconButton color="info" onClick={handleOpen} size="small" sx={{ p: 0.3 }}>
                      <Icon icon="clarity:note-edit-line" height={18} width={18} />
                    </IconButton>
                  </Stack>
                )}

                <Stack>
                  <IconButton onClick={onMenuOpen} size="small" ref={anchorEl}>
                    <Icon icon="eva:more-vertical-fill" height={15} />
                  </IconButton>

                  {openM && (
                    <MoreTaskOPtion
                      open={openM}
                      onClose={closeHoverState}
                      rights={rights}
                      anchorEl={anchorEl.current}
                      edit={handleOpen}
                      del={onOpenD}
                      dependence={onOpenDep}
                      task={task}
                      openTaskDetails={openTaskDetails}
                      recordingState={setRecording}
                      onToggleVisibility={handleToggleVisibility}
                      onBudget={onBudgetOpen}
                      handleBudgetChange={handleBudgetChange}
                    />
                  )}

                  {openBudget && (
                    <BudgetModal
                      open
                      secondPos
                      onClose={onCloseBudget}
                      onChange={handleBudgetChange}
                      anchor={anchorEl}
                      amount={task?.budget}
                      projectBudget={{ estimated: 100 }}
                      symbol={'CFA'}
                    />
                  )}
                </Stack>
              </Stack>
            )}
          </Stack>
        ) : (
          <TaskListEditTitle task={task} onClose={handleClose} />
        )}
        <div ref={ref} />
        {!open && (
          <Stack direction="row" height={1} justifyContent="flex-end" spacing={1}>
            {!isEmpty(task?.priority) && <TaskLinePriority task={task} />}

            <Stack height={1} justifyContent="center" width={25}>
              {!isEmpty(task?.attachments) ? (
                <Tooltip
                  title={
                    task?.attachments?.length
                      ? `${task?.attachments?.length} fichier${task?.attachments?.length > 1 ? 's' : ''}`
                      : 'Aucun fichier'
                  }
                >
                  <IconButton onClick={openTaskDetails} size="small" sx={{ height: 25, width: 25, pt: 1.2, pl: 0.7 }}>
                    <Badge count={task?.attachments?.length} size="small" status="success">
                      <Icon icon="ant-design:file-text-filled" height={15} width={15} color={baseColors.GREY} />
                    </Badge>
                  </IconButton>
                </Tooltip>
              ) : null}
            </Stack>
          </Stack>
        )}

        {openD && (
          <ActionModal
            title="Supprimer la tâche ?"
            desc="Cette action ne peut pas être annulée"
            color="error"
            open={openD}
            onClose={onCloseD}
            body={<DependencyRenderListOnDelete card={task} />}
            moreAction={
              <Stack direction="row" spacing={1} alignItems="center">
                <Checkbox
                  size="small"
                  color="error"
                  checked={deleteOk}
                  onChange={(e) => setDeleteOK(e.currentTarget.checked)}
                />
                <Typography fontSize={14} fontWeight={120}>
                  Je comprends qu'une fois supprimé, cette tâche ne peut pas être récupérée.
                </Typography>
              </Stack>
            }
            action={
              <LoadingButton
                loading={deleting}
                color="error"
                variant="contained"
                disabled={!deleteOk}
                onClick={handleDeletion}
              >
                Supprimer
              </LoadingButton>
            }
          />
        )}

        {openDep && <DependencyChoice open={openDep} onClose={onCloseDep} currentTask={task} />}
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        pr={10}
        width={1}
        justifyContent="flex-end"
        position="absolute"
        bottom={-5}
      ></Stack>
    </Stack>
  );
};

export const TaskListVoiceNote = ({ task, recordingState }) => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const voiceRef = useRef();
  const [voiceNoteTime, setVoiceNoteTime] = useState(0);

  const {
    user: { displayName, photoURL, email, id }
  } = useAuth();
  const [uploading, setFUploading] = useState(false);
  const [voiceNote, setVoiceNote] = useState(null);
  const [voiceNoteBlob, setVoiceNoteBlob] = useState(null);
  const [openVoiceNote, setOpenVoiceNote] = useState(null);
  const { isRecording, recordingTime, startRecording, stopRecording } = useAudioRecorder({
    onNoteDone: setVoiceNote,
    getBlob: setVoiceNoteBlob
  });

  useEffect(() => {
    if (voiceNote) {
      return setOpenVoiceNote(true);
    }
    setOpenVoiceNote(false);
  }, [voiceNote]);

  const onRecordingClick = () => {
    if (isRecording) {
      setVoiceNoteTime(recordingTime);
      stopRecording();

      return;
    }
    startRecording();
    recordingState(true);
  };

  const onCloseVoiceNote = () => {
    setOpenVoiceNote(false);
    recordingState(false);
  };

  const onSendVoiceNote = () => {
    onCloseVoiceNote();

    const docId = nanoid();
    const onAudioSave = (_file) => {
      setFUploading(false);

      const commentObject = {
        userId: id,
        avatar: photoURL,
        name: displayName,
        email,
        messageType: 'audio',
        createdAt: serverTime(),
        message: _file?.url,
        read: 0,
        readState: []
      };

      dispatch(
        addCommentToTask({
          taskId: task?.id,
          generateId: docId,
          commentBbject: commentObject,
          taskName: task?.name,
          canAccess: [...(task?.canAccess || []), ...(task?.managers || [])],
          callback: () => {
            enqueueSnackbar('Note vocale ajoutée aux commentaires', { variant: 'success' });
            setVoiceNote(null);
            setVoiceNoteBlob(null);
          }
        })
      );
    };
    const fileName = `Note vocale ${numGenerate()}`;
    const file = new File([voiceNoteBlob], fileName, { type: 'audio/webm' });
    saveFile(file, onAudioSave, null, setFUploading, docId);
  };

  const cancelVoiceNote = () => {
    onCloseVoiceNote();
  };

  return (
    <Stack direction="row-reverse" alignItems="center">
      {recordingTime && (
        <Label color="info" style={{ height: 17 }}>
          <Typography fontSize={12}> {timerDisplay(recordingTime)} </Typography>
        </Label>
      )}

      <Stack width={1} ref={voiceRef}>
        <MenuLine
          title="Note vocale"
          icon={isRecording ? 'eva:mic-fill' : 'eva:mic-off-fill'}
          {...(isRecording && { color: 'red' })}
          width={17}
          onClick={onRecordingClick}
        />
      </Stack>
      {openVoiceNote && (
        <MenuPopover
          open={openVoiceNote}
          disabledArrow
          onClose={cancelVoiceNote}
          anchorEl={voiceRef.current}
          horizontal="right"
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          <MusicPlayerSlider song={voiceNote} duration={voiceNoteTime} />
          <Stack direction="row" justifyContent="space-between" sx={{ p: 1 }}>
            <Button
              color="inherit"
              size="small"
              sx={{ bgcolor: (t) => alpha(t.palette.secondary.main, t.palette.action.hoverOpacity) }}
              onClick={cancelVoiceNote}
            >
              Annuler
            </Button>
            <Button
              color="success"
              size="small"
              sx={{ bgcolor: (t) => alpha(t.palette.success.main, t.palette.action.hoverOpacity) }}
              onClick={onSendVoiceNote}
            >
              Envoyer
            </Button>
          </Stack>
        </MenuPopover>
      )}
      {uploading && <UploadingOneFileProgress uploading={uploading} />}
    </Stack>
  );
};

export const TaskListAssigneView = ({ task }) => {
  const anchorRef = useRef(null);
  const { open, handleClose, handleOpen } = useToggle();
  const { updateTask } = useProjectContext();
  const rights = getTaskRights(task);

  const handeChange = (_, value) => {
    handleClose();
    const val = value.map((newValue) => {
      return {
        id: newValue.id,
        name: newValue?.displayName || newValue?.name || null,
        email: newValue.email,
        avatar: newValue?.photoURL || newValue?.photoUrl || newValue?.avatar || null
      };
    });

    if (rights.update) updateTask({ ...task, assignee: val }, task);
  };

  return (
    <Stack direction="row" ref={anchorRef} sx={{ cursor: 'pointer' }}>
      <Stack onClick={handleOpen}>
        <Avatar.Group maxCount={6} size="small">
          {task?.assignee?.length ? (
            task?.assignee?.map((_one, idx) => (
              <Tooltip key={_one?.id + idx} title={_one?.displayName || _one?.name} arrow>
                <Avatar
                  src={_one?.avatar || _one?.photoURL || _one?.photoUrl || null}
                  alt={_one?.displayName}
                  style={{
                    backgroundColor: createAvatar(_one?.displayName || _one?.name).color2
                  }}
                >
                  {createAvatar(_one?.displayName || _one?.name).name}
                </Avatar>
              </Tooltip>
            ))
          ) : (
            <IconButton size="small" sx={{ border: (t) => `1px dashed ${t.palette.divider}`, p: 0.2 }}>
              <Icon icon={personAdd} height={17} />
            </IconButton>
          )}
        </Avatar.Group>
      </Stack>
      <UserPicker
        anchorEl={anchorRef}
        open={open}
        onClose={handleClose}
        onChange={handeChange}
        users={task?.assignee}
        restrictToProjectFromTask={task}
      />
    </Stack>
  );
};

export const TaskListResponsableView = ({ task }) => {
  const anchorRef = useRef(null);
  const { open, handleClose, handleOpen } = useToggle();
  const { updateTask } = useProjectContext();
  const rights = getTaskRights(task);

  const handeChange = (_, value) => {
    handleClose();

    const val = value.map((newValue) => {
      return {
        id: newValue.id,
        name: newValue?.displayName || newValue?.name || null,
        email: newValue.email,
        avatar: newValue?.photoURL || newValue?.photoUrl || newValue?.avatar || null
      };
    });

    if (rights.update) updateTask({ ...task, responsable: val.at(0) || null }, task);
  };

  return (
    <Stack direction="row" ref={anchorRef} sx={{ cursor: 'pointer' }}>
      <Stack onClick={handleOpen}>
        {task?.responsable ? (
          <Tooltip title={task?.responsable?.name} arrow>
            <Avatar
              src={task?.responsable?.avatar}
              alt={task?.responsable?.name}
              size="small"
              style={{
                backgroundColor: createAvatar(task?.responsable?.name).color2
              }}
            >
              {createAvatar(task?.responsable?.name).name}
            </Avatar>
          </Tooltip>
        ) : (
          <IconButton size="small" sx={{ border: (t) => `1px dashed ${t.palette.divider}`, p: 0.2 }}>
            <Icon icon={personAdd} height={17} />
          </IconButton>
        )}
      </Stack>

      <UserPicker
        anchorEl={anchorRef}
        open={open}
        onClose={handleClose}
        onChange={handeChange}
        title={'superviseur'}
        field={'responsable'}
        preTitle="Ajouter un"
        users={task?.responsable ? [task?.responsable] : []}
        single
        restrictToProjectFromTask={task}
      />
    </Stack>
  );
};

export const TaskListEcheanceView = ({ row }) => {
  const theme = useTheme();
  const anchorEl = useRef();
  const { updateTask } = useProjectContext();
  const [dueChange, setDueChange] = useState(row?.due);
  const rights = getTaskRights(row);

  const { validateNewDue, ...params } = useTaskDueDateGuard(row, updateTask);

  useEffect(() => setDueChange(row?.due), [row?.due]);

  const { dueDate, endTime, startTime, onChangeDueDate, openPicker, onOpenPicker, onClosePicker } = useDatePicker({
    date: dateConverter(row?.due)
  });

  const onClose = (val) => {
    let newDue = dueDate;
    if (val) {
      newDue = val;
    }
    onClosePicker();
    if (!isEqual(newDue, dueChange) && rights.update && validateNewDue(newDue)) {
      updateTask({ ...row, due: newDue }, row);
    }
  };

  const onDueChange = (val) => {
    onChangeDueDate(val);
  };

  return (
    <Stack>
      <TaskDueDateGuardPrompt {...params} />

      <Stack ref={anchorEl} onClick={onOpenPicker} sx={{ cursor: 'pointer' }}>
        {endTime ? (
          isTaskAccepted(row) || row?.state === TASK_STATE_VALIDATION.DONE ? (
            <Typography
              color={baseColors.GREEN}
              style={{
                fontWeight: 'bold',
                fontSize: 11
              }}
            >
              {' '}
              {format(gDate(endTime), 'dd MMM yyyy', { locale: LocalFr })}{' '}
            </Typography>
          ) : gDate(endTime) > new Date() && diffDaysWithToDay(endTime) <= 2 ? (
            <Tooltip title={format(gDate(endTime), 'dd MMM yyyy', { locale: LocalFr })}>
              <Typography color={baseColors.RED} style={{ fontWeight: 'bold', fontSize: 11 }}>
                - {diffDaysWithToHours(endTime)} heures
              </Typography>
            </Tooltip>
          ) : gDate(endTime) < new Date() ? (
            <Tooltip title="En retard">
              <Typography color={baseColors.RED} style={{ fontWeight: 'bold', fontSize: 11 }}>
                {format(gDate(endTime), 'dd MMM yyyy', { locale: LocalFr })}
              </Typography>
            </Tooltip>
          ) : (
            <Typography
              sx={{ color: (t) => (t.mode === 'light' ? baseColors.BLACK : 'inherit') }}
              style={{ fontWeight: 'bold', fontSize: 11 }}
            >
              {format(gDate(endTime), 'dd MMM yyyy', { locale: LocalFr })}
            </Typography>
          )
        ) : (
          <IconButton size="small" color="primary">
            <Icon icon={calendarFill} height={18} color={baseColors.GREY} />
          </IconButton>
        )}
      </Stack>

      {openPicker && (
        <DateTineRangeSelector
          open={openPicker}
          anchorEl={anchorEl.current}
          onClose={onClose}
          onChange={onDueChange}
          deb={startTime}
          end={endTime}
        />
      )}
    </Stack>
  );
};

export const TaskLineStateView = ({ row }) => {
  const {
    user: { id }
  } = useAuth();
  const { open, handleClose, handleOpen } = useToggle();
  const { updateTask, currentProject } = useProjectContext();

  const handleValidateTask = (_state) => {
    if (_state === TASK_STATE_VALIDATION.ACCEPTED) {
      updateTask({ ...row, state: _state, completed: true }, row);
      return;
    }
    if (_state === TASK_STATE_VALIDATION.DONE) {
      handleOpen();
      return;
    }

    updateTask({ ...row, state: _state, completed: false }, row);
  };

  const closeManage = (newValues, autoValidate) => {
    handleClose();

    if (newValues?.length) {
      updateTask({ ...row, managers: newValues, state: TASK_STATE_VALIDATION.DONE, completed: false }, row);
      return;
    }
    if (autoValidate) {
      updateTask({ ...row, managers: [], state: TASK_STATE_VALIDATION.DONE, completed: false }, row);
    }
  };

  return (
    <Stack justifyContent="center" alignItems="center">
      <KanbanTaskCardValidation
        admin={row?.createdBy}
        card={row}
        userId={id}
        managers={row?.managers}
        state={row?.state}
        intervenants={row?.assignee}
        taskId={row?.id}
        taskName={row?.name}
        handleChangeState={handleValidateTask}
      />

      {open && (
        <KanbanTaskManager
          users={[]}
          managers={row?.responsable ? [row?.responsable] : currentProject?.managers || []}
          open={open}
          onClose={closeManage}
        />
      )}
    </Stack>
  );
};

export const TaskLinePriority = ({ task }) => {
  const [openP, onOpenP, onCloseP] = useToggleV2();
  const priorityRef = useRef();
  const [priority, setPriority] = useState(task?.priority);
  const { updateTask } = useProjectContext();

  const handleClose = (val) => {
    onCloseP();
    updateTask({ ...task, priority: val }, task);
  };

  return (
    <>
      {!isEmpty(priority) ? (
        <Stack width={1} justifyContent="start">
          <Stack width={35}>
            <Tooltip title={priority}>
              <IconButton ref={priorityRef} onClick={onOpenP} size="small" sx={{ ml: 1, p: 0.65 }}>
                <Icon icon="eva:flag-fill" color={priorityColor(priority)} height={15} width={15} />
              </IconButton>
            </Tooltip>
          </Stack>
        </Stack>
      ) : (
        <Stack ref={priorityRef}>
          <MenuLine icon="eva:flag-outline" onClick={onOpenP} title="Priorité" />
        </Stack>
      )}
      {openP && (
        <PriorityFlags
          value={priority}
          open={openP}
          onClose={handleClose}
          onChange={setPriority}
          anchorRef={priorityRef}
        />
      )}
    </>
  );
};

export const TaskListEditTitle = ({ task, onClose }) => {
  const [title, setTitle] = useState(task?.name);
  const [mentions, setMentions] = useState(task?.editor_mentions || []);
  const { updateTask } = useProjectContext();

  const handleClose = () => {
    onClose();
    if (!isEmpty(title) && title !== task?.name) {
      updateTask({ ...task, name: title, mentions: mentions }, task);
    }
  };

  const handleKeyDown = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  const onKeyUp = (event) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      if (event.target.value) handleClose();
    }
  };

  const handleChangeTitle = ({ newValue, mentions }) => {
    setTitle(newValue);
    setMentions(mentions);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Stack width={1}>
        <EditorMentionEnter value={title} onKeyDown={handleKeyDown} onKeyUp={onKeyUp} onChange={handleChangeTitle} />
      </Stack>
    </ClickAwayListener>
  );
};

export const TaskListEditOperation = ({ operation, onClose }) => {
  const [title, setTitle] = useState(operation?.name);
  const { updateColumn } = useProjectContext();

  const handleClose = () => {
    onClose();
    if (!isEmpty(title) && title !== operation?.name) {
      updateColumn(operation?.id, title, operation?.color);
    }
  };

  const handleKeyDown = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  const onKeyUp = (event) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      if (event.target.value) handleClose();
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <InputBase
        fullWidth
        autoFocus
        size="small"
        value={title}
        onKeyDown={handleKeyDown}
        onKeyUp={onKeyUp}
        onChange={(e) => setTitle(e.currentTarget.value)}
        sx={{
          color: 'white',
          fontSize: 16
        }}
      />
    </ClickAwayListener>
  );
};

export const TaskDependences = ({ task, type, size, isList = false }) => {
  const [open, onOpen, onClose] = useToggleV2();

  return (
    <>
      {task?.dependences && task?.dependences[type]?.length ? (
        <MTooltip
          title={`Dépendance ${type === DEPS_TYPES.PENDING ? "d'attente" : 'de ' + type.toString().toLowerCase()}`}
          arrow
          placement="top"
        >
          <Stack direction="row" alignItems="center" spacing={0.5} onClick={onOpen} sx={{ cursor: 'pointer' }}>
            <CIconButton size="small" sx={{ borderRadius: 0.5 }}>
              <Icon
                icon={type === DEPS_TYPES.TASK ? 'bi:link-45deg' : 'teenyicons:stop-circle-solid'}
                height={size?.icon || 10}
                color={
                  type === DEPS_TYPES.PENDING
                    ? baseColors.WARINIG
                    : type === DEPS_TYPES.LOCK
                    ? baseColors.RED
                    : baseColors.GREEN
                }
              />
            </CIconButton>
            {!isList && (
              <Typography
                fontSize={size?.text || 7}
                fontWeight="bold"
                color={
                  type === DEPS_TYPES.PENDING
                    ? baseColors.WARINIG
                    : type === DEPS_TYPES.LOCK
                    ? baseColors.RED
                    : baseColors.GREEN
                }
              >
                {task?.dependences[type]?.length} {type}
              </Typography>
            )}
          </Stack>
        </MTooltip>
      ) : null}
      {open && <DependencyChoice open={open} onClose={onClose} currentTask={task} />}
    </>
  );
};
