
/**
 * 
 * @description require array with object with id property
 * @param {[]} oldest 
 * @param {[]} current 
 * @returns {[]} new elements
 */
export const newOnArray = (oldest, current) => {
    const newest= [];

    current.forEach((c) => {
        const exist = oldest.find(old => old.id === c.id);
        if(!exist){
            newest.push(c);
        }
    });

    return newest;
}

/**
 * 
 * @description require array with object with id property
 * @param {[]} oldest 
 * @param {[]} current 
 * @returns {[]} new elements
 */
export const newOnArrayWithEmail = (oldest, current) => {
    const newest= [];

    current?.forEach((c) => {
        const exist = oldest?.find(old => old?.email === c?.email);
        if(!exist){
            newest?.push(c);
        }
    });

    return newest;
}