export function unixToDateString(unix) {
  if (unix) {
    const date = new Date(unix * 1000);
    return `${date.getDate()} / ${date.getMonth() + 1} / ${date.getFullYear()}`;
  } else {
    return '';
  }
}

export function unixReverseToDateString(unix) {
  const date = new Date(unix * 1000);
  return `${date.getFullYear()} / ${date.getMonth() + 1} / ${date.getDate()}`;
}

export function toDateTime(secs) {
  var t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
}
