import { filter, indexOf } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import firestore from '../../utils/firestore';
// import { stageCreationNotification } from './notifications';

const initialState = {
    isLoading: false,
    error: false,
    stages: [],
    notifications: null
};

const slice = createSlice({
    name:'stage',
    initialState,
    reducers:{
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },
  
      // HAS ERROR
      hasError(state, action) {
        state.isLoading = false;
        state.error = action.payload;
      },

      //AUDIENCE ADD AND UPDATE SUCCESS
      addSuccess(state, action) {
        state.isLoading = false;
        state.stages.push(action.payload);
      },

      updateSuccess(state, action) {
        state.isLoading = false;
        const toUpdate = indexOf(state.stages, (stage) => stage.id === action.payload);
        let newStage = [...state.stages];
        newStage.splice(toUpdate, 1, action.payload);
        state.stages = newStage;
      },

      //GET AUDIENCE SUCCESS
      getStageSuccess(state, action) {
        state.isLoading = false;
        state.stages = action.payload;
      },

      deleteStageSuccess(state, action) {
        state.loading = false;
        state.stages = filter(state.stages, (stages) => stages.id !== action.payload);
    }
    }
});

export default slice.reducer;

export function getStageList(stages){
    return async (dispatch) =>{
        try {
            dispatch(slice.actions.startLoading()); 
            dispatch(slice.actions.getStageSuccess(stages));
        } catch (error) {
            dispatch(slice.actions.hasError(error));   
        }
    }
}

export function createStage(stage, callback=null){
    return async(dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const doc = await firestore.collection('stages').add(stage);

            dispatch(slice.actions.addSuccess({ id: doc.id, ...stage }));

            if(callback)  callback(doc.id);

            // dispatch(stageCreationNotification({data:stage}))

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function updateStage(stage, callback=null) {
    return async(dispatch) => {
        try {
            dispatch(slice.actions.startLoading());    

            const {id, ...rest} = stage;

            await firestore.collection('stages').doc(id).set({...rest},{merge: true});

            dispatch(slice.actions.updateSuccess({id: id, ...stage }));

            if(callback) return callback(id);

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}


export function deleteStage(id, callback=null) {
    return async(dispatch) =>{
        try {
            dispatch(slice.actions.startLoading());
            await firestore.collection('stages').doc(id).delete();
            dispatch(slice.actions.deleteStageSuccess(id));

            if(callback) return callback();
        } catch (error) {
            dispatch(slice.actions.hasError(error)); 
        }   
    }
}