import React , { useState, useRef } from 'react';
import { isFunction } from 'lodash';
import { AvatarGroup, Box, Button, Dialog, DialogContent, Paper, Stack, Tooltip, Typography } from '@mui/material';
import useToggle from 'src/hooks/useToggle';
import { MAvatar } from 'src/components/@material-extend';
import createAvatar from 'src/utils/createAvatar';
import MenuPopover from 'src/components/MenuPopover';
import { SelectCollaborates } from '../project/ProjectCardOPtion';


const Relance = ({show, onClose, onRelanceValidate}) => {
    const anchorRef = useRef(null);
    const [sendTo, setSendTo] = useState([]);
    const { open, handleClose, handleOpen } = useToggle();


    const handleCanClose = (_, raison) =>{
        if(raison !== 'backdropClick'){
            isFunction(onClose) && onClose();
            setSendTo([]);
        }
    }

    const handleAssigne = (_, _value) => {
        const val = _value.map((newValue) => {
            return {
                id: newValue.id,
                name: newValue?.displayName || newValue?.name || null,
                email: newValue.email,
                avatar: newValue?.photoURL || newValue?.photoUrl || newValue?.avatar || null
            }
        });

        setSendTo(val);
    }


    const handleSendRelance = () =>{
        isFunction(onRelanceValidate) && onRelanceValidate(sendTo);
        handleCanClose(null, 'close');
    }

    return ( 
        <Dialog fullWidth maxWidth="sm" open={show} onClose={handleCanClose}>
            <Box pl={3} sx={{position:'relative',  minHeight:50}}>
                <Paper
                     elevation={4}
                     square
                     sx={{
                         left:20,
                         minHeight:40,
                         minWidth:100,
                         position:'absolute',
                         transform: 'skewX(25deg)',
                         borderRadius:'0 0 10px 10px',
                         bgcolor:(t)=> t.palette.primary.main
                     }}
                ></Paper>
                <Paper
                    elevation={4}
                    square
                    sx={{
                        pt:1,
                        left:40,
                        minHeight:40, 
                        minWidth:200,
                        position:'absolute',
                        transform: 'skewX(-25deg)',
                        borderRadius:'0 0 10px 10px',
                        bgcolor:(t)=> t.palette.primary.main
                    }}
                >
                    <Typography align='center'fontSize={18} fontWeight='bold' color="white" sx={{transform: 'skewX(25deg)', ml:-3}}> Relance </Typography>
                </Paper>
            </Box>
            <DialogContent>
                <Stack mb={2} direction="row" justifyContent="space-between">
                    <Typography fontWeight='bold'>Envoyer à  </Typography>
                    <Button color="primary" onClick={handleOpen} ref={anchorRef} >Ajouter / Retirer</Button>
                </Stack>

                {sendTo.length ? (
                    <AvatarGroup max={12} spacing="medium" sx={{justifyContent: 'start'}}>
                        {sendTo.map((_one, idx) =>(
                            <Tooltip key={_one?.id} title={_one.name}>
                                <MAvatar 
                                    key={_one?.id+idx}
                                    aria-label='recipe-relance'
                                    src={_one?.avatar}
                                    alt={_one?.name}
                                    color={createAvatar(_one?.name).color}
                                >
                                    {createAvatar(_one?.name).name}
                                </MAvatar>
                            </Tooltip>
                        ))}
                    </AvatarGroup>
                    ) 
                    : <Typography color="ActiveCaption" align="center"> Ajouter des destinataires </Typography>
                }

                <Stack mt={3} direction="row" width={1} spacing={3} justifyContent="flex-end" > 
                    <Button color="primary" variant="outlined" onClick={handleCanClose}>Annuler</Button>
                    <Button color="primary" variant="contained" onClick={handleSendRelance}>Envoyer</Button>
                </Stack>

            </DialogContent>
           {open &&
            <MenuPopover disabledArrow width={400} sx={{ height: {xs: 1, md: 550} }} 
                         anchorEl={anchorRef.current} open={open} onClose={handleClose}>
                <SelectCollaborates title="Envoyer à" field='assignee' currents={sendTo}
                    onClose={handleClose} onChange={handleAssigne}/>
            </MenuPopover>
            }
        </Dialog> 
    );
}
 
export default Relance;