import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import { useToggleV2 } from 'src/hooks/useToggle';
import { Icon } from '@iconify/react';
import KanbanDetailsActions from './KanbanDetailsActions';
import Scrollbar from 'src/components/Scrollbar';

export default function KanbanTaskActivity({ id }) {
  const [open, _, __, handleSwith] = useToggleV2();

  return (
    <Accordion expanded={open} onChange={handleSwith}>
      <AccordionSummary expandIcon={<Icon icon="ic:round-expand-more" />}>
        <Typography variant="overline"> Activités </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p:0,px:-20}}>
        <Stack height="70vmin"  >
          {/*<Scrollbar>*/}
            <KanbanDetailsActions id={id} />
          {/*</Scrollbar>*/}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
