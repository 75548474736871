import { trim, orderBy, filter } from 'lodash';
import { useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { fr as LocalFr } from 'date-fns/locale';
import peopleFill from '@iconify/icons-eva/people-fill';
import { format, isSameDay, isSameMonth } from 'date-fns';
import calendarFill from '@iconify/icons-eva/calendar-fill';
// material
import {
  Box,
  Paper,
  Stack,
  Button,
  Tooltip,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
  CircularProgress,
  ClickAwayListener
} from '@mui/material';
import { MobileDateRangePicker } from '@mui/lab';
import { alpha } from '@mui/material/styles';
//
import MenuPopover from '../../../components/MenuPopover';
import { MIconButton } from '../../../components/@material-extend';
import useToggle from 'src/hooks/useToggle';
import MAvatar from '../../../components/@material-extend/MAvatar';
import createAvatar from 'src/utils/createAvatar';
import { multipleFilesSave, SaveTaskDocs } from 'src/redux/slices/document';
import { useDispatch } from 'src/redux/store';
import Dropzone from 'react-dropzone';
import useAuth from 'src/hooks/useAuth';
import usePersons from '../../../hooks/usePersons';
import { nameFilter } from 'src/utils/nameFilter';
import { TASK_STATE_VALIDATION } from 'src/constants';

// ----------------------------------------------------------------------

export const defaultTask = {
  name: '',
  completed: false,
  attachments: [],
  comments: [],
  description: '',
  due: [null, null],
  observers: [],
  responsable: null,
  progress: 0,
  rappel: null,
  rappels: [],
  deadLine: null,
  mamangers: [],
  assignee: [],
  assigneIds: [],
  assigneByIds: [],
  priority: '',
  cardIds: [],
  state: TASK_STATE_VALIDATION.PENDING
};

export function useDatePicker({ date }) {
  const [dueDate, setDueDate] = useState([date[0], date[1]]);
  const [openPicker, setOpenPicker] = useState(false);

  const startTime = dueDate[0];
  const endTime = dueDate[1];

  const isSameDays = isSameDay(new Date(startTime), new Date(endTime));
  const isSameMonths = isSameMonth(new Date(startTime), new Date(endTime));

  const handleChangeDueDate = (newValue) => {
    setDueDate(newValue);
    console.log({ newValue });
  };

  const handleOpenPicker = () => {
    setOpenPicker(true);
  };

  const handleClosePicker = () => {
    setOpenPicker(false);
  };

  return {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate: handleChangeDueDate,
    openPicker,
    onOpenPicker: handleOpenPicker,
    onClosePicker: handleClosePicker
  };
}

DisplayTime.propTypes = {
  isSameDays: PropTypes.bool,
  isSameMonths: PropTypes.bool,
  onOpenPicker: PropTypes.func,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  sx: PropTypes.object
};

export function DisplayTime({ startTime, endTime, isSameDays, isSameMonths, onOpenPicker, noYear = false, sx }) {
  const style = {
    typography: 'caption',
    cursor: 'pointer',
    '&:hover': { opacity: 0.72 }
  };

  if (isSameMonths) {
    return (
      <Box onClick={onOpenPicker} sx={{ ...style, ...sx }}>
        {isSameDays
          ? format(new Date(endTime), 'dd MMM', { locale: LocalFr })
          : `${format(new Date(startTime), 'dd', { locale: LocalFr })} - ${format(
              new Date(endTime),
              noYear ? 'dd MMM' : 'dd MMM yyyy',
              { locale: LocalFr }
            )}`}
      </Box>
    );
  }
  return (
    <Box onClick={onOpenPicker} sx={{ ...style, ...sx }}>
      {format(new Date(startTime), 'dd MMM', { locale: LocalFr })} -{' '}
      {format(new Date(endTime), noYear ? 'dd MMM' : 'dd MMM yyyy', { locale: LocalFr })}
    </Box>
  );
}

KanbanTaskAdd.propTypes = {
  onAddTask: PropTypes.func,
  onCloseAddTask: PropTypes.func
};

export default function KanbanTaskAdd({ onAddTask, onCloseAddTask, projectCanAccess }) {
  const [name, setName] = useState('');
  const [completed, setCompleted] = useState(false);
  const anchorRef = useRef(null);
  const [assignee, setAssigne] = useState([]);
  const [attachements, setAttachements] = useState([]);
  const { open: openAff, handleOpen: handleOpenAff, handleClose: handleCloseAff } = useToggle();

  const {
    user: { email, id, displayName, role }
  } = useAuth();
  const { persons, loading: userLoading } = usePersons({ email: email });

  const [popoverSearch, setPopoverSearch] = useState('');

  const dispatch = useDispatch();
  const [isUploading, setUploading] = useState(false);

  const {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker
  } = useDatePicker({
    date: [null, null]
  });

  const users = useMemo(() => {
    if (role?.isGuest) {
      return filter(projectCanAccess || [], (_user) => persons.find((_pers) => _pers.email === _user.email));
    }
    return persons;
  }, [persons, role, projectCanAccess]);

  const handleKeyUpAddTask = (event) => {
    if ((event.key === 'Enter' || event.keyCode === 13) && !event.shiftKey) {
      if (trim(name) !== '') {
        onAddTask({ ...defaultTask, name, due: dueDate, completed, assignee });
        return setName('');
      }
      onCloseAddTask();
    }
  };

  const handleToolbarKeyDown = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  const handleClickAddTask = () => {
    if (name) {
      setUploading(true);
      const handleFiles = (newfiles) => {
        const user_to_files = newfiles.map((_file) => {
          return {
            ..._file,
            email,
            userId: id,
            displayName,
            createAt: new Date()
          };
        });
        onAddTask({ ...defaultTask, name, due: dueDate, completed, assignee, attachments: [...user_to_files] });
        dispatch(SaveTaskDocs(newfiles));
        setUploading(false);
        onCloseAddTask();
        setAttachements([]);
        setAssigne([]);
        setCompleted(false);
        onChangeDueDate([null, null]);
        return setName('');
      };
      multipleFilesSave(attachements, handleFiles);
    } else {
      onCloseAddTask();
    }
  };

  const handleAssigne = (selected, newValue) => {
    const exist = selected.find((u) => u.id === newValue.id);
    if (!exist) {
      setAssigne([
        ...selected,
        {
          id: newValue.id,
          name: newValue.displayName,
          email: newValue.email,
          avatar: newValue.photoURL
        }
      ]);
      return;
    }
    const rest = selected.filter((u) => u.id !== newValue.id);
    setAssigne(rest);
  };

  const onFileDrop = (acceptedFiles) => {
    setName((n) => (n.trim() !== '' ? n : acceptedFiles[0].name));

    if (acceptedFiles[0].type.includes('image')) {
      setAttachements(() => {
        acceptedFiles[0].url = URL.createObjectURL(acceptedFiles[0]);
        return acceptedFiles;
      });
    }
  };

  const popoverFilterFunction = (users) => {
    const sort_user = orderBy(users, 'displayName');
    return nameFilter(sort_user, 'displayName', popoverSearch);
  };

  // const onListenAway = () =>{
  //   onCloseAddTask();
  //   setAttachements([]);
  //   setAssigne([]);
  //   setCompleted(false)
  //   onChangeDueDate([null, null])
  //   return setName('');
  // }

  return (
    <>
      <Dropzone onDrop={(acceptedFiles) => onFileDrop(acceptedFiles)}>
        {({ getRootProps }) => (
          <Box {...getRootProps()}>
            <Stack spacing={2}>
              <ClickAwayListener onClickAway={handleClickAddTask}>
                <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
                  {attachements.length > 0 && attachements[0].type.includes('image') && (
                    <Box
                      sx={{
                        pt: '60%',
                        borderRadius: 1,
                        overflow: 'hidden',
                        position: 'relative',
                        transition: (theme) =>
                          theme.transitions.create('opacity', {
                            duration: theme.transitions.duration.shortest
                          }),
                        ...(completed && {
                          opacity: 0.48
                        })
                      }}
                    >
                      <Box
                        component="img"
                        src={attachements[0].url}
                        sx={{ position: 'absolute', top: 0, width: 1, height: 1 }}
                      />
                    </Box>
                  )}

                  <OutlinedInput
                    multiline
                    size="small"
                    placeholder="Titre"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                    onKeyUp={handleKeyUpAddTask}
                    onKeyDown={handleToolbarKeyDown}
                    sx={{ '& input': { p: 0 }, '& fieldset': { borderColor: 'transparent !important' } }}
                  />

                  <Stack direction="row" justifyContent="space-between">
                    <Stack direction="row" spacing={1}>
                      <Stack direction="row" alignItems="center" ref={anchorRef} onClick={handleOpenAff}>
                        {assignee.length > 0 ? (
                          assignee.slice(0, 3).map((pers, idx) => (
                            <Tooltip key={idx} title={pers.name}>
                              <MAvatar
                                src={pers.avatar}
                                alt={pers.name}
                                color={pers.avatar ? 'default' : createAvatar(pers.name).color}
                                sx={{ height: 25, width: 25, fontSize: 13 }}
                              >
                                {createAvatar(pers.name).name}
                              </MAvatar>
                            </Tooltip>
                          ))
                        ) : (
                          <Tooltip title="Assigner">
                            <MIconButton size="small">
                              <Icon icon={peopleFill} width={20} height={20} />
                            </MIconButton>
                          </Tooltip>
                        )}
                        {assignee.length > 3 && '...'}
                      </Stack>
                      <MenuPopover
                        width="max-content"
                        open={openAff}
                        onClose={handleCloseAff}
                        anchorEl={anchorRef.current}
                      >
                        <TextField
                          fullWidth
                          label=" "
                          size="small"
                          value={popoverSearch}
                          onChange={(e) => setPopoverSearch(e.currentTarget.value)}
                        />
                        <Box className="scroll-smooth-small" sx={{ maxHeight: 170, overflowY: 'auto' }}>
                          {userLoading ? (
                            <CircularProgress size={20} />
                          ) : (
                            users &&
                            popoverFilterFunction(users).map((user) => {
                              const exist = assignee.find((ob) => ob.email === user.email);
                              return (
                                <MenuItem
                                  key={user.id}
                                  sx={{
                                    py: 0.75,
                                    px: 1.5,
                                    bgcolor: (theme) => exist && alpha(theme.palette.primary.main, 0.5)
                                  }}
                                  onClick={() => handleAssigne(assignee, user)}
                                >
                                  <MAvatar
                                    sx={{
                                      width: (theme) => theme.spacing(3),
                                      height: (theme) => theme.spacing(3),
                                      fontSize: 10
                                    }}
                                    src={user?.photoURL}
                                    alt={user?.displayName}
                                    color={user?.photoURL ? 'default' : createAvatar(user.displayName).color}
                                  >
                                    {createAvatar(user?.displayName).name}
                                  </MAvatar>
                                  <Box sx={{ m: 1 }} />
                                  <Typography variant="body2"> {user?.displayName} </Typography>
                                </MenuItem>
                              );
                            })
                          )}
                        </Box>
                      </MenuPopover>

                      <Stack direction="row" alignItems="center">
                        {startTime && endTime ? (
                          <DisplayTime
                            startTime={startTime}
                            endTime={endTime}
                            isSameDays={isSameDays}
                            isSameMonths={isSameMonths}
                            onOpenPicker={onOpenPicker}
                          />
                        ) : (
                          <Tooltip title="Date de réalisation">
                            <MIconButton size="small" onClick={onOpenPicker}>
                              <Icon icon={calendarFill} width={20} height={20} />
                            </MIconButton>
                          </Tooltip>
                        )}

                        <MobileDateRangePicker
                          toolbarTitle="Sélectionnez la plage de date"
                          startText="Début"
                          inputFormat="dd/MM/yyyy"
                          endText="Fin"
                          todayText="Aujourd'hui"
                          open={openPicker}
                          onClose={onClosePicker}
                          onOpen={onOpenPicker}
                          value={dueDate}
                          onChange={onChangeDueDate}
                          renderInput={() => {}}
                        />
                      </Stack>
                    </Stack>
                  </Stack>

                  {isUploading && (
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        top: 0,
                        left: 0,
                        right: 0,
                        bgcolor: alpha('#FFFFFF', 0.6),
                        zIndex: (theme) => theme.zIndex.drawer + 1
                      }}
                    >
                      <CircularProgress size={30} />
                    </Stack>
                  )}
                </Paper>
              </ClickAwayListener>
              <Button variant="contained" color="info" onClick={handleClickAddTask}>
                Enregistrer
              </Button>
            </Stack>
          </Box>
        )}
      </Dropzone>
    </>
  );
}
