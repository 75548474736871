import { createContext, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWTNow, initWorldTime } from 'src/redux/slices/worldTime';

export const useWorldTime = () => useContext(WorldTimeContext);

export default function WorldTimeProvider({ children }) {
  const dispatch = useDispatch();

  const { snap = null, initAt = null } = useSelector((state) => state.worldTime);

  const now = useMemo(() => getWTNow(snap, initAt), [snap, initAt]);

  const refresh = () => dispatch(initWorldTime());

  useEffect(() => {
    refresh();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const store = {
    now,
    refresh
  };

  return <WorldTimeContext.Provider value={store}>{children}</WorldTimeContext.Provider>;
}

const WorldTimeContext = createContext({
  /**
   * @type date | null
   */
  now: null,
  refresh: () => {}
});
