import React, { useState } from 'react'
import { Stack } from '@mui/material'
import CustomFileView from 'src/components/CustomFileView';
import { nanoid } from '@reduxjs/toolkit';
import { serverTime } from 'src/utils/serverTime';
import { useDispatch } from 'src/redux/store';
import { multipleFilesSave, SaveAffectDocs } from 'src/redux/slices/document';
import UploadingOneFileProgress from 'src/components/UploadingOneFileProgress';

export default function AffectAttach({ details, handleDetailChange,  field, user, disableUpload=false, hideDelete=false, placeholder }) {
    const [isUploading, setUploading] = useState(false);
    const [uploadings, setUploadingFiles] = useState([]);
    const dispatch = useDispatch();

    const handleChange = (files) =>{
        setUploading(true);
        const handleFiles = (newfiles) => {
            const user_to_files = newfiles.map((_file) => {
                return {
                    ..._file,
                    email: user?.email || '',
                    userId: user?.id ||'',
                    id: _file?.id || nanoid(),
                    displayName: user?.displayName || '',
                    createAt: serverTime()
                };
            });

            setUploading(false);

            let newAttachs = details[field].map((_att) => {
                if (!_att?.id) {
                    return {..._att, id: nanoid()}
                }
                return _att;
            });

            handleDetailChange(field, [...newAttachs, ...user_to_files]);

            dispatch(SaveAffectDocs(newfiles));
        };
        multipleFilesSave(files, handleFiles, null, setUploadingFiles);
    }

    
    const onRemove = (file) =>{
        const rest = details[field].filter((att) => {
            if (att?.id) {
                return att.id !== file.id
            }
            return att !== file;
        });
        handleDetailChange(field, [...rest]);
    }

    const onUpdateFile = (oldFile, newFile) => {
        const rest = details[field].map((att) => {
            if (oldFile?.id) {
                if (att?.id === oldFile?.id) {
                    return newFile;
                }
                return att;
            }

            if (att === oldFile) {
                return newFile;
            }
            return att;
        });
        handleDetailChange(field, [...rest]);
    }



  return (
    <Stack width={1} spacing={2} flexWrap='wrap'>
        <CustomFileView 
            placeholder={placeholder}
            attachments={ details[field] || []}
            disableUpload={disableUpload}
            hideDelete={hideDelete}
            isUploading={isUploading}
            onChange={handleChange}
            onRemove={onRemove}
            onUpdateFile={onUpdateFile}
        />
        {uploadings.length ? uploadings.map((_file, idx) =>(
            <UploadingOneFileProgress uploading={_file} key={`up_${idx}`} />
        )) : null  }
    </Stack>
  )
}
