import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useRef } from 'react';
import { IconButtonAnimate } from './animate';
import Iconify from './Iconify';
import MenuPopover from './MenuPopover';

MoreMenuButtonV2.propTypes = {
  actions: PropTypes.node.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onOpen: PropTypes.func
};

export default function MoreMenuButtonV2({ actions, open, onOpen, onClose }) {
  const popover = useRef();
  return (
    <>
      <Tooltip title="Plus">
        <IconButtonAnimate ref={popover} size="small" onClick={onOpen}>
          <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
        </IconButtonAnimate>
      </Tooltip>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={popover.current}
        onClose={onClose}
        anchorOrigin="bottom"
        horizontal="center"
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        arrow="top-center"
        sx={{
          mt: -0.5,
          width: 'auto',
          '& .MuiMenuItem-root': {
            px: 1,
            typography: 'body2',
            borderRadius: 0.75,
            '& svg': { mr: 2, width: 20, height: 20 }
          }
        }}
      >
        {actions}
      </MenuPopover>
    </>
  );
}
