import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import useLogo from 'src/hooks/useLogo';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
  defaultSrc: PropTypes.string
};
const primarySrc = '';
export default function Logo({ sx, defaultSrc = null }) {
  const { logo_url } = useLogo();

  return <Box component="img" alt="logo" src={defaultSrc || logo_url || primarySrc} height={110} {...sx} />;
}
