import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import CustomFileView from 'src/components/CustomFileView';

KanbanTaskAttachments.propTypes = {
    attachments: PropTypes.array.isRequired
};


export default function KanbanTaskAttachments({
                                                  attachments,
                                                  onChange,
                                                  onRemove,
                                                  isUploading,
                                                  onUpdateFile,
                                                  disableUpload = false,
                                                  hideDelete = false
                                              }) {

    return (
        <Stack width={1} flexWrap='wrap' spacing={2}>
            <CustomFileView 
                attachments={attachments}
                disableUpload={disableUpload}
                hideDelete={hideDelete}
                isUploading={isUploading}
                onChange={onChange}
                onRemove={onRemove}
                onUpdateFile={onUpdateFile}
            />
        </Stack>
    );
}
