// material
import { Box } from '@mui/material';
//
import { Icon } from '@iconify/react';
import fileFill from '@iconify/icons-eva/file-fill';

// ----------------------------------------------------------------------

export const FORMAT_IMG = ['jpg', 'jpeg', 'gif', 'bmp', 'png'];
export const FORMAT_VIDEO = ['m4v', 'avi', 'mpg', 'mp4', 'webm'];
export const FORMAT_WORD = ['doc', 'docx', 'msword', 'dotx', 'docm', 'dot', 'doc', 'wps-office.doc'];
export const FORMAT_EXCEL = ['xls', 'xlsx', 'sheet', 'spreadsheetml', 'csv'];
export const FORMAT_POWERPOINT = ['ppt', 'pptx', 'presentationml', 'presentation'];
export const FORMAT_PDF = ['pdf', 'application/pdf'];
export const FORMAT_PHOTOSHOP = ['psd'];
export const FORMAT_ILLUSTRATOR = ['ai', 'esp'];

export function getFileType(fileUrl = '') {
  const filename = getFilename(fileUrl);
  return filename?.split('.').pop();
}

export function getFileName(fileUrl = '') {
  return fileUrl?.substring(fileUrl?.lastIndexOf('/') + 1).replace(/\.[^/.]+$/, '');
}

export function getFileFullName(fileUrl = '') {
  return fileUrl?.split('/').pop();
}

export function getFileFormat(fileUrl = '') {
  let format;

  switch (fileUrl?.includes(getFileType(fileUrl))) {
    case FORMAT_IMG.includes(getFileType(fileUrl)):
      format = 'image';
      break;
    case FORMAT_VIDEO.includes(getFileType(fileUrl)):
      format = 'video';
      break;
    case fileUrl?.includes('word'):
      format = 'word';
      break;
    case FORMAT_WORD.includes(getFileType(fileUrl)):
      format = 'word';
      break;
    case fileUrl?.includes('excel'):
      format = 'excel';
      break;
    case FORMAT_EXCEL.includes(getFileType(fileUrl)):
      format = 'excel';
      break;
    case fileUrl?.includes('powerpoint'):
      format = 'powerpoint';
      break;
    case FORMAT_POWERPOINT.includes(getFileType(fileUrl)):
      format = 'powerpoint';
      break;
    case FORMAT_PDF.includes(getFileType(fileUrl)):
      format = 'pdf';
      break;
    case FORMAT_PHOTOSHOP.includes(getFileType(fileUrl)):
      format = 'photoshop';
      break;
    case FORMAT_ILLUSTRATOR.includes(getFileType(fileUrl)):
      format = 'illustrator';
      break;
    default:
      format = getFileType(fileUrl);
  }

  return format;
}

const getIcon = (name, size) => (
  <Box component="img" src={`/static/icons/file/${name}.svg`} alt={name} sx={size ? size : { width: 28, height: 28 }} />
);

export function getFileThumb(fileUrl = '') {
  let thumb;

  switch (getFileFormat(fileUrl)) {
    case 'image':
      thumb = <Box component="img" src={fileUrl} alt={fileUrl} sx={{ width: 1, height: 1 }} />;
      break;
    case 'video':
      thumb = getIcon('file_type_video');
      break;
    case 'word':
      thumb = getIcon('file_type_word');
      break;
    case 'excel':
      thumb = getIcon('file_type_excel');
      break;
    case 'powerpoint':
      thumb = getIcon('file_type_powerpoint');
      break;
    case 'pdf':
      thumb = getIcon('file_type_pdf');
      break;
    case 'photoshop':
      thumb = getIcon('file_type_photoshop');
      break;
    case 'illustrator':
      thumb = getIcon('file_type_ai');
      break;
    case 'plain':
      thumb = getIcon('file_type_text');
      break;
    default:
      thumb = <Box component={Icon} icon={fileFill} sx={{ width: 28, height: 28 }} />;
  }
  return thumb;
}

export function getFileThumbSecond(type = '', url, size = null) {
  type = type?.replace('/', '.');

  switch (getFileFormat(type)) {
    case 'image':
      return <Box component="img" src={url} alt={'...'} sx={size ? size : { width: 28, height: 28 }} />;

    case 'video':
      return getIcon('file_type_video', size);

    case 'word':
      return getIcon('file_type_word', size);

    case 'excel':
      return getIcon('file_type_excel', size);

    case 'powerpoint':
      return getIcon('file_type_powerpoint', size);

    case 'pdf':
      return getIcon('file_type_pdf', size);

    case 'photoshop':
      return getIcon('file_type_photoshop', size);

    case 'illustrator':
      return getIcon('file_type_ai', size);
    case 'plain':
      return getIcon('file_type_text', size);

    default:
      return <Box component={Icon} icon={fileFill} sx={size ? size : { width: 28, height: 28 }} />;
  }
}

export function getFileThumbUrl(type, url) {
  type = type?.replace('/', '.');

  const getIcon = (name) => `/static/icons/file/${name}.svg`;

  switch (getFileFormat(type)) {
    case 'image':
      return url;

    case 'video':
      return getIcon('file_type_video');

    case 'word':
      return getIcon('file_type_word');

    case 'excel':
      return getIcon('file_type_excel');

    case 'powerpoint':
      return getIcon('file_type_powerpoint');

    case 'pdf':
      return getIcon('file_type_pdf');

    case 'photoshop':
      return getIcon('file_type_photoshop');

    case 'illustrator':
      return getIcon('file_type_ai');
    case 'plain':
      return getIcon('file_type_text');

    default:
      return getIcon('file_type_uknown');
  }
}

export function getFileThumbUrlCustom(type, url) {
  type = type.replace('/', '.');

  const getIcon = (name) => `/static/icons/file/${name}.svg`;

  switch (getFileFormat(type)) {
    case 'image':
      return url;

    case 'video':
      return getIcon('file_type_video');

    case 'word':
      return getIcon('file_type_word');

    case 'excel':
      return getIcon('file_type_excel');

    case 'powerpoint':
      return getIcon('file_type_powerpoint');

    case 'pdf':
      return getIcon('file_type_pdf');

    case 'photoshop':
      return getIcon('file_type_photoshop');

    case 'illustrator':
      return getIcon('file_type_ai');
    case 'plain':
      return getIcon('file_type_text');

    default:
      return null;
  }
}

export const getFilename = (url = '') => {
  const removeSlash = url.substring(url.lastIndexOf('/') + 1);
  const removeQuery = removeSlash.includes('?') ? removeSlash.substring(0, removeSlash.lastIndexOf('?')) : removeSlash;
  return removeQuery;
};
