import {createSlice} from '@reduxjs/toolkit';
import {filter, indexOf} from 'lodash';
import firestore from 'src/utils/firestore';
// utils
import axios from '../../utils/axios';
import {blogCreationNotification} from "./notifications";

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    posts: [],
    post: null,
    recentPosts: [],
    hasMore: true,
    index: 0,
    step: 11
};

const slice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET POSTS
        getPostsSuccess(state, action) {
            state.isLoading = false;
            state.posts = action.payload;
        },

        // GET POST INFINITE
        getPostsInitial(state, action) {
            state.isLoading = false;
            state.posts = action.payload;
        },

        getMorePosts(state) {
            const setIndex = state.index + state.step;
            state.index = setIndex;
        },

        noHasMore(state) {
            state.hasMore = false;
        },


        addSuccess(state, action) {
            state.isLoading = false;
            state.posts.push(action.payload);
        },

        updateSuccess(state, action) {
            state.isLoading = false;
            const toUpdate = indexOf(state.posts, (post) => post.id === action.payload);
            let newPost = [...state.posts];
            newPost.splice(toUpdate, 1, action.payload);
            state.posts = newPost;
        },

        deletePostSuccess(state, action) {
            state.loading = false;
            state.audiences = filter(state.audiences, (audience) => audience.id !== action.payload);
        },


        // GET POST
        getPostSuccess(state, action) {
            state.isLoading = false;
            state.post = action.payload;
        },

        // GET RECENT POST
        getRecentPostsSuccess(state, action) {
            state.isLoading = false;
            state.recentPosts = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {getMorePosts} = slice.actions;

//----------------------------------------------------------------------

export function createPost(post, onResolve = null) {
    const handleResolve = (hasSucceed = false, error = '') => {
        onResolve && onResolve(hasSucceed, error)
    }
    return async (dispatch) => {
        try {
            dispatch(slice.actions.startLoading());
            const result = await firestore.collection('posts').add(post);
            dispatch(slice.actions.addSuccess({id: result.id, ...post}));
            handleResolve(true)

            dispatch(blogCreationNotification({post:{...post,id:result.id}}));

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            handleResolve(false, error)
        }
    }
}

// ----------------------------------------------------------------------

export function updatePost(post, callback = null) {
    const handleResolve = (hasSucceed = false, error = '') => {
        callback && callback(hasSucceed, error)
    }

    return async (dispatch) => {
        try {
            dispatch(slice.actions.startLoading());
            const {id, ...rest} = post;
            await firestore.collection('posts').doc(id).update({...rest});
            dispatch(getPost(id));
            dispatch(slice.actions.updateSuccess(post));
            handleResolve(true)
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            handleResolve(false, error)
        }
    };
}


export function deletePost(id, callback = null) {
    const handleResolve = (hasSucceed = false, error = '') => {
        callback && callback(hasSucceed, error)
    }
    return async (dispatch) => {
        try {
            dispatch(slice.actions.startLoading());
            await firestore.collection('posts').doc(id).delete()
            dispatch(slice.actions.deletePostSuccess(id));
            handleResolve(true)
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
            handleResolve(false, error)
        }
    };
}


// ----------------------------------------------------------------------

export function getAllPosts(posts) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.getPostsSuccess(posts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPostsInitial(index, step) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axios.get('/api/blog/posts', {
                params: {index, step}
            });
            const results = response.data.results.length;
            const {maxLength} = response.data;

            dispatch(slice.actions.getPostsInitial(response.data.results));

            if (results >= maxLength) {
                dispatch(slice.actions.noHasMore());
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

export function getPost(id) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const doc = await firestore.collection('posts').doc(id).get()
            if (doc.data())
                dispatch(slice.actions.getPostSuccess({id: doc.id, ...doc.data()}));
            else dispatch(slice.actions.hasError('Not found'));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}


// ----------------------------------------------------------------------

export function getRecentPosts() {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {

            const docSnap = await firestore.collection('posts')
                .orderBy('createdAt', 'desc').limit(5).get()

            dispatch(slice.actions.getRecentPostsSuccess(docSnap.docs.map(doc => ({id: doc.id, ...doc.data()}))));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
