import { isObject, orderBy } from 'lodash';

export const PROJECT_STATE = {
  ALL: 'Tout',
  IN_PROGRESS: 'En cours',
  SUSPENDED: 'Suspendus',
  CLOSED: 'Clôturés'
};
export const PROJECT_STATE_VAL = {
  ALL: '',
  IN_PROGRESS: 'open',
  SUSPENDED: 'suspended',
  CLOSED: 'closed'
};

export const PROJECT_VIEW = {
  FOLDER: 'folder',
  CARD: 'card',
  LIST: 'list'
};

export const orderProject = (projects, id) => {
  let _pro = orderBy(projects, ['lastOpen'], ['desc']);
  _pro = _pro.sort((x, y) => {
    let xv;
    let yv;
    xv = isObject(x?.favorite) ? x?.favorite[id] : undefined;
    yv = isObject(y?.favorite) ? y?.favorite[id] : undefined;

    return xv === yv ? 0 : xv ? -1 : 1;
  });
  return _pro;
};
