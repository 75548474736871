import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Popover } from '@mui/material';

// ----------------------------------------------------------------------

const ArrowStyle = styled('span')(({ arrow, theme, customcolor }) => {
  const SIZE = 12;

  const POSITION = -(SIZE / 2);

  const borderStyle = `solid 1px ${customcolor || theme.palette.grey[500_12]}`;

  const topStyle = {
    borderRadius: '0 0 3px 0',
    top: POSITION,
    borderBottom: borderStyle,
    borderRight: borderStyle
  };
  const bottomStyle = {
    borderRadius: '3px 0 0 0',
    bottom: POSITION,
    borderTop: borderStyle,
    borderLeft: borderStyle
  };
  const leftStyle = {
    borderRadius: '0 3px 0 0',
    left: POSITION,
    borderTop: borderStyle,
    borderRight: borderStyle
  };
  const rightStyle = {
    borderRadius: '0 0 0 3px',
    right: POSITION,
    borderBottom: borderStyle,
    borderLeft: borderStyle
  };

  return {
    [theme.breakpoints.up('sm')]: {
      zIndex: 1,
      width: SIZE,
      height: SIZE,
      content: "''",
      position: 'absolute',
      transform: 'rotate(-135deg)',
      color: customcolor || theme.palette.background.paper,
      background: customcolor || theme.palette.background.paper
    },
    // Top
    ...(arrow === 'top-left' && { ...topStyle, left: 20 }),
    ...(arrow === 'top-center' && { ...topStyle, left: 0, right: 0, margin: 'auto' }),
    ...(arrow === 'top-right' && { ...topStyle, right: 20 }),
    // Bottom
    ...(arrow === 'bottom-left' && { ...bottomStyle, left: 20 }),
    ...(arrow === 'bottom-center' && { ...bottomStyle, left: 0, right: 0, margin: 'auto' }),
    ...(arrow === 'bottom-right' && { ...bottomStyle, right: 20 }),
    // Left
    ...(arrow === 'left-top' && { ...leftStyle, top: 20 }),
    ...(arrow === 'left-center' && { ...leftStyle, top: 0, bottom: 0, margin: 'auto' }),
    ...(arrow === 'left-bottom' && { ...leftStyle, bottom: 20 }),
    // Right
    ...(arrow === 'right-top' && { ...rightStyle, top: 20 }),
    ...(arrow === 'right-center' && { ...rightStyle, top: 0, bottom: 0, margin: 'auto' }),
    ...(arrow === 'right-bottom' && { ...rightStyle, bottom: 20 })
  };
});

// ----------------------------------------------------------------------

MenuPopover.propTypes = {
  sx: PropTypes.object,
  children: PropTypes.node,
  disabledArrow: PropTypes.bool,
  arrow: PropTypes.oneOf([
    'top-left',
    'top-center',
    'top-right',
    'bottom-left',
    'bottom-center',
    'bottom-right',
    'left-top',
    'left-center',
    'left-bottom',
    'right-top',
    'right-center',
    'right-bottom'
  ])
};

/**
 *
 * @type {Popover}
 */
export function CustomMenuPopover({
  children,
  arrow = 'top-right',
  disablePortal = false,
  disabledArrow,
  width = 230,
  sx,
  zIndex,
  anchorOrigin,
  transformOrigin,
  anchorEl,
  customcolor,

  ...other
}) {
  return (
    <Popover
      disablePortal={disablePortal}
      sx={{ ...(zIndex && { zIndex: zIndex }) }}
      anchorOrigin={anchorOrigin || { vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={transformOrigin || { vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          width: width,
          overflow: 'inherit',
          mt: 0.5,
          ...sx
        }
      }}
      anchorEl={anchorEl}
      {...other}
    >
      {!disabledArrow && <ArrowStyle arrow={arrow} customcolor={customcolor} />}

      {children}
    </Popover>
  );
}

export default function MenuPopover({
  children,
  arrow = 'top-right',
  disablePortal = false,
  disabledArrow,
  width = 230,
  sx,
  zIndex,
  anchorOrigin,
  horizontal = 'left',
  transformOrigin,
  anchorEl,
  ...other
}) {
  return (
    <Popover
      disablePortal={disablePortal}
      sx={{ ...(zIndex && { zIndex: zIndex }) }}
      anchorOrigin={{ vertical: anchorOrigin || 'bottom', horizontal: horizontal }}
      transformOrigin={transformOrigin || { vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        sx: {
          width: width,
          overflow: 'inherit',
          mt: 0.5,
          ...sx
        }
      }}
      anchorEl={anchorEl}
      {...other}
    >
      {!disabledArrow && <ArrowStyle arrow={arrow} />}

      {children}
    </Popover>
  );
}

/**
 *
 * @param {{
 * arrow: string,
 * disabledArrow: boolean,
 * width: number,
 * open: boolean,
 * onClose: function,
 * sx: any,
 * other: any,
 * anchorEl,
 * zIndex: function(theme)
 * }} props
 * @returns
 */
export function MenuPopoverCenter({ children, zIndex, arrow = 'top-right', disabledArrow, width = 230, sx, ...other }) {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      sx={{ ...(zIndex && { zIndex: zIndex }) }}
      PaperProps={{
        sx: {
          width: width,
          overflow: 'inherit',
          mt: 0.5,
          ...sx
        }
      }}
      {...other}
    >
      {!disabledArrow && <ArrowStyle arrow={arrow} />}

      {children}
    </Popover>
  );
}
export function MenuPopoverRight({ children, sx, width = 230, ...other }) {
  return (
    <Popover
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      PaperProps={{
        sx: {
          mt: 1.5,
          ml: 0.5,
          overflowX: 'inherit',
          boxShadow: (theme) => theme.customShadows.z20,
          border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`,
          width: width,
          ...sx
        }
      }}
      {...other}
    >
      <ArrowStyle className="arrow" />

      {children}
    </Popover>
  );
}
