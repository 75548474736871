import { isEmpty, isEqual, uniq } from "lodash";
import { AFFECT_STATE_VALIDATION } from "src/constants";
import { AffectationType } from "src/models/Affectation_m";
import { updateAffectation } from "src/redux/slices/affectations";
import { serverTime } from "src/utils/serverTime";

/**
 * 
 * @param {AffectationType} details 
 * @param {AffectationType} oldAffect 
 * @param {string?} state 
 * @param {*} user 
 * @param {Function} setOpenAlert 
 * @param {Function?} onClose 
 * @param {Function} enqueueSnackbar 
 * @param {Function} dispatch 
 * @param {Function} toprint 
 */
 export const affectValidateAction = (details, oldAffect, state=null, user, setOpenAlert, onClose, enqueueSnackbar, dispatch, toprint, canPrint) =>{
    let tosave = {...details};
    let snackMessage = 'Mise à jour réussie';

    const allAssignIds = [
        ...details.assigne.responsable,
        ...details.assigne.collaborateur,
    ].map(_one => _one.id);


    
    if(state){
        tosave = { ...tosave, state}
        if(state === AFFECT_STATE_VALIDATION.PENDING){

            if(!details.assigne.responsable.length){
                return setOpenAlert("Veuillez séléctionner au moins un destinataire");
            }
            
            if(isEmpty(details.correspondance.annotation)){
                return setOpenAlert("Veuillez ajouter une annotation");
            }    
            
            snackMessage = 'Assignée avec succès';


            tosave =  {
                ...tosave, 
                canPrint,
                state: state,
                toReviewIds: [...details.toReviewIds,  ...details.toDoIds, canPrint?.id || ''],
                toDoIds: allAssignIds,
                assignedAt: { 
                    date: serverTime(), 
                    by: { 
                        id: user.id, 
                        name: user.displayName 
                    } 
                }
            };

        }

        if(state === AFFECT_STATE_VALIDATION.DONE){
            const respIds = details.assigne.responsable.map(_one => _one.id) || [];
            tosave = {
                ...tosave,
                toValidate: respIds,
                toReviewIds: [...details.toReviewIds, ...details.toDoIds, ...allAssignIds ],
                toDoIds: []
            }
        }

        if(state === AFFECT_STATE_VALIDATION.ONVALIDATE){
            const respIds = [details.responsable.id]
            tosave = {
                ...tosave,
                toValidate: respIds,
                toReviewIds: [...details.toReviewIds, ...details.toDoIds, ...allAssignIds ],
                toDoIds: []
            }
        }

        if(state === AFFECT_STATE_VALIDATION.INPROGRESS ||  state === AFFECT_STATE_VALIDATION.REJECTED ){
            
            tosave = {
                ...tosave,
                toReviewIds: [...details.toReviewIds, ...details.toDoIds, ...allAssignIds ],
                toDoIds: [],
                toValidate: []
            }
        }

        if(state === AFFECT_STATE_VALIDATION.ACCEPTED){
         
            tosave = {
                ...tosave,
                historyIds: uniq([...details.toReviewIds, ...details.toDoIds, ...allAssignIds, ...details.historyIds ]),
                toValidate: [],
                toDoIds: [],
                toReviewIds: []
            }
        }

    }

    const callback = () =>{
        onClose && onClose();
        enqueueSnackbar(snackMessage, { variant: 'info'});
        if(!isEmpty(state) && state === AFFECT_STATE_VALIDATION.PENDING){
           // toprint({...details, canPrint});
        }
    }
    
    if(!isEqual(details.assigne.responsable, oldAffect.assigne.responsable)){
        tosave = {
            ...tosave,
            assignedAt: {
                date: serverTime(),
                by: { id: user.id, name: user.displayName }
            }
        }   
    }
    
    if(isEmpty(state) && details.state === AFFECT_STATE_VALIDATION.PENDING){
        tosave ={
            ...tosave,
            toDoIds: [...allAssignIds, details.responsable?.id || '']
        }
    }

    dispatch(updateAffectation({ affect: tosave , oldAffect: oldAffect, callback }))
}