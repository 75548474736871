import React from 'react';
import ReactDOM from 'react-dom';
import { Box, Alert, Grid, Typography, LinearProgress } from '@mui/material';
import EllipsisText from './EllipsisText';

/** @param {{ uploading: {name: string, progress: number, error: any }}} */
export default function UploadingOneFileProgress({ uploading }) {
  return uploading && uploading?.progress
    ? ReactDOM.createPortal(
        <Box
          sx={{
            zIndex: (theme) => theme.zIndex.appBar + 1001,
            position: 'absolute',
            bottom: '70px',
            right: '1.5rem',
            maxWidth: '350px'
          }}
        >
          <Alert
            severity="success"
            color={uploading.error ? 'error' : 'info'}
            sx={{ m: 1, minWidth: 300, maxWidth: 350 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <EllipsisText text={uploading.name} style={{ fontSize: 13 }} />
              </Grid>
              {uploading.error && (
                <Grid item xs={3}>
                  <Typography noWrap> Erreur </Typography>
                </Grid>
              )}
              <Grid item xs={12}>
                <LinearProgress
                  sx={{ minWidth: 220, maxWidth: 350 }}
                  variant="buffer"
                  color={uploading.error ? 'error' : 'info'}
                  value={uploading.error ? 100 : uploading.progress * 100}
                  valueBuffer={10}
                />
              </Grid>
            </Grid>
          </Alert>
        </Box>,
        document.body
      )
    : null;
}
