import React from 'react';
import { useSelector } from 'src/redux/store';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';

export default function useTaskDetails({taskId}) {
    const [loading, setLoading] = React.useState(false);

    const getTask = useSelector(state => state.firestore.ordered[`${taskId}_get_details_for_task`]);
    const task = React.useMemo(()=> isEmpty(getTask) ? null : getTask?.at(0) , [getTask]);

    useFirestoreConnect(()=> taskId ? [
        {
            collection:'tasks', 
            doc: taskId,
            storeAs: `${taskId}_get_details_for_task`
        }
    ] : []);

    React.useEffect(()=> {
        setLoading(!isLoaded(getTask));
    },[getTask]);

  return {loading, task};
}
