import React, {useState, useRef, useMemo, createContext, useContext} from 'react'
import {orderBy} from 'lodash';
import {Icon} from '@iconify/react';
import {
    alpha,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    IconButton,
    Collapse,
    Box,
    Tooltip,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    List,
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import {baseColors} from 'src/constants/color';
import CIconButton from 'src/components/CIconButton';
import MenuPopover from 'src/components/MenuPopover';
import EllipsisText from 'src/components/EllipsisText';
import Scrollbar from 'src/components/Scrollbar';
import {MuiCustomInput} from 'src/components/CustomInput';
import {useTaskLineContext} from '../TaskProjectList';
import {useToggleV2} from 'src/hooks/useToggle';
import {TASK_STATE_VALIDATION} from 'src/constants';
import {taskStateColor} from 'src/helpers/taskStateColor';
import {Avatar, Checkbox} from 'antd';
import useSubTask from 'src/hooks/useSubTask';
import LoadingView from 'src/components/LoadingView';
import {nameFilter} from 'src/utils/nameFilter';
import {DisplayTime, useDatePicker} from '../kanban/KanbanTaskAdd';
import {dateConverter} from 'src/helpers/dueDateConverter';
import {DEPS_TYPES} from 'src/constants/deps';
import {dispatch} from 'src/redux/store';
import {addDepsToTask} from 'src/redux/slices/kanban';
import {useSnackbar} from 'notistack';


const DependencyProvider = createContext({
    deps: [],
    currentTask: null,
    popoverSearch: '',
    setDeps: () => {
    },
    setPopoverSearch: () => {
    },
    popoverFilterFunction: () => []
});

const useDepsContext = () => useContext(DependencyProvider);


export default function DependencyChoice({open, onClose, currentTask}) {
    const [deps, setDependence] = useState(currentTask.dependences || {});
    const [selected, setSelected] = useState(null);
    const [toSave, setToSave] = useState(deps);
    const [popoverSearch, setPopoverSearch] = useState('');
    const anchorEl = useRef();
    const {enqueueSnackbar} = useSnackbar();

    const popoverFilterFunction = (tasks) => {
        const sort_task = orderBy(tasks, 'name');
        return nameFilter(sort_task, 'name', popoverSearch);
    }

    const onSelect = (val) => {
        setSelected(val);
    }

    const setDeps = (val) => {
        const d = {...deps};
        const ts = {...toSave};

        d[selected] = val.map((_v) => {
            return {
                id: _v?.id,
                name: _v?.name,
                due: _v?.due,
                assignee: _v?.assignee,
                state: _v?.state || null,
                dependences: _v?.dependences
            }
        });

        setDependence(d);

        ts[selected] = val.map((_v) => {
            return {
                id: _v?.id,
                name: _v?.name,
                due: _v?.due,
                assignee: _v?.assignee,
                state: _v?.state || null,
            }
        })
        setToSave(ts);
    }


    const removeDep = (item, type) => {
        const vals = deps[type];

        const d = {...deps};
        const ts = {...toSave};

        const val = vals.filter((_d) => _d?.id !== item?.id);

        d[type] = val.map((_v) => {
            return {
                id: _v?.id,
                name: _v?.name,
                due: _v?.due,
                assignee: _v?.assignee,
                dependences: _v?.dependences,
                state: _v?.state || null,
            }
        });

        setDependence(d);

        ts[type] = val.map((_v) => {
            return {
                id: _v?.id,
                name: _v?.name,
                due: _v?.due,
                state: _v?.state || null,
                assignee: _v?.assignee,
            }
        })
        setToSave(ts);
    }


    const store = {
        deps: deps[selected] || [],
        setDeps,
        selected,
        removeDep,
        currentTask,
        popoverSearch,
        setPopoverSearch,
        popoverFilterFunction,
    }


    const getChanges = (type) => {
        const prev = (currentTask?.dependences || {})[type] || [];
        const current = deps[type];

        const added = current?.filter(el => prev.filter(e => e?.id === el?.id).length === 0) || []
        const removed = prev?.filter(el => current.filter(e => e?.id === el?.id).length === 0) || []

        return {added, removed};
    }

    const getDepChanges = () => {
        return {
            [DEPS_TYPES.PENDING]: getChanges(DEPS_TYPES.PENDING),
            [DEPS_TYPES.LOCK]: getChanges(DEPS_TYPES.LOCK),
            [DEPS_TYPES.TASK]: getChanges(DEPS_TYPES.TASK),
        }
    }

    const saveChoice = () => {
        onClose();
        const changes = getDepChanges();
        dispatch(addDepsToTask(currentTask, deps, toSave, changes, () => {
            enqueueSnackbar('Dépendence ajouté avec succes', {variant: 'success'});
        }));
    }


    return (
        <DependencyProvider.Provider value={store}>
            <Dialog open={open} onClose={onClose} maxWidth='sm' fullWidth scroll='paper'>
                <DialogTitle>
                    <Stack width={1} direction='row' justifyContent='space-between' mb={1} spacing={3}>
                        <Stack width={0.7}>
                            <Typography fontWeight='bold'> Dépendances </Typography>
                            <EllipsisText material text={currentTask?.name} style={{fontSize: 13}}/>
                        </Stack>
                        <Stack direction='row' spacing={2} height={30}>
                            <Button size='small' disabled={!Object.entries(deps).length} variant='outlined'
                                    color='success' onClick={saveChoice}>
                                Appliquer
                            </Button>
                            <CIconButton onClick={onClose}>
                                <Icon icon='eva:close-fill'/>
                            </CIconButton>
                        </Stack>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={2} ref={anchorEl}>
                        <DependencyBlock
                            color={baseColors.WARINIG}
                            icon='teenyicons:stop-circle-solid'
                            title={DEPS_TYPES.PENDING}
                            desc='Tâches qui doivent être terminées avant cette tâche'
                            actionTitle='Ajouter une tâche en attente'
                            action={() => onSelect(DEPS_TYPES.PENDING)}
                            list={deps[DEPS_TYPES.PENDING] || []}
                        />
                        <DependencyBlock
                            color={baseColors.RED}
                            icon='teenyicons:stop-circle-solid'
                            title={DEPS_TYPES.LOCK}
                            desc="Tâches qui ne peuvent pas démarrer tant que cette tâche n'est pas terminée"
                            actionTitle='Ajouter une tâche qui est bloqué'
                            action={() => onSelect(DEPS_TYPES.LOCK)}
                            list={deps[DEPS_TYPES.LOCK] || []}
                        />
                        <DependencyBlock
                            color={baseColors.GREEN}
                            icon='bi:link-45deg'
                            title={DEPS_TYPES.TASK}
                            desc="Les tâches qui sont liées les unes aux autres, mais qui ne sont pas réellement dépendantes les unes des autres"
                            actionTitle='Ajouter une tâche liée'
                            action={() => onSelect(DEPS_TYPES.TASK)}
                            list={deps[DEPS_TYPES.TASK] || []}
                        />
                    </Stack>
                    {selected &&
                        <TaskList open={selected !== null} currentTask={currentTask} onClose={() => setSelected(null)}
                                  anchorEl={anchorEl.current}/>}
                </DialogContent>
            </Dialog>
        </DependencyProvider.Provider>
    )
}

const DespItem = ({item, type}) => {
    const {deps, removeDep} = useDepsContext();
    const [hover, onHover, endHover] = useToggleV2();

    const onRemove = () => {
        removeDep(item, type);
    }


    const {
        endTime,
        startTime,
        isSameDays,
        isSameMonths,
        onOpenPicker
    } = useDatePicker({
        date: dateConverter(item?.due)
    });


    return (
        <ListItemButton onMouseEnter={onHover} onMouseLeave={endHover}>
            <ListItemIcon>
                <Tooltip title={item?.state || TASK_STATE_VALIDATION.PENDING} arrow placement='top'>
                    <Box
                        height={8}
                        width={8}
                        bgcolor={taskStateColor(item?.state)}
                        mr={1}
                    />
                </Tooltip>
            </ListItemIcon>
            <ListItemText
                primary={<EllipsisText text={item?.name} style={{fontSize: 13}}/>}
                secondary={
                    startTime && endTime ? (
                        <DisplayTime
                            startTime={startTime}
                            endTime={endTime}
                            isSameDays={isSameDays}
                            isSameMonths={isSameMonths}
                            onOpenPicker={onOpenPicker}
                            sx={{fontSize: 11, color: 'grey.500'}}
                        />
                    ) : null}
            />
            {
                hover &&
                <IconButton size='small' onClick={onRemove}>
                    <Icon icon='eva:close-fill' height={20}/>
                </IconButton>
            }
        </ListItemButton>
    );
}

const DespList = ({deps = [], type}) => {
    return (
        <List dense>
            {deps?.length ? deps?.map((_dep, idx) => <DespItem key={_dep?.id + idx} item={_dep} type={type}/>) : null}
        </List>
    );
}


const DependencyBlock = ({icon, title, desc, action, actionTitle, list = [], color = 'black'}) => {


    return (
        <Stack border={`0.5px solid ${color}`} borderLeft={`2px solid ${color}`} p={2} spacing={0.5}
               bgcolor={alpha(color, 0.03)}>
            <Stack direction='row' alignItems="center" spacing={1}>
                <Icon icon={icon} height={12} color={color}/>
                <Typography color={color} fontSize={11} fontWeight='bold'> {title} </Typography>
            </Stack>
            <Typography variant='caption' color='grey.500'> {desc} </Typography>
            {
                list.length ? <DespList deps={list} type={title}/> : null
            }
            <Stack width={1} alignItems='start' pt={1}>
                <Button startIcon={<Icon icon='eva:plus-fill' height={13}/>} color='inherit' size='small'
                        onClick={action}>
                    {actionTitle}
                </Button>
            </Stack>
        </Stack>
    );
}

const OneOperation = ({colId, columns, data}) => {
    const [open, _, __, onToggle] = useToggleV2(true);
    const {currentTask, popoverFilterFunction} = useDepsContext();

    const result = useMemo(() => {
        const col = columns[colId];
        const cardIds = col?.cardIds || [];
        const mcards = [];

        [...cardIds].forEach(_one => {
            if (data[_one] && _one !== currentTask?.id) {
                mcards.push(data[_one]);
            }
        });

        return {mcards, col, cardIds};

    }, [columns, colId, data, currentTask?.id]);


    return (
        <Stack width={1} mb={1}>
            <Stack direction='row' spacing={1} alignItems='center'>
                <IconButton size='small' onClick={onToggle}>
                    <Icon icon='eva:arrow-right-fill'/>
                </IconButton>
                <EllipsisText text={`(${result?.mcards?.length || 0}) ${result.col?.name}`}
                              style={{fontWeight: 'bold'}}/>
            </Stack>
            <Collapse sx={{pl: 1}} in={open}>
                {result?.mcards?.length ? popoverFilterFunction(result?.mcards).map((_task) => (
                    <OnTaskLine key={_task?.id} task={_task}/>)) : null}
            </Collapse>
        </Stack>
    );
}

const OnTaskLine = ({task}) => {
    const [open, _, __, onToggle] = useToggleV2();
    const {setDeps, deps} = useDepsContext();

    const handleSelected = () => {
        const exist = deps.find(_dep => _dep?.id === task?.id);
        if (exist) {
            const rest = deps.filter(_dep => _dep?.id !== task?.id)
            setDeps(rest);
            return;
        }
        setDeps([...deps, task]);
    }

    const {
        endTime,
        startTime,
        isSameDays,
        isSameMonths,
        onOpenPicker
    } = useDatePicker({
        date: dateConverter(task?.due)
    });

    return (
        <>
            <ListItemButton role={undefined} onClick={task?.subTasksCount ? onToggle : handleSelected}>
                <ListItemIcon>
                    <Checkbox checked={Boolean(deps.find(_one => _one?.id === task?.id))} onChange={handleSelected}/>
                </ListItemIcon>
                <ListItemText
                    primary={
                        <Stack direction='row' width={1} alignItems='center' spacing={1}>
                            <Tooltip title={task?.state || TASK_STATE_VALIDATION.PENDING} arrow placement='top'>
                                <Box
                                    height={8}
                                    width={8}
                                    bgcolor={taskStateColor(task?.state)}
                                    mr={1}
                                />
                            </Tooltip>
                            <EllipsisText text={task?.name} material/>
                        </Stack>}
                    primaryTypographyProps={{fontSize: 13}}
                    secondary={
                        <Stack pl={2} direction='row' spacing={2} alignItems='flex-end'>
                            <Typography fontSize={11} color='grey.500'>
                                {task?.subTasksCount ? `${task?.subTasksCount} sous-tâche(s)` : ''}
                            </Typography>
                            {startTime && endTime ? (
                                <DisplayTime
                                    startTime={startTime}
                                    endTime={endTime}
                                    isSameDays={isSameDays}
                                    isSameMonths={isSameMonths}
                                    onOpenPicker={onOpenPicker}
                                    sx={{fontSize: 11, color: 'grey.500'}}
                                />
                            ) : null}
                        </Stack>
                    }
                />
                {task?.subTasksCount ? open ? <ExpandLess/> : <ExpandMore/> : null}

            </ListItemButton>
            {open &&
                <Collapse in={open} timeout='auto' sx={{pl: 2}}>
                    <SubTaskList parent={task}/>
                </Collapse>
            }
        </>

    );
}

const SubTaskList = ({parent}) => {
    const {loading, subTasks} = useSubTask({taskId: parent?.id});

    return (
        <Stack width={1}>
            {loading ? <Stack width={1}> <LoadingView/> </Stack>
                : subTasks?.length ? subTasks.map((_task) => (<OnTaskLine key={_task?.id} task={_task}/>)) : null
            }
        </Stack>
    );
}


const TaskList = ({open, onClose, anchorEl}) => {
    const {board} = useTaskLineContext();
    const {setPopoverSearch, popoverSearch} = useDepsContext();


    return (
        <MenuPopover anchorEl={anchorEl} open={open} onClose={onClose} width={500} sx={{height: '90vh'}} disabledArrow
                     anchorOrigin='top' horizontal='center' transformOrigin={{vertical: 'top', horizontal: 'center'}}>
            <Stack p={1} spacing={2} height='80vh'>
                <Stack width={1} direction='row' justifyContent='space-between' alignItems='center' mb={1}>
                    <Typography fontWeight='bold' pt={1} pl={1}> Liste des tâches </Typography>
                    <CIconButton onClick={onClose}>
                        <Icon icon='eva:close-fill'/>
                    </CIconButton>
                </Stack>
                <MuiCustomInput
                    size='small'
                    fullWidth
                    value={popoverSearch}
                    onChange={(e) => setPopoverSearch(e.currentTarget.value)}
                    placeholder="Rechercher les tâches principales... "
                />
                <Scrollbar>
                    <Stack p={1}>
                        <List dense>
                            {
                                board?.columnOrder?.length ? board?.columnOrder?.map((colId) => (
                                    <OneOperation key={colId} colId={colId} columns={board.columns} data={board.data}/>
                                )) : null
                            }
                        </List>
                    </Stack>
                </Scrollbar>
            </Stack>
        </MenuPopover>
    );
}