import { findIndex } from 'lodash';
import React, { useRef, useState, useMemo } from 'react';
import { Icon } from '@iconify/react';
import {
  alpha,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { downloadFile } from 'src/helpers/downloadFile';
import useAuth from 'src/hooks/useAuth';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';
import { getFileFormat, getFileThumbUrl } from 'src/utils/getFileFormat';
import ExcelFileEditor from './ExcelFileEditor';
import MenuPopover from './MenuPopover';
import PdfFileEditor from './PdfFileEditor';
import WordFileEditor from './WordFileEditor';
import LightboxModal from './LightboxModal';
import CustomUploadFile from './CustomUploadFile';
import VideoPlayerModal from 'src/components/VideoPlayerModal';
import { baseColors } from 'src/constants/color';

/**
 *
 * @param {{
 *  attachments: Array,
 *  placeholder: string,
 *  onRemove: Function,
 *  isUploading: Any,
 *  onUpdateFile: Function,
 *  disableUpload: Boolean,
 *  hideDelete: Boolean,
 *  onChange: Function,
 * }} props
 */
export default function CustomFileView(props) {
  const {
    attachments,
    onRemove,
    isUploading,
    onUpdateFile,
    placeholder = 'Télécharger vos fichiers et documents ici',
    disableUpload = false,
    hideDelete = false,
    onChange
  } = props;
  const { user } = useAuth();
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const imagesLightbox = useMemo(() => attachments.map((att) => att.url), [attachments]);

  const handleOpenLightbox = (url) => {
    const selectedImage = findIndex(imagesLightbox, (index) => index === url);
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  const handleCloseLightbox = () => {
    setOpenLightbox(false);
  };

  return (
    <Stack direction="row" spacing={2} width={1}>
      {!isUploading ? (
        <Stack direction="row" spacing={2} alignItems="center">
          <CustomUploadFile onChange={onChange} disableUpload={disableUpload} />
          {!attachments.length && (
            <Typography color="grey.500" fontStyle="italic">
              {placeholder}
            </Typography>
          )}
        </Stack>
      ) : (
        <Box sx={{ position: 'relative' }}>
          <CustomUploadFile onChange={onChange} disableUpload={disableUpload} />
          <Stack
            sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, bgcolor: 'transparent' }}
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress size={20} color="primary" />
          </Stack>
        </Box>
      )}

      {attachments.map((_att, idx) => (
        <AttachmentItem
          key={_att?.url + idx}
          attachment={_att}
          user={user}
          hideDelete={hideDelete}
          onRemove={onRemove}
          onUpdateFile={onUpdateFile}
          handleOpenLightbox={handleOpenLightbox}
        />
      ))}

      <LightboxModal
        images={imagesLightbox}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onClose={handleCloseLightbox}
      />
    </Stack>
  );
}

/**
 *
 * @param {{
 *  user: Any,
 *  idx: number,
 *  attachment: Any,
 *  onRemove : Function,
 *  hideDelete: Function,
 *  onUpdateFile: Function,
 *  imagesLightbox: Function,
 *  handleOpenLightbox: Function,
 * }} props
 */
const AttachmentItem = (props) => {
  const { idx, user, onRemove, attachment, hideDelete, onUpdateFile, handleOpenLightbox } = props;

  const deleteRef = useRef();
  const { open, handleOpen, handleClose } = useToggle();
  const [opDelete, handleOpenDelete, handleCloseDelete] = useToggleV2();
  const [hover, onHover, endHover] = useToggleV2();
  const [visible, show, hide] = useToggleV2();

  const handleDownload = () => {
    downloadFile(attachment.url, attachment.name);
  };

  const openVideoReader = () => {
    show();
  };

  const handleOnRemove = () => {
    onRemove(attachment);
    handleCloseDelete();
  };

  const handleUpdate = (newFile) => {
    onUpdateFile(attachment, {
      ...attachment,
      url: newFile.url,
      latestUpdatedBy: { name: user?.displayName, id: user.id, email: user.email, avatar: user.photoURL }
    });
  };

  return (
    <>
      <Tooltip title={attachment?.name || ''}>
        <Box
          ref={deleteRef}
          key={attachment.url}
          sx={{ position: 'relative' }}
          onMouseEnter={onHover}
          onMouseLeave={endHover}
        >
          {attachment?.type?.includes('image') ? (
            <Box
              component="img"
              src={attachment.url}
              onClick={() => handleOpenLightbox(attachment.url)}
              sx={{
                m: 0.5,
                width: 80,
                height: 80,
                objectFit: 'cover',
                cursor: 'pointer',
                borderRadius: 1,
                bgcolor: alpha(baseColors.BLACK, 0.05)
              }}
            />
          ) : (
            <Tooltip title={attachment.name} placement="bottom">
              <Box
                onClick={attachment?.type?.includes('video') ? openVideoReader : handleOpen}
                component="img"
                src={getFileThumbUrl(attachment?.type, attachment.url)}
                width={80}
                height={80}
                sx={{ m: 0.5 }}
              />
            </Tooltip>
          )}
          {hover ? (
            <Stack
              width={1}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                bgcolor: (theme) => (theme.palette.mode === 'light' ? 'white' : theme.palette.action.selected),
                borderRadius: 1,
                border: (t) => `1px solid ${t.palette.divider}`
              }}
              justifyContent="space-between"
              direction="row"
            >
              <Tooltip title="Ouvrir">
                <IconButton
                  size="small"
                  color="default"
                  onClick={() =>
                    attachment?.type?.includes('image')
                      ? handleOpenLightbox(attachment.url)
                      : attachment.type.includes('video')
                      ? openVideoReader()
                      : handleOpen()
                  }
                >
                  <Icon icon="eva:eye-fill" width={15} height={15} />
                </IconButton>
              </Tooltip>

              <Tooltip title="Télécharger">
                <IconButton size="small" color="default" onClick={handleDownload}>
                  <Icon icon="eva:download-fill" width={15} height={15} />
                </IconButton>
              </Tooltip>
              {!hideDelete && (
                <Tooltip title="Supprimer">
                  <IconButton size="small" color="error" onClick={handleOpenDelete}>
                    <Icon icon="eva:trash-fill" width={15} height={15} />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          ) : null}
        </Box>
      </Tooltip>

      {opDelete && (
        <MenuPopover open={opDelete} onClose={handleCloseDelete} anchorEl={deleteRef.current} disabledArrow>
          <Box sx={{ p: 1 }}>
            <Typography> Êtes-vous sûr de vouloir supprimer ce fichier ? </Typography>
            <Stack direction="row" justifyContent="space-between">
              <Button color="inherit" onClick={handleCloseDelete}>
                Annuler
              </Button>
              <Button color="error" onClick={handleOnRemove}>
                {' '}
                Supprimer
              </Button>
            </Stack>
          </Box>
        </MenuPopover>
      )}

      {open && (
        <Dialog fullScreen fullWidth open={open}>
          <Paper square sx={{ bgcolor: 'transparent', width: '100%', height: '100vh' }}>
            {getFileFormat(attachment?.type) === 'word' && (
              <WordFileEditor
                pathUrl={attachment?.url}
                fileName={attachment?.name}
                type={attachment?.type}
                email={user?.email || ''}
                fileId={attachment?.id || ''}
                handleUpdate={handleUpdate}
                onClose={handleClose}
              />
            )}

            {getFileFormat(attachment?.type) === 'pdf' && (
              <PdfFileEditor
                file={attachment}
                email={user?.email || ''}
                handleUpdate={handleUpdate}
                onClose={handleClose}
              />
            )}

            {getFileFormat(attachment?.type) === 'excel' && (
              <ExcelFileEditor
                file={attachment}
                email={user?.email || ''}
                handleUpdate={handleUpdate}
                onClose={handleClose}
              />
            )}
          </Paper>
        </Dialog>
      )}

      {visible && <VideoPlayerModal open={visible} onClose={hide} {...attachment} />}
    </>
  );
};
