import React, { createContext, useContext, useMemo, useState } from 'react';
import { TaskType } from 'src/models/task_m';
import TaskItemDetail from 'src/section/tasks/v4/TaskItemDetail';

const TaskContextProvider = createContext({
  /**
   * @param {TaskType} task
   */
  openTaskDetails: (task) => {},
  closeTaskDetails: () => {}
});

export const useTaskV4Context = () => useContext(TaskContextProvider);

export default function TaskProvider({ children }) {
  const [open, setOpen] = useState(false);
  const [task, setTasks] = useState(null);

  const openTaskDetails = (_task) => {
    setTasks(_task);
    setOpen(true);
  };

  const closeTaskDetails = () => {
    setOpen(false);
    setTasks(null);
  };

  const store = useMemo(() => ({ openTaskDetails, closeTaskDetails }), []);

  return (
    <TaskContextProvider.Provider value={store}>
      {children} {open && <TaskItemDetail task={task} open={open} onClose={closeTaskDetails} />}
    </TaskContextProvider.Provider>
  );
}
