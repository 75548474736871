export const CHANNEL_MENTION = {
  id: '@Canal',
  name: 'Canal'
};

export const CANAL_INFO_TAB = {
  ABOUT: 'À propos',
  MEMBERS: 'Membres'
};
export const CHANNEL_FIELD = {
  TITLE: 'title',
  DESC: 'description'
};

export const CONFIRM_MESSAGE = {
  LEAVE: { title: 'Êtes-vous sûr de vouloir quitter ce canal ?', action: 'Quitter le canal' },
  CLOSE: {
    title:
      'Une fois ce canal cloturé, personne ne pourra y envoyer des messages. \nToute fois le contenu sera toujours disponible',
    action: 'Cloturer le canal'
  },
  OPEN: {
    title: 'Rouvrir le canal pour tout le monde',
    action: 'Rouvrir le canal'
  },
  DELETE_CANAL: {
    title:
      'Êtes-vous sûr de vouloir supprimer ce canal ? \nUne fois supprimé tous les messages contenus dans ce canal ne pourront plus être récupérés',
    action: 'Supprimer le canal'
  },

  DELETE_MESSAGE: {
    title: 'Êtes-vous sûr de vouloir supprimer ce message ? ',
    action: 'Supprimer le message'
  }
};

export const REACTION_TYPE = {
  LIKE: 'like'
};
