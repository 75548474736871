import {useMemo} from "react";
import {flattenDeep, uniqBy, values} from "lodash";
import {Stack, Typography} from "@mui/material";

export default function DependencyRenderListOnDelete({card}) {
    const deps = useMemo(() => {
        return uniqBy(flattenDeep(values(card?.dependences || {}) || []), 'id');
    }, [card?.dependences])

    if (deps.length === 0)
        return null;

    return (
        <Stack spacing={1}>
            <Typography>
                Cette tâche est liée à
            </Typography>
            <ul style={{marginLeft: 40}}>
                {
                    deps?.map(el => (
                        <li key={el?.id}>
                            {el?.name}
                        </li>
                    ))
                }
            </ul>
        </Stack>
    );
}