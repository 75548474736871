import { Icon } from '@iconify/react';
import { MIconButton } from '../../components/@material-extend';
import { useNavigate } from 'react-router-dom';
import backFill from '@iconify/icons-eva/arrow-back-outline';
import { Tooltip } from '@mui/material';
import { useDashbord2 } from 'src/layouts/GuestLayout';

export default function Forward({ hasTitle }) {
  const navigate = useNavigate();
  const { setTitle } = useDashbord2();

  const forwardAction = () => {
    navigate(-1);
    setTitle(null);
  };

  return (
    <Tooltip title="retour" arrow>
      <MIconButton
        onClick={forwardAction}
        size="small"
        sx={{ bgcolor: (t) => t.palette.primary.main, '&:hover': { bgcolor: (t) => t.palette.primary.main } }}
      >
        <Icon color="white" icon={backFill} width={30} height={30} />
      </MIconButton>
    </Tooltip>
  );
}
