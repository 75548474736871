import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogContent, IconButton, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Iconify from 'src/components/Iconify';
import MoreMenuButtonV2 from 'src/components/MoreMenuButtonV2';
import { UploadMultiFile } from 'src/components/upload';
import { useToggleV2 } from 'src/hooks/useToggle';
import { TaskType } from 'src/models/task_m';
import { dispatch } from 'src/redux/store';
import ConfirmDialog from '../../../components/ConfirmDialog';
import { serverTime } from 'src/utils/serverTime';
import { multipleFilesSave } from 'src/redux/slices/document';
import UploadingOneFileProgress from 'src/components/UploadingOneFileProgress';
import { deleteTask } from 'src/redux/slices/kanban';

/**
 *
 * @param {{ task : TaskType, onUpdate: Function, onClose: Function, user: firebase.default.UserInfo }} props
 * @returns
 */
export default function TaskItemDetailToolbar({ task, onUpdate, onClose, user }) {
  const { email, displayName, uid: id } = user;
  const [completed, setCompleted] = useState(task?.completed);
  const [openConfirm, onOpenConfirm, onCloseConfirm] = useToggleV2();
  const [open, handleOpenMenu, handleCloseMenu] = useToggleV2();
  const [openFile, handleOpenFile, handleCloseFile] = useToggleV2();
  const [deleting, setDeleting] = useState(false);
  const [files, setFiles] = useState([]);
  const [isUploading, setUploading] = useState(false);
  const [uploadings, setUploadingFiles] = useState([]);

  useEffect(() => {
    setFiles(task?.attachments);
  }, [task?.attachments]);

  const handleCompleted = () => {
    setCompleted(!completed);
    dispatch(onUpdate(task, { completed: !completed }, () => {}));
  };

  const handleDelete = () => {
    handleCloseMenu();
    setDeleting(true);
    dispatch(
      deleteTask({ cardId: task?.id }, () => {
        setDeleting(false);
        onCloseConfirm();
        onClose && onClose();
      })
    );
  };

  const handleAction2 = () => {
    handleCloseMenu();
    console.log('ACTION 2', task.id);
  };

  const handleOpenConfirmation = () => {
    handleCloseMenu();
    onOpenConfirm();
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [setFiles, files]
  );

  const handleRemove = useCallback(
    (file) => {
      setFiles(files.filter((one) => one.name !== file.name));
    },
    [setFiles, files]
  );

  const handleRemoveAll = useCallback((val) => {
    setFiles([]);
  }, []);

  const handleFiles = (_files) => {
    const user_to_files = _files.map((_file) => {
      return {
        ..._file,
        email,
        userId: id,
        id: _file?.id || uuidv4(),
        displayName,
        createAt: serverTime()
      };
    });
    setUploading(false);

    dispatch(onUpdate(task, { attachments: [...user_to_files] }, () => {}));
  };

  const onHandleUplaodFile = () => {
    setUploading(true);
    handleCloseFile();
    multipleFilesSave(files, handleFiles, null, setUploadingFiles);
  };

  return (
    <Stack direction="row" justifyContent="space-between">
      <Stack>
        <Button
          size="small"
          color="inherit"
          onClick={handleCompleted}
          variant={completed ? 'contained' : 'outlined'}
          {...(completed && { sx: { color: 'white', bgcolor: 'green' } })}
          startIcon={<Icon icon="ph:check-light" />}
        >
          {completed ? 'Terminée' : 'Marquer comme terminée'}
        </Button>
      </Stack>
      <Stack direction="row" spacing={1}>
        <Button variant="contained" color="primary" onClick={onClose}>
          Enregistrer
        </Button>
        <Tooltip title="Ajouter une pièce jointe" arrow placement="top">
          <IconButton size="small" onClick={handleOpenFile}>
            <Icon icon="fluent-mdl2:attach" />
          </IconButton>
        </Tooltip>

        <MoreMenuButtonV2
          open={open}
          onOpen={handleOpenMenu}
          onClose={handleCloseMenu}
          actions={
            <>
              <MenuItem onClick={handleOpenConfirmation}>
                <Iconify icon="eva:trash-fill" color="red" />
                Supprimer
              </MenuItem>

              <MenuItem onClick={handleAction2}>
                <Iconify icon="mdi:link-variant" />
                Copier le lien
              </MenuItem>
            </>
          }
        />

        <ConfirmDialog
          open={openConfirm}
          onClose={onCloseConfirm}
          title={
            <Typography>
              Êtes-vous sûr de vouloir supprimer la tâche <strong>{task.name}</strong>?
            </Typography>
          }
          actions={
            <>
              <Button variant="outlined" color="inherit" onClick={onCloseConfirm}>
                Annuler
              </Button>
              <LoadingButton loading={deleting} variant="contained" color="error" onClick={handleDelete}>
                Supprimer
              </LoadingButton>
            </>
          }
        />
        {openFile && (
          <Dialog open={openFile} onClose={handleCloseFile}>
            <DialogContent>
              <UploadMultiFile
                files={files}
                loading={isUploading}
                onChange={setFiles}
                onDrop={handleDrop}
                onRemove={handleRemove}
                onRemoveAll={handleRemoveAll}
                onUpload={onHandleUplaodFile}
              />
            </DialogContent>
          </Dialog>
        )}
        {uploadings.map((upload, idx) => (
          <UploadingOneFileProgress key={upload?.name || '' + idx} uploading={upload} />
        ))}
      </Stack>
    </Stack>
  );
}
