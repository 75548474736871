import { Stack, Tooltip } from '@mui/material';
import React, { useCallback } from 'react';
import Iconify from '../Iconify';
import TextMaxLine from '../TextMaxLine';
import { Box, Button } from '@mui/joy';
import { useDropzone } from 'react-dropzone';
import { getFileThumbSecond } from 'src/utils/getFileFormat';

/**
 *
 * @param {{
 *  placeholder: string,
 *  multiple:boolean,
 *  maxSize: number,
 *  accept: string,
 *  onChange: (files) => void,
 *  value: Array<any>,
 *  disabled: boolean,
 *  fileView: React.ReactNode
 * }} props
 *
 *
 * @description multiple: if can wrap multiple file not,
 * @description maxSize: file max size,
 * @description accept: type of file,
 * @description onChange: callback when files dorp,
 * @description values: Array of file or Array of file link
 * @description fileView: A Custom view for the file handler
 *
 * @returns
 */
export default function MuiUploadFileInput({
  placeholder,
  multiple = true,
  maxSize,
  accept,
  onChange,
  value,
  disabled,
  fileView
}) {
  const handleDropFile = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        Object.assign(file, {
          url: URL.createObjectURL(file)
        });
      });

      onChange(acceptedFiles);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  const { getInputProps, getRootProps, fileRejections } = useDropzone({
    multiple: multiple,
    onDrop: handleDropFile,
    ...(accept && { accept: accept }),
    ...(maxSize && { maxSize: maxSize }),
    ...(disabled && { disabled: disabled })
  });
  return (
    <Stack
      {...getRootProps()}
      component={Button}
      px={1}
      width={1}
      spacing={1}
      direction="row"
      variant="plain"
      color="inherit"
      display="flex"
      alignItems="center"
      justifyContent="start"
      {...(fileView && { bgcolor: 'transparent' })}
    >
      <input {...getInputProps()} />

      {!fileView &&
        value.map((file) => {
          const { name, url, type } = file;

          return (
            <Tooltip key={file.url} title={name} arrow>
              {type.includes('image') ? (
                <Box
                  component="img"
                  alt="file preview"
                  src={url}
                  sx={{
                    borderRadius: 1,
                    objectFit: 'cover',
                    width: 30,
                    height: 30
                  }}
                />
              ) : (
                <Box>{getFileThumbSecond(type, url, { width: 30, height: 30 })}</Box>
              )}
            </Tooltip>
          );
        })}

      {fileView || (
        <>
          <Iconify icon="material-symbols:attach-file-add-rounded" sx={{ height: 20, width: 20 }} />
          <TextMaxLine fontSize={10} fontWeight={100} color="grey.500">
            {placeholder}
          </TextMaxLine>
        </>
      )}
    </Stack>
  );
}
