import React, { createContext, useContext, useMemo } from 'react';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { homeViewList } from 'src/constants/home.js';
import { matchPath, useLocation } from 'react-router-dom';

const HomeContextProvider = createContext({
  view: '',
  isHome: false,
  setView: () => {}
});

export const useHomeContext = () => useContext(HomeContextProvider);

export default function HomeContext({ children }) {
  const [view, setView] = useLocalStorage('_view_home_state', homeViewList.ALL);
  const { pathname } = useLocation();
  const isHome = useMemo(() => !!matchPath({ path: 'dashboard/app', end: false }, pathname), [pathname]);

  const store = { view, setView, isHome };
  return <HomeContextProvider.Provider value={store}>{children}</HomeContextProvider.Provider>;
}
