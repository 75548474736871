import { Checkbox, Input, Option, Select, Sheet } from '@mui/joy';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { MuiCustomDateInput } from './MuiCustomDateInput';
import MuiUploadFileInput from './upload/MuiUploadFileInput';

/**
 *
 * @param {{
 * type: 'text' | 'date' | 'file' | 'checkbox' | 'select' ,
 * field: string,
 * label: string,
 * value: any,
 * options?: Array<any>,
 * error: boolean,
 * required: boolean,
 * helperText: string,
 * noWritable: boolean,
 * disabled: boolean,
 * fileView: React.ReactNode,
 * onChange: (field:string, value:any)=>void
 * }} props
 * @returns {React.ReactNode}
 */
const InputLine = ({
  type = 'text',
  field,
  label,
  value,
  onChange,
  options = [],
  error,
  required,
  helperText,
  noWritable = false,
  disabled = false,
  fileView = null
}) => {
  return (
    <Stack width={1} spacing={0.5}>
      <Box fontSize={11} fontWeight={400}>
        {label}
        {required ? (
          <Box component="span" position="relative" pl={0.5} width={50}>
            <Typography position="absolute" top={-5} left={0} color="red" fontSize={16}>
              *
            </Typography>
          </Box>
        ) : (
          ''
        )}
      </Box>
      <Sheet
        variant="soft"
        sx={{
          borderRadius: 'sm',
          height: 40,
          display: 'flex',
          alignItems: 'center',
          ...(error && { outline: `0.1px solid red` }),
          ...(fileView && { bgcolor: 'transparent', border: 'none' })
        }}
      >
        {type === 'date' && (
          <MuiCustomDateInput disabled={disabled} value={value} onChange={(val) => onChange(field, val)} />
        )}
        {type === 'checkbox' && (
          <Checkbox
            readOnly={noWritable}
            disabled={disabled}
            sx={{ ml: 1 }}
            checked={Boolean(value)}
            onChange={(e) => onChange(field, e.target.checked)}
          />
        )}
        {type === 'file' && (
          <MuiUploadFileInput
            disabled={disabled}
            placeholder="Pièces jointes"
            value={value}
            onChange={(val) => onChange(field, val)}
            fileView={fileView}
          />
        )}
        {(type === 'text' || type === 'number') && (
          <Input
            type={type}
            fullWidth
            value={value}
            readOnly={noWritable}
            disabled={disabled}
            onChange={(e) => onChange(field, e.currentTarget.value)}
            variant="soft"
            multiline
            sx={{ fontSize: 11, fontWeight: 'bold' }}
          />
        )}
        {type === 'select' && (
          <Select
            variant="plain"
            disabled={disabled}
            value={value}
            onChange={(_, val) => onChange(field, val)}
            slotProps={{ listbox: { sx: { zIndex: (t) => t.zIndex.modal + 1002, fontSize: 13, fontWeight: 600 } } }}
            sx={{ width: 1, bgcolor: 'transparent', fontSize: 13, fontWeight: 600 }}
          >
            {options.map((opt, index) => (
              <Option key={opt + index} value={opt}>
                {opt}
              </Option>
            ))}
          </Select>
        )}
      </Sheet>
      <Typography fontSize={8} color="error">
        {type === 'date' && error ? 'Date invalide' : helperText}
      </Typography>
    </Stack>
  );
};

export default InputLine;
