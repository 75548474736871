import { Icon } from '@iconify/react';
import { Button, CircularProgress, MenuItem, Stack, Typography } from '@mui/material';
import { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import CIconButton from 'src/components/CIconButton';
import { MuiCustomInput } from 'src/components/CustomInput';
import EllipsisText from 'src/components/EllipsisText';
import Iconify from 'src/components/Iconify';
import { CustomMenuPopover } from 'src/components/MenuPopover';
import Scrollbar from 'src/components/Scrollbar';
import useProject from 'src/hooks/useProject';
import { useToggleV2 } from 'src/hooks/useToggle';
import { dispatch, useSelector } from 'src/redux/store';

export default function AddProjectToTask({ task, user, onUpdate }) {
  const { project } = useSelector((state) => state.kanban);
  const [open, onOpen, onClose] = useToggleV2();
  const moreRef = useRef();
  const [currentProject, setCurrentProject] = useState(null);

  React.useEffect(() => {
    if (project?.length) {
      const exist = project?.find((_one) => _one?.id === task?.projectKey);
      setCurrentProject(exist);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project]);

  const onProjectSelected = (selected) => {
    dispatch(onUpdate(task, { projectKey: selected.id, projectName: selected?.name }));
    setCurrentProject(selected);
    onClose();
  };

  return (
    <Stack direction="row" spacing={1} alignItems="center" height={20}>
      <Stack
        borderRadius={1}
        component={CIconButton}
        noBorder
        disableGutters
        sx={{ px: 0.5, py: 0 }}
        dense
        ref={moreRef}
        onClick={onOpen}
      >
        {!isEmpty(currentProject?.name || task?.projectName) ? (
          <Typography fontWeight="bold" fontSize={13}>
            {currentProject?.name || task?.projectName || ''}
          </Typography>
        ) : (
          <Button size="small" color="inherit" sx={{ fontWeight: '200', p: 0.1 }} startIcon={<Icon icon="ion:add" />}>
            Ajouter à un projet
          </Button>
        )}
      </Stack>

      <Stack borderRadius={1} disableGutters sx={{ p: 0.5 }} dense>
        {!isEmpty(currentProject?.name || task?.projectName) ? (
          <Typography fontWeight="bold" fontSize={13}>
            {''}
          </Typography>
        ) : (
          <Button size="small" color="inherit" sx={{ fontWeight: '200', p: 0.1 }} startIcon={<Icon icon="ion:add" />}>
            Ajouter à une opération
          </Button>
        )}
      </Stack>

      {open && (
        <ProjectList
          user={user}
          open={open}
          onClose={onClose}
          anchorEl={moreRef.current}
          currentProject={currentProject}
          onProjectSelected={onProjectSelected}
        />
      )}
    </Stack>
  );
}

/**
 *
 * @param {{user: firebase.default.UserInfo, open: Boolean, onClose: function, anchorEl:Any, currentProject: Any, onProjectSelected: function(project) }} props
 */
const ProjectList = ({ user, open, onClose, anchorEl, currentProject, onProjectSelected }) => {
  const { projects, loading } = useProject({ id: user.uid, status: ['open'] });

  const projectSelected = (selected) => {
    onProjectSelected(selected);
  };

  return (
    <CustomMenuPopover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      sx={{ height: 300, py: 2 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      disabledArrow
    >
      <Stack height={1}>
        <Stack px={1}>
          <MuiCustomInput fullWidth placeholder="rechercher..." size="small" sx={{ p: 0, px: 1 }} />
        </Stack>
        <Scrollbar>
          {loading && <CircularProgress />}
          {projects.map((prod, index) => (
            <Stack
              component={MenuItem}
              key={prod?.id + index}
              direction="row"
              alignItems="center"
              spacing={3}
              width={1}
              onClick={() => projectSelected(prod)}
            >
              <Stack width={2}>
                {currentProject?.id === prod?.id && <Iconify icon="ic:round-done" sx={{ color: 'primary.main' }} />}
              </Stack>
              <EllipsisText text={prod?.name || ''} style={{ fontSize: 14 }} />
            </Stack>
          ))}
        </Scrollbar>
      </Stack>
    </CustomMenuPopover>
  );
};
