import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { TaskType } from 'src/models/task_m';
import { dispatch } from 'src/redux/store';
import { multipleFilesSave, SaveTaskDocs } from 'src/redux/slices/document';
import UploadingOneFileProgress from 'src/components/UploadingOneFileProgress';
import CustomFileView from 'src/components/CustomFileView';
import { serverTime } from 'src/utils/serverTime';

/**
 *
 * @param {{task: TaskType, user: firebase.default.UserInfo, onUpdate: function(string, any, function) }} props
 * @returns
 */
export default function TaskFileView({ task, user, onUpdate }) {
  const { email, displayName, uid: id } = user;
  const [files, setFiles] = useState(task?.attachments || []);
  const [isUploading, setUploading] = useState(false);
  const [uploadings, setUploadingFiles] = useState([]);

  useEffect(() => {
    setFiles(task?.attachments);
  }, [task?.attachments]);

  const onRemoveFile = (file) => {
    const rest = files.filter((att) => {
      if (att?.id) {
        return att.id !== file.id;
      }
      return att !== file;
    });
    setFiles([...rest]);
  };

  const onUpdateFile = (oldFile, newFile) => {
    const rest = files.map((att) => {
      if (oldFile?.id) {
        if (att?.id === oldFile?.id) {
          return newFile;
        }
        return att;
      }

      if (att === oldFile) {
        return newFile;
      }
      return att;
    });

    setFiles([...rest]);
  };

  const handleUploadAttachement = (_files) => {
    setUploading(true);

    const handleFiles = (newfiles) => {
      const user_to_files = newfiles.map((_file) => {
        return {
          ..._file,
          email,
          userId: id,
          id: _file?.id || uuidv4(),
          displayName,
          createAt: serverTime()
        };
      });

      setUploading(false);

      let newAttachs = files.map((_att) => {
        if (!_att?.id) {
          return { ..._att, id: uuidv4() };
        }
        return _att;
      });
      let attach = [...newAttachs, ...user_to_files];
      setFiles(attach);
      dispatch(onUpdate(task, { attachments: [...attach] }, () => {}));
      dispatch(SaveTaskDocs(newfiles));
    };
    multipleFilesSave(_files, handleFiles, null, setUploadingFiles);
  };

  return (
    <Stack width={1}>
      <CustomFileView
        attachments={files}
        isUploading={isUploading}
        onRemove={onRemoveFile}
        onUpdateFile={onUpdateFile}
        onChange={handleUploadAttachement}
      />
      {uploadings.map((upload, idx) => (
        <UploadingOneFileProgress key={upload?.name || '' + idx} uploading={upload} />
      ))}
    </Stack>
  );
}
