import React from 'react';
import {Stack , Box} from '@mui/material';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { PROGRESS_STATE } from './../constants/index';
import { styled, useTheme, alpha } from '@mui/material/styles';


const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));


export default function ProgressView({value, height, size, ...others }) {
  const theme = useTheme();
  return (
    <Stack direction="row" spacing={1}>
      <Box  sx={{ flexGrow: 1, mr: 0.5, mt:0.5 }} {...others}>
        <BorderLinearProgress
          sx={{
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor:  PROGRESS_STATE(value),
              },
            ...(height && { height}),
          }}
          variant="determinate" value={value} />
      </Box>
       <span style={{ color: theme.palette.mode === 'light' ? '#41414d' : '#828294', ...(size && { fontSize: size })}}>{Number(Number(value || 0).toFixed(0))}%</span>
    </Stack>
  );
}




const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 7,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: 'transparent',
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 7,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));



export function ProgressViewInverValue({value, height, size, color, ...others }) {

  return (
    <Stack direction="row" spacing={1} sx={{position:'relative'}}>
      <Box  sx={{ flexGrow: 1, mt:1, bgcolor:alpha(color, 0.4), borderRadius:7 }} {...others}>
        <BorderLinearProgress2
          sx={{
              [`& .${linearProgressClasses.bar}`]: {
                backgroundColor:  color,
              },
            ...(height && { height}),
          }}
          variant="determinate" value={value || 5} />
      </Box>
      <Stack width={1} justifyContent='center' alignItems='center' sx={{position:'absolute'}}>
      <span 
        style={{ 
          marginTop:5,
          color: '#FFFFFF',
          fontWeight:900,
          ...(size && { fontSize: size })
        }}
      >
          {Number(Number(value || 0).toFixed(0))}%
      </span>
      </Stack>
    </Stack>
  );
}
