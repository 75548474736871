import React,  {useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// eslint-disable-next-line
import saveFill from '@iconify/icons-eva/save-fill';
// eslint-disable-next-line
import downloadFill from '@iconify/icons-eva/download-fill';
import { useSnackbar } from 'notistack';
import { Stack, IconButton, Backdrop, CircularProgress, Tooltip } from '@mui/material';
import { SpreadsheetComponent } from '@syncfusion/ej2-react-spreadsheet';
import { L10n } from '@syncfusion/ej2-base';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import { defautL10nLocale } from './../constants/defaultL10nLocale';
import { saveFile } from 'src/redux/slices/document';

L10n.load({
    fr: defautL10nLocale
});

const saveUrl='https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/save';

export default function ExcelFileEditor({file, handleUpdate, onClose }) {
    const excelRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [uploading, setUploading] = useState(false);


   const onCreate = () =>{
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = (event) => {
        var blob = xhr.response;
        const _file = new File([blob], file?.name || '', { type: file?.type || '' });
        excelRef.current.open({file: _file});    
    };

    xhr.open('GET', file?.url);
    xhr.send();
   }

  
   const handleSave = () =>{
       setLoading(true);
       if(excelRef.current){
            excelRef.current?.save({
                url:saveUrl,
                fileName: file?.name,
                saveType: "Xlsx"
            });
        }
   }
  
   const beforSave = (args) =>{
       args.needBlobData = true;
       args.fileName = file?.name;
   }
 
   const saveComplete = (args) =>{
            let exportedDoc = args.blobData;
      
            const _file = new File([exportedDoc], file?.name, { type: file?.type });
            saveFile(_file, handleUpdate, ()=>{
                                            setLoading(false); 
                                            onClose();
                                            enqueueSnackbar('Document enrégistré avec succès', { variant: 'success' });
                                        }, setUploading, file?.id);
   }


  return (
  <div>
        <Backdrop open={loading} sx={{ color:"#fff", zIndex: (theme) => theme.zIndex.drawer + 150 }}>
            {!uploading ? <CircularProgress color="inherit" />
            : 
            <CircularProgressWithLabel size={70} color='inherit' value={uploading?.progress || 0} />}
        </Backdrop>

        <Stack sx={{bgcolor:'#0B6E17' }} justifyContent="flex-end" direction="row" width={1}>

            <Stack direction="row" spacing={3}  sx={{ py: 0.5, px: 2 }}>   

                <Stack direction="row" height={30} width={25}>
                    <Tooltip title="Enrégistrer">
                        <IconButton size="small" color="default" onClick={handleSave}>
                            <Icon color="white" icon={saveFill} width={22} height={22} />
                        </IconButton>
                    </Tooltip>
                </Stack>
                
                <Stack direction="row" height={30} width={25}>
                    <Tooltip title="Fermer">
                        <IconButton size="small" color="inherit" onClick={onClose}>
                            <Icon color="white" icon={closeFill} width={22} height={22} />
                        </IconButton>
                    </Tooltip>
                </Stack>

            </Stack>
            
        </Stack>

    <SpreadsheetComponent 
        ref={excelRef}
        openUrl='https://ej2services.syncfusion.com/production/web-services/api/spreadsheet/open'
        created={onCreate}
        beforeSave={beforSave}
        saveComplete={saveComplete}
        locale='fr'
        height='90vh'
    >

      </SpreadsheetComponent>
  </div>
  );
}
