import { createContext, useContext, useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import { useToggleV2 } from 'src/hooks/useToggle';
import { useMicrosoftMail } from 'src/contexts/MicrosoftMailContext';

import { isEmpty, keys, isEqual } from 'lodash';

import MailLogin from 'src/section/mail/MailLogin';
import MailComposer from 'src/section/mail/MailComposer';
import MailAccountColor from 'src/section/mail/MailAccountColor';
import { igniteMsAccount, refreshLinkedAccount } from 'src/redux/slices/microsoftMail';

export const useMailContext = () => useContext(MailContext);

export default function MailProvider({ children, composerAnchor }) {
  const { user } = useAuth();
  const dispatch = useDispatch();

  const [folder, setFolder] = useState(MAIL_FOLDER.INBOX);
  const [account, setAccount] = useState(null);
  const [mails, setMails] = useState([]);
  const [readingMail, setReadingMail] = useState(null);
  const [isComposerOpen, onOpenComposer, onCloseComposer] = useToggleV2();
  const [isLoginBoxOpen, onOpenLoginBox, onCloseLoginBox] = useToggleV2();

  const [readMail, setReadMail] = useState(null);

  const [prevLinkedAccount, setPrevLinkedAccount] = useState({});

  const [isColorsOpen, onOpenColors, onCloseColors] = useToggleV2();

  const microsoftMail = useMicrosoftMail();

  const mailsLoading = useMemo(() => {
    return microsoftMail.loadingSnap.length !== 0;
  }, [microsoftMail.loadingSnap.length]);

  const linkedMails = useMemo(() => [...microsoftMail.accounts], [microsoftMail.accounts]);

  const contentState = useMemo(
    () => getMailState(mailsLoading, linkedMails, account, mails, readingMail),
    [account, linkedMails, mails, mailsLoading, readingMail]
  );

  const mailsOfCurrentFolder = useMemo(() => {
    let current = [];

    keys(microsoftMail.mailsSnap).forEach((email) => {
      const snap = (microsoftMail.mailsSnap[email] || {})[folder];
      const account = linkedMails?.find((el) => el?.email === email);
      keys(snap).forEach((id) => {
        current.push({ ...snap[id], account, folder });
      });
    });

    return current;
  }, [folder, linkedMails, microsoftMail.mailsSnap]);

  const handleDisconnectAccount = (account) => {
    console.log('disconnect', account);
  };

  const handleLinkMail = (account) => {
    console.log('handleLinkMail', account);
  };

  const openAccountColorPicker = (account) => {
    onOpenColors();
    setAccount(account);
  };
  const handlePickAccountColor = (account, color) => {
    console.log('color picker', account, color);

    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.updateAccount(account.email, { color, id: account?.id });
    }
  };

  const sendMail = (account, mail, onResolve) => {
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.sendMail(account?.email, mail, onResolve);
    }
  };

  const getMailAttachments = (account, folderType, mailId, onResolve) => {
    console.log('level 1');
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.getMailAttachments(account, folder, mailId, onResolve);
    }
  };

  const deleteMail = (account, folder, mailId, onResolve) => {
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.deleteMail(account, folder, mailId, onResolve);
    }
  };

  const updateMail = (account, folder, maiId, values) => {
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.updateMail(account, folder, maiId, values);
    }
  };

  const toggleMailImportance = (account, folder, mailId, isImportant, onResolve) => {
    console.log('toggleMailImportance process', account, folder, mailId);
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.toggleMailImportance(account, folder, mailId, isImportant, onResolve);
    }
  };

  const openMail = (mail) => {
    setReadMail(mail);
    if (mail?.account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.markAsRead(mail?.account?.user?.email, mail?.folder, mail?.id);
    }
  };

  const logout = (account) => {
    if (account?.platform === MAIL_PLATFORM.MICROSOFT) {
      microsoftMail.logout(account);
    }
  };

  useEffect(() => {
    const bc = new BroadcastChannel('mail_chanel');

    bc.onmessage = (ev) => {
      const account = {
        ...ev?.data,
        email: ev?.data?.user?.email
      };

      onCloseLoginBox();
      onOpenColors();
      setAccount(account);

      dispatch(refreshLinkedAccount());

      console.log('channel', account);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(refreshLinkedAccount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const linkedAccount = user.linkedAccount;

    if (!isEmpty(linkedAccount)) {
      if (isEqual(linkedAccount, prevLinkedAccount)) return;

      let microsoft = {};

      keys(linkedAccount).forEach((email) => {
        const data = linkedAccount[email];

        if (!data) return;

        if (data?.platform === MAIL_PLATFORM.MICROSOFT) {
          microsoft = {
            ...microsoft,
            [email]: data
          };
        }
      });

      dispatch(igniteMsAccount({ accountSnap: microsoft }));
      setPrevLinkedAccount(linkedAccount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.linkedAccount]);

  const store = {
    folder,
    setFolder,
    mailsLoading,
    account,
    setAccount,
    logout,
    emails: linkedMails,
    mailsOfCurrentFolder,
    disconnectAccount: handleDisconnectAccount,
    openComposer: onOpenComposer,
    openLoginBox: onOpenLoginBox,
    contentState,
    openAccountColorPicker,
    readMail,
    setReadMail,
    openMail,
    sendMail,
    deleteMail,
    toggleMailImportance,
    getMailAttachments,
    updateMail
  };

  return (
    <MailContext.Provider value={store}>
      {children}

      {isComposerOpen && <MailComposer anchor={composerAnchor} open onClose={onCloseComposer} />}

      {isLoginBoxOpen && <MailLogin open onClose={onCloseLoginBox} onLink={handleLinkMail} />}

      {isColorsOpen && (
        <MailAccountColor account={account} onChoose={handlePickAccountColor} open onClose={onCloseColors} />
      )}
    </MailContext.Provider>
  );
}

const MailContext = createContext({
  emails: [],
  mailsOfCurrentFolder: [],
  mailsLoading: false,
  folder: null,
  setFolder: (folder) => Promise.all(),
  account: null,
  setAccount: (account) => Promise.all(),
  disconnectAccount: (account) => Promise.all(),
  openComposer: () => Promise.all(),
  openLoginBox: () => Promise.all(),
  contentState: null,
  openAccountColorPicker: (account) => Promise.all(),
  readMail: null,
  setReadMail: (mail) => Promise.all(),
  updateMail: (account, folder, maiId, values) => Promise.all(),
  openMail: (mail) => Promise.all(),
  logout: (account) => Promise.all(),
  /**
   *
   * @param account
   * @param mail {{attachments: string[], subject: string, from: string, to: string, cc: string, body: string}} * @returns {*}
   * @param onResolve
   * @returns {*}
   */
  sendMail: (account, mail, onResolve) => Promise.all(),
  deleteMail: (account, folder, mailId, onResolve) => Promise.all(),
  toggleMailImportance: (account, folder, mailId, isImportant, onResolve) => Promise.all(),
  getMailAttachments: (email, folderType, mailId, onResolve) => Promise.all()
});

//#region folder
export const MAIL_FOLDER = {
  INBOX: 'INBOX',
  SENT: 'SENT',
  SPAM: 'SPAM',
  TRASH: 'TRASH'
};

export const MAIL_PLATFORM = {
  MICROSOFT: 'microsoft'
};

export const mailFolderOptions = [
  {
    label: 'Boite de réception',
    icon: 'material-symbols:inbox-outline',
    value: MAIL_FOLDER.INBOX
  },
  {
    label: 'Envoyé',
    icon: 'material-symbols:send-outline',
    value: MAIL_FOLDER.SENT
  },
  {
    label: 'Spam',
    icon: 'lucide:mail-warning',
    value: MAIL_FOLDER.SPAM
  },
  {
    label: 'Corbeille',
    icon: 'bi:trash',
    value: MAIL_FOLDER.TRASH
  }
];

//#endregion

const fakeMails = [
  {
    email: 'tleguede@gmail.com',
    token: '',
    expire: new Date()
  },
  {
    email: 'random@gmail.com',
    token: '',
    expire: new Date()
  }
];

const getMailState = (mailsLoading, linkedMails = [], currentAccount, mails, readingMail) => {
  if (mailsLoading) return CONTENT_STATE.LOADING;
  if (linkedMails?.length === 0) return CONTENT_STATE.NO_LINKED_ACCOUNT;
  if (mails?.length === 0) return CONTENT_STATE.NO_EMAILS;
  if (readingMail) return CONTENT_STATE.READING_MAILS;

  return CONTENT_STATE.LIST_MAILS;
};

const CONTENT_STATE = {
  LOADING: 'LOADING',
  NO_LINKED_ACCOUNT: 'NO_LINKED_ACCOUNT',
  NO_EMAILS: 'NO_EMAILS',
  LIST_MAILS: 'LIST_MAILS',
  READING_MAILS: 'READING_MAILS'
};
