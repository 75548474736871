import React, {useState, useMemo, useRef} from 'react'
import {useToggleV2} from "src/hooks/useToggle";

import {DateTime} from 'luxon'
import {datesBetween, gDate, getOnlyDate, gfDateTime, isToday} from "src/utils/formatTime";
import {capitalize, orderBy, min, max, values, uniqBy, keys} from "lodash";
import {format} from "date-fns";
import {fr as LocalFr} from "date-fns/locale";


import {Stack, Typography, Button, Paper, Tooltip} from '@mui/material'
import {DatePicker, TimePicker} from "antd";


import {Icon} from '@iconify/react'
import AudienceMinuteFormater from "src/section/_dashboard/acceuil-v2/audience/AudienceMinuteFormater";
import Scrollbar from "src/components/Scrollbar";
import createAvatar from "src/utils/createAvatar";

export default function MeetDays({start, end, defaultSelected = [], disponibility, onChange, disabled = false}) {
    const ref = useRef()
    const instance = getFiveDates(start, end, disponibility)
    const [days, setDays] = useState(instance.days)
    const [daysWithHours, setDaysWithHours] = useState(uniqDate([...defaultSelected, ...instance.daysWithHours]))
    const [selectedDates, setSelectedDates] = useState(uniqDate([...defaultSelected]))

    const groupedPerMonth = useMemo(() => paginateChatMessage(days), [days])

    const handleSelect = (date) => {
        const metas = selectedDates?.map(el => el?.toString())
        const _current = date.toString();

        if (metas.includes(_current)) {
            // setSelectedDates(prevState => [...prevState]?.filter(el => el?.toString() !== date.toString()))
            handleChange([...selectedDates]?.filter(el => el?.toString() !== date.toString()))

        } else {
            // setSelectedDates(prevState => [...prevState, date])
            handleChange([...selectedDates, date])

        }
    }

    const handleAddDay = (day) => {
        setDays(prevState => [...prevState, getOnlyDate(day)])

        setTimeout(()=>{
            const id = DateTime.fromJSDate(day).ordinal
            const element = document.getElementById(id)
            element?.scrollIntoView({behavior: 'smooth', block: 'end'})
         },1000)

    }


    const handleAddDayWithHour = (cursor, day) => {
        const _cursor = DateTime.fromJSDate(cursor)
        const _day = DateTime.fromJSDate(day)
        const result = new Date(_cursor.year, cursor.getMonth(), _cursor.day, _day.hour, _day.minute)

        setDaysWithHours(prevState => [...prevState, result])
        // setSelectedDates(prevState => [...prevState, result])
        handleChange([...selectedDates, result])
    }

    const handleChange = (change) => {
        onChange && onChange(change)
        setSelectedDates(change)
    }

    return (
        <Stack direction={'row'} spacing={2} height={1}>
            <Scrollbar sx={{height: 1}}>


                <Stack direction={'row'} spacing={4} height={1}>

                    {
                        groupedPerMonth?.map(el => {
                            const {color2} = createAvatar(el?.title)
                            return (
                                <Stack spacing={2}>
                                    <Stack>
                                        <Typography variant={'caption'}>
                                            {capitalize(el?.title)}
                                        </Typography>
                                        <Stack width={50} height={2} bgcolor={color2} borderRadius={15}/>
                                    </Stack>

                                    <Stack direction={'row'} spacing={2} height={1} key={el?.cursor}>

                                        {
                                            el?.dates?.map(cursor => {
                                                const id=DateTime.fromJSDate(cursor).ordinal
                                                // console.log('cursr',id)
                                                // new Date().getUTCMilliseconds()

                                                return (
                                                    <div
                                                        id={id}
                                                        key={cursor?.toString()}
                                                    >
                                                        <DayCol

                                                            cursor={cursor}
                                                            dates={getCurrentDatesWithHours(cursor, daysWithHours)}
                                                            selectedDates={selectedDates}
                                                            onSelect={handleSelect}
                                                            onAddHour={handleAddDayWithHour}
                                                            disabled={disabled}
                                                        />
                                                    </div>

                                                );
                                            })
                                        }

                                    </Stack>
                                </Stack>
                            );
                        })
                    }
                </Stack>
                {/*<Stack direction={'row'} spacing={2} height={1}>*/}

                {/*    {*/}
                {/*        days?.map(cursor => (*/}
                {/*            <DayCol*/}
                {/*                key={cursor?.toString()}*/}
                {/*                cursor={cursor}*/}
                {/*                dates={getCurrentDatesWithHours(cursor, daysWithHours)}*/}
                {/*                selectedDates={selectedDates}*/}
                {/*                onSelect={handleSelect}*/}
                {/*                onAddHour={handleAddDayWithHour}*/}
                {/*                disabled={disabled}*/}
                {/*            />*/}
                {/*        ))*/}
                {/*    }*/}
                {/*    <Stack ref={ref} minWidth={150} height={10} sx={{color: 'white'}}>*/}
                {/*        .testa*/}
                {/*    </Stack>*/}
                {/*</Stack>*/}
            </Scrollbar>

            <NewDateBtn onAdd={handleAddDay} disabled={disabled}/>
        </Stack>
    )
}

const DayCol = ({cursor, dates = [], selectedDates = [], onSelect, onAddHour, disabled}) => {
    const [hover, onHover, onLeave] = useToggleV2()
    const [open, onOpen, , , onStateChange] = useToggleV2()

    const _dates = useMemo(() => {
        return dates?.map(el => ({
            date: el,
            key: el?.toString()
        }))
    }, [dates])

    const props = () => {
        return isToday(cursor) ? {fontWeight: 'bold'} : {};
    }
    return (
        <Stack onMouseLeave={onLeave} onMouseEnter={onHover} height={1}>

            <Stack
                pl={.5}
                spacing={1}
                width={100}
                sx={{
                    borderStyle: 'solid',
                    borderWidth: 2,
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderColor: t => isToday(cursor) ? t?.palette?.primary?.main : 'transparent'
                }}
            >
                <Typography fontSize={20} {...props()}>
                    {capitalize(frenchFormat(cursor, 'EEEE'))}
                </Typography>
                <Typography {...props()}>
                    {capitalize(frenchFormat(cursor, 'dd'))}
                </Typography>
            </Stack>

            <Stack spacing={3} mt={3}>
                {
                    orderBy(_dates, 'key',)?.map(el => (
                        <HourBtn
                            key={el?.key}
                            date={el?.date}
                            onClick={() => !disabled && onSelect(el?.date)}
                            isSelected={isSelected(el?.date, selectedDates)}
                        />
                    ))
                }
            </Stack>

            <Stack height={90} width={1}>
                {
                    !disabled && hover && (
                        <Button
                            color={'inherit'}
                            startIcon={<Icon icon={'eos-icons:modified-date-outlined'}/>}
                            sx={{mt: 2}}
                            onClick={onOpen}
                        >
                            Ajouter
                        </Button>
                    )
                }
            </Stack>

            <AudienceMinuteFormater trigger={open}>
                {
                    open && (
                        <TimePicker
                            open={open} onOpenChange={onStateChange}
                            style={{position: "absolute", opacity: 0}}
                            popupStyle={{zIndex: 1550}}
                            showTime={false}
                            format="YYYY-MM-DD HH:mm"
                            onChange={(_, val) => onAddHour(cursor, gDate(val))}

                        />
                    )

                }
            </AudienceMinuteFormater>
        </Stack>
    )
}

const HourBtn = ({date, isSelected, onClick}) => {
    return (
        <>
            <Button
                size={'small'}
                variant={isSelected ? "contained" : 'outlined'}
                onClick={onClick}
                startIcon={isSelected ? <Icon icon={'bi:check-lg'}/> : <Icon icon={'bx:time-five'}/>}
                color={isSelected ? "primary" : 'inherit'}
                sx={{py: 2}}
            >
                {frenchFormat(date, 'HH:mm')}
            </Button>
        </>
    )
}

const NewDateBtn = ({onAdd, disabled}) => {
    const [open, onOpen, , , onStateChange] = useToggleV2()

    return (
        <Stack px={2}
               pt={2}
               sx={{
                   boxShadow: "-12px 1px 14px -19px rgba(0,0,0,0.17)"
               }}
        >

            <Stack direction={'row'}>
                <Tooltip title={'Ajouter une date'}>
                    <Button
                        variant={"outlined"} color={'inherit'}
                        disabled={disabled} onClick={onOpen}
                        startIcon={<Icon icon={'carbon:add-alt'}/>}>
                        Jour
                    </Button>
                </Tooltip>
            </Stack>
            {
                open && (
                    <DatePicker
                        open={open} onOpenChange={onStateChange}
                        style={{position: "absolute", opacity: 0}}
                        popupStyle={{zIndex: 1550}}
                        showTime={false}
                        format="YYYY-MM-DD HH:mm"
                        onChange={(_, val) => onAdd(gDate(val))}

                    />
                )

            }
        </Stack>
    )
}

export const audienceProps = (audience) => {
    const proposedDates = (audience?.proposedDates || [])?.map(el => gDate(el))

    if (proposedDates.length === 0) {

        return {}
    }

    if (proposedDates.length === 1) {
        const start = gDate(proposedDates[0])
        return {start, defaultSelected: [start]}
    }

    if (proposedDates.length >= 2) {

        const start = min(proposedDates)
        const end = max(proposedDates)
        return {start, end, defaultSelected: proposedDates}
    }

    return {}
}

//#region utils
const isSelected = (cursor, list = []) => {
    const metas = list?.map(el => el?.toString())
    const _current = cursor.toString();
    return metas.includes(_current)
}
const getFiveDates = (start, end, disponibility) => {
    const daysWithHours = []
    let days = datesBetween(new Date(), DateTime.now().plus({days: 5}).toJSDate())
        ?.map(el => getOnlyDate(el))

    if (start && !end) {
        days = datesBetween(gDate(start), DateTime.fromJSDate(gDate(start)).plus({days: 5}).toJSDate())
            ?.map(el => getOnlyDate(el))
    }

    if (start && end) {
        days = [...datesBetween(gDate(start), gDate(end))
            ?.map(el => getOnlyDate(el)), getOnlyDate(end)]
    }

    for (const day of days) {
        const instance = DateTime.fromJSDate(day);
        const hours = values(disponibility)?.find(el => el?.weekday === instance.weekday)?.hours || []
        if (hours?.length !== 0) {
            // console.log(day, hours)
            for (const hour of hours) {
                daysWithHours.push(instance.set({hour: hour?.hour, minute: hour?.minutes}).toJSDate())
            }
        }
        // daysWithHours.push(DateTime.fromJSDate(day).set({hour: 9}).toJSDate())
        // daysWithHours.push(DateTime.fromJSDate(day).set({hour: 10}).toJSDate())
        // daysWithHours.push(DateTime.fromJSDate(day).set({hour: 15}).toJSDate())
        // daysWithHours.push(DateTime.fromJSDate(day).set({hour: 16}).toJSDate())
    }

    return {daysWithHours, days};
}

const getCurrentDatesWithHours = (cursor, dates = []) => {
    return dates?.filter(el => {
        return getOnlyDate(el).toString() === getOnlyDate(cursor).toString()
    })
}
const frenchFormat = (date, mask) => {
    return format(gDate(date), mask || 'MMMM yyyy', {
        locale: LocalFr
    })
}

const uniqDate = dates => {
    const format = dates?.map(date => ({
        date,
        key: gfDateTime(date)
    }))

    const result = uniqBy(format, 'key')

    return result?.map(el => el?.date)
}

//#endregion

function paginateChatMessage(dates = []) {
    return generate(dates)?.map(el => {

        return {
            ...el,
            title: gfDate(el?.cursor),
            dates: el?.dates?.sort((date1, date2) => date1 - date2)
        }
    })
}


export function getCursor(date) {
    const parsed = gDate(date);

    const year = parsed.getFullYear();
    const month = parsed.getMonth();

    return new Date(year, month, 1);
}

function generate(list = []) {
    const map = {};

    list.forEach(el => {
        const cursor = getCursor(el);
        const isIncluded = Boolean(keys(map).filter(key => key.toString() === cursor.toString()).length);

        if (isIncluded) {
            map[cursor] = [...map[cursor], el];
        } else {
            map[cursor] = [el];
        }
    })

    return keys(map).map(cursor => ({
        cursor,
        dates: map[cursor]
    }))

}

export const gfDate = date => {
    const parsed = gDate(date);
    if (parsed === null) return ''
    const year = parsed.getFullYear();
    const isTheCurrentYear = year === (new Date()).getFullYear()


    return date ? format(parsed, `MMM yyyy`, {
        locale: LocalFr
    }) : null;
};



