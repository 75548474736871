import {Badge, Button, Dialog, Divider, MenuItem, Paper, Stack, TextField, Typography} from "@mui/material";
import {Icon} from "@iconify/react";
import {themeColor} from "src/constants/color";
import Scrollbar from "src/components/Scrollbar";
import Center from "src/components/Center";
import LabelStyle from "src/components/LabelStyle";
import {customTextFieldTheme} from "src/components/CustomInput";
import {useTheme} from "@mui/material/styles";
import {Field} from "src/section/contact/v4/ContactForm_V4";
import TaskDescriptionInput from "src/section/tasks/components/TaskDescriptionInput";

export default function ContactSendMail_V4({open, onClose}) {
    const theme = useTheme();

    return (
        <Dialog open={open} onClose={onClose} maxWidth={'xl'}>
            <Paper sx={{borderRadius: 1}} variant="outlined">
                <Stack py={1} width={'50vw'} minHeight={'60vh'}>

                    {/*Header*/}
                    <Stack>
                        <Stack direction={'row'} justifyContent={'space-between'} px={3} py={2}>

                            <Typography variant={'h6'}>
                                Email
                            </Typography>

                            <Button
                                startIcon={<Icon icon="ic:baseline-send"/>}
                                sx={{
                                    color: 'white',
                                    fontWeight: 100,
                                    px: 2,
                                    fontSize: 13,
                                    bgcolor: 'black',
                                    ':hover': {bgcolor: themeColor.CARROT_ORANGE, color: themeColor.RICH_BLACK}
                                }}
                            >
                                Envoyer
                            </Button>
                        </Stack>
                        <Divider/>
                    </Stack>

                    <Scrollbar>
                        <Stack height={1} width={1} p={2} spacing={3}>

                            <Stack spacing={2}>
                                <Field label={'Destinaraires'} value={'random@guy.dot,'}/>
                                <Field label={'Objet'}/>
                            </Stack>

                            <Stack spacing={.5} width={1}>
                                <LabelStyle>
                                    MESSAGE
                                </LabelStyle>
                                <Field   minRows={4} multiline/>

                                {/*<TaskDescriptionInput placeholder={'message...'} task={{}} onUpdate={() => {*/}
                                {/*}}/>*/}
                            </Stack>

                        </Stack>

                    </Scrollbar>


                </Stack>
            </Paper>
        </Dialog>

    )
}