import React from 'react';
import { Paper, Stack } from '@mui/material';
import DocHeaderDashboard from 'src/components/fileDriver/documentBreadcrumbs/DocHeaderDashboard';

export default function TaskDetailsHeaderScrumb({path}) {
  return (
    <Stack direction='row' alignItems='center' border={t => `1px solid ${t.palette.divider}`} component={Paper} variant='outline' px={1}> 
       <DocHeaderDashboard 
       sx={{ mb: 0 }} 
       color='inherit'
       typographyStyle={{ fontSize: 13 }}
       separator="›"
       links={path} />
    </Stack>
  )
}
