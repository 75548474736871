import {useToggleV2} from "src/hooks/useToggle";
import ActionModal from "src/components/ActionModal";
import {Button, Checkbox, Stack, Typography} from "@mui/material";
import React from "react";

export default function DeleteModalOption  ({canDelete = false, open, onClose, onAccept, title, notDeleteText, deleteText})  {
    const [isConfirmed, _, __, setConfirm] = useToggleV2()

    const handleDelete = () => {
        onAccept()
        onClose()
    }

    return (
        <>

            {
                !canDelete && open && (
                    <ActionModal
                        title={title || 'Suppression d\'un évènement'}
                        color='error'
                        open
                        onClose={onClose}
                        desc={notDeleteText || "Vous ne pouvez pas supprimer ce projet. Verifiez si vous etês le créateur ou si vous avez les autorisations requis "}
                        closeText='OK'
                    />
                )

            }

            {
                canDelete && open && (
                    <ActionModal
                        title={title || 'Suppression de projet'}
                        color='error'
                        open
                        onClose={onClose}
                        moreAction={
                            <Stack direction='row' spacing={1} alignItems='center'>
                                <Checkbox size='small' color='error' checked={isConfirmed}
                                          onChange={(e,) => setConfirm(e.target.checked)}/>
                                <Typography fontSize={13} fontWeight={120}>
                                    {deleteText || "Je comprends qu'une fois supprimé, le projet ne pourra plus être récupéré"}
                                </Typography>
                            </Stack>
                        }
                        action={
                            <Button
                                color='error' variant='contained'
                                disabled={!isConfirmed} onClick={handleDelete}
                            >
                                Appliquer
                            </Button>
                        }
                    />
                )

            }

        </>
    )
}