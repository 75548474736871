import React, { useMemo } from 'react';
import { Icon } from '@iconify/react';
import { Radio, Tooltip as ATooltip } from 'antd';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';
import { Button, IconButton, Stack, Tooltip } from '@mui/material';
import { AffectationType } from 'src/models/Affectation_m';
import { AFFECT_STATE_VALIDATION } from 'src/constants';
import { baseColors } from 'src/constants/color';

/** @param {{ affect: AffectationType, onChange: Function, user: Any }} */
export default function AffectValidation({ affect, onChange, isButton = false, user }) {
  const iconCheck = () => {
    if (affect.state === AFFECT_STATE_VALIDATION.NOT_ASSIGN) return 'akar-icons:arrow-forward-thick-fill';
    if (affect.state === AFFECT_STATE_VALIDATION.PENDING) return 'clarity:play-solid';
    if (affect.state === AFFECT_STATE_VALIDATION.INPROGRESS) return 'akar-icons:light-bulb';
    if (affect.state === AFFECT_STATE_VALIDATION.DONE) return 'akar-icons:circle-check-fill';
    if (affect.state === AFFECT_STATE_VALIDATION.ACCEPTED) return 'ant-design:like-filled';
    if (affect.state === AFFECT_STATE_VALIDATION.REJECTED) return 'charm:refresh';
    return 'ant-design:stop-outlined';
  };
  const colorState = () => {
    if (affect.state === AFFECT_STATE_VALIDATION.NOT_ASSIGN) return !isButton ? baseColors.GREEN : 'success';
    if (affect.state === AFFECT_STATE_VALIDATION.PENDING) return !isButton ? baseColors.GREYLIGHT : 'inherit';
    if (affect.state === AFFECT_STATE_VALIDATION.INPROGRESS) return !isButton ? baseColors.BLUE : 'info';
    if (affect.state === AFFECT_STATE_VALIDATION.DONE) return !isButton ? baseColors.BLUE : 'info';
    if (affect.state === AFFECT_STATE_VALIDATION.ACCEPTED) return !isButton ? baseColors.GREEN : 'success';
    if (affect.state === AFFECT_STATE_VALIDATION.REJECTED) return !isButton ? baseColors.RED : 'error';
    return !isButton ? baseColors.GREYLIGHT : 'inherit';
  };
  const nextAction = () => {
    if (affect.state === AFFECT_STATE_VALIDATION.NOT_ASSIGN) return AFFECT_STATE_VALIDATION.PENDING;
    if (affect.state === AFFECT_STATE_VALIDATION.PENDING) return AFFECT_STATE_VALIDATION.INPROGRESS;
    if (affect.state === AFFECT_STATE_VALIDATION.INPROGRESS) return AFFECT_STATE_VALIDATION.DONE;
    if (affect.state === AFFECT_STATE_VALIDATION.DONE) return AFFECT_STATE_VALIDATION.ONVALIDATE;
    if (affect.state === AFFECT_STATE_VALIDATION.ACCEPTED) return AFFECT_STATE_VALIDATION.ACCEPTED;
    if (affect.state === AFFECT_STATE_VALIDATION.REJECTED) return AFFECT_STATE_VALIDATION.INPROGRESS;
    return AFFECT_STATE_VALIDATION.NOT_ASSIGN;
  };

  const tooltipTitle = () => {
    if (affect.state === AFFECT_STATE_VALIDATION.NOT_ASSIGN) return 'Assigner';
    if (affect.state === AFFECT_STATE_VALIDATION.PENDING) return 'Démarrer';
    if (affect.state === AFFECT_STATE_VALIDATION.INPROGRESS) return 'En cours';
    if (affect.state === AFFECT_STATE_VALIDATION.DONE) return 'Terminée';
    return affect.state;
  };

  const isSupManager = useMemo(
    () => Boolean(user.id === affect?.responsable?.id || user.id === affect?.createdBy?.id),
    [affect, user.id]
  );

  const isManager = useMemo(
    () => Boolean(affect?.assigne?.responsable.find((_one) => _one.id === user.id)),
    [affect, user.id]
  );

  const onClick = (action) => () => {
    onChange && onChange(action);
  };

  const disabled = useMemo(() => {
    if (affect.state === AFFECT_STATE_VALIDATION.NOT_ASSIGN || affect.state === AFFECT_STATE_VALIDATION.ONVALIDATE) {
      return !isSupManager;
    }
    return false;
  }, [affect, isSupManager]);

  const disabledM = useMemo(() => {
    if (affect.state === AFFECT_STATE_VALIDATION.DONE) {
      return !isManager && !isSupManager;
    }
    return false;
  }, [affect, isManager, isSupManager]);

  const disableVal = useMemo(() => affect.state === AFFECT_STATE_VALIDATION.ACCEPTED, [affect]);

  const allAssignIds = useMemo(() => Boolean(affect?.assigne?.responsable?.length), [affect]);

  return (
    <Stack>
      {isButton ? (
        affect.state === AFFECT_STATE_VALIDATION.ONVALIDATE ? (
          <Stack direction="row" spacing={2}>
            <Button
              size="small"
              disabled={disabled}
              variant="contained"
              color="error"
              onClick={onClick(AFFECT_STATE_VALIDATION.REJECTED)}
            >
              {AFFECT_STATE_VALIDATION.REJECTED}
            </Button>
            <Button
              size="small"
              disabled={disabled}
              variant="contained"
              color="success"
              onClick={onClick(AFFECT_STATE_VALIDATION.ACCEPTED)}
            >
              {AFFECT_STATE_VALIDATION.ACCEPTED}
            </Button>
          </Stack>
        ) : (
          <Button
            size="small"
            disabled={disabledM || disabled || !allAssignIds || disableVal}
            variant="contained"
            color={colorState()}
            onClick={onClick(nextAction())}
          >
            {affect.state === AFFECT_STATE_VALIDATION.NOT_ASSIGN
              ? 'Assigner'
              : affect.state === AFFECT_STATE_VALIDATION.PENDING
              ? 'Démarrer'
              : affect.state === AFFECT_STATE_VALIDATION.INPROGRESS
              ? 'Terminer'
              : affect.state}
          </Button>
        )
      ) : affect.state === AFFECT_STATE_VALIDATION.ONVALIDATE ? (
        <Radio.Group size="small" type="text" value="none">
          <ATooltip title={isSupManager ? 'Rejeter' : 'En attente de validation'}>
            <Radio.Button
              disabled={disabled}
              style={{ backgroundColor: '#FFAF8A' }}
              onClick={onClick(AFFECT_STATE_VALIDATION.REJECTED)}
            >
              <DislikeOutlined twoToneColor="#DB3C2C" />
            </Radio.Button>
          </ATooltip>
          <ATooltip title={isSupManager ? 'Accepter' : 'En attente de validation'}>
            <Radio.Button
              disabled={disabled}
              style={{ backgroundColor: '#CBF16E' }}
              onClick={onClick(AFFECT_STATE_VALIDATION.ACCEPTED)}
            >
              <LikeOutlined twoToneColor="#58960A" />
            </Radio.Button>
          </ATooltip>
        </Radio.Group>
      ) : (
        <Tooltip title={tooltipTitle()} arrow>
          {disabledM || disabled || !allAssignIds ? (
            <Icon icon={iconCheck()} />
          ) : (
            <IconButton
              size="small"
              sx={{ bgcolor: colorState(), color: 'white', ':hover': { bgcolor: colorState() } }}
              onClick={disableVal || disabledM || disabled || !allAssignIds ? undefined : onClick(nextAction())}
            >
              <Icon icon={iconCheck()} />
            </IconButton>
          )}
        </Tooltip>
      )}
    </Stack>
  );
}
