import { IconButton, Stack } from '@mui/material';
import { Avatar } from 'antd';
import UserAvatar from 'src/components/UserAvatar';
import React, { useRef } from 'react';
import useToggle from 'src/hooks/useToggle';
import UserPicker from 'src/components/UserPicker';
import { Icon } from '@iconify/react';
import personAdd from '@iconify/icons-eva/person-add-fill';

export default function AssigneeView({ users = [], maxCount = 6, onChange, hasError, pureWhite, single = false }) {
  const anchorRef = useRef(null);
  const { open, handleClose, handleOpen } = useToggle();

  return (
    <Stack direction="row" ref={anchorRef} sx={{ cursor: 'pointer' }}>
      <Stack onClick={handleOpen}>
        {users?.length > 0 ? (
          <Avatar.Group maxCount={maxCount} size="small">
            {users?.map((el) => (
              <UserAvatar user={el} key={el?.id} size={30} />
            ))}
          </Avatar.Group>
        ) : (
          <IconButton
            color={hasError ? 'error' : 'default'}
            size="small"
            sx={{
              border: (t) => `1px dashed ${t.palette.divider}`,
              p: 1,
              bgcolor: pureWhite ? 'white' : null
            }}
          >
            <Icon icon={personAdd} height={17} />
          </IconButton>
        )}
      </Stack>

      <UserPicker
        anchorEl={anchorRef}
        open={open}
        onClose={handleClose}
        bottomRight
        onChange={(_, users) => onChange(users)}
        users={users}
        single={single}
      />
    </Stack>
  );
}
