import {useState, useRef} from "react";
import {useFormik} from "formik";
import {useSnackbar} from "notistack";
import useAuth from "src/hooks/useAuth";
import {useDispatch} from "src/redux/store";
import useIsMountedRef from "src/hooks/useIsMountedRef";
import {useTheme} from "@mui/material/styles";
import {useToggleV2} from "src/hooks/useToggle";

import * as Yup from "yup";
import {customTextFieldTheme} from "src/components/CustomInput";
import {createcontact, updatecontact as updatecontactFun} from 'src/redux/slices/contact';
import {CIVILITY_TYPE, CONTACT_TYPE, ftContactType} from "src/constants";
import {themeColor} from "src/constants/color";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import frLocale from "date-fns/locale/fr";

import {
    Button, Dialog, Paper, Stack, Typography, Divider, Tooltip,
    TextField, MenuItem, Container, InputAdornment, DialogTitle, IconButton
} from '@mui/material'
import LabelStyle from "src/components/LabelStyle";
import Scrollbar from "src/components/Scrollbar";
import {LoadingButton, LocalizationProvider, MobileDatePicker} from "@mui/lab";
import UploadMAvatar from "src/components/upload/UploadMAvatar";
import UserPicker from "src/components/UserPicker";

import {Icon} from "@iconify/react";
import {Close} from "@mui/icons-material";
import AssigneeView from "src/components/AssigneeView";
import personAdd from "@iconify/icons-eva/person-add-fill";
import {getCurrentUserAccess} from "src/helpers/user";
import {useContactContext} from "src/contexts/ContactContext";


export default function ContactForm_V4({selected, open, onClose}) {
    const theme = useTheme();
    const isEdit = Boolean(selected)
    const [loading, setLoading] = useState(false);
    const {user: {email, id}} = useAuth();
    const dispatch = useDispatch();
    const isMountedRef = useIsMountedRef();
    const {enqueueSnackbar} = useSnackbar();
    const {canEdit} = useContactContext()
    const isEditable = canEdit(selected);

    const contactFormSchema = Yup.object().shape({
        lastName: Yup.string().required('Le nom est requis'),
        firstName: Yup.string().required('Le prenom est requis'),
        contactType: Yup.string().required('Le type de contact est requis'),
        civility: Yup.string().required('civilité requis'),
        tel: Yup.string().required('le Téléphone est requis'),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            lastName: selected?.lastName || '',
            photoUrl: selected?.photoUrl || null,
            contactType: selected?.type || selected?.subtype || selected?.contactType || CONTACT_TYPE.CLIENT.title,
            firstName: selected?.firstName || '',
            civility: selected?.civility || CIVILITY_TYPE.Mr,
            title: selected?.title || '',
            email: selected?.email || '',
            adresse: selected?.adresse || '',
            tel: selected?.tel || '',
            city: selected?.city || '',
            country: selected?.country || '',
            society: selected?.society || '',
            representant: selected?.representant || '',
            dateDeb: selected?.dateDeb || '',
            duration: selected?.duration || '',
            sharedWith: selected?.sharedWith || []
        },

        validationSchema: contactFormSchema,
        onSubmit: async (values, {setErrors, setSubmitting, resetForm,}) => {
            try {
                setLoading(true);

                const callback = () => {
                    enqueueSnackbar(`${isEdit ? 'contact mise à jours' : 'contact ajouté'}`, {variant: isEdit ? 'info' : 'success'});
                    onClose && onClose();
                    if (isMountedRef.current) {
                        resetForm();
                        setSubmitting(false);
                        setLoading(false);
                        onClose();
                    }
                    return;
                };

                let {contactType, ...rest} = values;
                let subtype = null;

                if (contactType !== CONTACT_TYPE.CLIENT.title) {
                    subtype = contactType;
                    contactType = CONTACT_TYPE.EMPLOYEE.title;
                }

                let contactObject = {
                    ...rest,
                    contactType,
                    subtype,
                    canAccessEmail: [`${email}`, ...values.sharedWith.map(el => el?.email)],
                    canAccessId: [`${id}`],
                    createAt: new Date(),
                    updateAt: new Date()
                };

                if (!isEdit) {
                    dispatch(createcontact({...contactObject, createdBy: getCurrentUserAccess()}, callback));
                }

                if (isEdit) {
                    contactObject = {
                        ...contactObject,
                        id: selected.id,
                        createAt: selected.createAt
                    };
                    dispatch(updatecontactFun(contactObject, callback));
                }

            } catch (error) {
                if (isMountedRef.current) {
                    setErrors({afterSubmit: error.code});
                    console.log(error);
                    enqueueSnackbar('une erreur s\'est produit ', {variant: 'error'});
                    setSubmitting(false);
                    setLoading(false);
                }
            }
        }
    });


    const {
        values,
        errors,
        touched,
        handleSubmit,
        getFieldProps,
        setFieldValue
    } = formik;

    const startCase = (field, value) => {
        var fchart = value.charAt(0).toUpperCase() + value.slice(1);
        setFieldValue(field, fchart);
    };

    const isGroup = values.contactType === CONTACT_TYPE.EMPLOYEE.sub.GROUP.title || values.contactType === CONTACT_TYPE.EMPLOYEE.sub.DEPARTMENT.title;


    const getProps = (name) => {
        return ({
            value: values[name],
            error: Boolean(touched[name] && errors[name]),
            helperText: touched[name] && errors[name],
            onChange: e => setFieldValue(name, e.currentTarget.value)
        })
    }


    return (
        <>
            <Dialog
                open={open} onClose={onClose}
                maxWidth={'xl'} fullWidth fullScreen
                PaperProps={{sx: {bgcolor: 'transparent'}}}
            >
                <DialogTitle>
                    <Stack direction={'row'} justifyContent={'end'} width={1}>
                        <Stack>
                            <Button
                                color={'inherit'}
                                fullWidth
                                onClick={onClose}
                                sx={{
                                    p: 2,
                                    // opacity: .7,
                                    color: 'white',
                                    bgcolor: 'black',
                                    transform: 'scale(.7)',
                                    ':hover': {
                                        opacity: 1,
                                        bgcolor: theme => theme.palette.error.main,
                                    }
                                }}
                            >
                                <Close/>
                            </Button>
                        </Stack>
                    </Stack>
                </DialogTitle>
                <Container>
                    <Paper sx={{borderRadius: 1}} variant="outlined">
                        <Stack py={1} minWidth={800} minHeight={'60vh'}>

                            {/*Header*/}
                            <Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} px={3} py={2}>

                                    <Typography variant={'h6'}>
                                        {isEdit ? "Editer" : "Créer"} un contact
                                    </Typography>

                                    <Stack direction={'row'} spacing={4}>

                                        <Share values={values} onChange={setFieldValue}/>

                                        <LoadingButton
                                            loading={loading} onClick={handleSubmit} disabled={!isEditable}
                                            startIcon={<Icon icon="ic:sharp-save-alt"/>}
                                            sx={{
                                                color: 'white',
                                                fontWeight: 100,
                                                px: 2,
                                                fontSize: 13,
                                                bgcolor: 'black',
                                                ':hover': {
                                                    bgcolor: themeColor.CARROT_ORANGE,
                                                    color: themeColor.RICH_BLACK
                                                }
                                            }}
                                        >
                                            Enregister
                                        </LoadingButton>
                                    </Stack>

                                </Stack>
                                <Divider/>
                            </Stack>

                            <Scrollbar>
                                <Stack height={1} width={1} p={2} spacing={3}>
                                    <Stack direction={'row'} spacing={3}>

                                        <Stack height={1} width={'40%'} alignSelf={'center'}>
                                            <UploadMAvatar
                                                value={values.photoUrl}
                                                onChange={(value) => setFieldValue('photoUrl', value)}
                                            />

                                        </Stack>

                                        <Stack width={1} spacing={2}>
                                            <Stack spacing={.5} width={1}>
                                                <LabelStyle>
                                                    TYPE DE CONTACT
                                                </LabelStyle>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    variant={'standard'}
                                                    SelectProps={{native: true}}
                                                    InputProps={{disableUnderline: true}}
                                                    sx={customTextFieldTheme(theme)}
                                                    {...getProps('contactType')}

                                                >
                                                    {Object.entries(CONTACT_TYPE).map(([key, option]) => {
                                                            return !option.sub ?
                                                                <option key={key} value={option.title}>
                                                                    {option.title === 'Client' ? 'Partenaire' : option.title}
                                                                </option> :

                                                                <optgroup key={key} label={ftContactType(option.title)}>
                                                                    {Object.entries(option.sub).map(([subKey, subOption]) => (
                                                                        <option key={`${key}_${subKey}`}
                                                                                value={subOption.title}>
                                                                            {subOption.title}
                                                                        </option>
                                                                    ))}
                                                                </optgroup>;
                                                        }
                                                    )}
                                                </TextField>

                                            </Stack>
                                            <Stack direction={'row'} spacing={2}>
                                                <Stack spacing={.5}>
                                                    <LabelStyle>
                                                        CIVILITE
                                                    </LabelStyle>
                                                    <TextField
                                                        select
                                                        variant={'standard'}
                                                        InputProps={{disableUnderline: true}}
                                                        sx={customTextFieldTheme(theme)}
                                                        {...getProps('civility')}
                                                    >
                                                        {Object.entries(CIVILITY_TYPE).map(([key, option]) => (
                                                            <MenuItem key={option} value={option}>
                                                                {option}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>

                                                </Stack>
                                                <Field label={'Nom *'} {...getProps('lastName')}/>

                                            </Stack>
                                            <Field label={'Prenom *'}  {...getProps('firstName')}/>
                                        </Stack>

                                    </Stack>

                                    <Stack direction={'row'} spacing={5}>
                                        <Field label={'Email'}  {...getProps('email')}/>
                                        <Field label={'Telephone *'}  {...getProps('tel')}/>
                                    </Stack>

                                    <Stack direction={'row'} spacing={5}>
                                        <Field label={'Pays'}  {...getProps('country')}/>
                                        <Field label={'Ville'}  {...getProps('city')}/>
                                    </Stack>

                                    {
                                        values.contactType === CONTACT_TYPE.CLIENT.title && (
                                            <Stack spacing={3}>
                                                <Stack>
                                                    <LabelStyle>
                                                        ENTREPRISE
                                                    </LabelStyle>
                                                    <Divider/>
                                                </Stack>

                                                <Stack direction={'row'} spacing={5}>
                                                    <Field label={'Nom'}  {...getProps('society')}/>
                                                    <Field label={'Representant'}  {...getProps('representant')}/>
                                                </Stack>
                                            </Stack>
                                        )
                                    }

                                    {
                                        [CONTACT_TYPE.EMPLOYEE.sub.VOLONTAIRE.title, CONTACT_TYPE.EMPLOYEE.sub.STAGIAIRE.title, CONTACT_TYPE.EMPLOYEE.sub.CONTRACTUEL.title,].includes(values.contactType) && (
                                            <Stack direction={'row'} spacing={5}>
                                                <LocalizationProvider dateAdapter={AdapterDateFns} locale={frLocale}>
                                                    <MobileDatePicker
                                                        label='Date début'
                                                        toolbarTitle="Selectioné une date"
                                                        todayText="Aujourd'hui"
                                                        mask='__/__/____'
                                                        ampm={false}
                                                        value={values.dateDeb}
                                                        inputFormat='dd/MM/yyyy'
                                                        onChange={(date) => setFieldValue('dateDeb', date)}
                                                        renderInput={(params) => (
                                                            <Field
                                                                {...params}
                                                                fullWidth
                                                                error={Boolean(touched.dateDeb && errors.dateDeb)}
                                                                helperText={touched.dateDeb && errors.dateDeb}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>

                                                <Field
                                                    fullWidth
                                                    label='Durée'
                                                    inputProps={{autoCapitalize: 'on'}}
                                                    InputProps={{
                                                        endAdornment: <InputAdornment position='start'>Mois</InputAdornment>
                                                    }}
                                                    {...getFieldProps('duration')}
                                                />
                                            </Stack>
                                        )
                                    }


                                </Stack>

                            </Scrollbar>


                        </Stack>
                    </Paper>

                </Container>
            </Dialog>


        </>
    )
}

const Share = ({values, onChange}) => {
    const ref = useRef()
    const [visible, show, hide] = useToggleV2()

    const tip = "Permettre l'accès a ce contact aux collaborateurs choisis"

    return (
        <Stack>

            {
                (values.sharedWith || [])?.length > 0
                    ? (

                        <Stack

                            direction={'row'} spacing={1}
                            justifyContent={'center'}
                        >
                            <Tooltip title={tip}>
                                <IconButton color={'default'} size="small" ref={ref} onClick={show} sx={{
                                    border: t => `1px dashed ${t.palette.divider}`,
                                    p: 1,
                                    // bgcolor: pureWhite ? 'white' : null
                                }}>
                                    <Icon icon={"material-symbols:share-outline"} height={17}/>
                                </IconButton>
                            </Tooltip>
                            <AssigneeView
                                users={values.sharedWith || []}
                                onChange={(users) => onChange('sharedWith', users)}
                                maxCount={5}
                            />
                        </Stack>

                    )
                    : (
                        <Tooltip title={tip}>
                            <Button
                                onClick={show} ref={ref}
                                variant={'outlined'} color={'inherit'}
                                startIcon={<Icon icon="material-symbols:share-outline"/>}
                                sx={{
                                    color: 'black',
                                    fontWeight: 100,
                                    px: 2,
                                    fontSize: 13,
                                    bgcolor: 'white',
                                    ':hover': {
                                        bgcolor: themeColor.CARROT_ORANGE,
                                        color: themeColor.RICH_BLACK
                                    }
                                }}
                            >
                                Partager
                            </Button>
                        </Tooltip>

                    )
            }


            {
                visible && (
                    <UserPicker
                        title={'Partager avec'} field={'sharedWith'}
                        open={visible} onClose={hide} anchorEl={ref}
                        users={values.sharedWith || []} onChange={onChange}
                    />

                )
            }
        </Stack>
    )
}

export const Field = ({label, error, helperText, ...rest}) => {
    const theme = useTheme();

    return (
        <Stack spacing={.5} width={1}>
            {
                label && (
                    <LabelStyle>
                        {label.toUpperCase()}
                    </LabelStyle>
                )
            }

            <TextField
                {...rest}
                fullWidth
                variant={'standard'}
                InputProps={{disableUnderline: true}}
                sx={customTextFieldTheme(theme)}

            />
            {
                error && (
                    <Typography fontSize={12} color={'red'}>
                        {helperText}
                    </Typography>
                )
            }

        </Stack>
    )
}