import {TASK_PRIORITIES, TASK_STATE_VALIDATION} from "src/constants";
import {gDate} from "../utils/formatTime";

export const isTaskAccepted = task => task?.completed || task?.state === TASK_STATE_VALIDATION.ACCEPTED || task?.progress === 100;
export const isTaskDone = task => !task?.completed && task?.state === TASK_STATE_VALIDATION.DONE;
export const isTaskReject = task => task?.state === TASK_STATE_VALIDATION.REJECTED;
export const isTaskUrgent = task => task?.priority === TASK_PRIORITIES.IMPORTANT && task?.state !== TASK_STATE_VALIDATION.REJECTED && task?.state !== TASK_STATE_VALIDATION.ACCEPTED;

export const isTaskLate = task => {
    if (!(task?.due[0] && task?.due[1]) || isTaskDone(task) || isTaskAccepted(task))
        return false;

    return gDate(task?.due[1]) < new Date()
}


export const isTaskDoneInTime = task => {
    return !isTaskLate(task) && (gDate(task?.metadata?.doneDate) <= gDate(task?.due[1]))
}
