import { Button, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { MIconButton } from './@material-extend';
import { Icon } from '@iconify/react';
import audioFill from '@iconify/icons-bi/mic';
import audioOffFill from '@iconify/icons-bi/mic-mute';
import { timerDisplay } from '../section/tasks/kanban/KanbanTaskCard';
import useAudioRecorder from '../hooks/useAudioRecorder';
import { useRef, useState } from 'react';
import { CustomMenuPopover } from './MenuPopover';
import MusicPlayerSlider from './MusicPlayerSlider';
import { nanoid } from '@reduxjs/toolkit';
import { serverTime } from '../utils/serverTime';
import { saveFile } from '../redux/slices/document';
import UploadingOneFileProgress from './UploadingOneFileProgress';
import useAuth from '../hooks/useAuth';
import { baseColors } from 'src/constants/color';

/**
 *
 * @param {{
 *  dispatcher: function(file, {commentObject, cleanup }),
 *  size: number?,
 *  setRecording: function(boolean),
 * setLoading: function(boolean),
 * customAction: function({onRecordingClick: function, isRecording: boolean}),
 * getBrutFile? : Boolean
 * }} param
 * @returns
 */
export default function AudioRecorder({
  dispatcher,
  size = 25,
  setRecording = null,
  setLoading = null,
  customAction,
  getBrutFile = false
}) {
  const voiceRef = useRef();
  const {
    user: { email, id, displayName, role, photoURL }
  } = useAuth();
  const [voiceNote, setVoiceNote] = useState(null);
  const [voiceNoteTime, setVoiceNoteTime] = useState(0);
  const [voiceNoteBlob, setVoiceNoteBlob] = useState(null);
  const [openVoiceNote, setOpenVoiceNote] = useState(null);
  const [uploading, setFUploading] = useState(false);
  const { isRecording, recordingTime, startRecording, stopRecording } = useAudioRecorder({
    onNoteDone: setVoiceNote,
    getBlob: setVoiceNoteBlob
  });

  const onRecordingClick = () => {
    if (isRecording) {
      setVoiceNoteTime(recordingTime);
      stopRecording();
      setOpenVoiceNote(true);
      return;
    }
    startRecording();
    if (setRecording) setRecording(true);
  };

  const onCloseVoiceNote = () => {
    setOpenVoiceNote(false);
    if (setRecording) setRecording(false);
  };

  const onSendVoiceNote = () => {
    if (setLoading) setLoading(true);
    setOpenVoiceNote(false);

    const docId = nanoid();

    const onAudioSave = (_file) => {
      setFUploading(false);

      const commentObject = {
        userId: id,
        avatar: photoURL,
        name: displayName,
        email,
        messageType: 'audio',
        createdAt: serverTime(),
        message: _file?.url,
        read: 0,
        readState: []
      };

      if (dispatcher) {
        dispatcher(
          _file,
          {
            commentObject,
            cleanup: () => {
              setVoiceNote(null);
              setVoiceNoteBlob(null);
            }
          },
          voiceNoteTime
        );
      }
    };

    const fileName = `Note vocale ${nanoid()}`;
    const file = new File([voiceNoteBlob], fileName, { type: 'audio/webm' });

    if (setRecording) setRecording(false);

    if (getBrutFile) {
      onAudioSave(file);
      return;
    }

    saveFile(file, onAudioSave, null, setFUploading, docId).catch((error) => console.error(error));
  };

  return (
    <Stack>
      <Stack direction="row-reverse" spacing={1} justifyContent="flex-end" alignItems="center">
        {recordingTime ? (
          <CustomMenuPopover
            open={Boolean(recordingTime)}
            anchorEl={voiceRef.current}
            width="max-content"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            arrow="bottom-center"
            customcolor={baseColors.BLEU_N}
            PaperProps={{ sx: { bgcolor: baseColors.BLEU_N, px: 2, py: 1 } }}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography fontSize={15} fontWeight="bold" color="#FFF">
                {timerDisplay(recordingTime)}
              </Typography>
              <IconButton size="small" onClick={onRecordingClick} sx={{ bgcolor: '#FFF' }}>
                <Icon icon="ic:round-stop" color={baseColors.REDD} />
              </IconButton>
            </Stack>
          </CustomMenuPopover>
        ) : null}

        <Stack ref={voiceRef}>
          {customAction ? (
            customAction({ onRecordingClick, isRecording })
          ) : (
            <Tooltip title="Note vocale" arrow>
              <MIconButton size="small" onClick={onRecordingClick}>
                <Icon
                  icon={isRecording ? audioFill : audioOffFill}
                  {...(isRecording && { color: 'red' })}
                  width={size}
                  height={size}
                />
              </MIconButton>
            </Tooltip>
          )}
        </Stack>
      </Stack>

      {openVoiceNote && (
        <CustomMenuPopover
          open={openVoiceNote}
          onClose={onCloseVoiceNote}
          anchorEl={voiceRef.current}
          zIndex={2500}
          disabledArrow
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
        >
          <MusicPlayerSlider song={voiceNote} duration={voiceNoteTime} />
          <Stack direction="row" justifyContent="flex-end" spacing={1} sx={{ px: 1, pb: 0.5 }}>
            <Button color="inherit" size="small" variant="outlined" onClick={onCloseVoiceNote}>
              Annuler
            </Button>
            <Button color="primary" size="small" variant="contained" onClick={onSendVoiceNote}>
              Envoyer
            </Button>
          </Stack>
        </CustomMenuPopover>
      )}

      {!uploading && <UploadingOneFileProgress uploading={uploading} />}
    </Stack>
  );
}
