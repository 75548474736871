import {createSlice} from '@reduxjs/toolkit';
// ----------------------------------------------------------------------

export const MULTI_SITES= {
    AROBASE_TOGO: 'AROBASE-TOGO',
    DIWA_PRODUCTS: 'DIWA PRODUCTS',
    DIWA_INTERNATIONAL: 'DIWA INTERNATIONAL',
    KAPI_CONSULT: 'KAPI CONSULT',
    DABA: 'DABA',
    JCEM: 'JCEM GROUPE TOGO',
    OSEOR_SARL: 'OSEOR SARL',
    OSEOR_SAS: 'OSEOR SAS',
    ZENER: 'ZENER'
}

const slice = createSlice({
    name: 'sites',
    initialState: {
        sites:MULTI_SITES,
        currentSite: localStorage.getItem('cursite') || 'AROBASE-TOGO'
    },
    reducers: {
        selectedSite(state, action){
                state.currentSite = action.payload
        }
    }
});

// Reducer
export default slice.reducer;

// Actions
export const {selectedSite} = slice.actions;

//----------------------------------------------------------------------
