export const AUDIENCE_BASE_TYPE = {
    WAITING: 'En Attente',
    REJECTED: 'Rejeté',
    FINISHED: 'Accepté',
};

export const AUDIENCE_TYPE = {
    ...AUDIENCE_BASE_TYPE,
    EXPIRED: 'Expire',
};


export const AUDIENCE_TYPE_COLOR = {
    [AUDIENCE_TYPE.WAITING]: 'info',
    [AUDIENCE_TYPE.EXPIRED]: 'error',
    [AUDIENCE_TYPE.REJECTED]: 'error',
    [AUDIENCE_TYPE.FINISHED]: 'success',
};

export const AUDIENCE = {
    PENDING: 'En attentes',
    ACCEPTED: 'Acceptées',
    REJECTED: 'Rejetées',
    ALL: 'Historique'
}