import { Player } from '@lottiefiles/react-lottie-player';

export default function LoadingView({size}) {
  return (
    <Player
      autoplay
      loop
      src="/static/json/loading.json"
      style={{ ...(size ? {...size} : {height: '200px', width: '200px'} ) }}
    />
  )
}