import { IconButton, Stack } from '@mui/material';
import React from 'react';
import { DatePicker } from 'antd';
import TextMaxLine from './TextMaxLine';
import SvgIconStyle from './SvgIconStyle';

export const MuiCustomDateInput = ({ value, onChange, disabled }) => {
  return (
    <Stack width={1} direction="row" alignItems="center" justifyContent="space-between" position="relative">
      <TextMaxLine pl={1} fontSize={12} fontWeight="bold" line={1}>
        {value || ''}
      </TextMaxLine>
      <IconButton size="small">
        <SvgIconStyle src="/static/icons/date.svg" color="neutral" sx={{ height: 18, width: 18 }} />
      </IconButton>
      <Stack width={1} position="absolute">
        <DatePicker
          onChange={(_, dateString) => onChange(dateString)}
          popupStyle={{ zIndex: 1550 }}
          disabled={disabled}
          format="DD-MM-YYYY"
          style={{ opacity: 0, height: 40 }}
        />
      </Stack>
    </Stack>
  );
};
