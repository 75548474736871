import {useMemo, useState} from 'react'
import {useToggleV2} from "src/hooks/useToggle";
import {useDispatch, useSelector} from "../../../redux/store";
import useAuth from "../../../hooks/useAuth";
import {Dialog, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import {baseColors} from "../../../constants/color";
import {Icon} from "@iconify/react/dist/iconify";
import {DepsWarnig} from "../../../assets/dependence";
import {gDate, gfDate} from "../../../utils/formatTime";
import {updateProject} from "../../../redux/slices/kanban";
import {useSnackbar} from "notistack";


export function useTaskDueDateGuard(task, updater = null) {
    const [due, setDue] = useState([null, null]);
    const [open, display, onClose] = useToggleV2();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const {user} = useAuth();
    const getProjects = useSelector((state) => state.firestore.ordered[`list_${user?.id}_My_Projects`]);

    const project = useMemo(() => {
        const projectId = task?.projectKey || task?.idProject || task?.projectId || null;
        return [...(getProjects||[])].find(el => el?.id === projectId);
    }, [getProjects, task])

    const projectInterval = useMemo(() => {
        const {start = null, end = null} = project?.dataInterval || {};
        return {start: gDate(start), end: gDate(end)}
    }, [project?.dataInterval])

    const managers = useMemo(() => {
        return [...(project?.managers || [])]
    }, [project?.managers])

    //eslint-disable-next-line react-hooks/exhaustive-deps
    const isManager = useMemo(() => managers?.find(el => el?.id === user?.id) || (project?.createBy?.id === user.id), [managers, user.id]);


    const validateNewDue = (due) => {
        setDue(due);

        const startBeforeInterval = projectInterval.start && due[0] && due[0] < projectInterval.start
        const endAfterInterval = projectInterval.end && due[1] && due[1] > projectInterval.end

        if (startBeforeInterval || endAfterInterval) {
            display();
            return false;
        }

        return true;

    }

    const updateTask = () => {
        onClose();
        if (updater) updater({...task, due}, task);

        const startBeforeInterval = projectInterval?.start && due[0] && due[0] < projectInterval.start
        const endAfterInterval = projectInterval?.end && due[1] && due[1] > projectInterval.end

        dispatch(updateProject({
            ...project,
            dataInterval:{
                start: startBeforeInterval ? due[0] : projectInterval?.start,
                end: endAfterInterval ? due[1] : projectInterval?.end,
            }
        },null,()=>{
            enqueueSnackbar("L'intervalle de date du projet a bien été mis à jour",{variant:'success'})
        }));

    }

    return {validateNewDue, updateTask, task, due, isManager, projectInterval, open, onClose};
}


export function TaskDueDateGuardPrompt({
                                           due = [null, null],
                                           isManager = false,
                                           projectInterval,
                                           open,
                                           onClose,
                                           updateTask
                                       }) {

    const startBeforeInterval = projectInterval?.start && due[0] && due[0] < projectInterval.start
    const endAfterInterval = projectInterval?.end && due[1] && due[1] > projectInterval.end

    return (
        <Dialog fullWidth maxWidth='sm' open={open} onClose={onClose} scroll='paper'>
            <DialogTitle>
                <Stack width={1} direction='row' justifyContent='flex-end'>
                    <Stack onClick={onClose} sx={{cursor: 'pointer'}} direction='row' alignItems='center'>
                        <Typography color={baseColors.RED}>Annuler</Typography>
                        <Icon icon='eva:close-fill' height={40} color={baseColors.GREY}/>
                    </Stack>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <Stack width={1} spacing={2} alignItems='center' p={2}>
                    <DepsWarnig/>
                    <Typography color={baseColors.RED} fontSize={18}>
                        Êtes-vous sûr de cela ?
                    </Typography>
                    <Typography>
                        Cette tâche rencontre les problèmes suivants
                    </Typography>
                    <Stack width={1} pt={2} pl={3}>
                        <ul>
                            {
                                startBeforeInterval && (
                                    <li>
                                        La date de départ de cette tâche {gfDate(due[0])} vient avant celle du
                                        projet {gfDate(projectInterval?.start)}
                                    </li>
                                )
                            }

                            {
                                endAfterInterval && (
                                    <li>
                                        La date de fin de cette tâche {gfDate(due[1])} vient après celle du
                                        projet {gfDate(projectInterval?.end)}
                                    </li>
                                )
                            }

                        </ul>
                    </Stack>

                    {
                        isManager && (
                            <Stack width={1} py={0.5} alignItems='center' borderRadius={1} bgcolor={baseColors.GREEN}
                                   sx={{cursor: 'pointer'}} onClick={updateTask}>
                                <Typography color='white' fontWeight='bold'>Résoudre le problème</Typography>
                                <Typography color='white' fontSize={12}>
                                    Réajuster l'intervalle de date du projet avec celle de la tâche
                                </Typography>
                            </Stack>
                        )
                    }

                    <Stack width={1} py={0.5} alignItems='center' borderRadius={1} bgcolor={baseColors.GREY}
                           sx={{cursor: 'pointer'}} onClick={onClose}>
                        <Typography color='white' fontWeight='bold'> Annuler </Typography>
                        {/*<Typography color='white' fontSize={12}>Continer sans resoudre les tâches*/}
                        {/*    d'actions </Typography>*/}
                    </Stack>
                </Stack>
            </DialogContent>


        </Dialog>
    )
}