import { createContext, useContext, useState, useEffect } from 'react';
// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
// hooks
import useAuth from './hooks/useAuth';

// components
import 'src/utils/ext';
import locale from 'antd/es/locale/fr_FR';
import { ConfigProvider } from 'antd';
import Settings from './components/settings';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import ProjectProvider from './contexts/ProjectContext';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import NotificationContext from './contexts/NotificationContext';

import 'moment/locale/fr-ch';
import 'src/assets/css/pulse.css';
import WorkflowProvider from './contexts/WorkflowContext';
import DocDriverContext from './contexts/DocDriverContext';
import Scrollbar from './components/Scrollbar';
import { AffectationProvider } from './contexts/AffectationContext';
import HomeContext from './contexts/HomeContext';
import AccueilTaskEditorContext from './contexts/AccueilTaskEditorContext';
import WorldTimeProvider from 'src/contexts/WorldTimeContext';
import TaskProvider from './contexts/TaskContext';
import AudienceProvider from 'src/contexts/AudienceContext';
import ContactProvider from 'src/contexts/ContactContext';
import CommunityContext from './contexts/CommunityContext';
import { useLocation } from 'react-router-dom';
import { Alert, Snackbar, Typography } from '@mui/material';
import CustomJoyTheme from './theme/joy';
import Affectation_v4Provider from './contexts/Affectation_v4Context';

// ----------------------------------------------------------------------

const NotifSnackBarContext = createContext({
  pathname: '',
  canSendChatNotif: true,
  notificationKeys: [],
  setCanSendChatNotif: () => {},
  setNotificationIds: () => {}
});

export const useNotifSnackbarContext = () => useContext(NotifSnackBarContext);

export default function App() {
  const { pathname } = useLocation();
  const { isInitialized, isAuthenticated } = useAuth();
  const [notificationKeys, setNotificationIds] = useState([]);
  const [canSendChatNotif, setCanSendChatNotif] = useState(true);

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const store = {
    notificationKeys,
    setNotificationIds,
    canSendChatNotif,
    setCanSendChatNotif,
    pathname
  };

  return (
    <>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={!isOnline}>
        <Alert severity="error">
          <Typography>
            <span style={{ fontWeight: 'bold' }}>Pas de connexion.</span> Veuillez vérifier votre connection internet
          </Typography>
        </Alert>
      </Snackbar>
      <Scrollbar>
        <ConfigProvider locale={locale}>
          <ThemePrimaryColor>
            <ThemeConfig>
              <CustomJoyTheme>
                <NotifSnackBarContext.Provider value={store}>
                  <NotistackProvider>
                    <NotificationContext>
                      <Settings />
                      <ScrollToTop />
                      <GoogleAnalytics />
                      {/* {isAuthenticated && <Notes />} */}
                      {isInitialized ? (
                        <HomeContext>
                          <AccueilTaskEditorContext>
                            <WorkflowProvider>
                              <ProjectProvider>
                                <TaskProvider>
                                  <Affectation_v4Provider>
                                    <AffectationProvider>
                                      <WorldTimeProvider>
                                        <AudienceProvider>
                                          <DocDriverContext>
                                            <CommunityContext>
                                              <ContactProvider>
                                                <Router />
                                              </ContactProvider>
                                            </CommunityContext>
                                          </DocDriverContext>
                                        </AudienceProvider>
                                      </WorldTimeProvider>
                                    </AffectationProvider>
                                  </Affectation_v4Provider>
                                </TaskProvider>
                              </ProjectProvider>
                            </WorkflowProvider>
                          </AccueilTaskEditorContext>
                        </HomeContext>
                      ) : (
                        <LoadingScreen />
                      )}
                      <audio id="sound" preload="auto">
                        <source src="/static/alarm/alarm_message.mp3" type="audio/mpeg" />
                        <embed hidden={true} autostart="false" loop={false} src="/static/alarm/alarm_message.mp3" />
                        <track default kind="captions" srcLang="fr" src="" />
                      </audio>
                    </NotificationContext>
                  </NotistackProvider>
                </NotifSnackBarContext.Provider>
              </CustomJoyTheme>
            </ThemeConfig>
          </ThemePrimaryColor>
        </ConfigProvider>
      </Scrollbar>
    </>
  );
}
