import {Box, IconButton, Typography} from '@mui/material';
import {experimentalStyled as styled} from '@mui/material/styles';
import PropTypes from "prop-types";
import {getFileThumbSecond} from "../../../utils/getFileFormat";
import Close from "@mui/icons-material/Close";
import { gfDate } from 'src/utils/formatTime';
import { downloadFile } from 'src/helpers/downloadFile';

const FileItemStyle = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    padding: theme.spacing(0, 2.5)
}));

const FileThumbStyle = styled('div')(({theme}) => ({
    width: 40,
    height: 40,
    flexShrink: 0,
    display: 'flex',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.grey[500_16],
    '& img': {width: '100%', height: '100%'},
    '& svg': {width: 24, height: 24}
}));


AttachmentItem.propTypes = {
    file: PropTypes.object,
    fileUrl: PropTypes.string
};

export default function AttachmentItem({file, createdAt, onRemove}) {



    const handleClickDownload = () => {
        downloadFile(file.url, file.name);
    };

    return (
        <FileItemStyle key={file.url} onClick={handleClickDownload} style={{cursor: "pointer"}}>
            <FileThumbStyle>
                {getFileThumbSecond(file.type, file.url)}
            </FileThumbStyle>
            <Box sx={{ml: 1.5, maxWidth: 150}}>
                <Typography variant="body2" noWrap>
                    {file.name}
                </Typography>
                <Typography
                    noWrap
                    variant="caption"
                    sx={{color: 'text.secondary', display: 'block'}}
                >
                    {gfDate(createdAt) || new Date().toLocaleDateString('fr')}
                </Typography>
            </Box>
            {
                onRemove && (
                    <IconButton>
                        <Close/>
                    </IconButton>
                )
            }

        </FileItemStyle>

    );
}