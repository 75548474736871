import React, { useState, forwardRef, useCallback, Fragment, useMemo } from 'react';
import {
  Box,
  Stack,
  Paper,
  Button,
  Collapse,
  Typography,
  CardActions,
  DialogActions,
  DialogContent
} from '@mui/material';
import HTMLParser from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { NOTIFICATION_TYPES } from 'src/constants';
import { useNotifSnackbarContext } from '../App';
import { Icon } from '@iconify/react';
import { useAffectationContext } from 'src/contexts/AffectationContext';
import { useProjectContext } from 'src/contexts/ProjectContext';
import { useAudienceContext } from 'src/contexts/AudienceContext';
import EllipsisText from './EllipsisText';
import TextMaxLine from './TextMaxLine';
import useNotifications from 'src/hooks/useNotifications';
import { useAffectation_v4Context } from 'src/contexts/Affectation_v4Context';

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '344px !important'
    }
  },
  card: {
    backgroundColor: '#FFB73A',
    width: '100%',
    minWidth: 400,
    maxWidth: 400
  },
  cardDemande: {
    backgroundColor: '#FFC5B1',
    width: '100%',
    minWidth: 400,
    maxWidth: 400
  },
  cardReject: {
    backgroundColor: '#FF8269',
    width: '100%',
    minWidth: 400,
    maxWidth: 400,
    color: 'white'
  },
  cardAccepted: {
    backgroundColor: '#0D6209',
    width: '100%',
    minWidth: 400,
    maxWidth: 400,
    color: 'white'
  },
  relance: {
    backgroundColor: '#148117',
    width: '100%',
    minWidth: 400,
    maxWidth: 400,
    color: 'white'
  },
  cardProjet: {
    backgroundColor: '#3389DB',
    width: '100%',
    minWidth: 400,
    maxWidth: 400,
    color: 'white'
  },
  typography: {
    fontWeight: 'bold'
  },
  actionRoot: {
    minWidth: 400,
    maxWidth: 400,
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between'
  },
  icons: {
    marginLeft: 'auto'
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest
    })
  },
  expandOpen: {
    transform: 'rotate(180deg)'
  },
  collapse: {
    padding: 16
  },
  checkIcon: {
    fontSize: 20,
    color: '#b3b3b3',
    paddingRight: 4
  },
  button: {
    padding: 0,
    textTransform: 'none'
  }
}));

const MuiCard = forwardRef(function Alert(props, ref) {
  return <Paper ref={ref} {...props} elevation={5} />;
});

export const NotificationSnackbar = (props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const { notificationKeys } = useNotifSnackbarContext();

  const { openDetailsById } = useAffectationContext();
  const { openById } = useProjectContext();
  const { openAudienceById } = useAudienceContext();
  const couriel = useAffectation_v4Context();

  const handleExpandClick = useCallback(() => {
    setExpanded((oldExpanded) => !oldExpanded);
  }, []);

  const handleDismiss = useCallback(() => {
    props?.onClose && props?.onClose(props.id, notificationKeys);
    props?.stopSound && props?.stopSound();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id, notificationKeys]);

  const handleDetail = () => {
    handleDismiss();
    if (props?.action || props?.url) {
      if (props?.type?.toLowerCase().indexOf('task') !== -1) {
        navigate(`${PATH_DASHBOARD.general.tasks}/${props.action?.sub}`, {
          state: {
            projectName: ' ',
            detailId: props.action?.id
          }
        });
        return;
      }

      if (
        props?.type === NOTIFICATION_TYPES.PROJECT_CREATE ||
        props?.type === NOTIFICATION_TYPES.PROJECT_UPDATE ||
        props?.type === NOTIFICATION_TYPES.PROJECT_MANAGER
      ) {
        navigate(`${PATH_DASHBOARD.general.tasks}/${props.action?.sub}`);
      }

      if (props?.type === NOTIFICATION_TYPES.PROJECT_CHAT) {
        navigate(`${PATH_DASHBOARD.general.tasksProject.replace(':projectId', props.url)}?tab=Conversation`, {
          state: { projectName: '  ' }
        });
      }

      if (props?.type?.includes('affect')) {
        openDetailsById(props?.action?.id);
      }

      if (props?.type?.toLowerCase()?.includes('task')) {
        const action = props?.action || {};
        const taskId =
          action?.id || action?.cardId || action?.detailId || action?.taskId || action?.returnId || props?.returnId;

        if (taskId) openById(taskId);
      }

      if (props?.type === NOTIFICATION_TYPES.AUDIENCE) {
        const audienceId = props?.action?.audienceId;
        if (audienceId) openAudienceById(audienceId);
      }

      if (
        props?.type === NOTIFICATION_TYPES.COMMUNICATION_ADD_TO_CHANNEL ||
        props?.type === NOTIFICATION_TYPES.COMMUNICATION_NEW_MESSAGE
      ) {
        const channelId = props?.action?.id;
        navigate(`${PATH_DASHBOARD.general.app}/${channelId}`);
      }
      if (props?.type === NOTIFICATION_TYPES.COMMUNICATION_NEW_ANSWER_MESSAGE) {
        const channelId = props?.action?.id;
        const messageId = props?.action?.messageId;

        navigate(`${PATH_DASHBOARD.general.app}/${channelId}/${messageId}`);
      }

      if (props?.type?.includes('couriel')) {
        couriel?.openFormById(props?.action?.id);
      }
    }
  };

  const classReturn = useMemo(() => {
    if (props?.type === NOTIFICATION_TYPES.TASK_VALIDATION_DEMANDE) {
      return 'cardDemande';
    }
    if (
      props?.type === NOTIFICATION_TYPES.TASK_VALIDATION_ACCEPTED ||
      props?.type === NOTIFICATION_TYPES.AFFECT_VALIDATE
    ) {
      return 'cardAccepted';
    }
    if (props?.type === NOTIFICATION_TYPES.TASK_VALIDATION_REJECT || props?.type === NOTIFICATION_TYPES.AFFECT_REJECT) {
      return 'cardReject';
    }
    if (
      props?.type === NOTIFICATION_TYPES.PROJECT_CREATE ||
      props?.type === NOTIFICATION_TYPES.PROJECT_UPDATE ||
      props?.type === NOTIFICATION_TYPES.AFFECT_ADD ||
      props?.type === NOTIFICATION_TYPES.AFFECT_PRINT
    ) {
      return 'cardProjet';
    }
    if (props?.type === NOTIFICATION_TYPES.RELANCE_TASK) {
      return 'relance';
    }

    return 'card';
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.type]);

  return (
    <MuiCard className={classes[classReturn]}>
      <CardActions classes={{ root: classes.actionRoot }}>
        <EllipsisText text={props.title} style={{ variant: 'subtitle', color: 'inherit', fontWeight: 'bold' }} />
        <Stack direction="row" spacing={1} alignItems="center" sx={{ cursor: 'pointer' }}>
          <Stack p={0.5} onClick={handleExpandClick}>
            <Icon icon="ic:twotone-expand-more" height={25} rotate={expanded ? 2 : 0} />
          </Stack>
        </Stack>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExits>
        <Paper square sx={{ borderRadius: '0 0 5px 5px' }} className={classes.collapse}>
          <Stack width={1} maxWidth={390} mb={1} spacing={0.2}>
            {(props?.type === NOTIFICATION_TYPES.COMMUNICATION_NEW_MESSAGE ||
              props?.type === NOTIFICATION_TYPES.COMMUNICATION_NEW_ANSWER_MESSAGE) && (
              <EllipsisText text={`# ${props?.action?.title}`} style={{ fontSize: 10, variant: 'overline' }} />
            )}
            {props?.type === NOTIFICATION_TYPES.COMMUNICATION_NEW_ANSWER_MESSAGE && (
              <TextMaxLine line={2} fontSize={12} variant="caption">
                *** {props?.action?.messageTitle} ***
              </TextMaxLine>
            )}
          </Stack>
          {HTMLParser(`${props?.desc || ''}`)}
          {(props?.action || props?.url) && (
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button size="small" onClick={handleDetail}>
                Ouvrir
              </Button>
            </Box>
          )}
        </Paper>
      </Collapse>
    </MuiCard>
  );
};

export const NotificationDemandeDialog = (props) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    props?.onClose(props.id);
    navigate(`${PATH_DASHBOARD.general.tasks}/${props.action?.sub}`, {
      state: {
        projectName: ' ',
        detailId: props.action?.id
      }
    });
  };

  return (
    <Fragment>
      <DialogContent>
        <Typography align="center" textAlign="center">
          Vous avez une demande de validation de la part de
          <Typography component={'span'} fontWeight="bold">
            {props?.by?.displayName}
          </Typography>
          sur la tâche
          <Typography component={'span'} fontWeight="bold">
            {props?.cardTitle}
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Stack justifyContent="flex-end">
          <Button color="success" onClick={navigateTo}>
            Ouvrir
          </Button>
        </Stack>
      </DialogActions>
    </Fragment>
  );
};

export const NotificationRejectDialog = (props) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    props?.onClose(props.id);
    navigate(`${PATH_DASHBOARD.general.tasks}/${props.action?.sub}`, {
      state: {
        projectName: ' ',
        detailId: props.action?.id
      }
    });
  };

  return (
    <Fragment>
      <DialogContent>
        <Typography align="center" textAlign="center">
          La demande de validation de la tâche
          <Typography component={'span'} fontWeight="bold">
            {' '}
            {props?.cardTitle}{' '}
          </Typography>
          a été rejeté par
          <Typography component={'span'} fontWeight="bold">
            {' '}
            {props?.by?.displayName}{' '}
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Stack justifyContent="flex-end">
          <Button color="success" onClick={navigateTo}>
            {' '}
            Ouvrir{' '}
          </Button>
        </Stack>
      </DialogActions>
    </Fragment>
  );
};

export const NotificationAcceptedDialog = (props) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    props?.onClose(props.id);
    navigate(`${PATH_DASHBOARD.general.tasks}/${props.action?.sub}`, {
      state: {
        projectName: ' ',
        detailId: props.action?.id
      }
    });
  };

  return (
    <Fragment>
      <DialogContent>
        <Typography align="center" textAlign="center">
          La demande de validation de la tâche
          <Typography component={'span'} fontWeight="bold">
            {' '}
            {props?.cardTitle}{' '}
          </Typography>
          a été accepté par
          <Typography component={'span'} fontWeight="bold">
            {' '}
            {props?.by?.displayName}{' '}
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Stack justifyContent="flex-end">
          <Button color="success" onClick={navigateTo}>
            {' '}
            Ouvrir{' '}
          </Button>
        </Stack>
      </DialogActions>
    </Fragment>
  );
};

export const NotificationTaskAssignDialog = (props) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    props?.onClose(props.id);
    navigate(`${PATH_DASHBOARD.general.tasks}/${props.action?.sub}`, {
      state: {
        projectName: ' ',
        detailId: props.action?.id
      }
    });
  };

  return (
    <Fragment>
      <DialogContent>
        <Typography align="center" textAlign="center">
          Vous avez été assigné à la tâche
          <Typography component={'span'} fontWeight="bold">
            {' '}
            {props?.cardTitle}{' '}
          </Typography>
          par
          <Typography component={'span'} fontWeight="bold">
            {' '}
            {props?.by?.displayName}{' '}
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Stack justifyContent="flex-end">
          <Button color="success" onClick={navigateTo}>
            {' '}
            Ouvrir{' '}
          </Button>
        </Stack>
      </DialogActions>
    </Fragment>
  );
};

export const NotificationProjectDialog = (props) => {
  const navigate = useNavigate();

  const navigateTo = () => {
    props?.onClose(props.id);
    navigate(`${PATH_DASHBOARD.general.tasks}/${props.action?.sub}`);
  };

  return (
    <Fragment>
      <DialogContent>
        <Typography align="center" textAlign="center">
          Vous avez été ajouter au projet
          <Typography component={'span'} fontWeight="bold">
            {' '}
            {props?.cardTitle}{' '}
          </Typography>
          par
          <Typography component={'span'} fontWeight="bold">
            {' '}
            {props?.by?.displayName}{' '}
          </Typography>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Stack justifyContent="flex-end">
          <Button color="success" onClick={navigateTo}>
            {' '}
            Ouvrir{' '}
          </Button>
        </Stack>
      </DialogActions>
    </Fragment>
  );
};
