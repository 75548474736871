import { map, filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import axiosRequest from '../../utils/axiosRequest';
// firestore
import firestore from '../../utils/firestore';
import { setTokens } from './notifications';

// ----------------------------------------------------------------------

const initialState = {
  loading: false,
  error: false,
  myProfile: null,
  posts: [],
  users: [],
  userList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.loading = true;
    },
    startUserLoading(state) {
      state.loading = true;
    },

    closeUserLoading(state) {
      state.loading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
      console.log(action.payload);
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.loading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.loading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.loading = false;
      state.users = action.payload;
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user.id !== action.payload);
      state.userList = deleteUser;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.loading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.loading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.loading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.loading = false;
      state.userList = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.loading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.loading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.loading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.loading = false;
      state.notifications = action.payload;
    },

    //CREATE USER
    createUserSuccess(state, action) {
      state.loading = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, startUserLoading, closeUserLoading } = slice.actions;

//------------------------------------------------------------------

export function deleteUser(id, callback = null) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await axiosRequest.delete(`/user/delete/${id}`);
      await firestore.collection('users').doc(id).set({ delete: true }, { merge: true });
      dispatch(slice.actions.deleteUser(id));
      if (callback) callback();
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/manage-users');
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/notifications-settings');
      dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addUserList(users) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getUsersSuccess(users));
      dispatch(setTokens(users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createUser({ user, callback = null, onError = null }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosRequest.post(`/user/create`, {
        ...user,
        id: '',
        username: user.displayName,
        tel: user.phoneNumber,
        role: user.roles
      });

      const { password, confirmation, ...createrUser } = user;

      await firestore.collection('users').doc(response.id).set(createrUser);

      dispatch(slice.actions.createUserSuccess(response.id));

      if (callback) callback(response.id, { ...user, id: response.id });

      const subject = process.env.REACT_APP_MAIL_SENDER;
      const from = process.env.REACT_APP_MAIL_SENDER;
      const domaine = window.location.host;

      const accessLink = `https://${domaine}/dashboard/user/account`;
      const description = `Bienvenue dans l'univers Okydook. Nous sommes heureux de vous compter parmi nos utilisateurs.`;
      const subdesc = `Veuillez-vous connecter via votre courriel et le mot de passe temporaire ci-dessous.`;

      const data = {
        salutation: 'Cher(e)',
        name: user?.name || user?.displayName,
        description: description,
        link: accessLink,
        subject: subject,
        identifiant: `Courriel : ${user.email}`,
        password: `Mot de passe : ${user.password}`,
        subdesc: subdesc,
        header: `Bienvenu sur Okydook management`
      };

      await axiosRequest.post('/mail/template', {
        to: user.email,
        from,
        templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
        data
      });
    } catch (error) {
      console.log('error', error);
      console.log('error2', error?.response);
      dispatch(slice.actions.hasError(error?.response?.data));
      if (onError) onError(error?.response?.data);
    }
  };
}

// ----------------------------------------------------------------------

export function updateUser({ user, callback = null }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const response = await axiosRequest.post(`/user/update`, {
        ...user,
        username: user.displayName,
        tel: user.phoneNumber,
        role: user.roles
      });

      await firestore.collection('users').doc(response.id).set(user, { merge: true });
      dispatch(slice.actions.createUserSuccess(response.id));
      if (callback) callback();
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateUserOtherVal({ id, values, callback = null }) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      await firestore
        .collection('users')
        .doc(id)
        .set({ ...values }, { merge: true });
      if (callback) callback();
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
