import { firestore } from 'src/contexts/FirebaseContext';

//#region gouti based project
export const projectsCollection = firestore.collection('new_projects');

export const goalsCollection = firestore.collection('new_projects_goals');

export const deliverableCollection = firestore.collection('new_projects_deliverables');

export const actionsCollection = firestore.collection('new_projects_actions');

export const problemsCollection = firestore.collection('new_projects_problems');

export const changesCollection = firestore.collection('new_projects_changes');

export const risksCollection = firestore.collection('new_projects_risks');

export const tasksCollection = firestore.collection('new_projects_tasks');

export const milestoneCollection = tasksCollection;

export const organisationCollection = firestore.collection('new_projects_organization');

export const flashReportCollection = firestore.collection('new_projects_flash_report');

export const timesheetCollection = firestore.collection('new_projects_timesheet');

export const financeCollection = firestore.collection('new_projects_finance');

//#endregion

export const moodCollection = firestore.collection('mood');

export const archiveCollection = firestore.collection('archives');

export const referenceCollection = firestore.collection('references');

export const archiveFoldersDoc = referenceCollection.doc('archive_folders');

export const surveyCollection = firestore.collection('meeting_survey');


export const folderCollection = firestore.collection('odoo_folder');

export const fileCollection = firestore.collection('odoo_files');

export const requestCollection = firestore.collection('odoo_requests');

export const sharingCollection = firestore.collection('odoo_sharing');

export const labelsCollection = firestore.collection('odoo_labels');

export const actionCollection = firestore.collection('odoo_actions');

export const activityCollection = firestore.collection('odoo_activities');


export const usersCollection = firestore.collection('users');
