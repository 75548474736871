import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'src/redux/store';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { getcontactList } from 'src/redux/slices/contact';
import { contactToAphabetList,goupToAlphabet } from 'src/utils/contactToAphabetList';




const sortFunction = (a, b) => {
  if (a < b)
    return -1
  if (a > b)
    return 1
  return 0
};


function sortObj(obj) {
  return Object.keys(obj).sort(sortFunction).reduce(function (result, key) {
    result[key] = obj[key];
    return result;
  }, {});
}


export default function useContactList({ type, email, subType = null }) {
  const [loading, setLoading] = useState(false);

  const getContacts = useSelector((state) => {
    if (subType) {
      return state.firestore.ordered[`${email}_contacts_${type}_${subType}`]
    }
    return state.firestore.ordered[`${email}_contacts_${type}`]
  });

  const getGroup = useSelector(state => state.firestore.ordered[`${email}_groups_${subType}`])

  const groups = useMemo(() => {
    return (isEmpty(getGroup) ? [] : getGroup)?.filter(el => el?.type === subType)
  }, [getGroup, subType])

  const alphabetGroup = useMemo(() => {
    return sortObj(goupToAlphabet(groups));
  }, [groups])

  const contacts = useMemo(() => { return isEmpty(getContacts) ? [] : getContacts }, [getContacts]);
  const alphabetContact = useMemo(() => { return isEmpty(getContacts) ? {} : sortObj(contactToAphabetList(getContacts)) }, [getContacts]);

  const dispatch = useDispatch();


  useFirestoreConnect(() => [
    {
      collection: 'contacts',
      where: [['contactType', '==', type], ['canAccessEmail', 'array-contains', email]],
      storeAs: `${email}_contacts_${type}`
    },
    {
      collection: 'contacts',
      where: [
        ['contactType', '==', type],
        ['subtype', '==', subType],
        ['canAccessEmail', 'array-contains', email]
      ],
      storeAs: `${email}_contacts_${type}_${subType}`
    },
    {
      collection: 'equipes',
      where: [['type', '==', subType],],
      storeAs: `${email}_groups_${subType}`
    }
  ]);


  useEffect(() => {
    if (!isLoaded(getContacts)) {
      setLoading(true);
    } else {
      setLoading(false);
      dispatch(getcontactList(contacts));
    }
  }, [getContacts, contacts, dispatch]);

  return { contacts, loading, alphabetContact, groups,alphabetGroup };
}
