export default {
    control: {
      fontSize: 14,
      fontWeight: "normal",
      outlineColor: "transparent",
      outline: "none"
    },
  
    "&multiLine": {
      highlighter: {
        padding: 9,
        border: "1px solid transparent",
        
      },
      input: {
        padding: 9,
        border: "none",
        outlineColor: "transparent",
        outline: "none"
      },
    },
  
    "&singleLine": {
      display: "inline-block",

      highlighter: {
        padding: 1,
        border: "2px inset transparent",
      },
      input: {
        padding: 3,
        border: "none",
        outlineColor: "transparent",
        outline: "none"
      },
    },
  
    suggestions: {
      list: {
        backgroundColor: "white",
        border: "1px solid rgba(0,0,0,0.15)",
        fontSize: 14,
      },
      item: {
        padding: "5px 15px",
        borderBottom: "1px solid rgba(0,0,0,0.15)",
        "&focused": {
          backgroundColor: "#cee4e5",
        },
      },
    },
  };