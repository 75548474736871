import { TASK_STATE_VALIDATION } from "src/constants"
import { baseColors } from "src/constants/color"


export const taskStateColor = (state) =>{
    if (state === TASK_STATE_VALIDATION.PENDING) return baseColors.GREY;
    if (state === TASK_STATE_VALIDATION.INPROGRESS) return baseColors.BLUELIGHT;
    if (state === TASK_STATE_VALIDATION.DONE) return baseColors.BLUEDARK;
    if (state === TASK_STATE_VALIDATION.ACCEPTED) return baseColors.GREEN;
    if (state === TASK_STATE_VALIDATION.REJECTED) return baseColors.RED;
    return baseColors.GREY;

}