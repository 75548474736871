// ----------------------------------------------------------------------

import { alpha } from '@mui/material';

export default function ToggleButton(theme) {
  const isLight = theme.palette.mode === 'light';

  return {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          color: theme.palette.grey[500],
          border: `solid 1px ${theme.palette.grey[500_32]}`,
          '&.Mui-selected': {
            color: theme.palette.grey[isLight ? 100 : 0],
            backgroundColor: isLight ? alpha('#000', 0.9) : theme.palette.action.selected,
            ':hover': {
              color: theme.palette.grey[isLight ? 900 : 0]
            }
          },
          '&.Mui-disabled': {
            color: theme.palette.grey[500_48]
          }
        }
      }
    }
  };
}
