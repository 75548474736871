import {createContext, useContext, useState} from 'react'
import {useToggleV2} from "src/hooks/useToggle";

import ContactForm_V4 from "src/section/contact/v4/ContactForm_V4";
import {deletecontact} from "src/redux/slices/contact";
import {useDispatch} from "src/redux/store";
import {useSnackbar} from "notistack";
import ContactSendMail_V4 from "src/section/contact/v4/ContactSendMail_V4";
import useAuth from "src/hooks/useAuth";

export const useContactContext = () => useContext(ContactContext)

export default function ContactProvider({children}) {
    const {user} = useAuth()
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const [openMail, onOpenMail, onCloseMail] = useToggleV2()

    const [open, onOpen, onClose] = useToggleV2()
    const [selected, setSelected] = useState(null)

    const openContactEditor = (contact = null) => {
        onOpen()
        setSelected(contact)
    }

    const closeContactEditor = () => {
        onClose()
        setSelected(null)
    }

    const removeContact = (contactId) => {
        dispatch(deletecontact(contactId, () => {
            enqueueSnackbar("Contact supprimé avec succès", {variant: "warning"});
        }))

    }

    const openMailComposer = ({email, emails}) => {
        onOpenMail()
    }

    const canDelete = (contact) => {
        return !contact?.createdBy || contact?.createdBy?.id === user?.id;

    }

    const canEdit = (contact) => {
        if (!contact || !contact?.createdBy) return true;
        return contact?.createdBy && contact?.createdBy?.id === user?.id
    }

    const store = {
        openContactEditor,
        closeContactEditor,
        removeContact,

        canDelete,
        canEdit,

        openMailComposer
    }

    return (
        <ContactContext.Provider value={store}>
            {children}

            {
                open && (
                    <ContactForm_V4 selected={selected} open={open} onClose={closeContactEditor}/>
                )
            }

            {
                openMail && (
                    <ContactSendMail_V4 open={openMail} onClose={onCloseMail}/>
                )
            }

        </ContactContext.Provider>
    )
}

const ContactContext = createContext({
    openMailComposer: (contact = null) => {
    },

    openContactEditor: (contact = null) => {
    },
    closeContactEditor: () => {
    },
    removeContact: (contactId = null) => {
    },

    canDelete: (contact = null) => {
    },
    canEdit: (contact = null) => {
    }
})