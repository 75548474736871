import React, { useMemo, useEffect, useRef } from 'react';
import { useInput } from '@mui/base';
import { styled } from '@mui/system';
import { TextField, InputBase, Stack, Typography, MenuItem } from '@mui/material';
import { baseColors } from 'src/constants/color';
import QuillEditor from './editor/quill';
import { useTheme } from '@mui/material/styles';
import { transformUserToAccess } from 'src/helpers/user';
import { LabelStyle } from 'src/section/tasks/kanban/KanbanTaskDetails';
import CountryCodeSelector from 'src/components/CountryCodeSelector';
import { capitalize } from 'lodash';
import { Mention, MentionsInput } from 'react-mentions';
import defaultMentionStyle from 'src/assets/css/default_editor_mention_style';
import defaultStyle from 'src/assets/css/default_editor_style';
import { useSelector } from 'src/redux/store';

export const blue = {
  200: '#80BFFF',
  400: '#3399FF'
};

export const grey = {
  10: '#FBFBFB',
  50: '#F6F9FB',
  100: '#E7EBF0',
  200: '#E0E3E7',
  300: '#CDD2D7',
  400: '#B2BAC2',
  500: '#A0AAB4',
  600: '#6F7E8C',
  700: '#3E5060',
  800: '#2D3843',
  900: '#1A2027'
};

const StyledInputElement = styled('input')(
  ({ theme }) => `
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  border: none;
  border-radius: 5px;
  padding: 12px 12px;
  transition: all 200ms ease;

  &:hover {
    background: ${theme.palette.mode === 'dark' ? null : grey[100]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &:focus {
    outline: 2px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]};
    outline-offset: 0px;
  }
`
);

/**
 * @type {InputBase}
 */
export const CustomInput = React.forwardRef(function (props, ref) {
  const { getRootProps, getInputProps } = useInput(props, ref);

  return (
    <div {...getRootProps()}>
      <StyledInputElement {...props} {...getInputProps()} />
    </div>
  );
});

export const StyledCustomAutocompletInput = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? grey[900] : grey[10],
  color: theme.palette.mode === 'dark' ? grey[300] : grey[900],
  border: `1px solid ${baseColors.GREY}`,
  borderRadius: '5px',
  padding: '6px 12px',
  transition: 'all 200ms ease',
  '&:hover': {
    outline: `2px solid ${theme.palette.primary.main}`,
    outlineOffset: 0
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none'
  }
}));

/**
 * @type {InputBase}
 */
export const CustomAutocompletInput = React.forwardRef(function (props, ref) {
  const { getRootProps, getInputProps } = useInput(props, ref);

  return (
    <div {...getRootProps()}>
      <StyledCustomAutocompletInput {...props} {...getInputProps()} />
    </div>
  );
});

export const StyledMuiCustomInput = styled(InputBase)(
  ({ theme }) => `
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
  background: ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
  border: 1px solid ${baseColors.GREY};
  border-radius: 5px;
  padding: 10px 10px;
  transition: all 200ms ease;
  
  &:hover {
    background: ${theme.palette.mode === 'dark' ? null : grey[50]};
    border-color: ${theme.palette.mode === 'dark' ? grey[700] : grey[400]};
  }

  &.MuiInputBase-root.Mui-focused {
    outline: 2px solid ${theme.palette.primary.main};
    outline-offset: 0px;
  }
`
);

/**
 * @type {InputBase}
 */
export const MuiCustomInput = React.forwardRef(function (props, ref) {
  const { getRootProps, getInputProps } = useInput(props, ref);

  const handleToolbarKeyDown = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  return (
    <div {...getRootProps()}>
      <StyledMuiCustomInput
        {...props}
        {...getInputProps()}
        onKeyDown={props?.nolockenter ? undefined : handleToolbarKeyDown}
      />
    </div>
  );
});

const parser = ({ id, name }) => {
  return `<span class=\"mention\" data-index=\"0\" data-denotation-char=\"@\" data-id=\"${id}\" data-value=\"Gouti Helper\">﻿<span contenteditable=\"false\"><span class=\"ql-mention-denotation-char\">@</span>${name}r</span>﻿</span>`;
};

export const CustomQuillField = ({ id = 'CustomQuillField', label, value = '', onChange, onMentions, ...rest }) => {
  const theme = useTheme();
  const { users } = useSelector((state) => state.user);

  const parsed = useMemo(() => {
    const persons = users?.map((el) => transformUserToAccess(el));
    const metas = persons?.map((el) => ({
      target: `@${el?.name}`,
      equiv: parser(el)
    }));

    let val = value;
    let flag = false;

    metas?.forEach((el) => {
      val = val?.replaceAll(el?.target, el?.equiv);
      flag = true;
    });

    return flag ? val : value;
  }, [users, value]);

  const handleChange = (val) => {
    if (val !== value && val !== `<p>${value}</p>`) {
      onChange(val, val.replace(/<[^>]+>/g, ''));
    }
  };

  // console.log(theme)

  return (
    <>
      <QuillEditor
        id={id}
        canMention={true}
        placeholder={label}
        value={parsed}
        onChange={handleChange}
        setMensions={onMentions}
         hideToolBar
        style={{
          padding: 0
        }}
        sx={{
          // backgroundColor: theme.palette.mode === 'dark' ? grey[900] : grey[50],
          color: theme.palette.mode === 'dark' ? grey[300] : grey[900],
          border: 'none',
           // padding: '6px 12px',
          transition: 'all 200ms ease',
          // '&:hover': {
          //   backgroundColor: theme.palette.mode === 'dark' ? null : grey[50],
          //   borderColor: theme.palette.mode === 'dark' ? grey[700] : grey[400]
          // },
          '&:focus': {
            outline: `2px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]}`,
            outlineOffset: 0
          },
          padding: '0 0 0 0',
          height:'100%',
        }}
        {...rest}
      />
    </>
  );
};

export const customTextFieldTheme = (theme) => {
  return {
    backgroundColor: theme.palette.mode === 'dark' ? grey[900] : grey[50],
    color: theme.palette.mode === 'dark' ? grey[300] : grey[900],
    border: 'none',
    borderRadius: '5px',
    padding: 1,
    px: 2,
    transition: 'all 200ms ease',
    '&:hover': {
      backgroundColor: theme.palette.mode === 'dark' ? null : grey[50],
      borderColor: theme.palette.mode === 'dark' ? grey[700] : grey[400]
    },
    '&:focus': {
      outline: `2px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]}`,
      outlineOffset: 0
    }
  };
};

export const Field = ({ label, helperText, error, anchor, InputProps, ...rest }) => {
  const theme = useTheme();

  return (
    <Stack spacing={1}>
      {label && (
        <Stack width={1}>
          <LabelStyle width="max-content">{capitalize(label)}</LabelStyle>
        </Stack>
      )}
      <TextField
        ref={anchor}
        fullWidth
        variant={'standard'}
        {...rest}
        InputProps={{
          disableUnderline: true,
          ...InputProps
        }}
        sx={customTextFieldTheme(theme)}
      />
      {helperText && (
        <Typography variant={'caption'} color={error ? 'red' : 'black'} fontSize={12}>
          {helperText}
        </Typography>
      )}
    </Stack>
  );
};

export const PhoneField = ({ label = '', helperText, error, countryCode, onCountryCodeChange, ...rest }) => {
  return (
    <Field
      label={label}
      helperText={helperText}
      error={error}
      {...rest}
      InputProps={{
        startAdornment: <CountryCodeSelector value={countryCode} onChange={onCountryCodeChange} />
      }}
    />
  );
};

export const SelectField = ({ label = '', options, helperText, error, ...rest }) => {
  const theme = useTheme();

  return (
    <Stack spacing={1}>
      {label && (
        <Stack width={1}>
          <LabelStyle width="max-content">{capitalize(label)}</LabelStyle>
        </Stack>
      )}
      <TextField
        select
        fullWidth
        variant={'standard'}
        InputProps={{
          disableUnderline: true
        }}
        sx={customTextFieldTheme(theme)}
        {...rest}
      >
        {options.map((val) => (
          <MenuItem key={val} value={val}>
            {val}
          </MenuItem>
        ))}
      </TextField>

      {helperText && (
        <Typography variant={'caption'} color={error ? 'red' : 'black'} fontSize={12}>
          {helperText}
        </Typography>
      )}
    </Stack>
  );
};

export const EditorMentionEnter = ({
  inputRef,
  value,
  onChange,
  onKeyDown,
  onKeyUp,
  useBackground = false,
  onsave,
  onAdd,
  id,
  style,
  placeholder,
  onMentionPopupStateChange
}) => {
  const ref = useRef();
  const { users } = useSelector((state) => state.user);
  const theme = useTheme();

  const handleChange = (_, newValue, newPlainTextValue, mentions) => {
    onChange({ newValue, newPlainTextValue, mentions });
    // window.document.getElementsByTagName().parentElement
  };

  const data = useMemo(
    () =>
      users.map((_user) => ({
        id: _user?.id,
        display: _user?.dispayName || _user?.name || `${_user?.lastName} ${_user?.firstName}` || ''
      })),
    [users]
  );

  const isMentionOpen = () => {
    const element = ref.current.getElementsByTagName('ul')[0];
    const parent = element?.parentNode;
    const opened = Boolean(element);

    if (parent && 'style' in parent && 'zIndex' in parent.style) parent.style.zIndex = theme.zIndex.drawer + 50;

    const ancestor = parent?.parentNode;

    const endWithArobase = value?.trim()?.lastIndexOf('@') === value?.length - 1;
    return opened || endWithArobase;
  };

  const handleKeyUp = (event) => {
    const isOpened = isMentionOpen();
    onMentionPopupStateChange && onMentionPopupStateChange(isOpened);
    onKeyUp && onKeyUp(Object.assign(event, { isMentionOpen: isOpened }));
  };

  return (
    <Stack width={1} ref={ref}>
      <MentionsInput
        inputRef={inputRef}
        style={{
          ...defaultStyle,
          ...(useBackground && { backgroundColor: theme.palette.mode === 'dark' ? null : grey[50] }),
          ...style
        }}
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onKeyUp={handleKeyUp}
        onBlur={onsave}
        id={id}
        placeholder={placeholder || ''}
        autoFocus
      >
        <Mention
          style={defaultMentionStyle}
          data={data}
          displayTransform={(_, display) => ` ${display}`}
          onAdd={onAdd}
          appendSpaceOnAdd
        />
      </MentionsInput>
    </Stack>
  );
};

/**
 *
 * @param {{
 *  inputRef: any,
 *  value: any,
 *  onChange: function({newValue, newPlainTextValue, mentions }),
 *  onBlur: function,
 *  onKeyDown: function,
 *  onKeyUp: function,
 *  onAdd: function(id, display)
 *  id: string,
 *  style: any,
 *  placeholder: string,
 *  single:boolean,
 *  autoFocus:boolean
 * }} props
 * @returns
 */
export const InputWithMention = ({
  inputRef,
  value,
  onChange,
  onKeyDown,
  onKeyUp,
  onAdd,
  id,
  onBlur,
  style,
  placeholder,
  autoFocus = false,
  single
}) => {
  const ref = useRef();
  const { users } = useSelector((state) => state.user);

  const handleChange = (_, newValue, newPlainTextValue, mentions) => {
    onChange({ newValue, newPlainTextValue, mentions });
  };

  const data = useMemo(
    () =>
      users.map((_user) => ({
        id: _user?.id,
        display: _user?.dispayName || _user?.name || `${_user?.lastName} ${_user?.firstName}` || ''
      })),
    [users]
  );

  return (
    <Stack width={1} ref={ref}>
      <MentionsInput
        inputRef={inputRef}
        style={{
          ...defaultStyle,
          ...style
        }}
        value={value}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        id={id}
        autoFocus={autoFocus}
        onBlur={onBlur}
        placeholder={placeholder || ''}
        {...(single && { singleLine: true })}
      >
        <Mention
          style={defaultMentionStyle}
          data={data}
          displayTransform={(_, display) => ` ${display}`}
          onAdd={onAdd}
          appendSpaceOnAdd
        />
      </MentionsInput>
    </Stack>
  );
};
