import {Sheet} from '@mui/joy';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    Button,
    Divider,
    Typography,
    Box,
    MenuItem, TextField,
    Tooltip, ButtonGroup,
} from '@mui/material';
import {Form, FormikProvider, useFormik} from 'formik';
import React, {useMemo, useState} from 'react';
import AttachReceivedSvg from 'src/components/AttachReceivedSvg';
import AttachSendSvg from 'src/components/AttachSendSvg';
import CIconButton from 'src/components/CIconButton';
import InputLine from 'src/components/CustomInputLine';
import Iconify from 'src/components/Iconify';
import {useAffectation_v4Context} from 'src/contexts/Affectation_v4Context';
import useAuth from 'src/hooks/useAuth';
import {Affectation_Setting_model, Affectation_v4_Type} from 'src/models/Affectation_v4_type';
import OrganigrammeItem from './OrganigrammeItem';
import {AFFECT_OPERATION_TYPE, AFFECT_PRIORITY} from 'src/constants/affectation';
import {getFileThumbSecond} from 'src/utils/getFileFormat';
import {compareArraysOfObjects} from 'src/utils/changeOnObject';
import {uniq} from 'lodash';
import {serverTime} from 'src/utils/serverTime';
import {dispatch} from 'src/redux/store';
import {updateAffectation} from 'src/redux/slices/affectation_v4';
import {AffectLineDue} from './liste/AffectationLine';
import AffectationRappel from './rappel/AffectationRappel';
import ActionModal from "src/components/ActionModal";
import {useToggleV2} from "src/hooks/useToggle";

/**
 *
 * @param {{open: boolean, affectation: Affectation_v4_Type, onClose: ()=>void}} props
 * @returns
 */
export default function AffectationDetails({open, affectation, onClose}) {
    const {user} = useAuth();
    const {settings: {organigramme = []} = Affectation_Setting_model} = useAffectation_v4Context();
    const [saving, setSaving] = useState(false);

    const [visible, display, hide] = useToggleV2()

    const formik = useFormik({
        initialValues: affectation,
        onSubmit: (values) => {
            const oldAssigne = affectation.assignationOrganigramme;
            const newAssigne = values.assignationOrganigramme;

            let toDoIds = affectation.toDoIds;
            let toReviewIds = uniq([...affectation.toReviewIds, user.uid]);

            //check change user on assigne organigramme and add to toDoIds
            newAssigne.forEach((level, index) => {
                const oldLevel = oldAssigne[index];
                const {added} = compareArraysOfObjects(oldLevel?.users || [], level.users);
                toDoIds = uniq([...toDoIds, ...added]);
            });

            toReviewIds.forEach((_id) => (toDoIds = toDoIds.filter((_oneId) => _oneId !== _id)));

            let toSave = {
                ...values,
                toDoIds,
                toReviewIds,
                updated: {
                    by: user.uid,
                    at: serverTime()
                }
            };

            dispatch(
                updateAffectation({
                    newAffectation: toSave,
                    oldAffectation: affectation,
                    callback: () => {
                    },
                    onError: () => {
                    }
                })
            );
            onClose();
        }
    });

    const {handleSubmit, setFieldValue, errors, values} = formik;


    const handleChangeDone = () => {
        setFieldValue('completed', true)
        setFieldValue('completedBy', user.uid)
        handleSubmit()
    };

    const handleChangeNotDone = () => {
        setFieldValue('completed', false)
        setFieldValue('completedBy', user.uid)
        handleSubmit()
    };

    const handleValidate = () => {
        setFieldValue('validation', {...values?.validation, state: true, by: user.uid})
        handleSubmit()
    };
    const handleReject = (rejectMessage) => {
        setFieldValue('validation', {...values?.validation, state: false, by: null})
        setFieldValue('rejectMessage', rejectMessage)
        handleChangeNotDone()
    };

    const levelDown = useMemo(() => {
        const myposition = organigramme?.findIndex((one) => one.users.find((_userId) => _userId === user.uid));
        const completedByposition = organigramme?.findIndex((one) =>
            one.users.find((_userId) => _userId === values.completedBy)
        );
        if (completedByposition !== -1 && myposition !== -1) {
            return myposition > completedByposition;
        }

        return false;
    }, [values.completedBy, organigramme, user.uid]);

    return (
        <>
            <FormikProvider value={formik}>
                <Dialog
                    maxWidth="lg"
                    fullWidth
                    open={open}
                    onClose={onClose}
                    PaperProps={{sx: {border: 'none', boxShadow: 'none', bgcolor: 'transparent', p: 2}}}
                    scroll="paper"
                >
                    <DialogTitle
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',

                            pb: 2,
                            bgcolor: 'background.paper',
                            borderTopLeftRadius: 20,
                            borderTopRightRadius: 20
                        }}
                    >
                        <Stack width={1} direction="row">
                            <Stack width={0.4} justifyContent="center">
                                <Typography fontWeight="bold" variant="h4">
                                    Assignation
                                </Typography>
                            </Stack>

                            <Stack width={0.6} spacing={3} direction="row" justifyContent="end">

                                <Stack direction={'row'} spacing={2}>

                                    {values.completed ? (
                                        values.completedBy === user.uid || levelDown ? (
                                            <Tooltip title="Marquer comme non terminée" arrow>
                                                <Button variant="contained" color="success" sx={{py: 1.5}}
                                                        onClick={handleChangeNotDone}>
                                                    Terminée
                                                </Button>
                                            </Tooltip>
                                        ) : !values.validation.state ? (
                                            <ButtonGroup>
                                                <Button variant="contained" color="error" sx={{py: 1.5}}
                                                        endIcon={<Iconify icon="uiw:dislike-o"/>}
                                                        onClick={display}>
                                                    Rejeter
                                                </Button>

                                                <Button variant="contained" color="success" sx={{py: 1.5}}
                                                        endIcon={<Iconify icon="uiw:like-o"/>}
                                                        onClick={handleValidate}>
                                                    Accepter
                                                </Button>
                                            </ButtonGroup>
                                        ) : (
                                            <Tooltip title="Marquer comme non terminée" arrow>
                                                <Button variant="contained" color="success" sx={{py: 1.5}}
                                                        onClick={handleChangeNotDone}>
                                                    Terminée
                                                </Button>
                                            </Tooltip>
                                        )
                                    ) : (
                                        <Button variant="outlined" color="inherit" sx={{py: 1.5}}
                                                endIcon={<Iconify icon="ph:check-bold"/>} onClick={handleChangeDone}>
                                            Marquer comme terminée
                                        </Button>
                                    )}
                                </Stack>

                                <Button variant="contained" color="primary" sx={{py: 1.5}} onClick={handleSubmit}>
                                    ENREGISTRER
                                </Button>
                            </Stack>
                        </Stack>
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            p: 3,
                            position: 'relative',
                            bgcolor: 'background.paper',
                            borderBottomLeftRadius: 20,
                            borderBottomRightRadius: 20
                        }}
                    >
                        <Form noValidate onSubmit={handleSubmit}>
                            <Stack width={1} direction="row" justifyContent="space-between" spacing={3}>
                                <Stack width={0.4}>
                                    <Sheet variant="soft" sx={{p: 2, borderRadius: 'md'}}>
                                        <Stack spacing={2}>
                                            <AssigneView
                                                user={user}
                                                organigramme={organigramme}
                                                assigne={affectation.assignationOrganigramme}
                                                toDoIds={affectation.toDoIds}
                                                onChange={setFieldValue}
                                            />
                                        </Stack>
                                    </Sheet>
                                </Stack>
                                <Stack width={0.6}>
                                    <Sheet>
                                        <Stack width={1} spacing={2}>
                                            <Divider/>

                                            <Stack position="relative">
                                                <Sheet variant="outlined" sx={{p: 2, borderRadius: 'sm'}}>
                                                    <Stack width={1} direction="row" spacing={3}>
                                                        <InputLine
                                                            noWritable={user.uid !== affectation.createdBy}
                                                            type="text"
                                                            label="Référence"
                                                            value={values.courriel_reference}
                                                            field="courriel_reference"
                                                            onChange={setFieldValue}
                                                        />
                                                        <InputLine
                                                            disabled={user.uid !== affectation.createdBy}
                                                            type="date"
                                                            label="Date"
                                                            value={values.courriel_date}
                                                            field="courriel_date"
                                                            onChange={setFieldValue}
                                                        />

                                                        <InputLine
                                                            noWritable={user.uid !== affectation.createdBy}
                                                            type="text"
                                                            label="Expéditeur"
                                                            value={affectation.correspondant.title}
                                                            field="correspondant.title"
                                                            onChange={setFieldValue}
                                                        />
                                                    </Stack>
                                                    <Stack width={1} direction="row" spacing={3}>
                                                        <InputLine
                                                            type="text"
                                                            label="Objet"
                                                            value={affectation.courriel_object}
                                                            field="courriel_object"
                                                            onChange={setFieldValue}
                                                        />
                                                    </Stack>
                                                </Sheet>
                                                <Box position="absolute" top={-10} left={20} bgcolor="#FFF" px={1}>
                                                    <Typography fontWeight="bold" fontSize={15}>
                                                        COURRIER
                                                    </Typography>
                                                </Box>
                                            </Stack>

                                            <Stack width={1} direction="row" spacing={3}>
                                                <InputLine
                                                    type="select"
                                                    options={Object.entries(AFFECT_PRIORITY).map(([_, val]) => val)}
                                                    label="Priorité"
                                                    value={affectation.priority}
                                                    field="priority"
                                                    onChange={setFieldValue}
                                                />

                                                <InputLine
                                                    type="select"
                                                    options={AFFECT_OPERATION_TYPE}
                                                    label="Type d'opération"
                                                    value={values.operation_type}
                                                    field="operation_type"
                                                    onChange={setFieldValue}
                                                />


                                            </Stack>

                                            <Stack width={1} direction="row" spacing={3}>

                                                <InputLine
                                                    type="date"
                                                    label="Date d'enrégistrement"
                                                    value={affectation.save_date}
                                                    field="save_date"
                                                    onChange={setFieldValue}
                                                />
                                                <Stack width={1} spacing={0.5}>
                                                    <Typography fontSize={12}>Échéance</Typography>
                                                    <AffectLineDue vue="input" affect={affectation}
                                                                   onChange={setFieldValue}/>
                                                </Stack>

                                            </Stack>


                                            <AffectationRappel affectation={affectation}/>


                                            <Stack width={1} alignItems="center" spacing={3}>
                                                <Typography fontSize={13}>Pièces jointes</Typography>
                                                <Stack width={1} direction="row" spacing={3} justifyContent="center">
                                                    <Stack direction="row" alignItems="center">
                                                        {values.attachments.received.map((file) => {
                                                            const {name, url, type} = file;

                                                            return (
                                                                <Tooltip key={file.url} title={name} arrow>
                                                                    {type.includes('image') ? (
                                                                        <Box
                                                                            component="img"
                                                                            alt="file preview"
                                                                            src={url}
                                                                            sx={{
                                                                                borderRadius: 1,
                                                                                objectFit: 'cover',
                                                                                width: 60,
                                                                                height: 60
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Box>{getFileThumbSecond(type, url, {
                                                                            width: 60,
                                                                            height: 60
                                                                        })}</Box>
                                                                    )}
                                                                </Tooltip>
                                                            );
                                                        })}
                                                    </Stack>
                                                    <Box
                                                        component={MenuItem}
                                                        sx={{
                                                            height: 100,
                                                            width: 100,
                                                            bgcolor: 'info.lighter',
                                                            borderRadius: 1,
                                                            border: (t) => `1px dashed ${t.palette.info[200]}`,
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Stack alignItems="center">
                                                            <AttachReceivedSvg/>
                                                            <Typography fontSize={10} color="info.main"
                                                                        fontWeight="bold">
                                                                Reçu
                                                            </Typography>
                                                        </Stack>
                                                    </Box>

                                                    <Sheet
                                                        variant="soft"
                                                        color="success"
                                                        sx={{
                                                            height: 100,
                                                            width: 100,
                                                            overflow: 'scroll',
                                                            borderRadius: 'sm',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}
                                                    >
                                                        <Stack alignItems="center">
                                                            <InputLine
                                                                type="file"
                                                                label=""
                                                                value={values.attachments.send}
                                                                field="attachments.send"
                                                                onChange={setFieldValue}
                                                                fileView={
                                                                    <Stack>
                                                                        <AttachSendSvg/>
                                                                        <Typography color="success.main" fontSize={10}
                                                                                    fontWeight="bold">
                                                                            Envoyée(s)
                                                                        </Typography>
                                                                    </Stack>
                                                                }
                                                            />
                                                        </Stack>
                                                    </Sheet>
                                                    <Stack direction="row" alignItems="center">
                                                        {values.attachments.send.map((file) => {
                                                            const {name, url, type} = file;

                                                            return (
                                                                <Tooltip key={file.url} title={name} arrow>
                                                                    {type.includes('image') ? (
                                                                        <Box
                                                                            component="img"
                                                                            alt="file preview"
                                                                            src={url}
                                                                            sx={{
                                                                                borderRadius: 1,
                                                                                objectFit: 'cover',
                                                                                width: 60,
                                                                                height: 60
                                                                            }}
                                                                        />
                                                                    ) : (
                                                                        <Box>{getFileThumbSecond(type, url, {
                                                                            width: 60,
                                                                            height: 60
                                                                        })}</Box>
                                                                    )}
                                                                </Tooltip>
                                                            );
                                                        })}
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                        </Stack>
                                    </Sheet>
                                </Stack>
                            </Stack>
                        </Form>
                    </DialogContent>
                    <Stack
                        direction="row"
                        spacing={1}
                        p={0.3}
                        alignItems="center"
                        sx={{
                            position: 'absolute',
                            top: '0px',
                            right: '5px',
                            borderRadius: 1,
                            backgroundColor: 'transparent'
                        }}
                    >
                        <CIconButton
                            noBorder
                            disabled={saving}
                            title="Fermer"
                            onClick={onClose}
                            sx={{
                                color: '#fff',
                                bgcolor: 'red',
                                ':hover': {bgcolor: 'red'},
                                ':disabled': {bgcolor: 'error.light'}
                            }}
                        >
                            <Iconify icon="eva:close-fill"/>
                        </CIconButton>
                    </Stack>
                </Dialog>
            </FormikProvider>
            <RejectionModal open={visible} onClose={hide} onAccept={handleReject}/>
        </>
    );
}

/**
 *
 * @param {{
 * user: firebase.default.UserInfo,
 * organigramme: Array<{label:string, users: Array}>,
 * assigne: Array<{label:string, users: Array}>,
 * onChange: (field, value) => void,
 * }} props
 */
const AssigneView = ({user, organigramme, assigne, onChange}) => {
    const myLevel = useMemo(() => {
        const position = organigramme.findIndex((one) => one.users.find((_userId) => _userId === user.uid));
        if (!assigne.length) {
            const _ass = organigramme.map((org) => ({...org, users: []}));
            if (position !== -1) {
                return {
                    assigne: _ass,
                    position
                };
            }
            return {assigne: _ass, position: 0};
        }
        return {assigne, position};
    }, [assigne, organigramme, user.uid]);

    const [assignationOrganigramme, setAssigneOrganigramme] = useState(myLevel.assigne);

    const handleAssigneTo = (level, newItem) => {
        setAssigneOrganigramme((old) => {
            let newOrg = [...old];
            if (newOrg[level]) {
                newOrg = newOrg.map((item, index) => {
                    if (index === level) {
                        return {...item, ...newItem};
                    }
                    return item;
                });

                onChange('assignationOrganigramme', [...newOrg]);
                return [...newOrg];
            }

            onChange('assignationOrganigramme', [...newOrg]);
            return [...newOrg];
        });
    };

    return (
        <Stack alignItems="start">
            {assignationOrganigramme.slice(myLevel.position + 1, organigramme.length).map((org, index) => (
                <OrganigrammeItem
                    key={org.label + index}
                    item={org}
                    isLast={index === assignationOrganigramme.length - 1}
                    isFirst={index === 0}
                    index={index}
                    updateLevel={handleAssigneTo}
                    assingation
                    defaultPosition={myLevel.position}
                />
            ))}
        </Stack>
    );
};

const RejectionModal = ({open, onClose, onAccept}) => {
    const [hasError, setError] = useState(false)
    const [message, setMessage] = useState('')
    const [isConfirmed, _, __, setConfirm] = useToggleV2()

    const handleAccept = () => {
        if (onAccept && message.length > 0) {
            onAccept(message)
            onClose()
        } else {
            setError(true)
        }
    }

    return (
        <>
            <ActionModal
                title={"Motif du rejet"}
                color='error'
                open={open}
                onClose={onClose}
                moreAction={
                    <Stack direction='row' spacing={1} alignItems='center'>
                        <TextField
                            value={message}
                            onChange={(args) => setMessage(args.target.value)}
                            placeholder={"Motif..."}
                            fullWidth multiline minRows={4}
                            error={hasError}
                            helperText={hasError && "Ce champs est requis"}
                        />
                    </Stack>
                }
                action={
                    <Button
                        color='error' variant='contained' onClick={handleAccept}
                    >
                        Rejeter
                    </Button>
                }
            />

        </>
    )
}
