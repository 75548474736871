import { filter, indexOf } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
import firestore from '../../utils/firestore';

const initialState = {
    isLoading: false,
    error: false,
    contacts: [],
    equipes: [],
    notifications: null
};

const slice = createSlice({
    name: 'contact',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        //AUDIENCE ADD AND UPDATE SUCCESS
        addSuccess(state, action) {
            state.isLoading = false;
            state.contacts.push(action.payload);
        },

        updateSuccess(state, action) {
            state.isLoading = false;
            const toUpdate = indexOf(state.contacts, (contact) => contact.id === action.payload);
            let newAudience = [...state.contacts];
            newAudience.splice(toUpdate, 1, action.payload);
            state.contacts = newAudience;
        },

        //GET AUDIENCE SUCCESS
        getcontactsuccess(state, action) {
            state.isLoading = false;
            state.contacts = action.payload;
        },

        //GET AUDIENCE SUCCESS
        getEquipessuccess(state, action) {
            state.isLoading = false;
            state.equipes = action.payload;
        },

        deletecontactsuccess(state, action) {
            state.loading = false;
            state.contacts = filter(state.contacts, (contact) => contact.id !== action.payload);
        }
    }
});

export default slice.reducer;

export function getcontactList(contacts) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.startLoading());
            dispatch(slice.actions.getcontactList(contacts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function getEquipeList(equipes) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.startLoading());
            dispatch(slice.actions.getEquipessuccess(equipes));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function createcontact(contact, callback = null) {
    return async (dispatch) => {
        try {

            dispatch(slice.actions.startLoading());

            const doc = await firestore.collection('contacts').add(contact);

            dispatch(slice.actions.addSuccess({ id: doc.id, ...contact }));

            if (callback) return callback(doc.id);

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
};



export function createEquipes(equipes, callback = null) {
    return async (dispatch) => {
        try {

            dispatch(slice.actions.startLoading());

            const doc = await firestore.collection('equipes').add(equipes);

            if (callback) return callback(doc.id);

        } catch (error) {
            callback && callback()
            dispatch(slice.actions.hasError(error));
        }
    }
};


export function editEquipes(equipes, callback = null) {
    return async (dispatch) => {
        try {


            dispatch(slice.actions.startLoading());

            const { id, lastName, ...rest } = equipes

            await firestore.collection('equipes').doc(id).set(rest, { merge: true })

            if (callback) return callback(id);

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
};

export function deleteEquipes(equipesId, callback = null) {
    return async (dispatch) => {
        try {


            dispatch(slice.actions.startLoading());

            await firestore.collection('equipes').doc(equipesId).delete();

            if (callback) return callback(equipesId);

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
};


export function updatecontact(contact, callback = null) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const { id, ...rest } = contact;

            await firestore.collection('contacts').doc(id).set({ ...rest }, { merge: true });

            dispatch(slice.actions.updateSuccess({ id: id, ...contact }));

            if (callback) return callback(id);

        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}


export function deletecontact(id, callback = null) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.startLoading());
            await firestore.collection('contacts').doc(id).delete();
            dispatch(slice.actions.deletecontactsuccess(id));

            if (callback) return callback();
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    }
}