import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import firestore from 'src/utils/firestore';

const comment = createSlice({
    name: 'comment',
    initialState: {
        isLoading: false,
        error: false,
        hasMore: true,
        comments: [],
        cursor: null
    },
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        noHasMore(state) {
            state.hasMore = false;
        },

        saveComments(state, action) {
            state.comments = action.payload
        },

        setCursor(state, action) {
            state.cursor = action.payload
        }
    }
})

export const {hasError, noHasMore, saveComments, startLoading} = comment.actions;

export default comment.reducer;

export const selectComments = (state) => state.comments;


//#region thunks

export const createComment = createAsyncThunk(
    'comment/create',
    async ({comment, postId, callback}, {dispatch}) => {
        try {
            await firestore.collection(`posts/${postId}/comments`).add(comment)
            callback && callback(true)
        } catch (e) {
            console.error(e)
            callback && callback(false, e)
        }

    }
)


export const updateComment = createAsyncThunk(
    'comment/update',
    async ({comment, postId, callback}, {dispatch}) => {
        try {
            const {id, ...rest}=comment;

            await firestore.collection(`posts/${postId}/comments`)
                .doc(id).update(rest)

            callback && callback(true)
        } catch (e) {
            console.error(e)
            callback && callback(false, e)
        }

    }
)


//#endregion thunks
