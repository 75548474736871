import { useState, useMemo, useEffect } from 'react';
import { useFirestoreConnect, isLoaded, isEmpty } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'src/redux/store';
import { getCurrentProject, getAllProjects } from 'src/redux/slices/kanban';

/**
 * 
 * @param {{ id: string, status?: []}} props 
 * @returns {{ projects: [], loading: Boolean }}
 */
export default function useProject({ id, status=null }) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const getProjects = useSelector((state) => state.firestore.ordered[`list_${id}_My_Projects`]);
    const projects = useMemo(()=> isEmpty(getProjects) ? [] : getProjects, [getProjects]);


    useFirestoreConnect(()=>[
        { 
            collection:'project', 
            ...(status ?{ 
                where:[['canAccessId', 'array-contains', id], ["state", "in", status]] 
            } : { 
                where:[['canAccessId', 'array-contains', id]]
            }), 
            orderBy:[['createdAt', 'desc']], 
            storeAs: `list_${id}_My_Projects`
        }
    ]);


    useEffect(()=> {
        setLoading(!isLoaded(getProjects));
        dispatch(getAllProjects(projects))
    },[getProjects, dispatch, projects]);

    return { loading, projects }
}

/**
 * 
 * @param {{ id: string, status?: []}} props 
 * @returns {{ projects: [], loading: Boolean }}
 */
export function useProjectHome({ id }) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const getProjects = useSelector((state) => state.firestore.ordered[`list_${id}_My_Projects_Home`]);
    const projects = useMemo(()=> isEmpty(getProjects) ? [] : getProjects, [getProjects]);


    useFirestoreConnect(()=>[
        { 
            collection:'project', 
            where:[['canAccessId', 'array-contains', id], ["state", "in", ['open']]],
            limit: 5,
            orderBy:[['lastOpen', 'desc']], 
            storeAs: `list_${id}_My_Projects_Home`
        }
    ]);


    useEffect(()=> {
        setLoading(!isLoaded(getProjects));
        dispatch(getAllProjects(projects))
    },[getProjects, dispatch, projects]);

    return { loading, projects }
}

export function useOneProject({ id }){
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const getProjects = useSelector((state) => state.firestore.ordered[`${id}_My_Project`]);
    const project = useMemo(()=> isEmpty(getProjects) ? {} : getProjects[0], [getProjects]);

    useFirestoreConnect(()=>[
        { 
            collection:'project', 
            doc: id,
            storeAs: `${id}_My_Project`
        }
    ]);


    useEffect(()=> {
        setLoading(!isLoaded(getProjects));
        dispatch(getCurrentProject(project))
    },[getProjects, dispatch, project]);

    return { loading, project }
}

