import {replace, isFinite} from 'lodash';
import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number) {
    return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number) {
    return numeral(number / 100).format('0.0%');
}

export function fNumber(number) {
    return numeral(number).format();
}

export function fShortenNumber(number) {
    return replace(numeral(number).format('0.00a'), '.00', '');
}

export function fData(number) {
    return numeral(number).format('0.0 b');
}


/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }

    const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


    return bytes.toFixed(dp) + ' ' + units[u];
}

export const parseNumber = (value, nullable = false) => {
    if (String(value)?.trim()?.length === 0) {
        return nullable ? null : 0
    }
    return Number(value)
    // return String(value)?.trim()?.length === 0 ? 0 : Number(value);
}

export const getNumberFieldProps = ({value, onChange} = null) => {

    const change = (val) => onChange && onChange(val)

    const handleChange = (event) => {
        const result = event?.target?.value || '';

        if (result?.trim()?.length === 0) return change(result)

        const sub = Number(result?.trim())

        if (isFinite(sub)) return change(sub)

        return change(value)

    }

    return ({
        value,
        onChange: handleChange
    })
}