import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'project_like_gouti',
  initialState: {
    projectId: null,
    deliverable: null,
    problem: null,
    change: null,
    risk: null,
    task: null,
    action: null,
    organization: null,
    flash: null,

    loading: false,
    error: null
  },
  reducers: {
    startLoading(state) {
      state.loading = true;
    },
    hasError(state, action) {
      state.error = action.payload;
      state.loading = false;
      console.error(action.payload);
    },
    gotDeliverable(state, action) {
      state.error = null;
      state.loading = false;
      state.deliverable = action.payload;
      state.projectId = action.payload?.projectId;
    },
    gotAction(state, action) {
      state.error = null;
      state.loading = false;
      state.action = action.payload;
      state.projectId = action.payload?.projectId;
    },
    gotProblem(state, action) {
      state.error = null;
      state.loading = false;
      state.problem = action.payload;
      state.projectId = action.payload?.projectId;
    },
    gotChange(state, action) {
      state.error = null;
      state.loading = false;
      state.change = action.payload;
      state.projectId = action.payload?.projectId;
    },
    gotRisk(state, action) {
      state.error = null;
      state.loading = false;
      state.risk = action.payload;
      state.projectId = action.payload?.projectId;
    },
    gotTask(state, action) {
      state.error = null;
      state.loading = false;
      state.task = action.payload;
      state.projectId = action.payload?.projectId;
    },
    gotOrganization(state, action) {
      state.error = null;
      state.loading = false;
      state.organization = action.payload;
      state.projectId = action.payload?.projectId;
    },
    gotFlash(state, action) {
      state.error = null;
      state.loading = false;
      state.flash = action.payload;
      state.projectId = action.payload?.projectId;
    },
    select(state, action) {
      state.projectId = action.payload;
    }
  }
});

export const {
  select,
  hasError,
  gotDeliverable,
  startLoading,
  gotAction,
  gotProblem,
  gotChange,
  gotRisk,
  gotTask,
  gotOrganization,
  gotFlash
} = slice.actions;

const goutiLikeProjectReducer = slice.reducer;

export default goutiLikeProjectReducer;