import { Icon } from '@iconify/react';
// import editFill from '@iconify/icons-eva/edit-fill';
// import deleteFill from '@iconify/icons-ant-design/delete-fill';
// import openFill from '@iconify/icons-eva/book-open-fill';
// import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
// material
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { isFunction } from 'lodash';

/**
 *
 * @param options {{icon: IconifyIcon, callback: function(), label: string}[]}
 * @param open {boolean}
 * @param onClose {function()}
 * @param anchor
 * @returns {JSX.Element}
 * @constructor
 */
export default function MorePopover({ options, open = false, onClose, anchor }) {

  const handleClose = (callback = null) => {
    isFunction(callback) && callback();
    onClose && onClose();
  };

  return (
    <Menu
      open={open}
      anchorEl={anchor.current}
      onClose={handleClose}
      PaperProps={{ sx: { width: 200, maxWidth: '100%' } }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {
        options.map((item) => (
            <MenuItem
              key={item.label}
              sx={{ color: 'text.secondary' }}
              onClick={() => handleClose(item.callback)}
            >
              {
                item.icon && (
                  <ListItemIcon>
                    <Icon icon={item.icon} width={24} height={24} />
                  </ListItemIcon>
                )
              }
              <ListItemText primary={item.label} primaryTypographyProps={{ variant: 'body2' }} />
            </MenuItem>
          )
        )
      }
    </Menu>
  );
}
