import {createSlice, nanoid} from '@reduxjs/toolkit';
import {auth} from 'src/contexts/FirebaseContext'
import firestore from "../../utils/firestore";
import {uniq, keys,} from 'lodash'

const slice = createSlice({
    name: 'conversation',
    initialState: {
        markedMessageIds: [],
        receivedMessageIds: [],
        sentMessage: {}
    },
    reducers: {
        markMessage: (state, action) => {
            state.markedMessageIds = uniq([
                ...state.markedMessageIds,
                ...action.payload
            ])
        },
        receiveMessage: (state, action) => {
            state.receivedMessageIds = uniq([
                ...state.receivedMessageIds,
                ...action.payload
            ])
        },
        hasError: (state, action) => {
            console.log(action.payload)
        },
        persitMessage: (state, action) => {
            const {conversationId, message} = action.payload;

            state.sentMessage = {
                ...state.sentMessage,
                [conversationId]: [
                    ...(state.sentMessage[conversationId] || []),
                    message
                ],
            }

        },


    }
})

export const {markMessage, hasError, persitMessage, receiveMessage} = slice.actions

const conversationReducer = slice.reducer;

export default conversationReducer;

const DOC_LIMIT = 400;

export function registerMessage(conversationId, messageId, callback = null) {
    return async (dispatch) => {
        try {
            dispatch(markMessage([messageId]));

            const ref = firestore.collection('conversations')
                .doc(conversationId).collection('history');

            const snap = await ref
                .where('count', '<', DOC_LIMIT)
                .limit(1).get();

            const newRecord = {
                [messageId]: [
                    {
                        userId: auth?.currentUser?.uid,
                        readAt: new Date()
                    }
                ]
            }

            if (!snap.empty) {
                const docRef = snap.docs[0];
                const data = docRef.data();

                await ref.doc(docRef.id).update({
                    record: {
                        ...data?.record,
                        ...newRecord
                    },
                    senders: {
                        ...data?.senders,
                        [messageId]: auth?.currentUser?.uid,
                    },
                    ids: [
                        ...data?.ids,
                        messageId
                    ],
                    count: (data?.count || 0) + 1
                })
            } else {
                await ref.add({
                    record: {
                        ...newRecord
                    },
                    ids: [messageId],
                    senders: {
                        [messageId]: auth?.currentUser?.uid,
                    },
                    count: 1,
                    createdAt: new Date()
                })
            }

            if (callback) {
                callback()
            }

        } catch (e) {
            dispatch(hasError(e))
        }
    }
}

export function markMessageAsRead(conversationId, messageIds = [], callback = null) {
    return async (dispatch, getStore) => {
        try {
            const {markedMessageIds = []} = getStore()?.conversation

            const ref = `conversations_messages_history_${conversationId}`;

            const rest = messageIds?.filter(id => !markedMessageIds.includes(id));

            const collectionRef = firestore.collection('conversations')
                .doc(conversationId).collection('history');

            if (rest?.length !== 0) {
                dispatch(markMessage(messageIds));

                const snap = await collectionRef.get();
                const data = snap?.docs?.map(doc => ({id: doc.id, ...doc.data()}))

                //#region helpers

                const history = [...data];
                const user = {
                    userId: auth?.currentUser?.uid,
                    readAt: new Date()
                }

                const noHistoryCase = async (conversationId, messageIds, colRef) => {
                    console.log('noHistoryCase', messageIds)
                    const record = {};

                    messageIds.forEach(id => {
                        record[id] = [user]
                    })

                    await colRef.add({
                        record,
                        ids: [...messageIds],
                        count: messageIds?.length,
                        createdAt: new Date()
                    })
                }

                const updateFound = (id) => {
                    const idx = history?.findIndex(el => el?.ids?.includes(id));

                    if (typeof idx === 'number') {
                     
                        const record = {
                            ...history[idx]?.record,
                            [id]: [
                                ...history[idx]?.record[id],
                                user,
                            ]
                        }

                        history[idx] = {
                            ...history[idx],
                            record,
                            ids: keys(record),
                            count: keys(record)?.length || 0
                        }

                    }
                }

                const createNotFound = (id) => {
                    const idx = history?.findIndex(el => {
                        return el?.count < DOC_LIMIT;
                    });

                    if (typeof idx === 'number') {
                        console.log('createNotFound/squeeze', id, history[idx])

                        const record = {
                            ...history[idx]?.record,
                            [id]: [user]
                        }

                        history[idx] = {
                            ...history[idx],
                            record,
                            ids: keys(record),
                            count: keys(record)?.length || 0
                        }

                    } else {
                        console.log('createNotFound/new', id, history[idx])

                        history?.push({
                            id: nanoid(),
                            record: {
                                [id]: [user]
                            },
                            ids: [id],
                            count: 1,
                            createdAt: new Date()
                        })
                    }

                }

                //#endregion


                if (data?.length === 0) {
                    await noHistoryCase(conversationId, messageIds, collectionRef)
                } else {

                    const found = messageIds?.filter(id => {
                        return data?.find(el => el?.ids?.includes(id))
                    });

                    const notFound = messageIds?.filter(id => {
                        return !found?.includes(id)
                    });
                    //
                    // console.group("creation")
                    // console.log(found)
                    // console.log(notFound)
                    // console.groupEnd()

                    found?.forEach(id => {
                        updateFound(id)
                    })

                    notFound?.forEach(id => {
                        createNotFound(id)
                    })


                    const batch = firestore.batch();

                    uniq(history)?.forEach(({id, ...rest}) => {
                        batch.set(collectionRef.doc(id), {
                            ...rest,
                            ids: keys(rest?.record || []),
                            count: keys(rest?.record || [])?.length || 0
                        }, {merge: true})
                    })

                    await batch.commit();

                }

            }

            if (callback) {
                callback()
            }

        } catch (e) {
            dispatch(hasError(e))
        }
    }
}

export function markMessageAsReceived(conversationId, messageIds = [], unmarkedReceivedIds=[],callback = null) {
    return async (dispatch, getStore) => {
        try {

            const {receivedMessageIds = []} = getStore()?.conversation

            const rest = messageIds?.filter(id => !receivedMessageIds.includes(id)&& unmarkedReceivedIds.includes(id));

            if (rest?.length !== 0) {
                dispatch(receiveMessage(rest))

                const collectionRef = firestore.collection('conversations')
                    .doc(conversationId).collection('history');

                const snap = await collectionRef.get();
                let data = snap?.docs?.map(doc => ({id: doc.id, ...doc.data()}));

                console.group('received')
                // console.log("conversationId", conversationId)
                // console.log("messageIds", messageIds)
                // console.log("receivedMessageIds", receivedMessageIds)
                // console.log("unmarkedReceivedIds", unmarkedReceivedIds)
                console.log("rest", rest)
                // console.log("data", data)

                messageIds?.forEach(id => {
                    data = registerInHistories(data, id);
                })

                // console.log("data", data)
                console.groupEnd()

                const batch = firestore.batch();

                data?.forEach(({id, ...rest}) => {
                    batch.set(collectionRef.doc(id), rest, {merge: true})
                })

                await batch.commit();

            }

        } catch (e) {
            console.log(e)
        }
    }

}

const registerInHistories = (histories = [], messageId, key = 'received') => {


    const newRecord = {
        [messageId]: [
            {
                userId: auth?.currentUser?.uid,
                readAt: new Date()
            }
        ]
    }

    const register = (hist) => {

        const prevRecord = hist[key] || {};
        const timeline = prevRecord[messageId] || [];

        const record = {
            [key]: {
                ...prevRecord,
                [messageId]: [
                    ...timeline,
                    {
                        userId: auth?.currentUser?.uid,
                        readAt: new Date()
                    }
                ]
            }
        };

        const ids = keys(hist?.record || {});
        const count = ids?.length;

        return {
            ...hist,
            ...record,
            ...(!hist?.createdAt && {createdAt: new Date()}),
            ids,
            count,
        }
    }

    //
    const copy = [...histories];

    const index = copy.findIndex(el => el?.ids?.includes(messageId))

    if (index !== -1) {
        copy[index] = register(copy[index])
        return copy;
    }

    const insertIndex = copy?.findIndex(el => el?.count < DOC_LIMIT);

    if (insertIndex !== -1) {
        copy[insertIndex] = register(copy[insertIndex])
        return copy;
    }


    const newHistory = {
        record: {},
        received: {},
        [key]: [newRecord],
        senders: {},
        ids: [messageId],
        count: 1
    };

    return [
        ...copy,
        newHistory
    ]
}