import React, {useRef} from 'react';
import {Icon} from '@iconify/react';
import playFill from '@iconify/icons-bi/play-fill';
import checkmarkFill from '@iconify/icons-bi/check';
import refreshFill from '@iconify/icons-eva/refresh-fill';
import {Tag, Tooltip, Radio} from 'antd';
import {LikeOutlined, DislikeOutlined} from '@ant-design/icons';
import {TASK_STATE_VALIDATION} from 'src/constants';
import MIconButton from '../../../components/@material-extend/MIconButton';
import {alpha} from '@mui/material/styles';
import useToggle, {useToggleV2} from 'src/hooks/useToggle';
import {
    Stack,
    Dialog,
    Button,
    DialogTitle,
    DialogActions,
} from '@mui/material';
import KanbanTaskCommentInput from '../../../components/CommentInput';
import {useDispatch} from 'src/redux/store';
import {addCommentToTask} from 'src/redux/slices/kanban';
import {useSnackbar} from 'notistack';
import {nanoid} from '@reduxjs/toolkit';
import DependencyTaskCanStartTrigger, {canStartTask} from "../dependency/DependencyTaskCanStartTrigger";


export default function KanbanTaskCardValidation({
                                                     state,
                                                     handleChangeState,
                                                     managers,
                                                     intervenants,
                                                     userId,
                                                     admin,
                                                     taskId,
                                                     taskName,
                                                     card
                                                 }) {

    const {open, handleOpen, handleClose} = useToggle();
    const [visible, display, hide] = useToggleV2();
    const anchor = useRef();

    const canValidate = React.useMemo(() => {
        if (managers?.length) {
            return managers.find(one => one?.id === userId) ? true : false;
        }
        return admin?.id === userId;
    }, [managers, admin?.id, userId]);


    const demarrerTask = () => {
        if (!canStartTask(card)) {
            display()
            return;
        }
        handleChangeState(TASK_STATE_VALIDATION.INPROGRESS);
    }

    const terminerTask = () => {
        handleChangeState(TASK_STATE_VALIDATION.DONE);
    }

    const rejeterTask = () => {
        handleChangeState(TASK_STATE_VALIDATION.REJECTED);
    }

    const validerTask = () => {
        handleChangeState(TASK_STATE_VALIDATION.ACCEPTED);
    }

    return (
        <>
            <DependencyTaskCanStartTrigger card={card} open={visible} onClose={hide} anchor={anchor}/>
            {(!state || state === TASK_STATE_VALIDATION.PENDING)
                ?
                <Tooltip title="Démarrer la tâche">
                    <MIconButton size="small" onClick={demarrerTask} ref={anchor}>
                        <Icon icon={playFill} width={20} height={20}/>
                    </MIconButton>
                </Tooltip>
                : state === TASK_STATE_VALIDATION.INPROGRESS
                    ?
                    <Tooltip title="Terminer">
                        <MIconButton size="small" onClick={terminerTask} color="info"
                                     sx={{bgcolor: (t) => alpha(t.palette.info.main, t.palette.action.hoverOpacity)}}>
                            <Icon icon={checkmarkFill} width={20} height={20}/>
                        </MIconButton>
                    </Tooltip>
                    : state === TASK_STATE_VALIDATION.DONE
                        ?
                        <Radio.Group size='small' type="text" value="none" disabled={!canValidate}>
                            <Tooltip title={canValidate ? "Rejetée" : "En attente de validation"}>
                                <Radio.Button style={{backgroundColor: '#FFAF8A'}} onClick={handleOpen}>
                                    <DislikeOutlined twoToneColor="#DB3C2C"/> </Radio.Button>
                            </Tooltip>
                            <Tooltip title={canValidate ? "Acceptée" : "En attente de validation"}>
                                <Radio.Button style={{backgroundColor: '#CBF16E'}} onClick={validerTask}> <LikeOutlined
                                    twoToneColor="#58960A"/> </Radio.Button>
                            </Tooltip>
                        </Radio.Group>
                        : state === TASK_STATE_VALIDATION.REJECTED
                            ?
                            <Tooltip title="Recommencer">
                                <MIconButton size="small" onClick={demarrerTask} color="error"
                                             sx={{bgcolor: (t) => alpha(t.palette.error.main, t.palette.action.hoverOpacity)}}>
                                    <Icon icon={refreshFill} width={20} height={20}/>
                                </MIconButton>
                            </Tooltip>
                            : state === TASK_STATE_VALIDATION.ACCEPTED
                                ? <Tag color="green">validée</Tag> : null}
            {open && <RejectComment open={open} onClose={handleClose} reject={rejeterTask}
                                    taskId={taskId} intervenants={intervenants} managers={managers}
                                    taskName={taskName}/>}
        </>
    );
}


export const RejectComment = ({open, onClose, reject, taskId, intervenants, managers, taskName}) => {
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const handleClose = (reason) => {
        if (reason !== 'backdropClick') {
            onClose();
            reject();
        }
    }

    const handleSubmitComment = (comment) => {
        const docId = nanoid();

        const commentObject = {
            ...comment,
            message: 'Rejet de tâche : ' + comment.message,
            read: 0,
            readState: []
        }

        dispatch(addCommentToTask({
            taskId: taskId, generateId: docId, commentBbject: commentObject,
            canAccess: [...intervenants || [], ...managers || []],
            taskName,
            callback: () => {
                enqueueSnackbar('Commentaire ajouté', {variant: 'info'});
            }
        }));

        handleClose();
    }

    const handleCancelWithoutComment = () => {
        onClose();
    }
    const handleCancelWithComment = () => {
        handleClose();
    }

    return (
        <Dialog open={open} onClose={(_, reason) => handleClose(reason)} disableEscapeKeyDown
                sx={{zIndex: t => t.zIndex.modal + 3}}>
            <DialogTitle> Ajouter un commentaire au refus </DialogTitle>
            <Stack sx={{p: 1, mt: 3}}>
                <KanbanTaskCommentInput idQ='task-validation-reject' voice={false} onChange={handleSubmitComment}/>
            </Stack>
            <DialogActions>
                <Stack direction="row" width={1} justifyContent="flex-end" spacing={2}>
                    <Button color="inherit" onClick={handleCancelWithoutComment}> Annuler </Button>
                    <Button color='success' onClick={handleCancelWithComment}> Rejeter sans commentaire </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}