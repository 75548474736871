import { groupBy, capitalize } from 'lodash';


const sortFunction = (a, b) =>{
    if (a < b)
        return -1
    if (a > b)
        return 1
    return 0
  };
  

function sortObj(obj) {
return Object.keys(obj).sort(sortFunction).reduce(function (result, key) {
    result[key] = obj[key];
    return result;
}, {});
}


function getFirstCharacter(name) {
    return capitalize(name && name.charAt(0));
}

export function contactToAphabetList (contacts){
    let data = groupBy(contacts, (contact) => getFirstCharacter(contact.lastName));
    return sortObj(data);
}

export const goupToAlphabet = collection=>{
    let data = groupBy(collection, (contact) => contact.title);
    return sortObj(data);
}