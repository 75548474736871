import { useState, createContext, useContext, useEffect } from 'react';
import { Outlet, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, styled, useTheme } from '@mui/material/styles';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
//
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';
import { PATH_DASHBOARD } from 'src/routes/paths';
import useUserList from 'src/hooks/useUserList';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 40;

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  // backgroundImage : `url(/static/bg-images/${theme.palette.mode}.jpg)`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed'
}));

const MainStyle = styled('div')(({ theme, removenav }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: removenav ? 0 : APP_BAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    paddingTop: removenav ? 0 : APP_BAR_DESKTOP + 16
  }
}));

// ----------------------------------------------------------------------

const DashbordContext = createContext({
  title: '',
  isTask: false,
  removeNav: false,
  sideOpen: true,
  onSideOpen: () => {},
  openUserSearch: false,
  userSearchValue: '',
  setRemoveNav: () => {},
  setUserSearchValue: () => {},
  setOpenUserSearch: () => {},
  setIsTask: (state) => {},
  setTitle: (title) => {}
});

export const useDashbord = () => {
  return useContext(DashbordContext);
};

export default function DashboardLayout(role) {
  const theme = useTheme();
  const { state, pathname } = useLocation();
  const [title, setTitle] = useState('');
  const [isTask, setIsTask] = useState(false);
  const { collapseClick, isMobile, setIsOpenSidebar, isOpenSidebar, setAsMobile } = useCollapseDrawer();
  const [isOpen, setIsOpen] = useState(false);
  const [userSearchValue, setUserSearchValue] = useState('');
  const [removeNav, setRemoveNav] = useState(false);
  const { users } = useUserList();

  useEffect(() => {
    const match_task = !!matchPath({ path: PATH_DASHBOARD.general.tasks, end: false }, pathname);
    if (match_task) {
      setAsMobile(true);
      setRemoveNav(true);
      return;
    }
    const match_app = !!matchPath({ path: PATH_DASHBOARD.general.app, end: false }, pathname);
    if (match_app) {
      setAsMobile(true);
      setRemoveNav(false);
      return;
    }

    setRemoveNav(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <DashbordContext.Provider
      value={{
        removeNav,
        setRemoveNav,
        sideOpen: isOpenSidebar,
        onSideOpen: setIsOpenSidebar,
        title,
        setTitle,
        isTask,
        setIsTask,
        openUserSearch: isOpen,
        setOpenUserSearch: setIsOpen,
        userSearchValue,
        setUserSearchValue
      }}
    >
      <RootStyle
        sx={{
          ...(state?.projectName && { backgroundImage: 'none', bgcolor: (theme) => theme.palette.background.default })
        }}
      >
        {!removeNav && <DashboardNavbar onOpenSidebar={() => setIsOpenSidebar(true)} />}
        {role && !role?.isGuest && <DashboardSidebar onCloseSidebar={() => setIsOpenSidebar(false)} />}

        <MainStyle
          removenav={removeNav}
          sx={{
            transition: theme.transitions.create('margin', {
              duration: theme.transitions.duration.complex
            }),
            ...(collapseClick &&
              !isMobile && {
                ml: '57px'
              }),
            height: '100vh',
            bgcolor: (t) => alpha(t.palette.action.hover, 0.07)
          }}
        >
          <Outlet />
        </MainStyle>
      </RootStyle>
    </DashbordContext.Provider>
  );
}
