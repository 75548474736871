import React, { useRef, useCallback, useMemo, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import ReactQuill from 'react-quill';
// material
import { styled } from '@mui/material/styles';

import EditorToolbar, { formats, redoChange, undoChange } from './QuillEditorToolbar';
import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { MAvatar } from 'src/components/@material-extend';
import createAvatar from 'src/utils/createAvatar';
import { isFunction, uniqBy } from 'lodash';
import { useSelector } from 'src/redux/store';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`,
  '& .ql-container.ql-snow': {
    borderColor: 'transparent',
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily
  },
  '& .ql-editor': {
    minHeight: 50,
    fontSize: 17,
    '&.ql-blank::before': {
      fontStyle: 'normal',
      color: theme.palette.text.disabled
    },
    '& pre.ql-syntax': {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900]
    }
  }
}));

// ----------------------------------------------------------------------

export default function QuillEditor({
  id,
  error,
  type,
  value,
  onChange,
  onAudioRecord,
  simple = false,
  hideToolBar = false,
  setMensions,
  sx,
  placeholder = 'Taper votre message',
  ...other
}) {
  const quillRef = useRef();
  const [selected, setSelected] = useState([]);
  const { users } = useSelector((state) => state.user);

  const mentionUsers = useMemo(
    () =>
      users?.map((_user) => {
        return { id: _user?.id, value: _user?.displayName || _user?.name, avatar: _user?.photoURL || _user?.avatar };
      }) || [],
    [users]
  );

  const modules = useMemo(
    () => ({
      toolbar: {
        container: `#${id}`,
        handlers: { undo: undoChange, redo: redoChange }
      },
      history: {
        delay: 500,
        maxStack: 3,
        userOnly: true
      },
      syntax: true,
      clipboard: {
        matchVisual: false
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@', '#'],
        offsetLeft: 10,
        offsetTop: 5,
        defaultMenuOrientation: 'top',
        renderItem: function (item, searchTerm) {
          return ReactDOMServer.renderToString(RenderMentionItem(item));
        },
        source: function (searchTerm, renderList, mentionChar) {
          let values;

          if (mentionChar === '@' || mentionChar === '#') {
            values = mentionUsers;
          }

          if (searchTerm.length === 0) {
            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (~values[i]?.value?.toLowerCase()?.indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        }
      }
    }),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleChange = useCallback((contents, delta, source, editor) => {
    onChange(contents, delta, source, editor);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (quillRef?.current) {
      const _quill = quillRef.current;
      const ops = _quill?.editor?.editor?.delta?.ops;
      const mentionSelected = ops.filter((_one) => _one?.insert?.mention);
      const _selecteds = mentionSelected.map((_one) => ({
        id: _one?.insert?.mention?.id,
        name: _one?.insert?.mention?.value
      }));
      const _selected = uniqBy(_selecteds, 'id');
      setSelected(_selected);
      setMensions && isFunction(setMensions) && setMensions(_selected);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <RootStyle
      sx={{
        ...(error && {
          border: (theme) => `solid 1px ${theme.palette.error.main}`
        }),
        ...sx,
        ...(!type && {
          '& .ql-formats button, .ql-align, .ql-color, .ql-background': {
            transform: 'scale(0.6)'
          }
        })
      }}
    >
      <div hidden={hideToolBar}>
        <EditorToolbar id={id} isSimple={simple} onAudioRecord={onAudioRecord} type={type} />
      </div>

      <ReactQuill
        ref={quillRef}
        theme={type || 'snow'}
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
        placeholder={placeholder}
        {...other}
      />
    </RootStyle>
  );
}

QuillEditor.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  simple: PropTypes.bool,
  sx: PropTypes.object
};

const RenderMentionItem = (item) => {
  return (
    <ListItem key={item.id} value={item.id}>
      <ListItemAvatar>
        <MAvatar
          src={item.avatar}
          alt={item.value}
          color={createAvatar(item.value).color}
          sx={{ width: 27, height: 27, fontSize: 12 }}
        >
          {createAvatar(item.value).name}
        </MAvatar>
      </ListItemAvatar>
      <ListItemText primary={item.value} />
    </ListItem>
  );
};
