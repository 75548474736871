import { Box, Tab, Tabs } from '@mui/material';
import { Icon } from '@iconify/react';
import { useState } from 'react'
import Appointments from './Appointments';
import MakingAppointment from './MakingAppointment';
import homeIcon from '@iconify/icons-eva/person-done-outline'
import MeetIcon from '@iconify/icons-eva/person-outline'

export const APPOINTMENT_TYPE = {
  Appointment : "Appointment",
  MakingAppointment : "MankingAppointment"
}

export const APPOINTMENT_TYPE_IVITE='Invite'

export const APPOINTMENT_TYPE_LABEL={
    [APPOINTMENT_TYPE.Appointment]:'Rendez-vous direct',
    [APPOINTMENT_TYPE.MakingAppointment]:'Prise de rendez-vous',
}



export default function Audience({ selected, onClose }) {
  const isEdit = selected ? true : false ;  



  const type = selected && selected?.audienceType;
   
  const initState = type ?  
                    type === APPOINTMENT_TYPE.Appointment ?
                    'Rendez-vous direct': 'Prise de rendez-vous'
                    : 'Rendez-vous direct';

  const [currentTab, setCurrentTab] = useState(initState);

    const AUDIENCE_TABS = [
        {
          value: 'Rendez-vous direct',
          icon: <Icon icon={homeIcon} width={20} height={20} />,
          component: <Appointments isEdit={isEdit} selectedAppointment={selected} onClose={onClose} />
        },
        {
          value: 'Prise de rendez-vous',
          icon: <Icon icon={MeetIcon} width={20} height={20} />,
          component: <MakingAppointment isEdit={isEdit} selectedAppointment={selected} onClose={onClose} />
        }
    ];

    const handleChangeTab = (event, newValue) => {
      setCurrentTab(newValue);
    };

    return (
        <Box>
            <Tabs
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={handleChangeTab}
          >
            {AUDIENCE_TABS.map((tab)=>(
              <Tab 
                disableRipple
                key={tab.value}
                label={tab.value}
                icon={tab.icon}
                value={tab.value}
              />
            ))}
          </Tabs>

          <Box sx={{ mb: 2 }} />

          {
            AUDIENCE_TABS.map((tab)=>{
              const isMatched = tab.value === currentTab;
              return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })
          }
        </Box>
    )
}
