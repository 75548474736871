import {isLoaded, useFirestoreConnect} from "react-redux-firebase";
import {useSelector} from 'react-redux'
import {useEffect, useMemo, useState} from "react";
import {useDispatch} from "../redux/store";
import {docDriverCollectionPaths} from "src/section/doc/types";

export default function useDocComment(docId, limit = 20) {
    const ref = `comment_${docId}`;
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const getComments = useSelector(state => state.firestore.ordered[ref])
    const comments = useMemo(() => {
        return [...(getComments || [])]
    }, [getComments])


    useFirestoreConnect([
        {
            collection: `${docDriverCollectionPaths.myFolders}/${docId}/comments`,
            orderBy: ['createdAt', "desc"],
            // limit,
            storeAs: ref
        }
    ])

    useEffect(() => {
        setLoading(!isLoaded(getComments));
    }, [getComments, comments, dispatch]);

    return {comments, loading, hasMore: false};

}
