import { isEmpty } from 'lodash';
import { MAIL_CONFIG } from 'src/config';
import { NOTIFICATION_TYPES } from 'src/constants';
import { CHANNEL_MENTION } from 'src/constants/community';
import { CONNECTION_STATE } from 'src/constants/user';
import { auth } from 'src/contexts/FirebaseContext';
import { checkIsOne } from 'src/helpers/checkIsOne';
import { formatDuration } from 'src/helpers/convertes';
import { ChannelType, CommMessageType } from 'src/models/communication_types';
import axiosRequest from 'src/utils/axiosRequest';
import { createNotification } from '../notifications';

const { domaine, protocole, subject, from, template } = MAIL_CONFIG;

const CHANNEL_FIELD = {
  members: 'members',
  notifications: 'notifications'
};

//---------------------CHANNEL-------------------------------

/**
 *
 * @param {{toNotify: Array, channel: ChannelType, }} param
 * @returns
 */
export function createChannelAddMemberNotification({ toNotify, channel }) {
  return async (dispatch) => {
    const { uid, displayName, photoURL } = auth.currentUser;
    const description = `Vous avez été ajouté au canal "${channel?.title}" par "${displayName}"`;

    const canAccessIds = toNotify?.map((_one) => _one.id) || [];
    let listIds = {};
    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: `Vous avez été ajouté au canal "${channel?.title}"`,
          description: description,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.COMMUNICATION_ADD_TO_CHANNEL,
          returnId: channel.id,
          cardTitle: channel.title,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: uid,
            displayName,
            photoURL
          },
          action: {
            id: channel.id,
            title: channel.title
          }
        }
      })
    );

    const accessLink = `https://${domaine}/dashboard/app/${channel.id}`;
    toNotify.map(async (pers) => {
      const data = {
        salutation: '',
        name: pers?.name,
        description: description,
        link: accessLink,
        subject: subject,
        header: `Vous avez été ajouté a un canal`
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: template.members,
        data
      });
    });
  };
}

export function createCommunicationChannelNotif({ channel, change = [] }) {
  return async (dispatch) => {
    let hasMember = null;

    change.map((_chg) => {
      if (_chg[CHANNEL_FIELD.members]) {
        hasMember = _chg[CHANNEL_FIELD.members];
      }
    });

    if (hasMember) {
      let toNotify = hasMember.new.filter((_one) => !hasMember.old.some((one) => one.id === _one.id));
      dispatch(createChannelAddMemberNotification({ toNotify, channel }));
    }
  };
}

//---------------------MESSAGES-------------------------------

/**
 *
 * @param {{message: CommMessageType, channel: ChannelType, users: Array}} param
 * @returns
 */
export function createCommunityMessageNotif({ message, channel, users }) {
  return async (dispatch) => {
    const { uid, displayName, photoURL } = auth.currentUser;
    let title = `Un message de ${displayName}`;
    let mention_title = `Vous avez été mentionné dans un message par ${displayName}`;
    let description = message.content;

    if (message?.attachments?.length) {
      description = `${description} 📷 fichier${checkIsOne(message.attachments.length) ? '' : 's'}`;
    }

    if (message?.voice) {
      description = `🎤 Message vocal (${formatDuration(message?.voice?.seconds)})`;
    }

    let isCanalMention = false;

    if (message?.mentions?.length) {
      if (message.mentions.find((_one) => _one.id === CHANNEL_MENTION.id)) {
        isCanalMention = true;
        mention_title = `Vous avez une mention générale dans le canal ${channel.title}`;
      }
    }

    const members = channel?.members || [];
    const memebersWithoutMe = members.filter((_user) => _user.id !== uid);

    const canNotifyMember = !channel.notifications
      ? memebersWithoutMe
      : memebersWithoutMe.filter(
          (_one) => channel?.notifications[_one.id] === undefined || channel?.notifications[_one.id]
        );

    let _mentions = isCanalMention ? channel?.members : message?.mentions || [];
    let mention_members = [];
    let no_mention_members = [];

    canNotifyMember.forEach((_user) => {
      if (_mentions.find((_one) => _one.id === _user.id)) {
        mention_members = [...mention_members, _user];
      } else {
        no_mention_members = [...no_mention_members, _user];
      }
    });

    const mentions_canNotify = mention_members.map((_one) => _one.id);
    const no_mentions_canNotify = no_mention_members.map((_one) => _one.id);

    let mention_listIds = {};
    let no_mention_listIds = {};
    mentions_canNotify.forEach((id) => (mention_listIds[id] = true));
    no_mentions_canNotify.forEach((id) => (no_mention_listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title,
          description,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.COMMUNICATION_NEW_MESSAGE,
          returnId: channel.id,
          cardTitle: mention_title + ' - ' + description,
          canAccess: mentions_canNotify,
          isUnRead: mention_listIds,
          by: {
            id: uid,
            displayName,
            photoURL
          },
          action: {
            id: channel.id,
            title: channel.title
          }
        }
      })
    );

    dispatch(
      createNotification({
        data: {
          title,
          description,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.COMMUNICATION_NEW_MESSAGE,
          returnId: channel.id,
          cardTitle: title + ' - ' + description,
          canAccess: no_mentions_canNotify,
          isUnRead: no_mention_listIds,
          by: {
            id: uid,
            displayName,
            photoURL
          },
          action: {
            id: channel.id,
            title: channel.title
          }
        }
      })
    );

    const mentions = isCanalMention ? memebersWithoutMe : message?.mentions || [];

    const canReceiveMail = users.filter((_user) => {
      const exist = mentions.find((_one) => _one.id === _user.id);

      if (exist && _user.state === CONNECTION_STATE.OFFLINE) {
        return true;
      }
      return false;
    });

    const accessLink = `https://${domaine}/dashboard/app/${channel.id}`;

    canReceiveMail.map(async (pers) => {
      const data = {
        salutation: '',
        name: title,
        subdesc: description,
        description: `# ${channel.title}`,
        link: accessLink,
        subject: subject,
        header: ''
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: template.members,
        data
      });
    });
  };
}

/**
 *
 * @param {{message: CommMessageType, channel: ChannelType, answer: CommMessageType, users: Array}} param
 * @returns
 */
export function createCommunityMessageAnswerNotif({ message, channel, answer, users }) {
  return async (dispatch) => {
    const { uid, displayName, photoURL } = auth.currentUser;

    let description = answer.content;
    let _desc = message.content;

    if (answer?.attachments?.length) {
      description = `${description} 📷 fichier${checkIsOne(answer.attachments.length) ? '' : 's'}`;
    }

    if (message?.attachments?.length) {
      _desc = `${_desc} 📷 fichier${checkIsOne(message.attachments.length) ? '' : 's'}`;
    }

    if (answer?.voice) {
      description = `🎤 Message vocal (${formatDuration(answer?.voice?.seconds)})`;
    }

    if (message?.voice) {
      _desc = `🎤 Message vocal (${formatDuration(message?.voice?.seconds)})`;
    }

    let title = `${displayName} à répondu au message ${_desc}`;
    let mention_title = `Vous avez été mentionné dans une réponse au message ${_desc} par ${displayName}`;

    let isCanalMention = false;

    if (answer?.mentions?.length) {
      if (answer.mentions.find((_one) => _one.id === CHANNEL_MENTION.id)) {
        mention_title = `Vous avez une mention générale dans le canal ${channel.title}`;
      }
    }

    const members = channel?.members || [];
    const memebersWithoutMe = members.filter((_user) => _user.id !== uid);

    const canNotifyMember = !channel.notifications
      ? memebersWithoutMe
      : memebersWithoutMe.filter(
          (_one) => channel?.notifications[_one.id] === undefined || channel?.notifications[_one.id]
        );

    let _mentions = isCanalMention ? channel?.members : answer?.mentions || [];
    let mention_members = [];
    let no_mention_members = [];

    canNotifyMember.forEach((_user) => {
      if (_mentions.find((_one) => _one.id === _user.id)) {
        mention_members = [...mention_members, _user];
      } else {
        no_mention_members = [...no_mention_members, _user];
      }
    });

    const mentions_canNotify = mention_members.map((_one) => _one.id);
    const no_mentions_canNotify = no_mention_members.map((_one) => _one.id);

    let mention_listIds = {};
    let no_mention_listIds = {};
    mentions_canNotify.forEach((id) => (mention_listIds[id] = true));
    no_mentions_canNotify.forEach((id) => (no_mention_listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title,
          description,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.COMMUNICATION_NEW_ANSWER_MESSAGE,
          returnId: channel.id,
          cardTitle: mention_title + ' - ' + description,
          canAccess: mentions_canNotify,
          isUnRead: mention_listIds,
          by: {
            id: uid,
            displayName,
            photoURL
          },
          action: {
            id: channel.id,
            title: channel.title,
            messageTitle: message.content,
            messageId: message?.id
          }
        }
      })
    );

    dispatch(
      createNotification({
        data: {
          title,
          description,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.COMMUNICATION_NEW_ANSWER_MESSAGE,
          returnId: channel.id,
          cardTitle: title + ' - ' + description,
          canAccess: no_mentions_canNotify,
          isUnRead: no_mention_listIds,
          by: {
            id: uid,
            displayName,
            photoURL
          },
          action: {
            id: channel.id,
            title: channel.title,
            messageTitle: message.content,
            messageId: message?.id
          }
        }
      })
    );

    const accessLink = `https://${domaine}/dashboard/app/${channel.id}`;

    const mentions = isCanalMention ? memebersWithoutMe : answer?.mentions || [];

    const canReceiveMail = users.filter((_user) => {
      const exist = mentions.find((_one) => _one.id === _user.id);

      if (exist && _user.state === CONNECTION_STATE.OFFLINE) {
        return true;
      }
      return false;
    });

    canReceiveMail.map(async (pers) => {
      const data = {
        salutation: '',
        name: title,
        subdesc: description,
        description: `# ${channel.title}`,
        link: accessLink,
        subject: subject,
        header: ''
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: template.members,
        data
      });
    });
  };
}
