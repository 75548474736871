import { baseColors } from './color';

export const CONTACT_TYPE = {
  CLIENT: { title: 'Client' },
  EMPLOYEE: {
    title: 'Employe',
    sub: {
      CONTRACTUEL: { title: 'Personnel' },
      STAGIAIRE: { title: 'Stagiaire' },
      VOLONTAIRE: { title: 'Volontaire' },
      GROUP: { title: 'Equipe' },
      DEPARTMENT: { title: 'Département' }
    }
  }
};
export const ftContactType = (type) => {
  if (type === CONTACT_TYPE.CLIENT.title) return 'Partenaire';
  if (type === CONTACT_TYPE.EMPLOYEE.title) return 'Employé';
  return type;
};

export const CIVILITY_TYPE = {
  Mr: 'Mr',
  Mme: 'Mme'
};

export const OLD_TASK = {
  WAITING: 'En attente',
  PENDING: 'En cours',
  END: 'Terminer'
};

export const CONTENT_TYPE = {
  TEXT: 'text',
  IMAGE: 'image',
  FILES: 'files',
  AUDIO: 'audio'
};

export const PERSONNAL_CALENDAR_TYPE = {
  EVENT: 'event',
  RAPPEL: 'rappel'
};

export const TASK_STATE_VALIDATION = {
  PENDING: 'Non démarrée',
  INPROGRESS: 'En cours',
  DONE: 'Terminée',
  REJECTED: 'Refusée',
  ACCEPTED: 'Validée'
};

export const TASK_VISIBILITY = {
  PUBLIC: 'Public',
  PRIVATE: 'Privée'
};

export const AFFECT_STATE_VALIDATION = {
  NOT_ASSIGN: 'Non assigné',
  ONVALIDATE: 'À valider',
  ...TASK_STATE_VALIDATION
};

export const TASK_STATE_PROGRESS = {
  [TASK_STATE_VALIDATION.PENDING]: 0,
  [TASK_STATE_VALIDATION.INPROGRESS]: 50,
  [TASK_STATE_VALIDATION.DONE]: 75,
  [TASK_STATE_VALIDATION.REJECTED]: 50,
  [TASK_STATE_VALIDATION.ACCEPTED]: 100
};

export const TASK_PRIORITIES = {
  IMPORTANT: 'URGENTE',
  HIGH: 'HAUTE',
  NORMAL: 'NORMALE',
  BASE: 'BASE'
};

export const RAPPEL_EVENT_TIMES = {
  MINUTE: 'Minutes',
  HOURS: 'Heures',
  DAY: 'Jours',
  WEEK: 'Semaines'
};

export const LOAN_RATES = [
  {
    label: '1 Mois',
    value: '1_MONTH',
    days: 30
  },
  {
    label: '2 Mois',
    value: '2_MONTH',
    days: 60
  },
  {
    label: '3 Mois',
    value: '3_MONTH',
    days: 90
  },
  {
    label: '4 Mois',
    value: '4_MONTH',
    days: 120
  },
  {
    label: '5 Mois',
    value: '5_MONTH',
    days: 150
  },
  {
    label: '6 Mois',
    value: '6_MONTH',
    days: 180
  },
  {
    label: '1 An',
    value: '1_YEAR',
    days: 365
  }
];

export const userRoles = {
  audience: 'audience',
  chat: 'chat',
  client: 'client',
  contact: 'contact',
  actualite: 'news',
  opportunity: 'opportunity',
  stage: 'stage',
  task: 'task',
  users: 'users',
  document: 'document',
  manage_persons: 'manage_persons',
  oldTask: 'oldTask',
  report: 'report'
};

export { AUDIENCE_TYPE, AUDIENCE_TYPE_COLOR, AUDIENCE_BASE_TYPE } from './audience';
export { REPORT_TYPE } from './report';
export {
  PROJECT_COLOR,
  PROJECT_STATUS,
  PROJECT_STATUS_COLOR,
  CALENDAR_TYPE,
  GOAL_STATE,
  Delivery_STATE,
  Milestone_STATE,
  RISKS_PROBABILITY,
  RISKS_IMPACT,
  Delivery_STATE_COLOR
} from './project';
export { NOTIFICATION_TYPES, NOTIFICATION_ICONS, NOTIFICATION_DEFAULT_ICON, getNotificationIcon } from './notification';

export const PROGRESS_STATE = (value) => {
  return value < 25
    ? baseColors.RED
    : value < 50
    ? baseColors.WARINIG
    : value < 75
    ? baseColors.BLUE
    : value < 100
    ? baseColors.GREEN
    : baseColors.GREY;
};

export const PROGRESS_PERCENT = (state) => {
  if (!state || state === TASK_STATE_VALIDATION.PENDING) {
    return 0;
  }
  if (state === TASK_STATE_VALIDATION.INPROGRESS) {
    return 25;
  }
  if (state === TASK_STATE_VALIDATION.INPROGRESS) {
    return 75;
  }
  if (state === TASK_STATE_VALIDATION.ACCEPTED) {
    return 100;
  }
  if (state === TASK_STATE_VALIDATION.REJECTED) {
    return 50;
  }
};

export const EVENT_RAPPEL_REPETE = {
  ONE_DAY: { value: 0, label: 'Une seule fois' },
  ALL_DAY: { value: 1, label: 'Tous les jours' },
  ALL_WEEK: { value: 2, label: 'Toutes les semaines' },
  ALL_MOUNTH: { value: 3, label: 'Tous les mois' },
  ALL_YEAR: { value: 4, label: 'Tous les ans' }
};

export const ARCHIVE_STATE = {
  PENDING: 'En traitement',
  FINISHED: 'Terminé',
  CLOSED: 'Clôturé'
};

export const ARCHIVE_STATE_COLOR = {
  [ARCHIVE_STATE.PENDING]: 'info',
  [ARCHIVE_STATE.CLOSED]: 'success',
  [ARCHIVE_STATE.FINISHED]: 'warning'
};
