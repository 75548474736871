import { TASK_STATE_VALIDATION, TASK_VISIBILITY } from "src/constants";
import { TaskType } from "src/models/task_m";

/** @type {TaskType} */
export const taskDefaultModel = {
  name: '',
  completed: false,
  attachments: [],
  comments: [],
  description: '',
  due: [null, null],
  observers: [],
  responsable: null,
  progress: 0,
  rappel: null,
  rappels: [],
  isDeleted: false,
  isDefaultTask: false,
  deadLine: null,
  mamangers: [],
  assignee: [],
  assigneIds: [],
  assigneByIds: [],
  priority: '',
  cardIds: [],
  visibility: TASK_VISIBILITY.PUBLIC,
  projectState: 'open',
  state: TASK_STATE_VALIDATION.PENDING
};

export const gProjectId = task => {
  return  task?.projectKey || task?.idProject
}