import {useEffect, useMemo, useState} from 'react';
import {isEmpty, isLoaded, useFirestoreConnect} from 'react-redux-firebase';
import {useSelector} from '../redux/store';

const getSelector = id => `task_actions_${id}`

export default function useTaskActions(taskId = null) {
    const [loading, setLoading] = useState(false);
    const getData = useSelector(state => state.firestore.ordered[getSelector(taskId)]);
    const actions = useMemo(() => isEmpty(getData) ? [] : getData, [getData]);

    useFirestoreConnect(() => [
        {
            collection: `tasks/${taskId}/actions`,
            storeAs: getSelector(taskId)
        }
    ]);

    useEffect(() => {
        setLoading(!isLoaded(getData));
    }, [getData]);


    return {actions, loading};
}
