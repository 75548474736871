import React, { useState } from 'react';
import { CustomMenuPopover } from './MenuPopover';
import { DatePicker } from 'antd';
import moment from 'moment';

export default function DateTineRangeSelector({ open, onClose, anchorEl, onChange, deb, end, hideTime = false }) {
  const [due, setDue] = useState([moment(deb || undefined), moment(end || undefined)]);
  let temp = [due.at(0)._d, due.at(1)._d];

  const handleChange = (val) => {
    const newDue = [val.at(0)._d, val.at(1)._d];
    setDue(val);
    temp = newDue;
    onChange(newDue);
  };

  const handleClose = () => {
    onClose(temp);
  };

  return (
    <CustomMenuPopover
      className="this-is-the-probleme"
      disablePortal
      open={open}
      width="max-content"
      anchorEl={anchorEl}
      disabledArrow
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <DatePicker.RangePicker
        {...(!hideTime && { showTime: { format: 'HH:mm' } })}
        open={open}
        onOpenChange={handleClose}
        onChange={handleChange}
        value={due}
        format="YYYY-MM-DD HH:mm"
      />
    </CustomMenuPopover>
  );
}
