import { isObject, isEmpty, isUndefined } from 'lodash';
import { DateTime } from 'luxon';
import { gDate } from 'src/utils/formatTime';

export const isFile = (element) => {
  return element instanceof File
}

export const isNot = (value) => {
  return Boolean(value) === false;
};

export const isNotEmpty = (value) => {
  return isNot(isEmpty(value));
};

export const isNotUndefined = (value) => {
  return isNot(isUndefined(value));
};

export const isFirebaseTimestamp = (timestamp) => {
  return isObject(timestamp) && ('toDate' in timestamp);
};

export const isPerson = person => {
  return isObject(person) && 'id' in person && 'displayName' in person && 'email' in person
    && isNotEmpty(person.id) && isNotEmpty(person.displayName) && isNotEmpty(person.email);
};

export const isLate = (deadline, startDate = new Date()) => {
  const end = DateTime.fromJSDate(gDate(deadline));
  const start = DateTime.fromJSDate(gDate(startDate));

  return end > start;
};

//#region pdf renderer
export const isTable = value => {
  return 'type' in value && value.type === 'table' && 'columns' in value && 'rows' in value;
};

export const isTitle = value => {
  return 'type' in value && value.type === 'title' && 'text' in value;
};

export const isText = value => {
  return 'type' in value && value.type === 'text' && 'text' in value;
};

//#endregion