import {createSlice} from '@reduxjs/toolkit';
import { auth } from 'src/contexts/FirebaseContext';
import firestore from 'src/utils/firestore';
import { serverTime } from 'src/utils/serverTime';

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    workflows:[],
    selectedWorkflow: null
};

const slice = createSlice({
    name: 'workflow',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getAllWorkFlowSucess(state, action){
            state.isLoading = false;
            state.workflows = action.payload;
        }

    }
});

// Reducer
export default slice.reducer;

// Actions
export const {getMorePosts} = slice.actions;

//----------------------------------------------------------------------

export function createWorkflow(workflow, callback=null) {

    return async (dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            await firestore.collection('workflows').add({
                ...workflow, 
                createdAt: serverTime(), 
                updatedAt: serverTime(),
                createdBy: {
                    id: auth.currentUser.uid,
                    name: auth.currentUser.displayName,
                    email: auth.currentUser.email,
                    avatar: auth.currentUser.photoURL
                }
            });

            if(callback)
                callback();

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function updateWorkflow(workflow, callback=null) {

    return async (dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            await firestore.collection('workflows').doc(workflow?.id).set({
                title: workflow?.title || '',
                nodes: workflow?.nodes || [],
                edges: workflow?.edges || [],
                updatedAt: serverTime(),
                lastUpdatedBy: {
                    id: auth.currentUser.uid,
                    name: auth.currentUser.displayName,
                    email: auth.currentUser.email,
                    avatar: auth.currentUser.photoURL
                }
            }, { merge: true} );

            if(callback)
                callback();

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    }
}


export function getAllWorkflows(workflows, callback=null) {

    return async (dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            dispatch(slice.actions.getAllWorkFlowSucess(workflows))

            if(callback)
                callback();

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    }
}

export function deleteWorkflow(workflowId, callback=null) {

    return async (dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

           await firestore.collection('workflows').doc(workflowId).delete();

            if(callback)
                callback();

        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    }
}
