import React, { useState, useCallback } from 'react';
import { isString } from 'lodash';
import { Icon } from '@iconify/react';
import { alpha, Box, Paper, styled, Tooltip } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { getFileThumbUrl } from 'src/utils/getFileFormat';
import { MIconButton } from 'src/components/@material-extend';
import { varFadeInRight } from 'src/components/animate';


const DropZoneStyle = styled('div')(({theme}) => ({
    width: 80,
    height: 80,
    fontSize: 24,
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    border: `dashed 1px ${theme.palette.divider}`,
    '&:hover': {opacity: 0.72}
}));

export default function CustomUploadFile({ onChange, disableUpload, size='80'}) {
    const [files, setFiles] = useState([]);

    const handleRemove = (file) => {
        const filteredItems = files.filter((_file) => _file !== file);
        setFiles(filteredItems);
    };

    const handleDrop = useCallback(
        (acceptedFiles) => {
            let results = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })
            );
            setFiles(results);
            onChange(results);
        },
        [setFiles, onChange]
    );


    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop: handleDrop,
    });

  return (
    <>  
    {files.map((file) => {
            const {name, type, preview} = file;
            const key = isString(file) ? file : name;
            return (
                <Box
                    key={key}
                    {...varFadeInRight}
                    sx={{
                        p: 0,
                        m: 0.5,
                        width: size,
                        height: size,
                        borderRadius: 1,
                        overflow: 'hidden',
                        position: 'relative'
                    }}
                >
                    {
                        type.includes('image') ?
                            <Paper
                                variant="outlined"
                                component="img"
                                src={isString(file) ? file : preview}
                                sx={{width: '100%', height: '100%', objectFit: 'cover', position: 'absolute'}}
                            /> :
                            <Tooltip title={name}>
                                <Box component="img"
                                    src={getFileThumbUrl(type, preview)} 
                                    width={size-10} height={size-10}
                                    sx={{m: 0.5}}
                                />
                            </Tooltip>
                    }
                    <Box sx={{top: 6, right: 6, position: 'absolute'}}>
                        <MIconButton
                            size="small"
                            onClick={() => handleRemove(file)}
                            sx={{
                                p: '2px',
                                color: 'common.white',
                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                '&:hover': {
                                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                                }
                            }}
                        >
                            <Icon icon='eva:close-fill' />
                        </MIconButton>
                    </Box>
                </Box>
            );
        })
    }
    {
    <DropZoneStyle
        {...getRootProps()}
        sx={{...(isDragActive && {opacity: 0.72})}}
    >
        <input {...getInputProps()} />
        <Box component={Icon} icon='eva:plus-fill' sx={{color: 'text.secondary'}}/>
    </DropZoneStyle>
    }
    </>
  )
}
