import { useRef, useEffect } from 'react';

export default function DatePickerMinuteFormater({ children, trigger }) {
  const ref = useRef();

  useEffect(() => {
    const list = window.document.body.getElementsByClassName('ant-picker-time-panel-column');

    if (list.length !== 0) {
      const minutesCols = list[1];
      const childs = minutesCols.children;

      for (let child of childs) {
        const minute = Number(child.textContent);
        const match = minute % 15 === 0;

        if (!match) {
          child.style.display = 'none';
        }
      }
    }
  }, [trigger]);

  return <div ref={ref}>{children}</div>;
}
