import { useTheme } from '@mui/material/styles';

import { transformUserToAccess } from 'src/helpers/user';
import createAvatar from 'src/utils/createAvatar';

import { Avatar, Tooltip } from '@mui/material';

export default function UserAvatar({ user, size = 20, variant, fontSize, sx, ...rest }) {
  const theme = useTheme();
  const person = transformUserToAccess(user);
  const { name, color } = createAvatar(person?.name, true);
  const bgcolor = theme.palette[color]?.main;

  return (
    <Tooltip title={person?.name} arrow>
      <Avatar
        variant={variant}
        src={person?.avatar?.preview || person?.avatar}
        alt={person?.name}
        sx={{
          cursor: 'pointer',
          height: size,
          width: size,
          color: 'white',
          bgcolor: person?.avatar ? 'grey.600' : bgcolor,
          ...sx,
          fontSize
        }}
        {...rest}
      >
        {name}
      </Avatar>
    </Tooltip>
  );
}
