import { Dialog, DialogContent, DialogTitle, Button, Stack } from '@mui/material';

import { Icon } from '@iconify/react';

export default function VideoPlayerModal({ url, type, name, open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      fullScreen
      sx={{
        zIndex: (t) => t.zIndex.tooltip + 200
      }}
      PaperProps={{
        sx: {
          bgcolor: 'transparent',
          zIndex: (t) => t.zIndex.tooltip + 10
        }
      }}
    >
      <DialogTitle>
        <Stack direction={'row'} justifyContent={'end'} width={1}>
          <Stack>
            <Button
              color={'inherit'}
              fullWidth
              onClick={onClose}
              sx={{
                py: 1,
                // opacity: .7,
                bgcolor: (t) => t.palette.background.paper,
                transform: 'scale(.7)',
                ':hover': {
                  opacity: 0.6,
                  bgcolor: (t) => t.palette.background.paper
                }
              }}
            >
              <Icon icon="eva:close-fill" height={30} width={30} />
            </Button>
          </Stack>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack width={1} height={1}>
          <video controls width={'100%'} height={'100%'}>
            <source src={url} type={type} />
            <track default kind="captions" srcLang="fr" src="" />
          </video>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
