import { isEmpty, isString } from 'lodash';
import PropTypes from 'prop-types';
// material
import { Box, Typography, Link, Stack } from '@mui/material';
//
import DocMBreadcrumbs from './DocMBreadcrumbs';

// ----------------------------------------------------------------------

DocHeaderDashboard.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object
};

export default function DocHeaderDashboard({
  links,
  action,
  icon,
  maxWidth,
  separator,
  typographyStyle,
  moreLink = '' || [],
  color,
  sx,
  ...other
}) {
  return (
    <Box sx={{ mb: 5, ...sx }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Stack direction='row' sx={{ flexGrow: 1 }}>
          {icon && icon}
          <DocMBreadcrumbs color={color} typographyStyle={typographyStyle} separator={separator} links={links} maxWidth={maxWidth} {...other} />
        </Stack>
        {action && <Box sx={{ flexShrink: 0 }}>{action}</Box>}
      </Box>

      {!isEmpty(moreLink) &&
       <Box sx={{ mt: 2, }}>
        {isString(moreLink) ? (
          <Link href={moreLink} target="_blank" variant="body2">
            {moreLink}
          </Link>
        ) : (
          moreLink.map((href, idx) => (
            <Link
              noWrap
              key={idx}
              href={href}
              variant="body2"
              target="_blank"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))
        )}
      </Box>}
    </Box>
  );
}
