import PropTypes from 'prop-types';
import {Icon} from '@iconify/react';
import checkmarkFill from '@iconify/icons-eva/checkmark-fill';
// material
import {Box, Radio, RadioGroup} from '@mui/material';

// ----------------------------------------------------------------------

IconColor.propTypes = {
    sx: PropTypes.object
};

function IconColor({sx, src, checked = false, height, width, ...other}) {
    return (
        <Box
            sx={{
                width: width || 35,
                height: height || 20,
                display: 'flex',
                // borderRadius: '50%',
                position: 'relative',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'currentColor',
                backgroundImage: `url('${src}')`,
                backgroundSize:'cover',
                transition: (theme) =>
                    theme.transitions.create('all', {
                        duration: theme.transitions.duration.shortest
                    }),
                ...sx
            }}
            {...other}
        >
            {checked && (<Icon icon={checkmarkFill}/>)}
        </Box>
    );
}

SingleBackgroundImagePicker.propTypes = {
    selected: PropTypes.string,
    onChange:PropTypes.func
};

const images = [...Array(10)].map((_, idx) => `/static/bg-images/bg-${idx + 1}.jpg`)

export default function SingleBackgroundImagePicker({selected, height=null, width=null, ...other}) {
   
    
    return (
        <RadioGroup row {...other}>
            {images.map((image) => {
                const isWhite = image?.includes('11')

                return (
                    <Radio
                        key={image}
                        value={image}
                        color="default"
                        checked={selected === image}
                        icon={
                            <IconColor
                                src={image}
                                height={height}
                                width={width}
                                sx={{
                                    ...(isWhite && {
                                        border: (theme) => `solid 1px ${theme.palette.divider}`
                                    })
                                }}
                            />
                        }
                        checkedIcon={
                            <IconColor
                                src={image}
                                checked
                                height={height}
                                width={width}
                                sx={{
                                    transform: 'scale(1.4)',
                                    '&:before': {
                                        opacity: 0.48,
                                        width: '100%',
                                        content: "''",
                                        height: '100%',
                                        borderRadius: '50%',
                                        position: 'absolute',
                                        boxShadow: '4px 4px 8px 0 currentColor'
                                    },
                                    '& svg': {width: 12, height: 12, color: 'common.white'},
                                    ...(isWhite && {
                                        border: (theme) => `solid 1px ${theme.palette.divider}`,
                                        boxShadow: (theme) => `4px 4px 8px 0 ${theme.palette.grey[500_24]}`,
                                        '& svg': {width: 12, height: 12, color: 'common.black'}
                                    })
                                }}
                            />
                        }
                        sx={{
                            color: 'white',
                            '&:hover': {opacity: 0.72}
                        }}
                    />
                );
            })}
        </RadioGroup>
    );
}
