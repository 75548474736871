/**
 * @typedef {{
 *  reference : string,
 *  typeOp : string,
 *  priority: string,
 *  annotation: Array,
 *  dateCourr:  Date?,
 *  objet: string,
 *  dateDue: Date?,
 *  dateArr: Date?,
 *  collabDue: Date?,
 *  dateAssigne: Date?,
 *  contenu: string,
 *  comment: string
 * }}
 */
export var AffectaionCorrespondanceType;

/**
 * @typedef {{
 *  titre: string,
 *  contact: string
 * }}
 */
export var AffectationExpediteurType;

/**
 * @typedef {{
 *  responsable: Array,
 *  collaborateur: Array,
 * }}
 */
export var AssigneType;

/**
 * @typedef {{
 *  correspondance: AffectaionCorrespondanceType,
 *  expediteur: AffectationExpediteurType,
 *  documents: Array,
 *  attachments: Array,
 *  responsable: Object,
 *  assigne: AssigneType,
 *  state: string,
 *  createdAt: Date?,
 *  updatedAt: Date?,
 *  assignedAt: { date: Date?, by: {name: string, id: string}},
 *  createdBy: { id:string, email: string, avatar: string, name: string},
 *  toDoIds: Array<string>,
 *  toReviewIds: Array<string>,
 *  toValidate: Array<string>,
 *  historyIds: Array<string>,
 *  type: string,
 *  isDelete: Boolean,
 *  canPrint: any,
 * }}
 */
export var AffectationType;

/** @type { AffectationType } */
export var AffectationTypeDefault = {
  correspondance: {
    annotation: [],
    comment: '',
    objet: '',
    contenu: '',
    dateArr: null,
    dateCourr: null,
    dateDue: null,
    collabDue: null,
    dateAssigne: null,
    priority: '',
    reference: '',
    typeOp: '',
    type: ''
  },
  expediteur: {
    titre: '',
    contact: ''
  },
  documents: [],
  attachments: [],
  responsable: null,
  toDoIds: [],
  toReviewIds: [],
  historyIds: [],
  toValidate: [],
  assigne: {
    responsable: [],
    collaborateur: []
  },
  isDelete: false,
  canPrint: null
};
