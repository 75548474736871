import {useRef, useMemo} from "react";
import {useToggleV2} from "src/hooks/useToggle";
import useAuth from "src/hooks/useAuth";
import useUserList from "src/hooks/useUserList";

import {Typography, Stack, Grid, Tooltip, Paper} from "@mui/material";
import {Avatar} from 'antd';
import {MoreVert} from "@mui/icons-material";
import {MenuLine} from "src/components/MenuLine";
import Center from "src/components/Center";
import MenuPopover from "src/components/MenuPopover";

import {Icon} from "@iconify/react";

import {gfDateTime} from "src/utils/formatTime";
import createAvatar from "src/utils/createAvatar";
import {transformUserToAccess} from "src/helpers/user";

export const Header = () => {
    return (
        <>
            <Grid
                container
                sx={{
                    height: 35,
                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    borderColor: t => t.palette.divider,
                }}
            >
                <Grid item md={6} sm={6} p={1}>
                    <Typography fontSize={12}>
                        TITRE DU RAPPEL
                    </Typography>
                </Grid>

                <Grid item md={3} sm={3} p={1}
                      sx={{
                          borderStyle: 'solid',
                          borderWidth: 0,
                          borderLeftWidth: 1,
                          borderRightWidth: 1,
                          borderColor: t => t.palette.divider,
                      }}
                >
                    <Typography fontSize={12}>
                        INTERVENANTS
                    </Typography>
                </Grid>

                <Grid item md={3} sm={3} p={1}>
                    <Typography fontSize={12}>
                        DATE
                    </Typography>
                </Grid>
            </Grid>

        </>
    )
}

export const Row = ({rappel, onOpenRappel, onDeletRappel}) => {
    const ref = useRef()
    const {user} = useAuth()
    const {users} = useUserList()

    const [open, onOpen, onClose] = useToggleV2()
    const [hover, onHover, onLeave] = useToggleV2()

    const isMine = useMemo(() => user.id === rappel?.createdById, [rappel?.createdById, user.id])

    const creator = useMemo(() => {
        const find = users?.find(el => el?.id === rappel?.createdById);
        return find ? transformUserToAccess(find) : null
    }, [rappel?.createdById, users])

    const collaborators = useMemo(() => users?.filter(el => {
        return user?.id !== el?.id && rappel?.userIds?.includes(el?.id)
    }), [rappel?.userIds, user?.id, users])


    const handleOpen = () => {
        if (onOpenRappel) onOpenRappel(rappel)
        onClose()
    }

    const handleDelete = () => {
        if (onDeletRappel) onDeletRappel(rappel)
        onClose()
    }

    return (
        <Paper elevation={2} sx={{mb:1}}>
            <Grid
                container
                onMouseEnter={onHover}
                onMouseLeave={onLeave}
                sx={{height: 40, }}
            >
                <Grid item md={6} sm={6} p={1}>
                    <Stack direction={'row'} justifyContent={"space-between"}>
                        <Typography fontSize={12} whiteSpace={"nowrap"} overflow={'hidden'} textOverflow={'ellipsis'} pt={.3}
                                    width={hover ? '87%' : 1}>
                            {rappel?.title}
                        </Typography>

                        {
                            hover && (
                                <Stack
                                    onClick={onOpen}
                                    height={25} width={25}
                                    sx={{
                                        bgcolor: 'white',
                                        borderStyle: 'solid',
                                        borderWidth: 1,
                                        borderColor: t => t.palette.divider,
                                        borderRadius: 1,
                                        cursor: 'pointer'
                                    }}
                                >
                                    <Center>
                                        <MoreVert fontSize={"small"}/>
                                    </Center>

                                </Stack>
                            )
                        }


                    </Stack>

                </Grid>

                <Grid item md={3} sm={3} p={1} ref={ref}

                >
                    <Stack direction={"row"} spacing={1}>
                        {!isMine && (
                            <Stack pt={.5}>
                                <Tooltip title={`Ce rappel vous est partagé par "${creator?.name}"`}>
                                    <Icon icon={"tdesign:share"}/>
                                </Tooltip>
                            </Stack>
                        )}

                        <Stack>
                            <Avatar.Group maxCount={4} size="small">
                                {collaborators?.length > 0 && (
                                    collaborators.map((_one, idx) => {
                                        return (
                                            <Tooltip key={_one?.id + idx} title={_one?.displayName || _one?.name} arrow>
                                                <Avatar
                                                    src={_one?.avatar || _one?.photoURL || _one?.photoUrl || null}
                                                    alt={_one?.displayName || _one?.name}
                                                    style={{
                                                        backgroundColor: createAvatar(_one?.displayName || _one?.name).color2,
                                                    }}
                                                >
                                                    {createAvatar(_one?.displayName || _one?.name).name}
                                                </Avatar>
                                            </Tooltip>
                                        );
                                    })
                                )}
                            </Avatar.Group>
                        </Stack>

                    </Stack>


                </Grid>

                <Grid item md={3} sm={3} p={1}>
                    <Typography fontSize={12} whiteSpace={"nowrap"} overflow={'hidden'} textOverflow={'ellipsis'}  pt={.3}>
                        {gfDateTime(rappel?.runAt)}
                    </Typography>
                </Grid>
            </Grid>


            <MenuPopover width={150} anchorEl={ref.current} open={open} onClose={onClose}>
                {!isMine && (<MenuLine title={"Ouvrir"} icon={"akar-icons:eye-open"} onClick={handleOpen}/>)}

                {isMine && (<MenuLine title={"Editer"} icon={"iconamoon:edit-bold"} onClick={handleOpen}/>)}

                {isMine && (
                    <MenuLine title={"Supprimer"} icon={"iconamoon:trash-light"} iconColor={"red"} color={"error"}
                              onClick={handleDelete}/>)}
            </MenuPopover>

        </Paper>
    )
}