import { capitalize } from 'lodash';
import { baseColors } from 'src/constants/color';

// ----------------------------------------------------------------------

const PRIMARY_NAME = ['A', 'N', 'H', 'L', 'Q', '9', '8', 'O'];
const INFO_NAME = ['F', 'G', 'T', 'I', 'J', '1', '2', '3'];
const SUCCESS_NAME = ['K', 'D', 'Y', 'B', '4', '5'];
const WARNING_NAME = ['P', 'E', 'R', 'S', 'C', 'U', '6', '7'];
const ERROR_NAME = ['V', 'W', 'X', 'M', 'Z'];

function getFirstCharacter(name, two = false) {
  if (!name) return '';

  const checkSecondName = name.split(' ');

  if (two && checkSecondName.length > 0) {
    return `${capitalize(checkSecondName.at(0).charAt(0))} ${capitalize(checkSecondName.at(1).charAt(0))}`;
  }

  return capitalize(name.charAt(0));
}

export function getAvatarColor(name) {
  if (PRIMARY_NAME.includes(getFirstCharacter(name))) return 'primary';
  if (INFO_NAME.includes(getFirstCharacter(name))) return 'info';
  if (SUCCESS_NAME.includes(getFirstCharacter(name))) return 'success';
  if (WARNING_NAME.includes(getFirstCharacter(name))) return 'warning';
  if (ERROR_NAME.includes(getFirstCharacter(name))) return 'error';
  return 'default';
}

export function getAvatarColor2(name) {
  if (PRIMARY_NAME.includes(getFirstCharacter(name))) return baseColors.GREEN;
  if (INFO_NAME.includes(getFirstCharacter(name))) return baseColors.BLEU_N;
  if (SUCCESS_NAME.includes(getFirstCharacter(name))) return baseColors.GREEN_N;
  if (WARNING_NAME.includes(getFirstCharacter(name))) return baseColors.WARINIG;
  if (ERROR_NAME.includes(getFirstCharacter(name))) return baseColors.RED;
  return 'default';
}

export function getAvatarUrl(object) {
  return object?.photoUrl || object?.photoURL || object?.avatar || null;
}

export default function createAvatar(name, two = false) {
  return {
    name: getFirstCharacter(name, two),
    color: getAvatarColor(name),
    color2: getAvatarColor2(name)
  };
}
