import {useMemo} from 'react';
import {TASK_STATE_VALIDATION} from "../../../constants";
import {DEPS_TYPES} from "../../../constants/deps";
import MenuPopover from "../../../components/MenuPopover";
import {Alert, AlertTitle, Button, Dialog, DialogContent, DialogTitle, Stack, Typography} from '@mui/material'
import {flattenDeep, uniqBy, values} from "lodash";
import {Icon} from '@iconify/react';
import {DepsWarnig} from 'src/assets/dependence';
import {baseColors} from 'src/constants/color';
import {useDispatch} from 'react-redux'
import {closeTaskDeps, updateCard} from "../../../redux/slices/kanban";
import {useSnackbar} from "notistack";

export const canStartTask = (card) => {
    const dependencies = card?.dependences || {};
    const pendings = dependencies[DEPS_TYPES.PENDING] || []
    const predicate = pendings?.filter(el => el?.state !== TASK_STATE_VALIDATION.ACCEPTED && el?.state !== TASK_STATE_VALIDATION.DONE)
    return (predicate?.length || 0) === 0;
}


export default function DependencyTaskCanStartTrigger({card, open = false, onClose, anchor}) {
    const dispatch = useDispatch();
    const deps = useMemo(() => {
        const dependencies = card?.dependences || {};
        return dependencies[DEPS_TYPES.PENDING] || [];
    }, [card?.dependences])
    const {enqueueSnackbar} = useSnackbar();

    const callBack = () => {
        enqueueSnackbar('Tâche modifiée avec succès', {variant: 'success'});
    }

    const handleContinue = () => {
        // console.log('continue')
        dispatch(updateCard({...card, state: TASK_STATE_VALIDATION.INPROGRESS}, callBack))
        onClose()
    }

    const handleCloseDeps = () => {
        // console.log('close deps')
        dispatch(closeTaskDeps(card, callBack))
        onClose()
    }

    return (
        <Dialog fullWidth maxWidth='sm' open={open} onClose={onClose} scroll='paper'>
            <DialogTitle>
                <Stack width={1} direction='row' justifyContent='flex-end'>
                    <Stack onClick={onClose} sx={{cursor: 'pointer'}} direction='row' alignItems='center'>
                        <Typography color={baseColors.RED} >Annuler</Typography>
                        <Icon icon='eva:close-fill' height={40} color={baseColors.GREY}/>
                    </Stack>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <Stack width={1} spacing={2} alignItems='center' p={2}>
                    <DepsWarnig/>
                    <Typography color={baseColors.RED} fontSize={18}>
                        Êtes-vous sûr de cela ?
                    </Typography>
                    <Typography>
                        Cette tâche a {deps?.length || 0} non résolu tâches.
                    </Typography>
                    <Stack width={1} pt={2} pl={3}>
                        <ul>
                            {
                                deps?.map(el => (
                                    <li key={el?.id}>
                                        {el?.name}
                                    </li>
                                ))
                            }
                        </ul>
                    </Stack>
                    <Stack width={1} py={0.5} alignItems='center' borderRadius={1} bgcolor={baseColors.GREEN}
                           sx={{cursor: 'pointer'}} onClick={handleCloseDeps}>
                        <Typography color='white' fontWeight='bold'>Résoudre toutes les tâches</Typography>
                        <Typography color='white' fontSize={12}>Clore toutes les tâches d'action qui ne sont pas encore
                            clos </Typography>
                    </Stack>
                    <Stack width={1} py={0.5} alignItems='center' borderRadius={1} bgcolor={baseColors.GREY}
                           sx={{cursor: 'pointer'}} onClick={handleContinue}>
                        <Typography color='white' fontWeight='bold'> Continuer quand même </Typography>
                        <Typography color='white' fontSize={12}>Continer sans resoudre les tâches
                            d'actions </Typography>
                    </Stack>
                </Stack>
            </DialogContent>


        </Dialog>
    )
}