import React from 'react';
import { uniqBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { nanoid } from '@reduxjs/toolkit';
import { useDispatch } from 'src/redux/store';
import LoadingView from 'src/components/LoadingView';
import { Stack, LinearProgress, Box } from '@mui/material';
import { addCommentToTask, deleteCommentFromTask, updateCommentReadState } from 'src/redux/slices/kanban';
import useTaskComment from '../../../hooks/useTaskComment';
import KanbanTaskCommentInput from '../../../components/CommentInput';
import CustomCommentList from 'src/components/CustomCommentList';
import useAuth from 'src/hooks/useAuth';



export default function KanbanTaskDetailsComment({idQ, details, users, userId, name,  }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { user } = useAuth();
    const [loading, setLoading] = React.useState(false);
    const { loading:commentLoading, taskComments } = useTaskComment({taskId: details.id});


    const comments = React.useMemo(()=>{    
        const filterOldTask =  details?.comments ? details?.comments?.map((one, idx)=>{
            const {id, ...rest} = one;
            return {...rest, userId: id, id:`${id}_${idx}`};
        }) : [];
      
        return  uniqBy([...filterOldTask, ...taskComments], 'id');
    }, [details?.comments, taskComments]);


    const addComment = (comment) =>{
        setLoading(true);
        const docId = nanoid();

        const commentObject = {
            ...comment,
            read: 0,
            readState: []
        }

        comments.push({...commentObject, id: docId});
        dispatch(addCommentToTask({taskId: details.id, generateId: docId, commentBbject: commentObject, taskName: details?.name,
             canAccess: [...details?.canAccess || [], ...details?.managers || [] ],
             callback:()=>{
            setLoading(false);
            enqueueSnackbar('Commentaire ajouté', {variant:'info'});
        }}));
    }


    const deleteCommentaire = ({id}) =>{
        dispatch(deleteCommentFromTask({taskId: details?.id, commentId: id, callback:() =>{
            enqueueSnackbar('Commentaire supprimé avec succès', {variant:'warning'});
        }}))
    }


    React.useEffect(() =>{
        const readFunction =() =>{
            const toUpdate = [];
            taskComments.forEach((one) =>{
                const rState = one?.readState || [];
                if(!rState.find(_one => _one.id === userId)){
                   toUpdate.push({...one, readState: [...rState, {id:userId, name:name}] });
                }

            });
            toUpdate.length && dispatch(updateCommentReadState({ taskId: details?.id, comments: toUpdate}));
        }

        readFunction();    
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[taskComments]);


  return (    
    <Stack sx={{ height: '80vh', maxHeight: '80vh' }}>
        {commentLoading && <LoadingView size={{ height: 150, width:150 }} />}
        <Box sx={{flexGrow: 1, display: 'flex', overflow: 'hidden'}}>
            <Box  sx={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
                {!commentLoading && comments.length > 0 && 
                    <CustomCommentList list={comments} onDeleteComment={deleteCommentaire} details={details} user={user} />
                }
                {loading && <Stack width={1}>  <LinearProgress /> </Stack> }
                <KanbanTaskCommentInput idQ={idQ}   onChange={addComment} />
            </Box>
        </Box>
    </Stack>
  );
}
