import { unionBy, uniqBy } from 'lodash';
import { useState, useEffect, useMemo } from 'react';
import useContactList from './useContactList';
import { CONTACT_TYPE } from './../constants/index';
import { PersonType } from 'src/models/Person_m';
import { useSelector } from 'src/redux/store';

/**
 *
 * @param {*} param0
 * @returns {{
 *  persons: Array<PersonType>
 * }}
 */
export default function usePersons({ email }) {
  const [loading, setLoading] = useState(false);

  const { loading: contactLoading, contacts } = useContactList({ type: CONTACT_TYPE.EMPLOYEE.title, email: email });
  const { loading: partLoading, contacts: partenaires } = useContactList({
    type: CONTACT_TYPE.CLIENT.title,
    email: email
  });
  const { users, loading: userLoading } = useSelector((state) => state.user);

  const persons = useMemo(() => {
    const unions = unionBy(users, contacts, 'email');
    const unions2 = unionBy(unions, partenaires, 'email');

    const result = unions2.map((pers) => {
      return {
        id: pers.id,
        email: pers.email,
        firstName: pers?.firstName || '',
        lastName: pers?.lastName || '',
        tel: pers?.phoneNumber || pers?.tel || '',
        type: pers?.contactTye || pers?.typeUser || '',
        fonction: pers?.poste || pers?.title || '',
        location: `${pers?.country || ''} - ${pers?.city || ''}` || '',
        displayName: pers?.displayName || `${pers?.lastName} ${pers?.firstName}`,
        photoUrl: pers?.photoUrl || '',
        photoURL: pers?.photoUrl || '',
        responsables: pers?.responsables ? pers?.responsables?.map((_res) => _res.id) : []
      };
    });

    const uniqResult = uniqBy(result, 'email');

    return uniqResult.sort((a, b) => {
      if (a.lastName < b.lastName) return -1;
      if (a.lastName > b.lastName) return 1;
      return 0;
    });
  }, [users, contacts, partenaires]);

  useEffect(() => {
    setLoading(userLoading || contactLoading || partLoading);
  }, [userLoading, contactLoading, partLoading]);

  return { loading, persons };
}
