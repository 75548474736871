import {useState} from 'react'
import {useTheme} from '@mui/material/styles'

import {customTextFieldTheme} from "src/components/CustomInput";

import {Button, Stack, TextField, Typography} from "@mui/material";
import MenuPopover from "src/components/MenuPopover";

export default function AudienceTableComment({value, open, onClose, onSave, anchor}) {
    const theme = useTheme()
    const [comment, setComment] = useState(value || '')

    const handleSave = () => {
        onSave(comment)
        onClose()
    }

    return (
        <MenuPopover sx={{width: 500, p: 2}}
                     open={open} onClose={onClose}
                     anchorEl={anchor.current}
        >

            <Stack spacing={2} direction={'row'} justifyContent={'space-between'} mb={1}>
                <Typography>
                    Motif du rejet
                </Typography>

            </Stack>

            <TextField
                fullWidth
                multiline
                minRows={4}
                label={' '}
                placeholder={'Motif du rejet'}
                value={comment || ''}
                onChange={ev => setComment(ev.target.value)}
                variant={'standard'}
                InputProps={{
                    disableUnderline: true
                }}
                sx={customTextFieldTheme(theme)}
            />

            <Stack direction={'row'} justifyContent={'end'} mt={1} spacing={1}>
                <Button size={'small'} onClick={onClose}>
                    Annuler
                </Button>
                <Button variant={'contained'} color={'primary'} size={'small'} onClick={handleSave}>
                    Valider
                </Button>
            </Stack>
        </MenuPopover>
    )
}