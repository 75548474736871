/**
 * @typedef {{
*  id: string,
*  title: string,
*  description: string,
*  type: string,
*  docId: string,
*  runAt: Date ,
*  nextRunAt: Date | null,
*  createdAt:  Date ,
*  updatedAt: Date | null,
*  userIds: string[], 
*  fcm: {{web:string[],mobile:string[]}},
*  metadata: any,
 
* }}
* 
*/
export var rappelType;

export const DERAULT_RAPPEL_DATA = {
    title: '',
    description: '',

    type: "default",
    docId: null,

    initialDate: null,
    runAt: null,

    isRecursive: false,

    userIds: [],
    fcm: {
        mobile: [],
        web: []
    },
    sendEmailNotification: false,
}