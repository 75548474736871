import React, { createContext, useContext, useState } from 'react';

const AccueilTaskEditorProvider = createContext({
  currentOperation: '',
  currentOperationId: '',
  currentProject: null,
  loadingProject: false,
  setLoadingProject: () => {},
  setCurrentOperation: () => {},
  setCurrentOperationId: () => {},
  setCurrentProject: () => {}
});

export const useAcContext = () => useContext(AccueilTaskEditorProvider);

export default function AccueilTaskEditorContext({ children }) {
  const [currentOperation, setCurrentOperation] = useState('');
  const [currentOperationId, setCurrentOperationId] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);
  const [loadingProject, setLoadingProject] = useState(false);

  const store = {
    currentOperationId,
    setCurrentOperationId,
    currentOperation,
    currentProject,
    loadingProject,
    setLoadingProject,
    setCurrentOperation,
    setCurrentProject
  };

  return <AccueilTaskEditorProvider.Provider value={store}>{children}</AccueilTaskEditorProvider.Provider>;
}
