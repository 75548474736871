import {  createSlice } from '@reduxjs/toolkit';
import firestore from '../../utils/firestore';
import { archiveCollection, archiveFoldersDoc } from 'src/constants/firestore';
import { isFile } from 'src/utils/type_check';
import { multipleFilesSave } from 'src/redux/slices/document';
import { isFunction } from 'lodash';
import { paginate } from 'src/utils/array';

const initialState = {
  isLoading: false,
  error: false,
  archives: [],
  selected: [],
  forlders: []
};

const slice = createSlice({
  name: 'archive',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    createdSuccess(state, action) {
      state.isLoading = false;
    },

    getFolders(state, action) {
      state.isLoading = false;
      state.forlders = action.payload;
    },

    getArchives(state, action) {
      state.isLoading = false;
      state.archives = action.payload;
    },

    getSelectedArc(state, action) {
      state.isLoading = false;
      state.selected = state.archives.filter(arc => arc.folder.id === action.payload);
    }
  }
});

export default slice.reducer;

export function getArchives(archives) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getArchives(archives));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSelectedArchives(id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getSelectedArc(id));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getFolders(folders) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getFolders(folders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function createFolder(folder, id) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const docRef = archiveFoldersDoc;

      await firestore.runTransaction((tranc) => {
        return tranc.get(docRef).then((sfDoc) => {
          if (sfDoc.exists) {
            const folders = sfDoc.data().folders;
            let newVal = [...folders, folder];
            tranc.update(docRef, { folders: newVal });
            dispatch(slice.actions.getFolders(newVal));
          } else {
            tranc.set(docRef, { folders: [folder] });
            dispatch(slice.actions.getFolders([folder]));
          }
        });
      });

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function createArchves(archives, callback) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const { files, ...rest } = archives;

      await multipleFilesSave(files, async (files = []) => {
        await firestore.collection('archives').add({ files, ...rest, updatedAt: new Date() });
      });

      dispatch(slice.actions.createdSuccess());
      if (callback) {
        return callback();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editArchives(archives, callback) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const { files = [], id, ...rest } = archives;

      const toUpload = files?.filter(one => isFile(one));

      await multipleFilesSave(toUpload, async (files = []) => {
        const previous = files?.filter(one => !isFile(one));
        const allFiles = [...previous, ...files];

        await firestore.collection('archives').doc(id).update({ files: allFiles, ...rest, updatedAt: new Date() });
      });

      dispatch(slice.actions.createdSuccess());
      if (callback) {
        return callback();
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function commentArchive(archiveId, comment, callback) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const files = comment?.files?.filter(one => isFile(one));

      await multipleFilesSave(files, async (files = []) => {
        await firestore.runTransaction(transaction => {
          const ref = archiveCollection.doc(archiveId);
          return transaction.get(ref).then(doc => {

            if (doc.exists) {
              const comments = [
                ...(doc.data()?.comments || []),
                {
                  ...comment,
                  files
                }
              ];

              transaction.update(ref, {
                comments,
                updatedAt: new Date()
              });
            }

          });
        });

      });

      dispatch(slice.actions.createdSuccess());

      callback && callback();

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function deleteArchive({ archiveId, softDelete = true }, callback) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const snap = firestore.collection('archives').doc(archiveId);

      if (softDelete) {
        await snap.update({ isDeleted: true, updatedAt: new Date() });
      } else {
        await snap.delete();
      }

      dispatch(slice.actions.createdSuccess());

      callback && callback();

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export const putArchiveToBin = (archiveIds= [], callback = null) => {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const groups = paginate(archiveIds, 400);

      await Promise.all(groups?.map(async collection => {
        const batch = firestore.batch();

        collection.forEach(id => {
          const doc = archiveCollection.doc(id);
          batch.update(doc, {
            isDeleted: true,
            updatedAt: new Date()
          });
        });

        await batch.commit();
      }));

      isFunction(callback) && callback();

    } catch (error) {
      dispatch(slice.actions.hasError(error));

    }
  };
};


export function restoreArchives(archiveIds = [], callback) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const groups = paginate(archiveIds, 400);

      await Promise.all(groups?.map(async collection => {
        const batch = firestore.batch();

        collection.forEach(id => {
          const doc = archiveCollection.doc(id);
          batch.update(doc, {
            isDeleted: false,
            updatedAt: new Date()
          });
        });

        await batch.commit();
      }));


      dispatch(slice.actions.createdSuccess());

      callback && callback();

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function starArchive({ archiveId, userId, remove = false }, callback) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());


      await firestore.runTransaction(transaction => {
        const ref = archiveCollection.doc(archiveId);
        return transaction.get(ref).then(doc => {

          if (doc.exists) {
            const list = doc.data()?.starredBy || [];
            let starredBy = [];

            const isEdit = list?.includes(userId);


            if (isEdit) {
              starredBy = list?.filter(id => id !== userId);
            } else {
              starredBy = [
                ...list,
                userId
              ];
            }

            transaction.update(ref, {
              starredBy,
              updatedAt: new Date()
            });

          }

        });
      });

      dispatch(slice.actions.createdSuccess());

      callback && callback();

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function followArchive({ archiveId, userId, remove = false }, callback) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());


      await firestore.runTransaction(transaction => {
        const ref = archiveCollection.doc(archiveId);
        return transaction.get(ref).then(doc => {

          if (doc.exists) {

            const list = doc.data()?.followedBy || [];
            let followedBy = [];

            const isEdit = list?.includes(userId);

            if (isEdit) {
              followedBy = list?.filter(id => id !== userId);
            } else {
              followedBy = [
                ...list,
                userId
              ];
            }

            transaction.update(ref, {
              followedBy,
              updatedAt: new Date()
            });

          }

        });
      });

      dispatch(slice.actions.createdSuccess());

      callback && callback();

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function sendMessageOnArchive({message, archiveId,  callback=null, _hasError=null}) {
  return async (dispatch) =>{
    try {
      await firestore.collection('arc_comments').doc(archiveId).collection('messages').add({...message});
      callback && callback();
    } catch (error) {
      console.log(error);
      _hasError && _hasError(error);
      dispatch(slice.actions._hasError(error));
    }
  }
}

export function deleteMessageOnArchive({messageId, archiveId,  callback=null, _hasError=null}) {
  return async (dispatch) =>{
    try {
      await firestore.collection('arc_comments').doc(archiveId).collection('messages').doc(messageId).delete()
      callback && callback();
    } catch (error) {
      console.log(error);
      _hasError && _hasError(error);
      dispatch(slice.actions._hasError(error));
    }
  }
}
