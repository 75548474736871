import React, { useRef, useMemo, useEffect } from 'react';
import { useToggleV2 } from 'src/hooks/useToggle';
import useAuth from 'src/hooks/useAuth';
import usePersons from 'src/hooks/usePersons';
import { useTheme } from '@mui/material/styles';

import { customTextFieldTheme } from 'src/components/CustomInput';
import { uniqBy, capitalize } from 'lodash';
import { transformUserToAccess } from 'src/helpers/user';

import {
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Stack,
  Autocomplete,
  TextField,
  Typography
} from '@mui/material';
import UserAvatar from 'src/components/UserAvatar';

import { LabelStyle } from 'src/section/tasks/kanban/KanbanTaskDetails';
import useAudiencePeople from 'src/hooks/useAudiencePeople';
import { useSelector } from 'src/redux/store';

export default function ContactAutoComplete({ value, onPick, helperText, error, label, disabled, ...rest }) {
  const theme = useTheme();
  const { users: persons } = useAudiencePeople();
  const userI = useSelector((state) => state.user);
  const list = useMemo(() => {
    const users = [...userI.users, ...persons]
      ?.map((el) => ({
        ...transformUserToAccess(el),
        firstName: el?.firstName || null,
        lastName: el?.lastName || null,
        ...el
      }))
      ?.map((el) => ({
        ...el,
        _helper: `${el?.name} ${el?.email}`
      }));
    return uniqBy(users, 'id');
  }, [persons, userI.users]);

  // console.log('users', userI.users)

  return (
    <Stack spacing={1}>
      {label && (
        <Stack width={1}>
          <LabelStyle width="max-content">{capitalize(label)}</LabelStyle>
        </Stack>
      )}
      <Autocomplete
        freeSolo
        disabled={disabled}
        options={list}
        disableClearable
        value={{ name: value }}
        getOptionLabel={(option) => option?.name}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        renderOption={(props, option, state) => {
          const handleClick = (event) => {
            props?.onClick(event);
            onPick && onPick(option);
          };

          return (
            <ListItemButton dense key={option?.id} {...props} onClick={handleClick}>
              <ListItemAvatar>
                <UserAvatar user={option} size={25} />
              </ListItemAvatar>

              <ListItemText primary={option?.name} secondary={`${option?.email}  `} />
            </ListItemButton>
          );
        }}
        renderInput={({ InputProps, ...params }) => {
          return (
            <TextField
              {...params}
              variant={'standard'}
              sx={customTextFieldTheme(theme)}
              {...rest}
              InputProps={{ disableUnderline: true, ...InputProps }}
            />
          );
        }}
      />

      {helperText && (
        <Typography variant={'caption'} color={error ? 'red' : 'black'} fontSize={12}>
          {helperText}
        </Typography>
      )}
    </Stack>
  );
}
