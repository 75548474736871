import React, { useState, useRef, useEffect, useMemo } from 'react';
import { isEqual, orderBy, filter } from 'lodash';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { Icon } from '@iconify/react';
import calendarFill from '@iconify/icons-eva/calendar-fill';
import EditFill from '@iconify/icons-eva/edit-fill';
import EditFill2 from '@iconify/icons-eva/edit-2-fill';
import audioFill from '@iconify/icons-bi/mic';
import audioOffFill from '@iconify/icons-bi/mic-mute';
import { useSnackbar } from 'notistack';
import {
  Box,
  Stack,
  Alert,
  Paper,
  Button,
  Tooltip,
  Backdrop,
  MenuItem,
  TextField,
  Typography,
  IconButton,
  OutlinedInput,
  CircularProgress,
  ClickAwayListener,
  Collapse
} from '@mui/material';
import { Avatar as DAvatar, Badge } from 'antd';
import { FileTextFilled } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import peopleFill from '@iconify/icons-eva/people-fill';
import { MAvatar, MIconButton } from '../../../components/@material-extend';
import createAvatar from 'src/utils/createAvatar';
import useToggle from '../../../hooks/useToggle';
import KanbanMenuPopover from './KanbanMenuPopover';
import { styled, alpha } from '@mui/material/styles';
import Etiquettes from './Etiquettes';
import MenuPopover, { MenuPopoverCenter } from '../../../components/MenuPopover';
import NotistackProvider from '../../../components/NotistackProvider';
import Dropzone from 'react-dropzone';
import { multipleFilesSave, saveFile } from 'src/redux/slices/document';
import { SaveTaskDocs } from '../../../redux/slices/document';
import { useDispatch } from 'src/redux/store';
import usePersons from '../../../hooks/usePersons';
import useAuth from '../../../hooks/useAuth';
import { useDatePicker } from './ModelKanbanTaskAdd';
import { toDateTime } from 'src/utils/unixDateTime';
import { DisplayTime } from './KanbanTaskAdd';
import CardEditPopover from './CardEditPopover';
import { nameFilter } from '../../../utils/nameFilter';
import ColorSinglePicker from '../../../components/ColorSinglePicker';
import useAudioRecorder from '../../../hooks/useAudioRecorder';
import Label from 'src/components/Label';
import MusicPlayerSlider from '../../../components/MusicPlayerSlider';
import numGenerate from '../../../utils/numGenerate';
import { nanoid } from '@reduxjs/toolkit';
import { addCommentToTask } from '../../../redux/slices/kanban';
import { serverTime } from '../../../utils/serverTime';
import UploadingOneFileProgress from '../../../components/UploadingOneFileProgress';
import { TASK_STATE_VALIDATION } from 'src/constants';
import KanbanTaskCardValidation from './KanbanTaskCardValidation';
import KanbanTaskManager from './KanbanTaskManager';
import { EditorMentionEnter, MuiCustomInput } from '../../../components/CustomInput';
import { baseColors } from 'src/constants/color';
import { VALIDATION_COLOR, REJECTION_COLOR } from 'src/constants/kanban';
import { getSelectedProjectRights } from '../../../utils/project';
import { useProjectContext } from 'src/contexts/ProjectContext';
import EllipsisText from 'src/components/EllipsisText';
import DateTineRangeSelector from 'src/components/DateTineRangeSelector';
import { TaskDueDateGuardPrompt, useTaskDueDateGuard } from '../due_date/TaskDueDateGuard';
import UserPicker from 'src/components/UserPicker';
import { showEditorTaskTitleWithMention } from '../editor/helpers';
// ----------------------------------------------------------------------

const COLOR_OPTIONS = [
  'inherit',
  '#2D2C2C',
  '#817F7E',
  '#BFBDBD',
  '#091A7A',
  '#1939B7',
  '#3366FF',
  '#84A9FF',
  '#037EB5',
  '#07D7FC',
  '#69FEFD',
  '#CDFEF6',
  '#05D8CA',
  '#07fcd5',
  '#69FECA',
  '#CDFEE4',
  '#548156',
  '#26A11F',
  '#5FE03E',
  '#B0F58B',
  '#B27928',
  '#F8BF51',
  '#FCE196',
  '#FEF7DC',
  '#7A0844',
  '#B7154C',
  '#ff2b4b',
  '#FF847F'
];

KanbanTaskCard.propTypes = {
  card: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func
};

const PopoverMenuItem = styled(MenuItem)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.mode === 'light' ? '#000000' : '#FFFFFF', 1),
  marginBottom: 4,
  color: theme.palette.mode === 'light' ? '#FFFFFF' : '#000000',
  borderRadius: 5,
  '&: hover': {
    backgroundColor: alpha(theme.palette.mode === 'light' ? '#000000' : '#FFFFFF', 1),
    color: theme.palette.mode === 'light' ? '#FFFFFF' : '#000000'
  }
}));

const PopoverMenuItemBox = styled(Box)(({ theme }) => ({
  '&: hover': {
    paddingLeft: 8
  }
}));

function priorityColor(priority) {
  let color = '';
  switch (priority) {
    case 'BASE':
      color = baseColors.GREEN;
      break;
    case 'NORMALE':
      color = baseColors.BLUE;
      break;
    case 'HAUTE':
      color = baseColors.WARINIG;
      break;
    case 'URGENTE':
      color = baseColors.RED;
      break;
    default:
      break;
  }
  return color;
}

function dateConverter(due) {
  let start = due[0],
    end = due[1];

  if (start?.seconds) {
    start = toDateTime(start.seconds).toString();
  }
  if (end?.seconds) {
    end = toDateTime(end.seconds).toString();
  }

  return [start, end];
}

export function timerDisplay(sec) {
  if (sec < 60) {
    if (sec < 10) return `00:0${sec}`;
    return `00:${sec}`;
  }

  let ctime = sec / 60;
  let entertime = Number(Number(ctime).toFixed(0));

  return `${entertime < 10 ? '0' + entertime : entertime}:${sec - entertime * 60}`;
}

export default function KanbanTaskCard({
  card,
  onDeleteTask,
  onUpdate,
  index,
  projectCanAccess,
  project,
  isHidden = false
}) {
  //#region STATES
  const { onOpen } = useProjectContext();
  const { name, attachments, assignee, priority } = card;
  const [due, setDue] = useState(card?.due);
  const [uploading, setFUploading] = useState(false);
  const [voiceNote, setVoiceNote] = useState(null);
  const [voiceNoteBlob, setVoiceNoteBlob] = useState(null);
  const [openVoiceNote, setOpenVoiceNote] = useState(null);
  let { isRecording, recordingTime, startRecording, stopRecording } = useAudioRecorder({
    onNoteDone: setVoiceNote,
    getBlob: setVoiceNoteBlob
  });
  const voiceRef = useRef();
  const [voiceNoteTime, setVoiceNoteTime] = useState(0);
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [completed, setCompleted] = useState(card.completed);
  const anchorRef = useRef();
  const anchorRefDel = useRef();
  const [pending, setPending] = useState(false);
  const { open: hover, handleClose: closeHover, handleOpen: openHover } = useToggle();
  const { open, handleClose, handleOpen } = useToggle();
  const { open: openD, handleClose: handleCloseD, handleOpen: handleOpenD } = useToggle();
  const { open: openT, handleClose: handleCloseT, handleOpen: handleOpenT } = useToggle();

  const { open: openDl, handleClose: closeDelete } = useToggle();
  const anchorRef2 = useRef();
  const anchorRefCard = useRef();
  const [assigneeF, setAssigne] = useState(assignee);
  const { open: openAff, handleOpen: handleOpenAff, handleClose: handleCloseAff } = useToggle();

  const { open: openColor, handleOpen: handleOpenColor, handleClose: handleCloseColor } = useToggle();
  const anchorRefColor = useRef();

  const { open: openSubTitle, handleOpen: handleOpenSubTitle, handleClose: handleCloseSubTitle } = useToggle();
  const subTitleRef = useRef();

  const [subTitle, setSubTitle] = useState(card?.subTitle || '');
  const [cColor, setColor] = useState(card?.etiquettes || '');
  const [popoverSearch, setPopoverSearch] = useState('');

  const {
    user: { email, id, displayName, role, photoURL }
  } = useAuth();
  const { persons, loading: userLoading } = usePersons({ email: email });

  const dispatch = useDispatch();
  const [isUploading, setUploading] = useState(false);

  const [title, setTitle] = useState(name);
  const [mentions, setMentions] = useState(card?.editor_mentions || []);
  const [canEdit, setCanEdit] = useState(false);
  const renameRef = useRef(null);

  const { open: openMan, handleClose: handleCloseMan, handleOpen: handleOpenMan } = useToggle();
  const { user } = useAuth();
  const rigths = getSelectedProjectRights(project, user);
  const anchorElD = useRef();
  const anchorElD2 = useRef();
  //#endregion

  //#region FUNCTIONS
  const {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate,
    openPicker,
    onOpenPicker,
    onClosePicker
  } = useDatePicker({
    date: dateConverter(due)
  });

  const users = useMemo(() => {
    if (role?.isGuest) {
      return filter(projectCanAccess || [], (_user) => persons.find((_pers) => _pers.email === _user.email));
    }
    return persons;
  }, [persons, role, projectCanAccess]);

  const isManager = useMemo(() => {
    if (project?.managers?.find((_one) => _one.email === email || _one.id === id)) {
      return true;
    }

    if (!project?.managers?.length && (card?.createdBy?.email === email || card?.createdBy?.id === id)) {
      return true;
    }

    return false;
  }, [project?.managers, card?.createdBy, email, id]);

  useEffect(() => {
    if (canEdit) {
      if (renameRef !== null) {
        renameRef.current.focus();
        renameRef.current.selectionStart = title.length;
      }
    }
  }, [canEdit, title]);

  useEffect(() => {
    setDue(card.due);
    onChangeDueDate(dateConverter(card?.due));
    setCompleted(card.completed);
    setAssigne(card.assignee);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [card]);

  const { validateNewDue, ...params } = useTaskDueDateGuard(card, onUpdate);

  const popoverFilterFunction = (users) => {
    const sort_user = orderBy(users, 'displayName');
    return nameFilter(sort_user, 'displayName', popoverSearch);
  };

  const backdropClose = () => {
    handleClose();
    handleCloseD();
    handleCloseT();
  };

  const handleAllowEtiquet = () => {
    handleOpenT();
  };

  const handleOpenDetails = () => {
    backdropClose();
    onOpen(card);
  };

  const handleDueDateClose = (_val) => {
    onClosePicker();

    if (validateNewDue(_val) && !isEqual(_val, dateConverter(due))) {
      onUpdate({ ...card, due: _val });
    }
  };

  //eslint-disable-next-line
  const handleChangeComplete = (event) => {
    const cmplt = event.target.checked;
    setCompleted(cmplt);
    onUpdate({ ...card, completed: cmplt, progress: cmplt ? 100 : card.progress === 100 ? 95 : card.progress });
  };

  const handleUpdateCardTitle = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      if (card.name !== title) {
        renameRef.current.blur();
        onUpdate({ ...card, assignee: assigneeF, name: title });
        setCanEdit(false);
      }
    }
  };

  const handleUpdateCardTitleAwzy = () => {
    if (card.name !== title) {
      onUpdate({ ...card, assignee: assigneeF, name: title });
    }
    setCanEdit(false);
  };

  const handleDeleteCard = () => {
    setPending(true);
    onDeleteTask(card.id);
    closeDelete();
    setPending(false);
    handleClose();
  };

  const handleOpenWhenNotEdited = () => {
    if (!canEdit) {
      handleOpenDetails();
    }
  };

  const handleAllowCanEdit = () => {
    backdropClose();
    setCanEdit(true);
  };

  const backdropOpen = () => {
    handleOpen();
    handleOpenD();
  };

  const handleUpdateEtiquete = (priority) => {
    onUpdate({ ...card, priority: priority, assignee: assigneeF });
  };

  const handleAssigne = (_, _value) => {
    const val = _value.map((newValue) => {
      return {
        id: newValue.id,
        name: newValue?.displayName || newValue?.name || null,
        email: newValue.email,
        avatar: newValue?.photoURL || newValue?.photoUrl || newValue?.avatar || null
      };
    });
    setAssigne(val);
  };

  const handleUpdateOnClosePopover = (_assigneFF) => {
    if (!isEqual(_assigneFF, assignee)) {
      onUpdate({ ...card, assignee: _assigneFF });
    }
    handleCloseAff();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const onFileDrop = (acceptedFiles) => {
    setUploading(true);
    const handleFiles = (newfiles) => {
      const user_to_files = newfiles.map((_file) => {
        return {
          ..._file,
          email,
          userId: id,
          displayName,
          createAt: new Date()
        };
      });
      onUpdate({ ...card, attachments: [...attachments, ...user_to_files] });
      dispatch(SaveTaskDocs(newfiles));
      setUploading(false);
    };
    multipleFilesSave(acceptedFiles, handleFiles);
  };

  const onHandleEdiSave = () => {
    backdropClose();
    onUpdate({ ...card, name: title, assignee: assigneeF, due: dueDate });
  };

  const onhandleCloseSubTitle = () => {
    handleCloseSubTitle();
    if (subTitle !== card?.subTitle) {
      onUpdate({ ...card, name: title, assignee: assigneeF, due: dueDate, subTitle });
    }
  };

  const handleChangeColor = (event) => {
    setColor(event.target.value);
    onUpdate({ ...card, name: title, assignee: assigneeF, due: dueDate, subTitle, etiquettes: event.target.value });
  };

  useEffect(() => {
    if (state?.detailId) {
      if (state?.detailId === card?.id) {
        navigate(pathname, { replace: true, state: { detailId: null } });
        handleOpenDetails();
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.detailId]);

  //#endregion

  //#region VOICE NOTE FUNCTIONS
  useEffect(() => {
    if (voiceNote) {
      return setOpenVoiceNote(true);
    }
    setOpenVoiceNote(false);
  }, [voiceNote]);

  const onRecordingClick = () => {
    if (isRecording) {
      setVoiceNoteTime(recordingTime);
      stopRecording();
      return;
    }
    startRecording();
  };

  const onCloseVoiceNote = () => {
    setOpenVoiceNote(false);
  };

  const onSendVoiceNote = () => {
    onCloseVoiceNote();

    const docId = nanoid();
    const onAudioSave = (_file) => {
      setFUploading(false);

      const commentObject = {
        userId: id,
        avatar: photoURL,
        name: displayName,
        email: email,
        messageType: 'audio',
        createdAt: serverTime(),
        message: _file?.url,
        read: 0,
        readState: []
      };

      dispatch(
        addCommentToTask({
          taskId: card?.id,
          generateId: docId,
          commentBbject: commentObject,
          taskName: card?.name,
          canAccess: [...(card?.canAccess || []), ...(card?.managers || [])],
          callback: () => {
            enqueueSnackbar('Note vocale ajoutée', { variant: 'info' });
            setVoiceNote(null);
            setVoiceNoteBlob(null);
          }
        })
      );
    };
    const fileName = 'Note vocale ' + numGenerate();
    const file = new File([voiceNoteBlob], fileName, { type: 'audio/webm' });
    saveFile(file, onAudioSave, null, setFUploading, docId);
  };

  const cancelVoiceNote = () => {
    onCloseVoiceNote();
  };
  //#endregion

  //#region STATE VALIDATION OF TASK
  const handleValidateTask = (_state) => {
    if (_state === TASK_STATE_VALIDATION.ACCEPTED) {
      onUpdate({ ...card, state: _state, completed: true });
      return;
    }
    if (_state === TASK_STATE_VALIDATION.DONE) {
      handleOpenMan();
      return;
    }
    onUpdate({ ...card, state: _state, completed: false });
  };
  //#endregion

  //#region MANAGERS
  const closeManage = (newValues, autoValidate) => {
    handleCloseMan();
    if (newValues?.length) {
      onUpdate({ ...card, managers: newValues, state: TASK_STATE_VALIDATION.DONE, completed: false });
      return;
    }
    if (autoValidate) {
      onUpdate({ ...card, managers: [], state: TASK_STATE_VALIDATION.DONE, completed: false });
    }
  };
  //#endregion

  const isClosed = useMemo(() => {
    return (
      completed || card?.state === TASK_STATE_VALIDATION.ACCEPTED || card?.state === TASK_STATE_VALIDATION.REJECTED
    );
  }, [card?.state, completed]);

  const closureColor = useMemo(() => {
    if (completed || card?.state === TASK_STATE_VALIDATION.ACCEPTED) return VALIDATION_COLOR;
    else if (card?.state === TASK_STATE_VALIDATION.REJECTED) return REJECTION_COLOR;
  }, [card?.state, completed]);

  const handleChangeDate = (date) => {
    if (validateNewDue(date)) onChangeDueDate(date);
  };

  const handleChangeTitle = ({ newValue, mentions }) => {
    setTitle(newValue);
    setMentions(mentions);
  };

  return (
    <>
      <Draggable draggableId={card.id} index={index}>
        {(provided) => (
          <div
            onMouseEnter={openHover}
            onMouseLeave={closeHover}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Collapse in={!isHidden}>
              <Dropzone onDrop={(acceptedFiles) => onFileDrop(acceptedFiles)}>
                {({ getRootProps }) => (
                  <Badge.Ribbon
                    style={{
                      fontSize: 11,
                      ...((isClosed || card.priority === '' || card.priority === null) && { display: 'none' })
                    }}
                    color={priorityColor(card.priority)}
                    text={card?.priority}
                  >
                    <Paper
                      {...getRootProps()}
                      ref={anchorRefCard}
                      variant="outlined"
                      sx={{
                        pl: 1.5,
                        pr: 1,
                        width: 1,
                        minHeight: 110,
                        position: 'relative',
                        '&:hover': {
                          boxShadow: (theme) => theme.customShadows.z1
                        },
                        ...(attachments.length > 0 && {
                          pt: 2
                        }),
                        ...(isClosed && {
                          opacity: 0.48,
                          // border: `2px solid ${closureColor} `,
                          borderRadius: 1,
                          cursor: 'pointer'
                        })
                      }}
                      onClick={
                        (completed || card?.state === TASK_STATE_VALIDATION.ACCEPTED) && isManager
                          ? handleOpenWhenNotEdited
                          : undefined
                      }
                    >
                      <TaskDueDateGuardPrompt {...params} />
                      <Box>
                        {isClosed && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              bgcolor: closureColor,
                              color: 'white',
                              textAlign: 'center',
                              p: 0.1,
                              fontSize: 11,
                              px: 2,
                              borderRadius: '5px 0 0 0'
                            }}
                          >
                            {card?.state}
                          </Box>
                        )}

                        <Stack onClick={handleOpenWhenNotEdited} sx={{ cursor: 'pointer' }}>
                          {attachments.length > 0 && attachments[0].type.includes('image') && (
                            <Box
                              sx={{
                                pt: '60%',
                                borderRadius: 1,
                                overflow: 'hidden',
                                position: 'relative',
                                transition: (theme) =>
                                  theme.transitions.create('opacity', {
                                    duration: theme.transitions.duration.shortest
                                  }),
                                ...(completed && {
                                  opacity: 0.48
                                })
                              }}
                            >
                              <Box component="img" src={attachments[0].url} sx={{ position: 'absolute', top: 0 }} />
                            </Box>
                          )}

                          {!canEdit && (
                            <Typography
                              color={(t) => (t.palette.mode === 'light' ? '#1f2124' : '#FFFFFF')}
                              fontSize={16}
                              textAlign="left"
                              sx={{
                                pt: 2,
                                pb: 1,
                                pr: 4,
                                whiteSpace: 'pre-line',
                                transition: (theme) =>
                                  theme.transitions.create('opacity', {
                                    duration: theme.transitions.duration.shortest
                                  }),
                                ...(completed && { opacity: 0.8 })
                              }}
                            >
                              {showEditorTaskTitleWithMention(name, card?.editor_mentions)}
                            </Typography>
                          )}

                          {canEdit && (
                            <ClickAwayListener onClickAway={handleUpdateCardTitleAwzy}>
                              <EditorMentionEnter
                                inputRef={renameRef}
                                placeholder="Titre"
                                value={title}
                                onChange={handleChangeTitle}
                                onKeyUp={handleUpdateCardTitle}
                              />
                            </ClickAwayListener>
                          )}
                        </Stack>

                        {!completed && (
                          <Box
                            ref={anchorRef}
                            sx={{
                              position: 'absolute',
                              top: 0,
                              right: 0,
                              mb: 0.5,
                              zIndex: (t) => 500
                            }}
                          >
                            {hover && (
                              <Tooltip title="">
                                <IconButton size="medium" onClick={backdropOpen}>
                                  <Icon icon={EditFill} height={20} width={20} />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        )}

                        {!completed && (
                          <Stack direction="column" sx={{ pb: 0.5 }}>
                            <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
                              <Stack direction="row" ref={anchorRef2} onClick={handleOpenAff}>
                                <DAvatar.Group maxCount={2} size="small">
                                  {assigneeF.length > 0 ? (
                                    assigneeF.map((pers, idx) => (
                                      <Tooltip key={idx} title={pers.name}>
                                        <DAvatar
                                          src={pers.avatar}
                                          alt={pers.name}
                                          style={{
                                            backgroundColor: createAvatar(pers?.name || pers?.displayName).color2
                                          }}
                                        >
                                          {createAvatar(pers.name).name}
                                        </DAvatar>
                                      </Tooltip>
                                    ))
                                  ) : (
                                    <Tooltip title="Assigner" arrow>
                                      <MIconButton size="small">
                                        <Icon icon={peopleFill} width={15} height={15} />
                                      </MIconButton>
                                    </Tooltip>
                                  )}
                                </DAvatar.Group>
                              </Stack>

                              <Stack
                                ref={anchorElD}
                                direction="row"
                                alignItems="center"
                                flexWrap="wrap"
                                spacing={0.5}
                                justifyContent="flex-end"
                              >
                                {startTime && endTime ? (
                                  <DisplayTime
                                    startTime={startTime}
                                    endTime={endTime}
                                    isSameDays={isSameDays}
                                    isSameMonths={isSameMonths}
                                    onOpenPicker={onOpenPicker}
                                  />
                                ) : (
                                  <Tooltip title="Date de réalisation" arrow>
                                    <MIconButton size="small" onClick={onOpenPicker}>
                                      <Icon icon={calendarFill} width={20} height={20} />
                                    </MIconButton>
                                  </Tooltip>
                                )}

                                {
                                  <KanbanTaskCardValidation
                                    admin={card?.createdBy}
                                    card={card}
                                    userId={id}
                                    managers={card?.managers}
                                    state={card?.state}
                                    intervenants={assigneeF}
                                    taskId={card?.id}
                                    taskName={card?.name}
                                    handleChangeState={handleValidateTask}
                                  />
                                }

                                {!openD && openPicker && (
                                  <DateTineRangeSelector
                                    open={openPicker}
                                    anchorEl={anchorElD.current}
                                    onClose={handleDueDateClose}
                                    onChange={handleChangeDate}
                                    deb={startTime}
                                    end={endTime}
                                  />
                                )}
                              </Stack>
                            </Stack>

                            <Stack direction="row" justifyContent="space-between" sx={{ mb: -1 }}>
                              {card?.subTitle && card?.subTitle.trim() !== '' ? (
                                <Stack
                                  onClick={handleOpenSubTitle}
                                  ref={subTitleRef}
                                  sx={{ cursor: 'pointer' }}
                                  maxWidth={0.5}
                                >
                                  <EllipsisText
                                    text={card?.subTitle}
                                    style={{
                                      color: cColor.trim() !== '' ? cColor : 'inherit',
                                      fontSize: 12
                                    }}
                                    placement="bottom"
                                    material
                                  />
                                </Stack>
                              ) : (
                                <Tooltip title="Etiquettes">
                                  <MIconButton disabled={!rigths?.edit} onClick={handleOpenSubTitle} ref={subTitleRef}>
                                    <Icon icon={EditFill2} width={15} height={15} />
                                  </MIconButton>
                                </Tooltip>
                              )}

                              <Stack direction="row" spacing={1} justifyContent="flex-end" alignItems="center" mb={0.5}>
                                <Stack>
                                  <IconButton size="small">
                                    <Badge size="small" count={card?.attachments?.length}>
                                      <Tooltip
                                        title={
                                          card?.attachments?.length === 0
                                            ? 'Aucun fichier'
                                            : `${card?.attachments?.length} fichier${
                                                card?.attachments?.length > 1 ? 's' : ''
                                              }`
                                        }
                                        arrow
                                      >
                                        <FileTextFilled style={{ color: baseColors.GREY }} />
                                      </Tooltip>
                                    </Badge>
                                  </IconButton>
                                </Stack>

                                {recordingTime && (
                                  <Label color="info" style={{ height: 17 }}>
                                    <Typography fontSize={12}> {timerDisplay(recordingTime)} </Typography>
                                  </Label>
                                )}

                                <Tooltip title="Note vocal" arrow>
                                  <MIconButton size="small" onClick={onRecordingClick} ref={voiceRef}>
                                    <Icon
                                      icon={isRecording ? audioFill : audioOffFill}
                                      {...(isRecording && { color: 'red' })}
                                      width={17}
                                      height={17}
                                    />
                                  </MIconButton>
                                </Tooltip>
                              </Stack>
                            </Stack>
                          </Stack>
                        )}

                        {isUploading && (
                          <Stack
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                              position: 'absolute',
                              bottom: 0,
                              top: 0,
                              left: 0,
                              right: 0,
                              bgcolor: alpha('#FFFFFF', 0.6),
                              zIndex: (theme) => theme.zIndex.drawer + 1
                            }}
                          >
                            <CircularProgress size={30} />
                          </Stack>
                        )}
                      </Box>
                      {cColor !== 'inherit' && cColor !== null && cColor !== '' && (
                        <Stack
                          sx={{
                            bgcolor: cColor,
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            width: 5,
                            zIndex: (t) => t.zIndex.appBar + 1
                          }}
                        />
                      )}
                    </Paper>
                  </Badge.Ribbon>
                )}
              </Dropzone>
            </Collapse>
          </div>
        )}
      </Draggable>

      {openMan && (
        <KanbanTaskManager
          users={[]}
          managers={card?.responsable ? [card?.responsable] : project?.managers || []}
          open={openMan}
          onClose={closeManage}
        />
      )}

      {uploading && <UploadingOneFileProgress uploading={uploading} />}

      <MenuPopoverCenter
        arrow="top-left"
        sx={{ width: 'max-content' }}
        open={openSubTitle}
        onClose={onhandleCloseSubTitle}
        anchorEl={subTitleRef.current}
      >
        <Stack spacing={2}>
          <Stack sx={{ p: 0.5 }}>
            <MuiCustomInput
              label=" "
              inputProps={{
                maxLenght: 30
              }}
              maxLenght={30}
              fullWidth
              size="small"
              placeholder="Etiquettes"
              value={subTitle}
              onChange={(e) => setSubTitle(e.currentTarget.value)}
            />
          </Stack>

          <Box sx={{ maxWidth: 300 }}>
            <ColorSinglePicker selected={cColor} onChange={handleChangeColor} colors={COLOR_OPTIONS} />
          </Box>
        </Stack>
      </MenuPopoverCenter>

      <UserPicker
        anchorEl={anchorRef2}
        open={openAff}
        onClose={handleUpdateOnClosePopover}
        onChange={handleAssigne}
        users={assigneeF || []}
        restrictToProjectFromTask={card}
      />

      <MenuPopover sx={{ width: 500 }} open={openDl} onClose={closeDelete} anchorEl={anchorRefDel.current}>
        <NotistackProvider>
          <Alert severity="error">Vous êtes sur le point de supprimer cette tâche, Continuer ?</Alert>
        </NotistackProvider>
        <Stack direction="row" spacing={3} sx={{ p: 1, float: 'right' }}>
          <Button color="info" disabled={pending} onClick={closeDelete}>
            {' '}
            Non, annuler
          </Button>
          <Button color="error" disabled={pending} onClick={handleDeleteCard}>
            {pending ? <CircularProgress color="primary" size={20} /> : ' Oui, supprimer'}
          </Button>
        </Stack>
      </MenuPopover>

      <Backdrop
        open={open}
        onClose={() => {
          alert('ok');
          handleClose();
        }}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <KanbanMenuPopover
          open={openT}
          onClose={handleCloseT}
          anchorEl={anchorRef.current}
          sx={{ width: 'max-content' }}
        >
          <Etiquettes priority={priority} handleUpdate={handleUpdateEtiquete} />
        </KanbanMenuPopover>

        <KanbanMenuPopover
          open={openColor}
          onClose={handleCloseColor}
          anchorEl={anchorRefColor.current}
          sx={{ width: 150 }}
        >
          <ColorSinglePicker onChange={handleChangeColor} colors={COLOR_OPTIONS} />
        </KanbanMenuPopover>

        <CardEditPopover
          open={openD}
          onClose={backdropClose}
          sx={{ bgcolor: 'transparent', border: 'none' }}
          anchorEl={anchorRefCard.current}
        >
          <Stack direction="row" spacing={2} sx={{ bgcolor: 'transparent' }}>
            <Stack spacing={2}>
              <Dropzone onDrop={(acceptedFiles) => onFileDrop(acceptedFiles)}>
                {({ getRootProps }) => (
                  <Paper
                    {...getRootProps()}
                    elevation={3}
                    square
                    sx={{
                      px: 2,
                      width: 360,
                      boxShadow: (theme) => theme.customShadows.z1,
                      '&:hover': {
                        boxShadow: (theme) => theme.customShadows.z16
                      },
                      ...(attachments.length > 0 && {
                        pt: 2
                      })
                    }}
                  >
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bgcolor: priorityColor(card.priority),
                        color: 'white',
                        textAlign: 'center',
                        p: 0.1,
                        fontSize: 9,
                        px: 2
                        // borderRadius:'5px 0 0 0'
                      }}
                    >
                      {card.priority}
                    </Box>

                    <Box>
                      {attachments.length > 0 && attachments[0].type.includes('image') && (
                        <Box
                          sx={{
                            pt: '60%',
                            borderRadius: 1,
                            overflow: 'hidden',
                            position: 'relative',
                            transition: (theme) =>
                              theme.transitions.create('opacity', {
                                duration: theme.transitions.duration.shortest
                              }),
                            ...(completed && {
                              opacity: 0.48
                            })
                          }}
                        >
                          <Box
                            component="img"
                            src={attachments[0].url}
                            sx={{ position: 'absolute', top: 0, width: 1, height: 1 }}
                          />
                        </Box>
                      )}

                      <EditorMentionEnter size="small" placeholder="Titre" value={title} onChange={handleChangeTitle} />
                    </Box>

                    <Box ref={anchorRef} sx={{ position: 'absolute', top: 0, right: 0, mb: 0.5 }}>
                      {hover && (
                        <Tooltip title="">
                          <IconButton size="medium" onClick={backdropOpen}>
                            <Icon icon={EditFill} height={20} width={20} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </Box>

                    <Stack direction="row" sx={{ pb: 0.5 }} justifyContent="flex-end" spacing={1}>
                      <Stack direction="row" ref={anchorRef2} onClick={handleOpenAff}>
                        {assigneeF.length > 0 ? (
                          assigneeF.slice(0, 5).map((pers, idx) => (
                            <Tooltip key={idx} title={pers.name}>
                              <MAvatar
                                src={pers.avatar}
                                alt={pers.name}
                                color={pers.avatar ? 'default' : createAvatar(pers.name).color}
                                sx={{ height: 25, width: 25, fontSize: 13 }}
                              >
                                {createAvatar(pers.name).name}
                              </MAvatar>
                            </Tooltip>
                          ))
                        ) : (
                          <Tooltip title="Assigner">
                            <MIconButton size="small">
                              <Icon icon={peopleFill} width={20} height={20} />
                            </MIconButton>
                          </Tooltip>
                        )}
                        {assigneeF.length > 5 && '...'}
                      </Stack>

                      <Stack ref={anchorElD2} direction="row" alignItems="center">
                        {startTime && endTime ? (
                          <DisplayTime
                            startTime={startTime}
                            endTime={endTime}
                            isSameDays={isSameDays}
                            isSameMonths={isSameMonths}
                            onOpenPicker={onOpenPicker}
                          />
                        ) : (
                          <Tooltip title="Date de réalisation">
                            <MIconButton size="small" onClick={onOpenPicker}>
                              <Icon icon={calendarFill} width={20} height={20} />
                            </MIconButton>
                          </Tooltip>
                        )}
                        {openPicker && (
                          <DateTineRangeSelector
                            open={openPicker}
                            anchorEl={anchorElD2.current}
                            onClose={handleDueDateClose}
                            onChange={handleChangeDate}
                            deb={startTime}
                            end={endTime}
                          />
                        )}
                      </Stack>
                    </Stack>

                    {isUploading && (
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          position: 'absolute',
                          bottom: 0,
                          top: 0,
                          left: 0,
                          right: 0,
                          bgcolor: alpha('#FFFFFF', 0.6),
                          zIndex: (theme) => theme.zIndex.drawer + 1
                        }}
                      >
                        <CircularProgress size={30} />
                      </Stack>
                    )}

                    <MenuPopover sx={{ p: 0.5 }} open={openAff} onClose={handleCloseAff} anchorEl={anchorRef2.current}>
                      <TextField
                        fullWidth
                        label=" "
                        size="small"
                        value={popoverSearch}
                        onChange={(e) => setPopoverSearch(e.currentTarget.value)}
                      />
                      <Box className="scroll-smooth-small" sx={{ maxHeight: 170, overflowY: 'auto' }}>
                        {userLoading ? (
                          <CircularProgress size={20} />
                        ) : (
                          users &&
                          popoverFilterFunction(users).map((user) => {
                            const exist = assigneeF.find((ob) => ob.email === user.email);
                            return (
                              <MenuItem
                                key={user.id}
                                sx={{
                                  py: 0.75,
                                  px: 1.5,
                                  bgcolor: (theme) => exist && alpha(theme.palette.primary.main, 0.5)
                                }}
                                onClick={() => handleAssigne(assigneeF, user)}
                              >
                                <MAvatar
                                  sx={{
                                    width: (theme) => theme.spacing(3),
                                    height: (theme) => theme.spacing(3),
                                    fontSize: 10
                                  }}
                                  src={user?.photoURL}
                                  alt={user?.displayName}
                                  color={user?.photoURL ? 'default' : createAvatar(user.displayName).color}
                                >
                                  {createAvatar(user?.displayName).name}
                                </MAvatar>
                                <Box sx={{ m: 1 }} />
                                <Typography variant="body2"> {user?.displayName} </Typography>
                              </MenuItem>
                            );
                          })
                        )}
                      </Box>
                    </MenuPopover>
                  </Paper>
                )}
              </Dropzone>

              <Button variant="contained" color="info" size="small" onClick={onHandleEdiSave}>
                Enregister
              </Button>
            </Stack>

            <Stack width={1}>
              <Box sx={{ bgcolor: 'transparent' }}>
                <PopoverMenuItemBox>
                  <PopoverMenuItem onClick={handleOpenDetails}>
                    <Typography variant="caption"> Ouvrir </Typography>
                  </PopoverMenuItem>
                </PopoverMenuItemBox>

                <PopoverMenuItemBox>
                  <PopoverMenuItem onClick={handleAllowCanEdit}>
                    <Typography variant="caption"> Editer </Typography>
                  </PopoverMenuItem>
                </PopoverMenuItemBox>

                <PopoverMenuItemBox>
                  <PopoverMenuItem onClick={handleAllowEtiquet}>
                    <Typography variant="caption"> Modifier la priorité </Typography>
                  </PopoverMenuItem>
                </PopoverMenuItemBox>

                <PopoverMenuItemBox>
                  <PopoverMenuItem onClick={handleOpenColor} ref={anchorRefColor}>
                    <Typography variant="caption"> Etiquettes </Typography>
                  </PopoverMenuItem>
                </PopoverMenuItemBox>
              </Box>
            </Stack>
          </Stack>
        </CardEditPopover>
      </Backdrop>

      {openVoiceNote && (
        <MenuPopover open={openVoiceNote} onClose={cancelVoiceNote} anchorEl={voiceRef.current}>
          <MusicPlayerSlider song={voiceNote} duration={voiceNoteTime} />
          <Stack direction="row" justifyContent="space-between" sx={{ p: 1 }}>
            <Button
              color="inherit"
              size="small"
              sx={{ bgcolor: (t) => alpha(t.palette.secondary.main, t.palette.action.hoverOpacity) }}
              onClick={cancelVoiceNote}
            >
              Annuler
            </Button>
            <Button
              color="success"
              size="small"
              sx={{ bgcolor: (t) => alpha(t.palette.success.main, t.palette.action.hoverOpacity) }}
              onClick={onSendVoiceNote}
            >
              Envoyer
            </Button>
          </Stack>
        </MenuPopover>
      )}
    </>
  );
}
