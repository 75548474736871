import { useState } from 'react'
import { 
    Box,
    Dialog, 
    Stack, 
    Typography, 
    IconButton, 
    DialogTitle, 
    DialogContent, 
    DialogActions, 
    Button, 
    Divider,
    Checkbox, 
    FormControlLabel
} from '@mui/material';
import ClosedIcon from '@mui/icons-material/Close';


export default function KanbanSendInvitationConfimation({open, onClose, invite=[], validation=null, onCancel=null}) {
    const [guest, setGuest] = useState([...invite]);
    const [isvalid, setIsValid] = useState(false);

    const handleCheckedGuest = (e, email) =>{
        const nguest = [...guest]
        const exist = nguest.find(_g => _g.email === email);
        exist.status = e;
        setGuest(nguest);
        if(nguest.find(one => one?.status)){
          return  setIsValid(true);
        }
        return setIsValid(false);
    }

    const onHandleValidate = () =>{
        const accepted = guest.filter((g) => g?.status);
        validation && validation(accepted);
        onClose();
    }


    const onHandleCancel = () => {
        validation && validation([]);
        onCancel && onCancel();
        onClose();
    }

    return (
        <Dialog  fullWidth maxWidth="xs" open={open} disableEscapeKeyDown  onBackdropClick={onHandleCancel} onClose={onHandleCancel} sx={{ zIndex: t => t.zIndex.modal + 3 }}>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between">
                    <Typography variant="overline" fontSize="17">
                        Invitation
                    </Typography>

                    <IconButton color="error" onClick={onHandleCancel} sx={{mt: -2}}>
                        <ClosedIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{mt: 2}}>
                <Typography variant="body2" align="center">
                    Êtes-vous certain de vouloir inviter le{guest.length>1 ? 's' :''} contact{guest.length>1 ? 's' :''} 
                </Typography>
                <Typography variant="body2" align="center">
                    externe{guest.length>1 ? 's':''} ci-dessous à rejoindre ce projet ?
                </Typography>

                <Box sx={{height:20}} />

                {guest && guest.map((inv, idx) => (
                    <Box key={idx}>
                        <Stack width={1}  direction="row" alignItems="center" justifyContent="space-between">
                            <Typography variant="overline"> {inv?.name || inv?.society || inv?.email} </Typography>
                            <FormControlLabel control={
                                <Checkbox 
                                    checked={inv?.status || false}
                                    onChange={(e)=>handleCheckedGuest(e.target.checked, inv.email)} 
                                    color={inv?.status ? 'success' : 'error'}
                                    />
                                    } 
                                label={inv?.status ? 'accepté': 'refusé'} /> 
                        </Stack>
                        <Divider light  />
                    </Box>
                ))} 
            </DialogContent>

            <DialogActions>
                <Button variant="contained" color="info" onClick={onHandleCancel}>Annuler</Button>
                <Button variant="outlined" color="info" onClick={onHandleValidate} disabled={!isvalid}>Valider</Button>
            </DialogActions>
        </Dialog>
    )
}
