import { useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'src/redux/store';
import { useFirestoreConnect } from 'react-redux-firebase';

export default function useLogo() {

    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
  
    const logo = useSelector(state => state.firestore.ordered.logo_url);

    const logo_url = useMemo(()=>{
      if(logo){
        if(logo.length > 0){
          const obj = logo[0];
          const currentLogo = obj[`logo_${isLight ? 'light' : 'dark'}`];
          return currentLogo?.url || ``;
        }
      } 
      return ``
    },[logo, isLight]);
    
    useFirestoreConnect(()=> [
      {collection: 'logo', doc: 'logo', storeAs: 'logo_url'}
    ]);
    
    localStorage.setItem('s_logo', logo_url);
    return { logo_url }
}
