import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import firestore from '../../utils/firestore';
import { omit, reject, uniq, uniqBy } from 'lodash';
import { auth } from '../../contexts/FirebaseContext';
import { NOTIFICATION_TYPES } from '../../constants';
import { PATH_DASHBOARD } from '../../routes/paths';
import axios from 'axios';
import axiosRequest from './../../utils/axiosRequest';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';
import { TASK_STATE_VALIDATION } from 'src/constants';
import { serverTime } from 'src/utils/serverTime';
import { getCurrentUserAccess } from 'src/helpers/user';
import { MAIL_CONFIG } from 'src/config';
import { compareArraysOfObjects } from 'src/utils/changeOnObject';

const { subject, from, domaine } = MAIL_CONFIG;

const slice = createSlice({
  name: 'notifications',
  initialState: {
    isLoading: false,
    error: false,
    tokens: [],
    notifications: []
  },
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error(action.payload);
    },

    gotSuccess(state) {
      state.isLoading = false;
    },

    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    },

    deleteNotification(state, action) {
      state.notifications = reject(state.notifications, { id: action.payload });
    },

    setTokens(state, action) {
      state.tokens = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;
// Actions
export const { startLoading, gotSuccess, hasError, getNotificationsSuccess } = slice.actions;

//#region async actions
export const setTokens = (users) => {
  return async (dispatch) => {
    const tokens = users
      .filter((u) => u?.token || u?.mobileToken)
      .map((us) => {
        return { id: us.id, token: us.token, mobileToken: us?.mobileToken };
      });
    dispatch(slice.actions.setTokens(tokens));
  };
};

export const getAllNotification = (list, callback = null) => {
  return async (dispatch) => {
    dispatch(startLoading());
    try {
      dispatch(getNotificationsSuccess(list));
      callback && callback();
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

const sendFirebaseNotification = async (getState, data, canAccessWithoutSender) => {
  const allTokens = getState().notifications.tokens;

  const tokensWithoutSender = allTokens.filter((one) => one.id !== auth.currentUser.uid);

  const canReceivNotification = [...canAccessWithoutSender].map((id) => {
    return [...tokensWithoutSender].find((tk) => tk.id === id);
  });

  const tokens = [];
  const mobileTokens = [];

  canReceivNotification.forEach((us) => {
    if (us?.token) {
      tokens.push(us.token);
    }
    if (us?.mobileToken) {
      mobileTokens.push(us?.mobileToken);
    }
  });

  const desc = {
    createdAt: data?.createdAt,
    type: data?.type,
    cardTitle: data?.cardTitle,
    title: data?.title,
    by: data?.by,
    description: data?.description,
    other: { ...(data?.other || { projectName: data?.action?.projectName }) },
    action: data?.returnId && {
      id: data?.returnId,
      sub: data?.action?.projectKey,
      ...(data?.action?.title && { title: data?.action?.title }),
      ...(data?.action?.messageId && { messageId: data?.action?.messageId }),
      ...(data?.action?.messageTitle && { messageTitle: data?.action?.messageTitle })
    },
    url: data?.action?.url
  };

  if (tokens.length) {
    await axios.post(`${process.env.REACT_APP_FBNOTIF}/many`, {
      tokens,
      mobileTokens,
      title: data.title,
      description: data?.description,
      data: desc
    });
  }
  if (mobileTokens.length) {
    await axios.post(`${process.env.REACT_APP_FBNOTIF}/many-mobile`, {
      tokens,
      mobileTokens,
      title: data.title,
      description: data?.description,
      data: desc
    });
  }
};

/**
 *
 * @param {{
 * data:{
 *      createdAt: Date,
 *      isUnRead: string[],
 *      canAccessEmail: string[],
 *      canAccess: string[],
 *      description: string,
 *      title: string,
 *      type: string
 *      action: {url: string?, title: string?, id: string?, sub: string?},
 *      by: {
 *          id: string,
 *          displayName: string,
 *          photoURL: string,
 *      },
 * },
 * callback: Function
 * }}
 * @returns {(function(*): Promise<void>)|*}
 */
export const createNotification = ({ data, callback }) => {
  return async (dispatch, getState) => {
    dispatch(startLoading());

    try {
      const { canAccess } = data;

      const canAccessWithoutSender = canAccess.filter((id) => id !== auth.currentUser.uid);

      await firestore.collection('notifications').add({
        ...{ ...data, canAccess: canAccessWithoutSender },
        by: {
          id: auth.currentUser.uid,
          displayName: auth.currentUser.displayName || '',
          photoURL: auth.currentUser.photoURL || ''
        }
      });

      sendFirebaseNotification(getState, data, canAccessWithoutSender);

      dispatch(gotSuccess());
      callback && callback();
    } catch (error) {
      console.log(error);
      dispatch(hasError(error));
    }
  };
};

export const setNotificationsAsRead = createAsyncThunk(
  'notification/mark_as_read',
  async ({ notificationIds = [] }, { dispatch, getState }) => {
    try {
      const {
        notifications: { notifications: list }
      } = getState();

      const userId = getCurrentUserAccess()?.id;
      const batch = firestore.batch();

      // console.log('notificationIds', notificationIds)

      notificationIds.forEach((id) => {
        const ref = firestore.collection('notifications').doc(id);
        batch.set(ref, { isUnRead: { [userId]: false } }, { mergeFields: [`isUnRead.${userId}`] });
      });

      await batch.commit();

      // for (const one of notificationIds) {
      //     const {isUnRead, id} = list.find(notif => notif.id === one);
      //
      //     let result = {
      //         [auth.currentUser.uid]: false
      //     };
      //
      //     if (isUnRead) result = {...isUnRead, ...result};
      //
      //     await firestore.collection('notifications').doc(id).set({
      //         isUnRead: result
      //     }, {merge: true});
      //
      // }
    } catch (error) {
      dispatch(hasError(error));
    }
  }
);
//#endregion

//#region PROJECT
export const ProjectCreateNotification = ({ project }) => {
  return async (dispatch) => {
    let notifTitle = 'Vous avez été ajouté à un projet';
    let notifType = NOTIFICATION_TYPES.PROJECT_MANAGER;

    const { canAccessId, managers } = project;

    let listIds = {};
    const canAccessIdManagers = managers?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: 'Vous avez été nommé gestionnaire',
          description: 'Vous avez été nommé << Gestionnaire >> du projet <<' + project?.name + '>>',
          createdAt: serverTime(),
          type: notifType,
          returnId: project?.id,
          cardTitle: project?.name,
          canAccess: canAccessIdManagers,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectKey: project.id
          }
        }
      })
    );

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Vous avez été ajouté au projet <<' + project?.name + '>>',
          createdAt: serverTime(),
          type: notifType,
          returnId: project?.id,
          cardTitle: project?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectKey: project.id
          }
        }
      })
    );
  };
};

export const ProjectUpdateNotification = ({ project, oldProject }) => {
  return async (dispatch) => {
    let notifTitle = 'Vous avez été ajouté a un projet';
    let notifType = NOTIFICATION_TYPES.PROJECT_UPDATE;

    const { canAccessId, managers } = project;

    let listIds = {};

    const oldcanAccessId = oldProject?.canAccessId || [];
    const oldManager = oldProject?.managers || [];

    const resAccessId = canAccessId.filter((_id) => !oldcanAccessId.find((id) => id === _id)) || [];
    const resAccessManager = managers?.filter((_id) => !oldManager.find((id) => id === _id)) || [];

    const resAccess = uniq([...resAccessId, ...resAccessManager]);

    resAccess.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Vous avez été ajouté au projet <<' + project?.name + '>>',
          createdAt: serverTime(),
          type: notifType,
          returnId: project?.id,
          cardTitle: project?.name,
          canAccess: resAccess,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectKey: project.id
          }
        }
      })
    );
  };
};
//#endregion

//#region Task

export const taskRetireNotification = ({ card, assigneChange, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Une tâche vous a été retirée';
    let notifType = NOTIFICATION_TYPES.TASK_RETRIEVE;

    let listIds = {};
    const canAccessIdManagers = assigneChange?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Vous avez été retiré de la tâche ' + card?.name + ' du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );
    const thisUser = auth.currentUser;

    const accessLink = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;
    const description = `La tâche ${card?.name} du projet ${projectName} vous a été retirée par ${thisUser?.displayName}`;

    assigneChange?.map(async (pers) => {
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: accessLink,
        subject: subject,
        header: `Une tâche vous a été retirée`
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
        data
      });
    });
  };
};

export const taskAssigneeNotification = ({ card, assigneChange, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Une tâche vous est attribuée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_ASSIGN_USER;

    const { displayName, uid, photoURL } = auth.currentUser;

    let listIds = {};
    const canAccessIdManagers = assigneChange?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Vous avez été assigné à la tâche ' + card?.name + ' du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: uid,
            displayName: displayName || '',
            name: displayName || '',
            photoURL: photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    const accessLink = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;
    const description = `La tâche "${card?.name}" du projet "${projectName}" vous a été attribuée par ${thisUser?.displayName}`;

    assigneChange?.map(async (pers) => {
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: accessLink,
        subject: subject,
        header: `Une tâche vous est attribuée'`
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
        data
      });
    });
  };
};

export const taskCompletedNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Tâche terminée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_ACCEPTED;
    const { displayName } = auth.currentUser;

    const { managers } = card;
    const canAccessId = managers?.map((_one) => _one.id) || [];

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `${displayName} a marqué la tâche << ${card?.name} >> du projet <<${projectName}>> comme terminée`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessId,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    const accessLink = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;
    const description = `Vous avez reçu une demande de validation sur la tâche ${card?.name} du projet ${projectName} par ${thisUser?.displayName}`;

    managers?.map(async (pers) => {
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: accessLink,
        subject: subject,
        header: `Une demande de validation`
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
        data
      });
    });
  };
};

export const taskValidationDemandeNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Demande de validation';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_DEMANDE;

    const { managers } = card;
    const canAccessId = managers?.map((_one) => _one.id) || [];

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Demande de validation pour la tâche ' + card?.name + ' du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessId,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    const accessLink = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;
    const description = `Vous avez reçu une demande de validation sur la tâche ${card?.name} du projet ${projectName} par ${thisUser?.displayName}`;

    managers?.map(async (pers) => {
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: accessLink,
        subject: subject,
        header: `Une demande de validation`
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
        data
      });
    });
  };
};

export const taskBeginningNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Tâche démarrée';
    let notifType = NOTIFICATION_TYPES.TASK_BEGINING;

    const { managers } = card;
    const canAccessId = managers?.map((_one) => _one.id) || [];

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'La tâche ' + card?.name + ' du projet ' + projectName + ' a été démarrée',
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessId,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    const accessLink = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;
    const description = `La tâche ${card?.name} du projet ${projectName} a été démarrée par ${thisUser?.displayName}`;

    managers?.map(async (pers) => {
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: accessLink,
        subject: subject,
        header: notifTitle
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
        data
      });
    });
  };
};

export const taskValidationRejectNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Tâche rejetée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_REJECT;

    const { canAccessId, assignee, managers } = card;

    let listIds = {};
    const canAccessIdManagers = managers?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Rejet de la tâche: ' + card?.name + ' du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    const accessLink = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;
    const description = `La demande de validation de la tâche ${card?.name} du projet ${projectName} a été rejetée par ${thisUser?.displayName}`;

    const toSend = uniqBy([...assignee, ...managers], 'email');

    toSend.map(async (pers) => {
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: accessLink,
        subject: subject,
        header: `Demande de validation rejetée`
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
        data
      });
    });
  };
};

export const taskValidationAcceptNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Tâche validée';
    let notifType = NOTIFICATION_TYPES.TASK_VALIDATION_ACCEPTED;

    const { canAccessId, assignee, managers } = card;

    let listIds = {};
    const canAccessIdManager = managers?.map((_one) => _one.id) || [];
    const assigneeIds = assignee?.map((_one) => _one.id) || [];

    const canAccessIds = uniq([...canAccessIdManager, ...canAccessId], 'id');
    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Tâche ${card?.name} du projet ${projectName} a été validée`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    const accessLink = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;
    const description = `La demande de validation de la tâche ${card?.name} du projet ${projectName} a été acceptée par ${thisUser?.displayName}`;

    const toSend = uniqBy([...assignee, ...(managers || [])], 'email');

    toSend.map(async (pers) => {
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: accessLink,
        subject: subject,
        header: `Demande de validation acceptée`
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
        data
      });
    });
  };
};

export const taskDueChangeNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'L’échéance d’une tâche qui vous est attribuée a été modifiée';
    let notifType = NOTIFICATION_TYPES.TASK_DUE_CHANGE;

    const { canAccessId, assignee, managers } = card;

    let listIds = {};
    const canAccessIdManager = managers?.map((_one) => _one.id) || [];

    const canAccessIds = uniq([...canAccessIdManager, ...canAccessId]);
    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `L’échéance de la tâche ${card?.name} du projet ${projectName} a été modifiée`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    const accessLink = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;
    const description = `L’échéance de la tâche ${card?.name} du projet ${projectName} a été modifiée par ${thisUser?.displayName}`;

    const toSend = uniqBy([...assignee, ...(managers || [])], 'email');

    toSend.map(async (pers) => {
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: accessLink,
        subject: subject,
        header: `L’échéance d’une tâche qui vous est attribuée a été modifiée`
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
        data
      });
    });
  };
};

export const taskattachmentsChangeNotification = ({ card, projectName }) => {
  return async (dispatch) => {
    let notifTitle = 'Une tâche que vous suivez a de nouvelles pièces jointes';
    let notifType = NOTIFICATION_TYPES.TASK_PIECE_JOINTES;

    const { canAccessId, assignee, managers } = card;

    let listIds = {};
    const canAccessIdManager = managers?.map((_one) => _one.id) || [];

    const canAccessIds = uniq([...canAccessIdManager, ...canAccessId]);
    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `De nouvelles piéces jointes ont de la tâche ${card?.name} du projet ${projectName}`,
          createdAt: new Date(),
          type: notifType,
          returnId: card?.id,
          cardTitle: card?.name,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            id: card?.id,
            projectKey: card.projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    const accessLink = `https://${domaine}/dashboard/task/${card.projectKey}/${card?.id || ''}`;
    const description = `La tâche ${card?.name} du projet ${projectName} a de nouvelles pièces jointes`;

    const toSend = uniqBy([...(assignee || []), ...(managers || [])], 'email');

    toSend.map(async (pers) => {
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: accessLink,
        subject: subject,
        header: `Une tâche que vous suivez a de nouvelles pièces jointes`
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
        data
      });
    });
  };
};

export const taskUpdateNotification = createAsyncThunk(
  'notification/task/update',
  async ({ card, change, projectName, theOld = null }, { dispatch, getState }) => {
    // let notifType = NOTIFICATION_TYPES.TASK;
    // let notifTitle = `Voici ce que vous avez manqué`;
    const { currentProject } = getState().kanban;

    const taskCard = { ...card, managers: [...(card?.managers || []), ...(currentProject?.managers || [])] };

    const { canAccessId, managers } = card;

    let listIds = {};
    const canAccessIdManagers = managers?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    if (change.length) {
      const stateChange = change.filter((_one) => _one?.state || false);
      const completedChange = change.filter((_one) => _one?.completed || false);
      const assigneChange = change.filter((_one) => _one?.assignee || false);
      const dueChange = change.filter((_one) => _one?.due || false);
      const attchmentChange = change.filter((_one) => _one?.attachments || false);

      if (assigneChange?.length) {
        if (theOld) {
          let oldAssinge = [...(theOld?.assignee || [])];
          let newAssigne = assigneChange[0]?.assignee || [];
          const { added, removed } = compareArraysOfObjects(oldAssinge, newAssigne);

          dispatch(taskRetireNotification({ card, assigneChange: removed, projectName }));
          dispatch(taskAssigneeNotification({ card, assigneChange: added, projectName }));
        } else {
          console.log('not old task');
        }
      }

      if (stateChange?.length) {
        if (completedChange[0]?.completed) {
          dispatch(taskCommentAddNotification({ taskCard, change, projectName }));
          return;
        }

        if (stateChange[0]?.state === TASK_STATE_VALIDATION.REJECTED) {
          dispatch(taskValidationRejectNotification({ taskCard, change, projectName }));
          return;
        }

        if (stateChange[0]?.state === TASK_STATE_VALIDATION.ACCEPTED) {
          dispatch(taskValidationAcceptNotification({ taskCard, change, projectName }));
          return;
        }
        if (stateChange[0]?.state === TASK_STATE_VALIDATION.INPROGRESS) {
          dispatch(taskBeginningNotification({ taskCard, change, projectName }));
          return;
        }
      }

      if (dueChange?.length) {
        dispatch(taskDueChangeNotification({ taskCard, projectName }));
      }
      if (attchmentChange?.length) {
        if (theOld) {
          let rest = attchmentChange[0]?.attachments;
          theOld?.attachments.forEach((_one) => (rest = rest?.filter((_as) => _as?.id !== _one?.id)));

          if (rest.length) {
            dispatch(taskattachmentsChangeNotification({ taskCard, projectName }));
          }
        }
      }
    }
  }
);

export const taskCreationNotification = createAsyncThunk('notification/task/new', async ({ card }, { dispatch }) => {
  const { canAccessId, managers } = card;

  let canAccessIdManagers = managers?.map((_one) => _one.id) || [];

  let listIds = {};
  const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId]);

  canAccessIds.forEach((id) => (listIds[id] = true));

  const desc = `Vous avez été ajouté à la tâche ${card.name} par 
     ${auth.currentUser.displayName},
      le ${format(new Date(), 'dd MMMM yyyy HH:mm', { locale: frLocale })}
    `;

  dispatch(
    createNotification({
      data: {
        title: `Nouvelle tâche`,
        description: desc,
        createdAt: new Date(),
        type: NOTIFICATION_TYPES.TASK,
        isUnRead: listIds,
        canAccess: canAccessIds,
        by: {
          id: auth.currentUser.uid,
          displayName: auth.currentUser.displayName || '',
          photoURL: auth.currentUser.photoURL || ''
        },
        action: {
          projectKey: card?.projectKey || card?.idProject,
          id: card?.id
        }
      }
    })
  );
});

export const taskDeletionNotification = createAsyncThunk(
  'notification/task/delete',
  async ({ cardId }, { dispatch, getState }) => {
    const {
      kanban: {
        board: { cards: cardList }
      }
    } = getState();
    const card = cardList[cardId];

    const { canAccessId, managers } = card;
    let listIds = {};
    const canAccessIdManagers = managers?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers, ...canAccessId]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: `Suppresion de la tache`,
          description: ` "${card.name}" `,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.TASK,
          isUnRead: listIds,
          canAccess: canAccessIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          }
        }
      })
    );
  }
);

export const taskCommentAddNotification = ({ canReceived, projectName, projectKey, taskId, taskName }) => {
  return async (dispatch) => {
    let notifTitle = 'Un commentaire a été publié dans une tâche que vous suivez';
    let notifType = NOTIFICATION_TYPES.TASK_COMMENT_ADD;

    let listIds = {};

    const canAccessIdManagers = canReceived?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: 'Vous un nouveau commentaire sur la tâche ' + taskName + ' du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: taskId,
          cardTitle: taskName,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            projectName,
            projectKey: projectKey
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    const accessLink = `https://${domaine}/dashboard/task/${projectKey}/${taskId}`;
    const description = `Un commentaire a été publié dans la tâche ${taskName} du projet ${projectName} par ${thisUser?.displayName}`;

    canReceived.map(async (pers) => {
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: accessLink,
        subject: subject,
        header: `Un commentaire a été publié dans une tâche que vous suivez`
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
        data
      });
    });
  };
};

export const taskMentionNotification = ({
  canReceived,
  projectName,
  projectKey,
  taskId,
  taskName,
  target = 'dans le commentaire'
}) => {
  return async (dispatch) => {
    let notifTitle = 'Vous avez été mentionné';
    let notifType = NOTIFICATION_TYPES.TASK_MENTION;

    let listIds = {};
    console.log('mentions -- ', canReceived);
    const canAccessIdManagers = canReceived?.map((_one) => _one.id) || [];
    const canAccessIds = uniq([...canAccessIdManagers]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Vous avez été mentionné ${target} sur la tâche "` + taskName + '" du projet ' + projectName,
          createdAt: new Date(),
          type: notifType,
          returnId: taskId,
          cardTitle: taskName,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          action: {
            target,
            projectName,
            projectKey: projectKey,
            taskId,
            id: taskId,
            cardId: taskId,
            detailId: taskId,
            returnId: taskId,
            taskName,
            cardName: taskName
          }
        },
        returnId: taskId
      })
    );

    const thisUser = auth.currentUser;
    const accessLink = `https://${domaine}/dashboard/task/${projectKey}/${taskId}`;
    const description = `Vous avez été mentionné ${target} dans la tâche "${taskName}" du projet "${projectName}" par ${thisUser?.displayName}`;

    canReceived.map(async (pers) => {
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: accessLink,
        subject: subject,
        header: 'Vous avez été mentionné'
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
        data
      });
    });
  };
};

//#endregion

//#region SUB TASK
export const subTaskAssignNotification = ({ taskName, taskId, projectId, projectName, subTask, oldSubTask = null }) => {
  return async (dispatch) => {
    let notifTitle = 'Une sous tâche vous a été assignée';
    let notifType = NOTIFICATION_TYPES.SUB_TASK_VALIDATION_ASSIGN_USER;

    const { assigne } = subTask;

    let listIds = {};
    const oldManager = oldSubTask?.assigne || [];

    const canAccessIdManager = assigne?.map((_one) => _one.id) || [];
    const resAccessId = canAccessIdManager.filter((_id) => !oldManager.find((id) => id === _id)) || [];
    const canAccessIds = uniq([...resAccessId]);

    canAccessIds.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: notifTitle,
          description: `Vous avez été ajouté à la sous tâche ${subTask?.title}, de la tâche ${taskName} du project ${projectName}`,
          createdAt: serverTime(),
          type: notifType,
          returnId: taskId,
          cardTitle: subTask?.title,
          canAccess: canAccessIds,
          isUnRead: listIds,
          by: {
            id: auth.currentUser.uid,
            displayName: auth.currentUser.displayName || '',
            photoURL: auth.currentUser.photoURL || ''
          },
          other: {
            taskName,
            projectName
          },
          action: {
            projectKey: projectId
          }
        }
      })
    );

    const thisUser = auth.currentUser;
    const accessLink = `https://${domaine}/dashboard/task/${projectId}/${taskId}`;
    const description = `Une sous tâche vous a été assignée sur la tâche ${taskName} du projet ${projectName} par ${thisUser?.displayName}`;

    assigne.map(async (pers) => {
      const data = {
        salutation: '',
        name: pers?.name || pers?.displayName,
        description: description,
        link: accessLink,
        subject: subject,
        header: `Une sous tâche vous a été assignée`
      };

      await axiosRequest.post('/mail/template', {
        to: pers.email,
        from,
        templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
        data
      });
    });
  };
};
//#endregion

//#region blog

export const blogCreationNotification = createAsyncThunk('notification/blog/new', async ({ post }, { dispatch }) => {
  dispatch(
    createNotification({
      data: {
        title: post.title,
        description: post.body.substring(0, 30),
        createdAt: new Date(),
        type: NOTIFICATION_TYPES.BLOG,
        isBroadcast: true,
        isUnRead: null,
        canAccess: null,
        action: {
          postId: post.id
        }
      }
    })
  );
});

//#endregion

//#region audience

export const audienceNotification = ({
  audience,
  type = NOTIFICATION_TYPES.AUDIENCE,
  users = [],
  title,
  description
}) => {
  return (dispatch) => {
    const canAccessId = (audience?.personToMeet || []).map((el) => el?.id);
    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title,
          description,
          createdAt: new Date(),
          type,
          isUnRead: listIds,
          canAccess: canAccessId,
          action: {
            url: PATH_DASHBOARD.general.accueil.root,
            audienceId: audience?.id
          }
        }
      })
    );
  };
};
export const audienceCreationNotification = createAsyncThunk(
  'notification/audience/new',
  async ({ data }, { dispatch }) => {
    const { canAccessId } = data;

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: "Nouvelle demande d'audience",
          description: `pour ${data.lastName} ${data.firstName}`,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.AUDIENCE,
          isUnRead: listIds,
          canAccess: canAccessId,
          action: {
            url: PATH_DASHBOARD.general.accueil.root
          }
        }
      })
    );
  }
);

export const audienceUpdateNotification = createAsyncThunk(
  'notification/audience/update',
  async ({ data }, { dispatch }) => {
    const { canAccessId } = data;

    let listIds = {};
    canAccessId.forEach((id) => (listIds[id] = true));

    dispatch(
      createNotification({
        data: {
          title: "Maj de la demande d'audience",
          description: `pour ${data.lastName} ${data.firstName}`,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.AUDIENCE,
          isUnRead: listIds,
          canAccess: canAccessId,
          action: {
            url: PATH_DASHBOARD.general.accueil.root
          }
        }
      })
    );
  }
);

//#endregion

//#region stage

export const stageCreationNotification = createAsyncThunk('notification/stage/new', async ({ data }, { dispatch }) => {
  try {
    const { docs } = await firestore.collection('users').where('roles.stage.read', '==', true).get();

    const canAccessId = [...docs.map((one) => one.id)];

    let shouldRead = {};
    canAccessId.forEach((id) => (shouldRead[id] = true));

    dispatch(
      createNotification({
        data: {
          title: 'Nouvelle demande de stage',
          description: `pour ${data.lastName} ${data.firstName}`,
          createdAt: new Date(),
          type: NOTIFICATION_TYPES.STAGE,
          isUnRead: shouldRead,
          canAccess: canAccessId,
          action: {
            url: PATH_DASHBOARD.general.accueil.root
          }
        }
      })
    );
  } catch (e) {
    console.error(e);
  }
});

//#endregion

//#region chat

export const chatCreationNotification = createAsyncThunk(
  'notification/chat/new',
  async ({ data }, { dispatch, getState }) => {
    try {
      const { conversationId } = data;
      const conversations = getState().firestore.ordered['conversations'];
      const { participants, participantsId, lastMessage = '' } = conversations?.find((el) => el?.id === conversationId);
      const { displayName, uid: currentUserId } = auth.currentUser;
      const isGroup = participants.length > 2;

      const receiverIds = participantsId.filter((one) => one !== currentUserId);

      let shouldRead = {};
      receiverIds.forEach((id) => (shouldRead[id] = true));

      dispatch(
        createNotification({
          data: {
            title: isGroup ? 'Nouveau message' : displayName,
            description: lastMessage,
            createdAt: new Date(),
            type: NOTIFICATION_TYPES.CHAT,
            isUnRead: shouldRead,
            canAccess: receiverIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            action: {
              url: null,
              lastMessage
            }
          }
        })
      );
    } catch (e) {
      console.error(e);
    }
  }
);

export const projectConversationNotification = createAsyncThunk(
  'notification/project/chat/new',
  async ({ data }, { dispatch }) => {
    try {
      const { participantIds, lastMessage, projectId, title, description } = data;

      let shouldRead = {};

      participantIds.filter((r) => r !== lastMessage.senderId).forEach((id) => (shouldRead[id] = true));
      // const description = data.message.length > 0 ? data.message : data.attachments.length > 0 ? 'Ajout de piéce joint' : 'Un nouveau message';

      dispatch(
        createNotification({
          data: {
            title: 'Vous avez un nouveau message dans la conversation du projet: <<' + title + ' >>',
            description,
            createdAt: new Date(),
            type: NOTIFICATION_TYPES.PROJECT_CHAT,
            isUnRead: shouldRead,
            canAccess: participantIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            action: {
              projectName: title,
              projectKey: projectId,
              url: projectId
            },
            url: projectId
          }
        })
      );
    } catch (e) {
      console.error(e);
    }
  }
);

export const chatForsakeNotification = (notifications) => {
  return async (dispatch) => {
    try {
      const batch = firestore.batch();

      notifications.forEach((one) => {
        const { id, canAccess, isUnRead, ...rest } = one;
        const ref = firestore.collection('notifications').doc(id);
        const currentUserId = auth.currentUser.uid;
        const newUnRead = omit(isUnRead, [currentUserId]);
        const newAccess = reject(canAccess, (id) => id === currentUserId);

        batch.set(ref, { ...rest, isUnRead: newUnRead, canAccess: newAccess });
      });

      await batch.commit();
    } catch (e) {
      console.error(e);
    }
  };
};

//#endregion

//#region RELANCE
export const sendTaskRelanceNotification = ({ sendTo, taskId, projectId, projectName, taskName, callback = null }) => {
  return async (dispatch) => {
    try {
      let notifTitle = 'Vous avez été relancé sur une tâche';
      let notifType = NOTIFICATION_TYPES.RELANCE_TASK;

      let listIds = {};

      const resAccessId = sendTo.map((_one) => _one?.id);
      const canAccessIds = uniq([...resAccessId]);

      canAccessIds.forEach((id) => (listIds[id] = true));

      const thisUser = auth.currentUser;
      const accessLink = `https://${domaine}/dashboard/task/${projectId}/${taskId}`;
      const description = `Vous avez été relancé sur la tâche ${taskName} du projet ${projectName} par ${thisUser?.displayName}`;

      dispatch(
        createNotification({
          data: {
            title: notifTitle,
            description: description,
            createdAt: serverTime(),
            type: notifType,
            returnId: taskId,
            cardTitle: notifTitle,
            canAccess: canAccessIds,
            isUnRead: listIds,
            by: {
              id: auth.currentUser.uid,
              displayName: auth.currentUser.displayName || '',
              photoURL: auth.currentUser.photoURL || ''
            },
            other: {
              taskName,
              projectName
            },
            action: {
              projectKey: projectId
            }
          }
        })
      );

      sendTo.map(async (pers) => {
        const data = {
          salutation: '',
          name: pers?.name || pers?.displayName,
          description: description,
          link: accessLink,
          subject: subject,
          header: `Vous avez été relancé sur une tâche`
        };

        await axiosRequest.post('/mail/template', {
          to: pers.email,
          from,
          templateId: process.env.REACT_APP_EMAIL_MEMBER_TEMPLATE,
          data
        });
      });
      callback && callback();
    } catch (error) {}
  };
};
//#endregion

/**
 *
 * @param data {{createdAt: Date, isUnRead: string[], canAccessEmail: string[], canAccess: string[], description: string, action: {url: string}, by: {id: string,displayName: string,photoURL: string,}, avatar: string, title: string, type: string}}
 * @param callback {function()}
 * @returns {(function(*): Promise<void>)|*}
 */
export const archiveNotification = (data, callback = null) => {
  return async (dispatch) => {
    try {
      dispatch(
        createNotification({
          data: { ...data, type: NOTIFICATION_TYPES.ARCHIVE, createdAt: new Date() },
          callback
        })
      );
    } catch (e) {}
  };
};

export const chatMentionNotification = ({ canReceived, conversationId }) => {
  return async (dispatch) => {
    try {
      let notifTitle = 'Vous avez été mentionné';
      let notifType = NOTIFICATION_TYPES.CHAT_MENTION;

      let listIds = {};
      const canAccessIdManagers = canReceived?.map((_one) => _one.id) || [];
      const canAccessIds = uniq([...canAccessIdManagers]);

      canAccessIds.forEach((id) => (listIds[id] = true));

      const person = getCurrentUserAccess();

      const data = {
        title: notifTitle,
        description: `Vous avez été mentionné dans une coversation par ${person.name}`,
        createdAt: new Date(),
        type: notifType,
        canAccess: canAccessIds,
        isUnRead: listIds,
        by: {
          id: auth.currentUser.uid,
          displayName: auth.currentUser.displayName || '',
          photoURL: auth.currentUser.photoURL || ''
        },
        action: {
          conversationId,
          userName: person.name
        }
      };
      dispatch(
        createNotification({
          data
        })
      );
    } catch (e) {
      console.log(e);
      console.trace();
    }
  };
};
