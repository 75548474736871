import React, { useMemo } from 'react';
import useTaskActions from 'src/hooks/useTaskActions';
import useToggle from 'src/hooks/useToggle.js';

import { keys, values, orderBy } from 'lodash';
import { gfDate, gfDateTime, gDate } from '../../../utils/formatTime';

import {
  Stack,
  ListItem,
  Chip,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  Collapse,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import LoadingView from '../../../components/LoadingView';
import Scrollbar from '../../../components/Scrollbar';
import ProgressView from '../../../components/ProgressView';

import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import KanbanTaskAttachments from './KanbanTaskAttachments';
import Markdown from '../../../components/Markdown';
import { useSelector } from 'src/redux/store';

export default function KanbanDetailsActions({ id }) {
  const { loading, actions } = useTaskActions(id);

  return (
    <Stack sx={{ px: 1 }} height={1}>
      <Scrollbar>
        {orderBy(
          actions?.map((one) => ({
            ...one,
            createAt: gDate(one?.createAt)
          })),
          'createAt',
          'desc'
        )?.map((one, key) => (
          <Action key={key} actions={one} />
        ))}
      </Scrollbar>

      {loading && (
        <Stack direction={'row'} justifyContent={'center'}>
          <Stack height={100} width={100}>
            <LoadingView />
          </Stack>
        </Stack>
      )}

      {!loading && actions?.length === 0 && (
        <Stack direction={'row'} justifyContent={'center'}>
          <Typography>Aucune action</Typography>
        </Stack>
      )}
    </Stack>
  );
}

export const Action = ({ actions }) => {
  const { users } = useSelector((state) => state.user);
  const { open } = useToggle();

  const changes = useMemo(() => {
    return actions?.change
      ?.map((one) => {
        const title = translate[keys(one)[0]?.trim()];
        const value = values(one)[0];

        return {
          title,
          value,
          id: keys(one)[0]
        };
      })
      ?.map((one) => ({
        ...one,
        ...(one?.id == 'due' && {
          value: [gfDate(one?.value[0]), gfDate(one?.value[1])]
        })
      }))
      ?.filter((one) => typeof one?.title !== 'undefined');
  }, [actions]);

  const personAvatar = useMemo(() => {
    const profile = users?.find((one) => one?.id === actions?.by?.id) || null;
    return profile?.avatar || profile?.photoUrl || null;
  }, [actions?.by?.id, users]);

  const title = useMemo(() => {
    return `${actions?.by?.displayName}  ${changes?.map((one, index) => {
      return ` ${index === 0 && one?.id !== 'completed' ? ' a modifié ' : ''} ${
        one?.id === 'completed' ? ' a marqué la tâche comme terminée ' : one?.title
      } ${changes?.length >= 2 && index !== changes?.length - 1 ? ' • ' : ''}`;
    })}`;
  }, [actions?.by?.displayName, changes]);

  return (
    <Stack mx={-2}>
      <ListItem dense>
        <ListItemButton>
          <ListItemAvatar>
            <Avatar src={personAvatar} sx={{ height: 25, width: 25 }} />
          </ListItemAvatar>
          <ListItemText
            primary={title}
            secondary={gfDateTime(actions?.createAt)}
            primaryTypographyProps={{
              fontSize: 14
            }}
            secondaryTypographyProps={{
              fontSize: 12
            }}
          />

          <IconButton hidden>{open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}</IconButton>
        </ListItemButton>
      </ListItem>

      <Collapse in={open} sx={{ px: 4 }}>
        <Stack
          sapcing={2}
          sx={{
            borderColor: '#aeaeae',
            borderWidth: 0.1,
            borderStyle: 'dotted',
            p: 0.5
          }}
        >
          {changes?.map((one, key) => (
            <Changes key={key} {...one} />
          ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const Changes = ({ title, value, id }) => {
  switch (id) {
    case 'due':
      return (
        <ListItem sx={{ p: 0 }}>
          <ListItemText primary={translate[id]} secondary={`Du ${value[0]} au ${value[1]}`} />
        </ListItem>
      );

    case 'description':
      return (
        <Stack sx={{ p: 0 }}>
          <Typography>{translate[id]}</Typography>
          <Markdown children={value} />
        </Stack>
      );

    case 'name':
      return (
        <ListItem sx={{ p: 0 }}>
          <ListItemText primary={translate[id]} secondary={value} />
        </ListItem>
      );

    case 'state':
      return (
        <ListItem sx={{ p: 0 }}>
          <ListItemText primary={translate[id]} secondary={value} />
        </ListItem>
      );

    case 'progress':
      return (
        <Stack sx={{ p: 0 }}>
          <Typography>{translate[id]}</Typography>
          <ProgressView value={value} />
        </Stack>
      );

    case 'assignee':
      return (
        <Stack sx={{ p: 0 }}>
          <Typography>{translate[id]}</Typography>

          <Grid container>
            {value.map((user, key) => (
              <Grid item key={key}>
                <Chip
                  sx={{ m: 0.5 }}
                  size="small"
                  variant="filled"
                  color="info"
                  label={user?.displayName || user?.name}
                />
              </Grid>
            ))}
          </Grid>
        </Stack>
      );

    case 'attachments':
      return (
        <Stack sx={{ p: 0 }} spacing={2}>
          <Typography>{translate[id]}</Typography>

          <Stack direction="row" flexWrap="wrap">
            <KanbanTaskAttachments attachments={value || []} disableUpload hideDelete />
          </Stack>
        </Stack>
      );

    default:
      return <></>;
  }
};

const translate = {
  due: 'la date de réalisation',
  description: 'la description',
  assignee: 'les personnes assignées',
  attachments: 'les fichiers attachés',
  name: 'le titre de la tâche',
  progress: 'la progression',
  state: 'le status'
};
