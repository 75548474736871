import {useRef, useState, useMemo} from "react";
import useToggle, {useToggleV2} from "src/hooks/useToggle";
import useRappel from "src/hooks/useRappel";
import useUserList from "src/hooks/useUserList";

import {Typography, Stack, Button} from "@mui/material";
import {Add} from "@mui/icons-material";
import {Row, Header} from './AffectationRappelTable'
import RappelForm from "../../rappel/RappelForm";
import DeleteModalOption from "src/components/DeleteModalOption";
import LoadingView from "src/components/LoadingView";
import {extractUserIdsFromAffectation} from "src/utils/rappel";
import {NOTIFICATION_TYPES} from "src/constants";


export default function AffectationRappel({affectation}) {
    const ref = useRef()
    const {users} = useUserList()
    const restrictUserListTo = useMemo(() => {
        const ids = extractUserIdsFromAffectation(affectation);
        return users?.filter(el => ids?.includes(el?.id))
    }, [affectation, users])

    const {loading: ul} = useUserList()
    const {rappels, remove, loading: rl} = useRappel({docId: affectation?.id})
    const loading = useMemo(() => ul ?? rl, [rl, ul])

    const [selected, setSelected] = useState(null)

    const {open, handleOpen, handleClose} = useToggle()
    const [openDelete, onOpenDelete, onCloseDelete] = useToggleV2()

    const handleOpenWith = (rappel) => {
        setSelected(rappel);
        handleOpen()
    }

    const handleInitateDeleteWith = (rappel) => {
        setSelected(rappel);
        onOpenDelete()
    }

    const handleDeleteFor = () => {
        remove(selected?.id)
        handleCloseAll()
    }
    const handleCloseAll = () => {
        setSelected(null);
        handleClose()
        onCloseDelete()
    }


    return (
        <Stack>
            <Stack
                sx={{

                    borderStyle: 'solid',
                    borderWidth: 0,
                    borderBottomWidth: 1,
                    borderColor: t => t.palette.divider,
                }}
            >
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <Stack>
                        <Typography fontSize={11}>Rappels</Typography>
                    </Stack>
                    <Button ref={ref} variant={'text'} size="small" startIcon={<Add/>}
                            onClick={handleOpen}
                    >
                        Ajouter
                    </Button>
                </Stack>
            </Stack>

            {
                loading && (
                    <LoadingView size={{height: '150px', width: '150px'}}/>

                )
            }

            {/*{*/}
            {/*    !loading && rappels.length > 0 && (*/}
            {/*        <Header />*/}
            {/*    )*/}
            {/*}*/}
            <Stack py={1}/>
            {
                !loading && rappels.map(el => (
                    <Row
                        key={el?.id} rappel={el}
                        onOpenRappel={handleOpenWith} onDeletRappel={handleInitateDeleteWith}
                    />
                ))
            }

            {
                open && (
                    <RappelForm
                        initalData={{
                            type: NOTIFICATION_TYPES.AFFECTATION,
                            docId: affectation?.id,
                            title: "Rappel de Courriel",
                            description: `Rappel pour le couriel d'Objet : ${affectation?.courriel_object} - Matricule : ${affectation?.save_reference}.`
                        }}
                        rappel={selected}
                        anchor={ref} open={open} onClose={handleCloseAll}
                        restrictUserListTo={restrictUserListTo}
                    />
                )
            }


            {
                openDelete && (
                    <DeleteModalOption
                        canDelete
                        open={openDelete}
                        onClose={handleCloseAll}
                        onAccept={handleDeleteFor}
                        title={'Suppression d\'un rappel'}
                        deleteText={"Je comprends qu'une fois supprimé, le projet ne pourra plus être récupéré"}
                    />
                )
            }


        </Stack>
    )
}

