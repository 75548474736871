import { TASK_PRIORITIES } from '.';

export const AFFECT_TYPE = {
  Enter: 'Entrant',
  Out: 'Sortant',
  due: 'Échéance',
  doc: 'Documents'
};

export const ORDER_TYPE = {
  ASC: 0,
  DESC: 2,
  CREATION: 3
};

export const AFFECT_PRIORITY = {
  VERY_IMPORTANT: 'TRÈS URGENTE',
  ...TASK_PRIORITIES
};

export const AFFECT_STEP = ['Correspondance', 'Pièces jointes'];

export const AFFECT_OPERATION_TYPE = ['', 'Fax', 'Mail', 'Courrier', 'Appel téléphonique', 'Note interne'];

export const AFFECT_ANNOTATION = [
  'Pour étude et avis',
  'Pour élements de réponse',
  'Pour suite à donner',
  'Pour information',
  'Pour représentation',
  'Pour disposition à prendre',
  'Pour lecture',
  'Pour diffusion',
  'Pour exploitation',
  'Pour synthèse',
  'Pour analyse',
  'Pour supervision',
  'Pour observation',
  'Pour transmission à',
  'Pour proposition',
  'À classer',
  "M'en parler",
  "M'accompagner",
  'En parler à',
  'Urgent',
  'Très urgent'
];
