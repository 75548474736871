import React from 'react'
import Label from './Label'
import useNotifications from './../hooks/useNotifications';

export default function NotificationLabel() {
    const {combine:notifications} = useNotifications();
    const unReadNotifications = notifications.filter(item => item.isUnRead === true);


    return unReadNotifications.length ? <Label color="error"> {unReadNotifications.length} </Label> : null
}
