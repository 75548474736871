export function changeOnObject(old, newest) {
  if (JSON.stringify(old) === JSON.stringify(newest)) {
    return [];
  }

  let changes = [];
  Object.entries(newest).forEach(([key, one]) => {
    if (old) {
      if (JSON.stringify(one) !== JSON.stringify(old[key])) {
        changes.push({ [key]: one });
      }
    } else {
      changes.push({ [key]: one });
    }
  });

  return changes;
}

export function changeOnObjectWithOldVal(old, newest) {
  if (JSON.stringify(old) === JSON.stringify(newest)) {
    return [];
  }

  let changes = [];
  Object.entries(newest).forEach(([key, one]) => {
    if (old) {
      if (JSON.stringify(one) !== JSON.stringify(old[key])) {
        changes.push({ [key]: { new: one, old: old[key] } });
      }
    } else {
      changes.push({ [key]: { new: one, old: null } });
    }
  });

  return changes;
}

export function compareArraysOfObjects(oldArray, newArray) {
  const added = [];
  const removed = [];
  const modified = [];

  // Recherche des objets ajoutés
  for (const newObj of newArray) {
    const found = oldArray.find((oldObj) => oldObj.id === newObj.id);
    if (!found) {
      added.push(newObj);
    }
  }

  // Recherche des objets supprimés
  for (const oldObj of oldArray) {
    const found = newArray.find((newObj) => newObj.id === oldObj.id);
    if (!found) {
      removed.push(oldObj);
    }
  }

  // Recherche des objets modifiés
  for (const newObj of newArray) {
    const oldObj = oldArray.find((obj) => obj.id === newObj.id);
    if (oldObj) {
      const keys = Object.keys(newObj);
      const isModified = keys.some((key) => newObj[key] !== oldObj[key]);
      if (isModified) {
        modified.push(newObj);
      }
    }
  }

  return {
    added,
    removed,
    modified
  };
}
