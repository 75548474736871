// eslint-disable-next-line
Object.defineProperty(Array.prototype, 'updateBy', {
  value: function(value = {}, iterate = 'id') {
    try {
      const list = this || [];
      return list?.map(one => {
        return one[iterate] === value[iterate] ? { ...one, ...value } : one;
      });
    } catch (e) {
      console.error(e);
      return this;
    }
  }
});