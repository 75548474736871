import { useRef } from 'react';
import { useIsOverflow } from 'src/hooks/useIsOverflow';
import { Grid, Stack, Typography, Tooltip as MTooltip } from '@mui/material';
import { Tooltip } from 'antd';

/**
 *
 * @param {{
 *  style: import('@mui/material/styles/createTypography').TypographyStyleOptions,
 * sx: import('@mui/material').SxProps,
 * text: any,
 * placement: string,
 * material: boolean,
 * component:any,
 * tooltip: any,
 * isHTML: boolean
 * }} param0
 * @returns
 */
const EllipsisText = ({
  text,
  style,
  sx,
  placement = 'top',
  tooltip,
  material = false,
  component = null,
  isHTML = false
}) => {
  const overflowRef = useRef(null);
  const isOverflow = useIsOverflow(overflowRef);

  return (
    <Stack width={1} maxWidth={1} sx={{ overflow: 'hidden' }}>
      <Grid container>
        <Grid item xs={12} md={12}>
          {isOverflow ? (
            material ? (
              <MTooltip
                title={tooltip || text}
                arrow
                placement={placement}
                sx={{ zIndex: (t) => t.zIndex.appBar + 10 }}
              >
                {isHTML ? (
                  <Typography
                    {...(component && { component: component })}
                    {...style}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      ...sx
                    }}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                ) : (
                  <Typography
                    {...(component && { component: component })}
                    {...style}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      ...sx
                    }}
                  >
                    {text}
                  </Typography>
                )}
              </MTooltip>
            ) : (
              <Tooltip title={tooltip || text} arrow placement={placement}>
                {isHTML ? (
                  <Typography
                    {...(component && { component: component })}
                    {...style}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      ...sx
                    }}
                    dangerouslySetInnerHTML={{ __html: text }}
                  />
                ) : (
                  <Typography
                    {...(component && { component: component })}
                    {...style}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      ...sx
                    }}
                  >
                    {text}
                  </Typography>
                )}
              </Tooltip>
            )
          ) : isHTML ? (
            <Typography
              {...(component && { component: component })}
              ref={overflowRef}
              {...style}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...sx
              }}
              {...(isHTML && { dangerouslySetInnerHTML: { __html: text } })}
            />
          ) : (
            <Typography
              {...(component && { component: component })}
              ref={overflowRef}
              {...style}
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...sx
              }}
            >
              {text}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Stack>
  );
};

export default EllipsisText;
