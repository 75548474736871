import PropTypes from 'prop-types';
import { forwardRef, useCallback, useEffect, useRef } from 'react';
// @mui
import { Typography, Link, Tooltip } from '@mui/material';
// utils
import GetFontValue from 'src/utils/getFontValue';

// ----------------------------------------------------------------------

/**
 * @type {Typography}
 */
const TextMaxLine = forwardRef(
  ({ asLink, variant = 'body1', line = 1, persistent = false, children, sx, getLine, ...other }, ref) => {
    const { lineHeight } = GetFontValue(variant);

    const style = {
      overflow: 'hidden',
      display: '-webkit-box',
      textOverflow: 'ellipsis',
      WebkitLineClamp: line,
      WebkitBoxOrient: 'vertical',
      ...(persistent && {
        height: lineHeight * line
      }),
      ...sx
    };

    const handleRef = useCallback(
      (node) => {
        if (node) {
          const computedStyle = window.getComputedStyle(node);
          const _lineHeight = parseFloat(computedStyle.getPropertyValue('line-height'));
          const maxHeight = parseFloat(computedStyle.getPropertyValue('height'));
          const actualLines = Math.round(maxHeight / _lineHeight);
          if (getLine) {
            getLine(actualLines);
          }
        }
      },
      [getLine]
    );

    if (asLink) {
      return (
        <Tooltip title={children} arrow>
          <Link color="inherit" variant={variant} sx={{ ...style }} {...other} ref={handleRef}>
            {children}
          </Link>
        </Tooltip>
      );
    }

    return (
      <Tooltip title={children} arrow>
        <Typography variant={variant} sx={{ ...style }} {...other} ref={handleRef}>
          {children}
        </Typography>
      </Tooltip>
    );
  }
);

TextMaxLine.propTypes = {
  asLink: PropTypes.bool,
  children: PropTypes.node.isRequired,
  line: PropTypes.number,
  persistent: PropTypes.bool,
  sx: PropTypes.object,
  variant: PropTypes.oneOf([
    'body1',
    'body2',
    'button',
    'caption',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'inherit',
    'overline',
    'subtitle1',
    'subtitle2'
  ])
};

export default TextMaxLine;
