import {Icon} from '@iconify/react';
import {Button, ButtonGroup, IconButton, Sheet} from '@mui/joy';
import {LoadingButton} from '@mui/lab';
import {Box, Checkbox, Paper, Stack, Tooltip, Typography} from '@mui/material';
import {Avatar, DatePicker,} from 'antd';
import {format} from 'date-fns';
import {fr as LocalFr} from 'date-fns/locale';
import {isArray, isEmpty, isString} from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import ActionModal from 'src/components/ActionModal';
import {GridStack, TableBaseLine} from 'src/components/AffectationLineComponent';
import CIconButton from 'src/components/CIconButton';
import ContactsDialog from 'src/components/ContactsDialog';
import Iconify from 'src/components/Iconify';
import {MenuLine} from 'src/components/MenuLine';
import MenuPopover from 'src/components/MenuPopover';
import SvgIconStyle from 'src/components/SvgIconStyle';
import TextMaxLine from 'src/components/TextMaxLine';
import {AFFECT_STATE_VALIDATION} from 'src/constants';
import {AFFECT_PRIORITY, AFFECT_TYPE} from 'src/constants/affectation';
import {baseColors} from 'src/constants/color';
import {useAffectation_v4Context} from 'src/contexts/Affectation_v4Context';
import {priorityColor} from 'src/helpers/priorityColor';
import useAuth from 'src/hooks/useAuth';
import {useToggleV2} from 'src/hooks/useToggle';
import {Affectation_Setting_model, Affectation_v4_Type} from 'src/models/Affectation_v4_type';
import {deleteCouriel, updateAffectation} from 'src/redux/slices/affectation_v4';
import {dispatch, useSelector} from 'src/redux/store';
import PriorityFlags from 'src/section/tasks/PriorityFlags';
import createAvatar, {getAvatarUrl} from 'src/utils/createAvatar';
import {gDate} from 'src/utils/formatTime';

/**
 *
 * @param {{affectation: Affectation_v4_Type, onOpen: (affectation)=> void, handleClick: ()=>void, selected: any, index: number}} props
 * @returns
 */
export default function AffectationLine({affectation, onOpen, handleClick, selected, index}) {
    const isSelected = (id) => selected.indexOf(id) !== -1;
    const isItemSelected = isSelected(affectation.id);
    const labelId = `enhanced-table-checkbox-${index}`;

    const handleUpdateAffectation = (field, value) => {
        let affect = {...affectation};
        if (isArray(field)) {
            field.map((_val, index) => {
                affect = {...affect, [field[index]]: value[index]};
            });
        }

        if (isString(field)) {
            affect = {...affect, [field]: value};
        }

        dispatch(
            updateAffectation({
                newAffectation: {...affect},
                oldAffectation: affectation,
                callback: () => {
                },
                onError: () => {
                }
            })
        );
    };

    return (
        <Stack width={1} component={Paper} sx={{cursor: 'pointer'}}>
            <TableBaseLine
                height={55}
                cols={[
                    {
                        element: (
                            <GridStack alignItems="center">
                                <Checkbox
                                    onClick={(e) => handleClick(e, affectation?.id)}
                                    color="primary"
                                    checked={isItemSelected}
                                    size="small"
                                    inputProps={{
                                        'aria-labelledby': labelId
                                    }}
                                />
                            </GridStack>
                        ),
                        width: 30,
                        minWidth: 30,
                        maxWidth: 30,
                        borderBottom: `1px solid #D9D9D9`,
                        borderLeft: `3px solid ${affectation.type === AFFECT_TYPE.Out ? baseColors.BLUE : baseColors.GREEN}`
                    },
                    {
                        element: (
                            <GridStack pl={1}>
                                <TextMaxLine
                                    fontSize={13}>{affectation.save_reference.replace('AFF_', '') || ''}</TextMaxLine>
                            </GridStack>
                        ),
                        width: 70,
                        minWidth: 70,
                        maxWidth: 70,
                        borderLeft: `1px solid #D9D9D9`,
                        borderBottom: `1px solid #D9D9D9`
                    },
                    {
                        element: (
                            <GridStack pl={1} alignItems="center" direction="row" justifyContent="space-between">
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width={{md: 0.9, lg: 0.95, xl: 1}}
                                    sx={{cursor: 'pointer'}}
                                >
                                    <Stack width={1} onClick={onOpen}>
                                        <TextMaxLine fontSize={13}>{affectation.courriel_object || ''}</TextMaxLine>
                                    </Stack>
                                    <Stack>
                                        <AffectAction affect={affectation}/>
                                    </Stack>
                                </Stack>
                            </GridStack>
                        ),
                        borderLeft: `1px solid #D9D9D9`,
                        borderBottom: `1px solid #D9D9D9`
                    },
                    {
                        element: (
                            <GridStack alignItems="start" pl={1}>
                                <TextMaxLine fontSize={13}>{affectation.correspondant.title || ''}</TextMaxLine>
                            </GridStack>
                        ),
                        width: 500,
                        borderLeft: `1px solid #D9D9D9`,
                        borderBottom: `1px solid #D9D9D9`
                    },
                    {
                        element: (
                            <GridStack
                                pl={1}
                                onClick={onOpen}
                                alignItems="start"
                                sx={{
                                    cursor: 'pointer',
                                    ...(isEmpty(affectation.annotation) && {
                                        my: 3,
                                        mx: 2,
                                        width: '80%',
                                        border: `2px solid ${baseColors.RED}`,
                                        borderRadius: 0.3,
                                        transform: 'scale(0.9)',
                                        animation: 'pulse 2s infinite'
                                    })
                                }}
                            >
                                <TextMaxLine fontSize={13}>{affectation.annotation}</TextMaxLine>
                            </GridStack>
                        ),
                        width: 500,
                        pulse: isEmpty(affectation.annotation),
                        borderLeft: `1px solid #D9D9D9`,
                        borderBottom: `1px solid #D9D9D9`
                    },
                    {
                        element: (
                            <GridStack>
                                <AffetLinePriority affect={affectation} onChange={handleUpdateAffectation}/>
                            </GridStack>
                        ),
                        width: 250,
                        borderLeft: `1px solid #D9D9D9`,
                        borderBottom: `1px solid #D9D9D9`
                    },
                    {
                        element: (
                            <GridStack>
                                <AffectLineDue affect={affectation} onChange={handleUpdateAffectation}/>
                            </GridStack>
                        ),
                        width: 300,
                        minWidth: 120,
                        borderLeft: `1px solid #D9D9D9`,
                        borderBottom: `1px solid #D9D9D9`
                    },
                    {
                        element: (
                            <GridStack>
                                <AffectLineAssigne affect={affectation} onChange={handleUpdateAffectation}/>
                            </GridStack>
                        ),
                        width: 200,
                        borderLeft: `1px solid #D9D9D9`,
                        borderBottom: `1px solid #D9D9D9`
                    },
                    {
                        element: (
                            <GridStack>
                                <AffectLineValidation affect={affectation} onChange={handleUpdateAffectation}/>
                            </GridStack>
                        ),
                        width: 200,
                        borderLeft: `1px solid #D9D9D9`,
                        borderBottom: `1px solid #D9D9D9`
                    }
                ]}
            />
        </Stack>
    );
}

/**
 *
 * @param {{affect: Affectation_v4_Type}} props
 * @returns
 */
const AffectAction = ({affect}) => {
    const anchorEl = useRef();
    const [deleteOk, setDeleteOK] = useState(false);
    const [open, onOpen, onClose] = useToggleV2();
    const [openD, onOpenD, onCloseD] = useToggleV2();
    const {handleOpenDetails} = useAffectation_v4Context();
    const {enqueueSnackbar} = useSnackbar();

    const onOpenDetails = () => {
        handleOpenDetails(affect);
        onClose();
    };

    const handleDeletion = () => {
        onCloseD();
        dispatch(
            deleteCouriel({
                affect,
                callback: () => {
                    enqueueSnackbar('Suppression reussie', {variant: 'warning'});
                }
            })
        );
    };

    const clickClosr = () => {
        onClose();
        onOpenD();
    };

    const archive = () => {
        onCloseD();
    };

    const onPrint = () => {
    };

    return (
        <Stack direction="row" spacing={1}>
            <Stack ref={anchorEl}>
                <CIconButton noBorder onClick={onOpen} size="small">
                    <Icon icon="eva:more-vertical-fill"/>
                </CIconButton>
            </Stack>
            {open && (
                <MenuPopover open={open} onClose={onClose} width={200} disabledArrow anchorEl={anchorEl.current}>
                    <MenuLine title="Ouvrir" color={baseColors.BLACK} onClick={onOpenDetails}
                              icon="clarity:play-solid"/>
                    <MenuLine
                        title="Editer"
                        color={baseColors.BLACK}
                        onClick={onOpenDetails}
                        icon="material-symbols:edit-square-outline-rounded"
                    />
                    <MenuLine title="Imprimer" color={baseColors.BLACK} onClick={onPrint} icon="ion:print"/>
                    {affect?.state === AFFECT_STATE_VALIDATION.ACCEPTED && (
                        <MenuLine title="Archiver" color={baseColors.BLACK} onClick={archive} icon="ic:round-archive"/>
                    )}
                    <MenuLine title="Supprimer" color={baseColors.RED} onClick={clickClosr} icon="eva:trash-fill"/>
                </MenuPopover>
            )}
            {openD && (
                <ActionModal
                    title="Supprimer cette affectation ?"
                    desc="Cette action ne peut pas être annulée"
                    color="error"
                    open={openD}
                    onClose={onCloseD}
                    moreAction={
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            onClick={() => setDeleteOK(!deleteOk)}
                            sx={{cursor: 'pointer'}}
                        >
                            <Checkbox size="small" color="error" checked={deleteOk}/>
                            <Typography fontSize={14} fontWeight={120}>
                                Je comprends qu'une fois supprimé, cette tâche ne peut pas être récupérée.
                            </Typography>
                        </Stack>
                    }
                    action={
                        <LoadingButton color="error" variant="contained" disabled={!deleteOk} onClick={handleDeletion}>
                            Supprimer
                        </LoadingButton>
                    }
                />
            )}
        </Stack>
    );
};

/** @param {{ affect: Affectation_v4_Type, onChange: (field:string, value: any) }} */
export const AffetLinePriority = ({affect, disabled, onChange}) => {
    const [openP, onOpenP, onCloseP] = useToggleV2();
    const priorityRef = useRef();
    const [priority, setPriority] = useState(affect.priority);

    React.useEffect(() => setPriority(affect.priority), [affect]);

    const handleClose = (val) => {
        onCloseP();
        onChange('priority', val);
    };

    return (
        <>
            {!isEmpty(priority) ? (
                <Stack>
                    <Stack width={35}>
                        <Tooltip title={priority}>
                            <IconButton
                                ref={priorityRef}
                                onClick={disabled ? undefined : onOpenP}
                                size="small"
                                variant="outlined"
                                color="neutral"
                                sx={{ml: 1, p: 0.65}}
                            >
                                <Icon icon="eva:flag-fill" color={priorityColor(priority)} height={15} width={15}/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
            ) : (
                <Stack ref={priorityRef} width={1} alignItems="center">
                    <Stack width={35}>
                        <Tooltip title="Ajouter une priorité" arrow>
                            <IconButton
                                variant="outlined"
                                color="neutral"
                                onClick={onOpenP}
                                size="small"
                                sx={{ml: 1, p: 0.65}}
                                disabled={disabled}
                            >
                                <Icon icon="eva:flag-outline" height={15} width={15}/>
                            </IconButton>
                        </Tooltip>
                    </Stack>
                </Stack>
            )}
            {openP && (
                <PriorityFlags
                    value={priority}
                    open={openP}
                    onClose={handleClose}
                    onChange={setPriority}
                    anchorRef={priorityRef}
                    list={AFFECT_PRIORITY}
                />
            )}
        </>
    );
};

/** @param {{ vue: 'input' | 'default', affect: Affectation_v4_Type, onChange: (field:string, value) }} */
export const AffectLineDue = ({affect, onChange, vue}) => {
    const {settings: {organigramme} = Affectation_Setting_model} = useAffectation_v4Context();
    const {user} = useAuth();
    const {due} = affect;
    const [dueDates, setDuedates] = useState(due || []);
    const [hover, onHover, endHover] = useToggleV2();

    useEffect(() => {
        if (affect.due?.length) {
            setDuedates(affect.due);
        }
    }, [affect.due]);

    const myLevel = useMemo(() => {
        const position = organigramme.findIndex((one) => one.users.find((_userId) => _userId === user.uid));
        if (!dueDates.length) {
            const _ass = organigramme.map((org) => ({label: org.label, assigneBy: null, assigneTo: null, date: null}));
            setDuedates(_ass);
            console.log(_ass, position);
            if (position !== -1) {
                return {
                    due: _ass,
                    position
                };
            }

            return {due: _ass, position: 0};
        }
        return {due: dueDates, position};
    }, [dueDates, organigramme, user.uid]);

    const myLevelDate = useMemo(() => {
        return myLevel.due[myLevel.position + 1]?.date;
    }, [myLevel]);

    const handleChangeDate = (datestr) => {
        const [day, month, year] = datestr.split('-');
        const jsDate = new Date(`${year}-${month}-${day}`);

        let newDates = [...dueDates];

        newDates = newDates.map((_d, index) => {
            if (index === myLevel.position + 1) {
                return {
                    ..._d,
                    date: jsDate
                };
            }
            return _d;
        });
        setDuedates(newDates);
        console.log({newDates, position: myLevel.position});
        onChange('due', newDates);
    };

    const onRemoveDate = () => {
        let newDates = [...dueDates];
        newDates = newDates.map((_d, index) => {
            if (index === myLevel.position + 1) {
                return {
                    ..._d,
                    date: null
                };
            }
            return _d;
        });

        setDuedates(newDates);
        onChange('due', newDates);
    };

    return myLevelDate ? (
        <Sheet
            component={Stack}
            direction="row"
            spacing={0.5}
            width={1}
            justifyContent="center"
            alignItems="center"
            sx={{cursor: 'pointer', px: 1, py: 1, borderRadius: 'sm'}}
            onMouseEnter={onHover}
            onMouseLeave={endHover}
            variant={vue === 'input' ? 'soft' : 'plain'}
        >
            <Stack width={1} fontWeight="bold" fontSize={12}>
                {format(gDate(myLevelDate), 'dd/MM/yy', {locale: LocalFr})}
            </Stack>
            {hover && (
                <CIconButton title="Supprimer" noBorder size="small" sx={{p: 0.1}} onClick={onRemoveDate}>
                    <Icon height={15} width={15} icon="eva:close-fill" color="red"/>
                </CIconButton>
            )}
        </Sheet>
    ) : (
        <Sheet
            component={Stack}
            position="relative"
            variant={vue === 'input' ? 'soft' : 'plain'}
            width={1}
            alignItems={vue === 'input' ? 'flex-end' : 'center'}
            sx={{cursor: 'pointer', px: 1, py: 0.5, borderRadius: 'sm'}}
        >
            <CIconButton title="ajouter une échéance" {...(vue === 'input' && {noBorder: true})}>
                <Iconify icon="bx:calendar"/>
            </CIconButton>
            <Stack width={1} position="absolute" sx={{cursor: 'pointer'}}>
                <DatePicker
                    onChange={(_, dateString) => handleChangeDate(dateString)}
                    popupStyle={{zIndex: 1550}}
                    format="DD-MM-YYYY"
                    style={{opacity: 0, height: 40, cursor: 'pointer'}}
                />
            </Stack>
        </Sheet>
    );
};

/** @param {{ affect: Affectation_v4_Type, onChange: (field, value) }} */
export const AffectLineAssigne = ({affect, onChange}) => {
    const {settings: {organigramme} = Affectation_Setting_model} = useAffectation_v4Context();
    const {users} = useSelector((state) => state.user);
    const {user} = useAuth();
    const {assignationOrganigramme} = affect;
    const [assigne, setAssigne] = useState(assignationOrganigramme || []);

    const myLevel = useMemo(() => {
        const position = organigramme.findIndex((one) => one.users.find((_userId) => _userId === user.uid));
        if (!assigne.length) {
            const _ass = organigramme.map((org) => ({...org, users: []}));
            if (position !== -1) {
                return {
                    assigne: _ass,
                    position
                };
            }
            return {assigne: _ass, position: 0};
        }
        return {assigne: assigne, position};
    }, [assigne, organigramme, user.uid]);

    const toCompleteUser = useMemo(
        () => myLevel?.assigne?.at(myLevel.position + 1).users?.map((userId) => users.find((user) => user?.id === userId)),
        [users, myLevel]
    );

    const customList = useMemo(() => {
        const levelUsers = organigramme[myLevel.position + 1];
        return levelUsers.users.map((userId) => users.find((user) => user?.id === userId));
    }, [myLevel.position, organigramme, users]);

    const handleAssigne = (users) => {
        const userIds = users?.map((user) => user?.id);
        let newOrg = [...assigne];

        newOrg = newOrg.map((item, index) => {
            if (index === myLevel.position + 1) {
                return {...item, users: userIds};
            }
            return item;
        });

        setAssigne(newOrg);
        onChange('assignationOrganigramme', newOrg);
    };

    return (
        <Stack direction="row" spacing={0.5} justifyContent="center" alignItems="center">
            <ContactsDialog
                assigne={toCompleteUser}
                onAssigne={handleAssigne}
                CustomList={customList}
                action={(popoverRef, onClick) => (
                    <Box ref={popoverRef} onClick={onClick}>
                        {myLevel?.assigne?.at(myLevel.position + 1)?.users?.length ? (
                            <Avatar.Group maxCount={1}>
                                {myLevel?.assigne?.at(myLevel.position + 1)?.users.map((userId) => {
                                    const user = users.find((one) => one?.id === userId);
                                    return (
                                        <Tooltip key={userId} title={user?.displayName || ''} arrow>
                                            <Avatar
                                                key={userId}
                                                src={getAvatarUrl(user)}
                                                style={{
                                                    backgroundColor: createAvatar(user?.displayName).color2,
                                                    fontWeight: 'bold',
                                                    borderRadius: 10,
                                                    marginRight: 8
                                                }}
                                            >
                                                {createAvatar(user?.displayName).name}
                                            </Avatar>
                                        </Tooltip>
                                    );
                                })}
                            </Avatar.Group>
                        ) : (
                            <CIconButton
                                noBorder
                                title={`ajouter ou retirer un ${myLevel?.assigne?.at(myLevel.position + 1)?.label || ''}`}
                            >
                                <Iconify icon="humbleicons:user-add"/>
                            </CIconButton>
                        )}
                    </Box>
                )}
            />
        </Stack>
    );
};

/** @param {{ affect: Affectation_v4_Type, onChange: (field, value) }} */
export const AffectLineValidation = ({affect, onChange}) => {
    const [completed, setCompleted] = useState(affect.completed);
    const [validated, setValidated] = useState(affect.validation);
    const {settings: {organigramme} = {organigramme: []}} = useAffectation_v4Context();
    const {user} = useAuth();

    const handleChangeDone = () => {
        setCompleted(true);
        onChange(['completed', 'completedBy'], [true, user.uid]);
    };

    const handleChangeNotDone = () => {
        setCompleted(false);
        onChange(['completed', 'completedBy'], [false, null]);
    };

    const handleReject = () => {
        const val = {...validated, state: false, by: null};
        setValidated(val);
        onChange('validation', val);
        handleChangeNotDone()
    };

    const handleValidate = () => {
        const val = {...validated, state: true, by: user.uid};
        setValidated(val);
        onChange('validation', val);
    };

    const levelDown = useMemo(() => {
        const myposition = organigramme.findIndex((one) => one.users.find((_userId) => _userId === user.uid));
        const completedByposition = organigramme.findIndex((one) =>
            one.users.find((_userId) => _userId === affect.completedBy)
        );
        if (completedByposition !== -1 && myposition !== -1) {
            return myposition > completedByposition;
        }

        return false;
    }, [affect.completedBy, organigramme, user.uid]);

    return (
        <Stack>
            {completed ? (
                affect.completedBy === user.uid || levelDown ? (
                    <Tooltip title="Marquer comme non terminée" arrow>
                        <Button size="sm" variant="solid" color="success" onClick={handleChangeNotDone}
                                sx={{fontSize: 10}}>
                            Terminée
                        </Button>
                    </Tooltip>
                ) : !validated.state ? (
                    <ButtonGroup size="sm">
                        <Tooltip title="rejeter" arrow>
                            <IconButton color="danger" variant="solid" onClick={handleReject}>
                                <Iconify icon="uiw:dislike-o"/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="accepter" arrow>
                            <IconButton color="success" variant="solid" onClick={handleValidate}>
                                <Iconify icon="uiw:like-o"/>
                            </IconButton>
                        </Tooltip>
                    </ButtonGroup>
                ) : (
                    <Tooltip title="Marquer comme non terminée" arrow>
                        <Button size="sm" variant="solid" color="success" onClick={handleChangeNotDone}
                                sx={{fontSize: 10}}>
                            Terminée
                        </Button>
                    </Tooltip>
                )
            ) : (
                <CIconButton title="Marquer comme terminée" onClick={handleChangeDone}>
                    <Iconify icon="icon-park-outline:done-all"/>
                </CIconButton>
            )}
        </Stack>
    );
};

/**
 *
 * @param {{vue: 'input' | 'default',date:Date|null, onChange:(value:Date|null)}}
 */
export const AffectLineDate = ({vue, date, onChange, error, helperText, disabled = false}) => {
    const [value, setValue] = useState(date);
    const [hover, onHover, endHover] = useToggleV2();

    const parseDateString = (dateString) => {
        // Split the input string into date and time parts
        const [datePart, timePart] = dateString.split(' ');

        // Split the date part into day, month, and year
        const [day, month, year] = datePart.split('-').map(Number);

        // Split the time part into hours and minutes
        const [hours, minutes] = timePart.split(':').map(Number);

        // Create a Date object using the parsed values (month is 0-based in JavaScript)
        const parsedDate = new Date(year, month - 1, day, hours, minutes);

        return parsedDate;
    }


    const handleChange = (val) => {
        if (disabled) return;

        const change = (val) => {
            setValue(val)
            if (onChange) onChange(val)
        }

        if (!val) return change(null)

        change(parseDateString(val))

    }

    return (
        <>
            {
                value ? (
                    <Sheet
                        component={Stack}
                        direction="row"
                        spacing={0.5}
                        width={1}
                        justifyContent="center"
                        alignItems="center"
                        sx={{cursor: 'pointer', px: 1, py: 1, borderRadius: 'sm'}}
                        onMouseEnter={onHover}
                        onMouseLeave={endHover}
                        variant={vue === 'input' ? 'soft' : 'plain'}
                    >
                        <Stack width={1} fontWeight="bold" fontSize={12}>
                            {format(gDate(value), 'dd/MM/yy, HH:mm', {locale: LocalFr})}
                        </Stack>
                        {hover && (
                            <CIconButton title="Supprimer" noBorder size="small" sx={{p: 0.1}}
                                         onClick={env => handleChange(null)}>
                                <Icon height={15} width={15} icon="eva:close-fill" color="red"/>
                            </CIconButton>
                        )}
                    </Sheet>
                ) : (
                    <Sheet
                        component={Stack}
                        position="relative"
                        variant={vue === 'input' ? 'soft' : 'plain'}
                        width={1}
                        alignItems={vue === 'input' ? 'flex-end' : 'center'}
                        sx={{cursor: 'pointer', px: 1, py: 0.5, borderRadius: 'sm'}}
                    >
                        <CIconButton title="ajouter une échéance" {...(vue === 'input' && {noBorder: true})}>
                            <Iconify icon="bx:calendar"/>
                        </CIconButton>
                        <Stack width={1} position="absolute" sx={{cursor: 'pointer'}}>
                            <DatePicker
                                onChange={(_, dateString) => handleChange(dateString)}
                                popupStyle={{zIndex: 1550}}
                                format="DD-MM-YYYY HH:mm"
                                style={{opacity: 0, height: 40, cursor: 'pointer'}}
                                showTime
                            />
                        </Stack>
                    </Sheet>
                )
            }
            {
                error && (
                    <Typography fontSize={11} pl={1} color={'error'}>
                        {helperText}
                    </Typography>
                )
            }


        </>
    )

}