import { useEffect, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { nanoid } from '@reduxjs/toolkit';
import { defaultDb } from './FirebaseContext';
import { useNotifSnackbarContext } from '../App';
import { NOTIFICATION_TYPES } from 'src/constants';
import { NotificationSnackbar } from './../components/NotificationBody';
import { playNotificationSound } from './../utils/webNotif';
import { matchPath } from 'react-router';
import { PATH_DASHBOARD } from 'src/routes/paths';
// import { Button } from '@mui/material';

export default function NotificationContext({ children }) {
  const { setNotificationIds, canSendChatNotif, pathname } = useNotifSnackbarContext();
  // const { addDialog, removeDialog } = useMultiDialog();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleCloseNotification = useCallback((id, _notif) => {
    const thisNotif = _notif.find((one) => one?.id === id);
    closeSnackbar(thisNotif?.key);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultDb.messaging.isSupported()) {
      let stopSound = null;

      defaultDb.messaging().onMessage((payload) => {
          const { title, body } = payload.notification;
          let customTitle = title;
          let customDesc = body;

          const bodyParse = JSON.parse(payload?.data['data']);
          if (
            bodyParse?.type === NOTIFICATION_TYPES.COMMUNICATION_NEW_MESSAGE &&
            !!matchPath({ path: PATH_DASHBOARD.general.app, end: false }, pathname)
          ) {
            return;
          }
          if (bodyParse?.type === NOTIFICATION_TYPES.RAPPEL) {
            stopSound = playNotificationSound(true);
          }

          const snackId = nanoid();
          let persist = true;

          if (bodyParse?.type === NOTIFICATION_TYPES.CHAT && !canSendChatNotif) {
            persist = false;
            return;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.CHAT) {
            persist = false;
            customTitle = 'Vous avez reçu un nouveau message';
            customDesc = `<strong> ${bodyParse?.by?.displayName}</strong> vous a envoyé un nouveau message`;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.TASK_VALIDATION_DEMANDE) {
            customTitle = 'Demande de validation';
            customDesc = `Vous avez une demande de validation de la part de <strong>
            ${bodyParse?.by?.displayName}</strong> sur la tâche <strong> << ${bodyParse?.cardTitle} >> </strong>
            du projet <strong> << ${bodyParse?.other?.projectName} >> </strong>`;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.TASK_MENTION) {
            const taskName = bodyParse?.action?.cardName || bodyParse?.action?.taskName;
            const projectName = bodyParse?.other?.projectName || bodyParse?.action?.projectName;

            customDesc = `Vous avez été mentionné ${bodyParse?.action?.target} sur la tâche <strong> <i>${taskName}</i></strong> du projet <strong> << ${projectName} >> </strong> par  <strong> <i>${bodyParse?.by?.displayName}</i></strong>`;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.TASK) {
            customDesc = `<strong> <i>${bodyParse?.by?.displayName}</i></strong> a apporté des modifications à la tâche <strong> << ${bodyParse?.cardTitle} >> </strong>
           du projet <strong> << ${bodyParse?.other?.projectName} >> </strong>`;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.TASK_VALIDATION_REJECT) {
            customTitle = 'Demande de validation rejetée';
            customDesc = `La demande de validation de la tâche
           <strong> << ${bodyParse?.cardTitle} >> </strong> du projet  <strong> << ${bodyParse?.other?.projectName} >> </strong> 
           a été rejetée par <strong>${bodyParse?.by?.displayName}</strong>`;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.TASK_BEGINING) {
            customTitle = 'Tâche démarrée';
            customDesc = `La tâche
           <strong> << ${bodyParse?.cardTitle} >> </strong> du projet  <strong> << ${bodyParse?.other?.projectName} >> </strong> 
           a été démarrée par <strong>${bodyParse?.by?.displayName}</strong>`;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.TASK_VALIDATION_ACCEPTED) {
            customTitle = 'Demande de validation acceptée';
            customDesc = `La demande de validation de la tâche 
          <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> 
          a été acceptée par <strong>${bodyParse?.by?.displayName}</strong>`;
          }

          if (
            bodyParse?.type === NOTIFICATION_TYPES.PROJECT_CREATE ||
            bodyParse?.type === NOTIFICATION_TYPES.PROJECT_UPDATE
          ) {
            customTitle = 'Vous avez été ajouter à un projet';
            customDesc = `Vous avez été ajouter au projet 
          <strong> << ${bodyParse?.cardTitle} >> </strong> par <strong>${bodyParse?.by?.displayName}</strong>`;
          }
          if (bodyParse?.type === NOTIFICATION_TYPES.PROJECT_MANAGER) {
            customTitle = 'Vous avez été nommé gestionnaire';
            customDesc = `Vous avez été ajouter au projet 
          <strong> << ${bodyParse?.cardTitle} >> </strong> par <strong>${bodyParse?.by?.displayName}</strong>`;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.TASK_VALIDATION_ASSIGN_USER) {
            customTitle = 'Vous avez été ajouté à une tâche';
            customDesc = `Vous avez été assigné à la tâche 
          <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> par <strong>${bodyParse?.by?.displayName}</strong>`;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.TASK_RETRIEVE) {
            customTitle = 'Une tâche vous a été retirée';
            customDesc = `La tâche <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> vous a été retirée par <strong>${bodyParse?.by?.displayName}</strong>`;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.TASK_DUE_CHANGE) {
            customTitle = 'L’échéance d’une tâche qui vous est attribuée a été modifiée';
            customDesc = `L’échéance de la tâche <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> a été modifiée par <strong>${bodyParse?.by?.displayName}</strong>`;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.TASK_PIECE_JOINTES) {
            customTitle = 'Une tâche que vous suivez a de nouvelles pièces jointes';
            customDesc = `La tâche <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> a une nouvelles pièces jointes, ajouté par <strong>${bodyParse?.by?.displayName}</strong>`;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.TASK_COMMENT_ADD) {
            customTitle = 'Un commentaire a été publié dans une tâche que vous suivez.';
            customDesc = `Un commentaire a été publié dans la tâche <strong> << ${bodyParse?.cardTitle} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> par <strong>${bodyParse?.by?.displayName}</strong>`;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.SUB_TASK_VALIDATION_ASSIGN_USER) {
            customTitle = 'Ajout à une sous tâche';
            customDesc = `Vous avez été assigné à la sous tâche <strong> <<${bodyParse?.cardTitle}>> </strong> de la tâche 
          <strong> << ${bodyParse?.other?.taskName} >> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> par <strong>${bodyParse?.by?.displayName}</strong>`;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.RELANCE_TASK) {
            customTitle = 'Relance sur une tâche';
            customDesc = `Vous avez été relancé sur la tâche <strong> <<${bodyParse?.cardTitle}>> </strong> du projet 
          <strong> << ${bodyParse?.other?.projectName} >> </strong> par <strong>${bodyParse?.by?.displayName}</strong>`;
          }

          if (bodyParse?.type?.includes('affect')) {
            customTitle = 'Affectation';
            customDesc = bodyParse?.description;
          }

          if (bodyParse?.type === NOTIFICATION_TYPES.CHAT_MENTION) {
            customTitle = 'Vous avez été mentionné';
            customDesc = `Vous avez été mentionné dans une coversation par <strong>${bodyParse?.by?.displayName}</strong>`;
          }


          if (bodyParse?.type?.includes('couriel')) {
            customTitle = bodyParse?.html?.title || bodyParse?.title;
            customDesc = bodyParse?.html?.description || bodyParse?.description;
          }

          const snackKey = enqueueSnackbar(
            <NotificationSnackbar
              open={true}
              title={customTitle}
              stopSound={stopSound}
              desc={customDesc}
              url={bodyParse?.url}
              action={bodyParse?.action}
              type={bodyParse?.type}
              id={snackId}
              onClose={handleCloseNotification}
            />,
            { persist: persist }
          );

          setNotificationIds((prev) => [...prev, { id: snackId, key: snackKey }]);
          return;
        });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canSendChatNotif, pathname]);

  return <>{children}</>;
}

 