import { styled } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

/**
 * @type {Tooltip}
 */
const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
  ({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.background.paper,
      color: 'inherit',
      maxWidth: 'max-content',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9'
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: '#FFF'
    }
  })
);

export default HtmlTooltip;
