import { Button, Stack, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import ActionModal from 'src/components/ActionModal';

export default function AddUserToProjectDialog({ open, onClose, users = [], onValidate }) {
  const many = useMemo(() => Boolean(users.length > 1), [users]);
  return (
    <ActionModal
      open={open}
      onClose={onClose}
      title="Confirmation"
      desc={`${many ? manyUsers : oneUser} `}
      closeText="Ne pas ajouter"
      action={
        <Button variant="contained" onClick={onValidate}>
          Ajouter
        </Button>
      }
    />
  );
}

const oneUser = "Cet utilisateur ne fait pas partie des intervenants de ce projet. Voulez-vous l'ajouter ?";
const manyUsers = 'Ces utilisateurs ne font pas partie des intervenants de ce projet. Voulez-vous les ajouter ?';
