import {Stack} from '@mui/material'

export default function Center({children, sx}) {
    return (
        <Stack justifyContent={'center'} sx={{...sx,height:"100%",width:"100%"}}>
            <Stack direction={'row'} justifyContent={'center'}>
                {children}
            </Stack>
        </Stack>
    )
}