import React from 'react';
import { useSelector } from 'src/redux/store';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';

export default function useTaskComment({taskId}) {
    const [loading, setLoading] = React.useState(false);

    const getTaskComment = useSelector(state => state.firestore.ordered[`${taskId}_comment_for_task`]);
    const taskComments = React.useMemo(()=> isEmpty(getTaskComment) ? [] : getTaskComment , [getTaskComment]);

    useFirestoreConnect(()=>[
        {
            collection:'tasks', 
            doc: taskId,
            subcollections:[{collection: 'comments'}], 
            orderBy:[['createdAt', 'desc']],
            storeAs: `${taskId}_comment_for_task`
        }
    ]);

    React.useEffect(()=> {
        setLoading(!isLoaded(getTaskComment));
    },[getTaskComment]);

  return {loading, taskComments};
}
