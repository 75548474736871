import { isEmpty } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import useAuth from './useAuth';
import { epochToDate } from '../utils/formatTime';
import { useDispatch } from '../redux/store';
import { chatForsakeNotification, getAllNotification, setNotificationsAsRead } from '../redux/slices/notifications';
import { useLocation, useNavigate } from 'react-router-dom';
import { NOTIFICATION_TYPES } from '../constants';
import { PATH_DASHBOARD } from '../routes/paths';
import { DateTime } from 'luxon';
import { useAffectationContext } from 'src/contexts/AffectationContext';
import { useSnackbar } from 'notistack';
import { useProjectContext } from 'src/contexts/ProjectContext';
import { useAudienceContext } from 'src/contexts/AudienceContext';
import { useCommunityContext } from 'src/contexts/CommunityContext';
import { useAffectation_v4Context } from 'src/contexts/Affectation_v4Context';

const getResult = (getList, id) => {
  const preResult = isEmpty(getList) ? [] : getList;
  return preResult
    ?.filter((el) => el?.type !== NOTIFICATION_TYPES.CHAT)
    ?.map((one) => {
      return {
        ...one,
        rawCreatedAt: one?.createdAt,
        createdAt: epochToDate(one.createdAt),
        isUnRead: one.isUnRead ? one?.isUnRead[id] : true
      };
    });
};

const dataF = DateTime.now().minus({ days: 7 }).toJSDate();

export default function useNotifications() {
  const {
    user: { id }
  } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const getNotifications = useSelector((state) => state.firestore.ordered.notifications);
  const getBroadcasts = useSelector((state) => state.firestore.ordered.broadcasts);

  const notifications = useMemo(() => getResult(getNotifications, id), [getNotifications, id]);
  const broadcasts = useMemo(() => getResult(getBroadcasts, id), [getBroadcasts, id]);

  const combine = useMemo(() => [...notifications, ...broadcasts], [notifications, broadcasts]);
  const { openDetailsById } = useAffectationContext();
  const { openById } = useProjectContext();
  const { openAudienceById } = useAudienceContext();
  const { selectedChannel, openChannelById } = useCommunityContext();

  const couriel = useAffectation_v4Context();

  const executeNotification = (notification) => {
    const { isUnRead, type, action, id } = notification;

    switch (type) {
      case NOTIFICATION_TYPES.COMMUNICATION_ADD_TO_CHANNEL:
        {
          navigate(PATH_DASHBOARD.general.app);
          openChannelById(action?.id);
          selectedChannel(action?.id);
        }
        break;

      case NOTIFICATION_TYPES.COMMUNICATION_NEW_MESSAGE:
        {
          navigate(PATH_DASHBOARD.general.app);
          openChannelById(action?.id);
          selectedChannel(action?.id);
        }
        break;

      case NOTIFICATION_TYPES.COMMUNICATION_NEW_ANSWER_MESSAGE:
        {
          navigate(`${PATH_DASHBOARD.general.app}/${action?.id}/${action?.messageId}`);
          openChannelById(action?.id);
          selectedChannel(action?.id);
        }
        break;

      case NOTIFICATION_TYPES.AUDIENCE:
        {
          const audienceId = action?.audienceId;
          if (audienceId) openAudienceById(audienceId);
        }
        break;
      case NOTIFICATION_TYPES.BLOG:
        action.postId && navigate(PATH_DASHBOARD.blog.post.replace(':title', action.postId));
        break;
      //
      // case NOTIFICATION_TYPES.TASK:
      //     action?.projectKey && navigate(`${PATH_DASHBOARD.general.tasks}/${action?.projectKey}`, {
      //         state: {
      //             projectName: ' ',
      //             detailId: notification?.returnId
      //         }
      //     });
      //     break;
      // case NOTIFICATION_TYPES.TASK_VALIDATION_ACCEPTED:
      //     action?.projectKey && navigate(`${PATH_DASHBOARD.general.tasks}/${action?.projectKey}`, {
      //         state: {
      //             projectName: ' ',
      //             detailId: notification?.returnId
      //         }
      //     });
      //     break;
      // case NOTIFICATION_TYPES.TASK_VALIDATION_ASSIGN_USER:
      //     action?.projectKey && navigate(`${PATH_DASHBOARD.general.tasks}/${action?.projectKey}`, {
      //         state: {
      //             projectName: ' ',
      //             detailId: notification?.returnId
      //         }
      //     });
      //     break;
      // case NOTIFICATION_TYPES.TASK_VALIDATION_DEMANDE:
      //     action?.projectKey && navigate(`${PATH_DASHBOARD.general.tasks}/${action?.projectKey}`, {
      //         state: {
      //             projectName: ' ',
      //             detailId: notification?.returnId
      //         }
      //     });
      //     break;
      // case NOTIFICATION_TYPES.TASK_VALIDATION_REJECT:
      //     action?.projectKey && navigate(`${PATH_DASHBOARD.general.tasks}/${action?.projectKey}`, {
      //         state: {
      //             projectName: ' ',
      //             detailId: notification?.returnId
      //         }
      //     });
      //     break;
      case NOTIFICATION_TYPES.PROJECT_CREATE:
        action?.projectKey &&
          navigate(`${PATH_DASHBOARD.general.tasks}/${action?.projectKey}`, {
            state: {
              projectName: ' '
            }
          });
        break;
      case NOTIFICATION_TYPES.PROJECT_UPDATE:
        action?.projectKey &&
          navigate(`${PATH_DASHBOARD.general.tasks}/${action?.projectKey}`, {
            state: {
              projectName: ' '
            }
          });
        break;

      case NOTIFICATION_TYPES.OPPORTUNITIES:
        navigate(PATH_DASHBOARD.general.opportunity.root);
        break;

      // case NOTIFICATION_TYPES.AUDIENCE:
      //     navigate(PATH_DASHBOARD.general.accueil.root);
      //     break;

      case NOTIFICATION_TYPES.STAGE:
        navigate(PATH_DASHBOARD.general.accueil.root);
        break;

      case NOTIFICATION_TYPES.CHAT:
        navigate(PATH_DASHBOARD.chat.root);
        break;

      case NOTIFICATION_TYPES.LOAN:
        navigate(PATH_DASHBOARD.gestion_client.root);
        break;

      case NOTIFICATION_TYPES.PROJECT_CHAT:
        action?.projectId &&
          navigate(`${PATH_DASHBOARD.general.tasksProject.replace(':projectId', action?.projectId)}?tab=Conversation`, {
            state: { projectName: '  ' }
          });
        break;

      case NOTIFICATION_TYPES.GOUTI_PROJECT:
        action?.id && navigate(PATH_DASHBOARD.general.project.wallet.detail.replace(':id', action?.id));
        break;

      case NOTIFICATION_TYPES.GOUTI_TASK:
        action?.id && navigate(PATH_DASHBOARD.general.project.progress_control.tasks.detail.replace(':id', action?.id));
        break;

      case NOTIFICATION_TYPES.GOUTI_ACTION:
        action?.id &&
          navigate(PATH_DASHBOARD.general.project.progress_control.actions.detail.replace(':id', action?.id));
        break;

      case NOTIFICATION_TYPES.GOUTI_PROBLEM:
        action?.id &&
          navigate(PATH_DASHBOARD.general.project.progress_control.problems.detail.replace(':id', action?.id));
        break;

      case NOTIFICATION_TYPES.GOUTI_RISK:
        action?.id && navigate(PATH_DASHBOARD.general.project.progress_control.risks.detail.replace(':id', action?.id));
        break;

      case NOTIFICATION_TYPES.GOUTI_CHANGE:
        action?.id &&
          navigate(PATH_DASHBOARD.general.project.progress_control.change.detail.replace(':id', action?.id));
        break;

      case NOTIFICATION_TYPES.GOUTI_DELIVERABLE:
        action?.id && navigate(PATH_DASHBOARD.general.project.framing.deliverables.detail.replace(':id', action?.id));
        break;

      case NOTIFICATION_TYPES.GOUTI_MILESTONE:
        action?.id && navigate(PATH_DASHBOARD.general.project.framing.milestones.detail.replace(':id', action?.id));
        break;

      case NOTIFICATION_TYPES.GOUTI_ORGANIZATION:
        action?.id && navigate(PATH_DASHBOARD.general.project.framing.organization.detail.replace(':id', action?.id));
        break;

      case NOTIFICATION_TYPES.GOUTI_GOAL:
        action?.id && navigate(PATH_DASHBOARD.general.project.framing.goals.root);
        break;

      case NOTIFICATION_TYPES.ARCHIVE:
        navigate(
          `${PATH_DASHBOARD.general.archive.root}/${action?.folderId && `?folderId=${action?.folderId}`}${action?.fileId && `&fileId=${action?.fileId}`
          }`
        );
        break;

      default: {
        if (type?.includes('couriel')) {
          return couriel?.openFormById(action?.id);
        }

        if (type.includes('affect')) {
          openDetailsById(action?.id);
        } else if (type?.toLowerCase()?.includes('task')) {
          const taskId =
            action?.id ||
            action?.cardId ||
            action?.detailId ||
            action?.taskId ||
            action?.returnId ||
            notification?.returnId;

          if (taskId) openById(taskId);
        } else {
          console.error(`Type "${type}" for the notification with id "${id}" not recognized!`);
        }
      }
    }

    isUnRead &&
      dispatch(
        setNotificationsAsRead({
          notificationIds: [notification.id]
        })
      );
  };

  useFirestoreConnect(() => [
    {
      collection: 'notifications',
      where: [
        ['canAccess', 'array-contains', id],
        ['createdAt', '>=', dataF]
      ],
      orderBy: ['createdAt', 'desc'],
      storeAs: 'notifications'
    },
    {
      collection: 'notifications',
      where: [
        ['isBroadcast', '==', true],
        ['createdAt', '>=', dataF]
      ],
      orderBy: ['createdAt', 'desc'],
      limit: 30,
      storeAs: 'broadcasts'
    }
  ]);

  useEffect(() => {
    const state = !isLoaded(getNotifications) || !isLoaded(getBroadcasts);

    setLoading(state);

    state && dispatch(getAllNotification([...(getNotifications || []), ...(getBroadcasts || [])]));
  }, [getNotifications, getBroadcasts, combine, dispatch]);

  //Forsake notifications when the user is on the chat
  useEffect(() => {
    const onChatPath = location.pathname === PATH_DASHBOARD.chat.root;
    const unReadNotifications = notifications.filter(
      (item) => item.isUnRead === true && item.type === NOTIFICATION_TYPES.CHAT
    );
    const unReadIds = [...unReadNotifications.map((one) => one.id)];
    const unReadNotifTrueData = getNotifications?.filter((one) => unReadIds.includes(one.id)) || [];

    if (onChatPath && !isEmpty(unReadNotifTrueData)) {
      dispatch(chatForsakeNotification(unReadNotifTrueData));
    }
  }, [dispatch, getNotifications, location, notifications]);

  return { loading, notifications, broadcasts, combine, executeNotification };
}
