import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { grey, blue } from "./CustomInput";



  
export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
      position: 'relative',
      color: theme.palette.mode === 'dark' ? grey[300] : grey[900],
      background: theme.palette.mode === 'dark' ? grey[900] : grey[50],
      border: 'none',
      fontSize: '0.875rem',
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      borderRadius: 5,
      fontFamily: [
          '-apple-system',
          'BlinkMacSystemFont',
          '"Segoe UI"',
          'Roboto',
          '"Helvetica Neue"',
          'Arial',
          'sans-serif',
          '"Apple Color Emoji"',
          '"Segoe UI Emoji"',
          '"Segoe UI Symbol"',
        ].join(','),

    '&:hover': {
        borderRadius: 5,
        background: theme.palette.mode === 'dark' ? null : grey[100],
        borderColor: theme.palette.mode === 'dark' ? grey[700] : grey[400],
    },
    
    '&:focus': {
        borderRadius: 5,
        outline: `2px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]}`,
        outlineOffset:0,
    },
  },
}));

