import React from 'react';
import { Icon } from '@iconify/react';
import flagFill from '@iconify/icons-eva/flag-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { Box, MenuItem, Typography } from '@mui/material';
import { CustomMenuPopover } from 'src/components/MenuPopover';
import { TASK_PRIORITIES } from 'src/constants';
import { priorityColor } from 'src/helpers/priorityColor';
import { baseColors } from 'src/constants/color';

export default function PriorityFlags({ value, open, onClose, anchorRef, onChange, horizontal, list = null }) {
  const handleChange = (val) => {
    onChange(val);
    onClose(val);
  };

  const handleClose = () => {
    onClose(value);
  };

  return (
    <CustomMenuPopover
      width={170}
      open={open}
      onClose={handleClose}
      disabledArrow
      anchorEl={anchorRef.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      {Object.entries(list || TASK_PRIORITIES).map((_priority) => (
        <MenuItem key={_priority[1]} onClick={() => handleChange(_priority[1])}>
          <Box
            component={Icon}
            icon={flagFill}
            color={priorityColor(_priority[1])}
            sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }}
          />
          <Typography fontSize={12}>{_priority[1]}</Typography>
        </MenuItem>
      ))}
      <MenuItem onClick={() => handleChange('')}>
        <Box
          component={Icon}
          icon={closeFill}
          color={baseColors.RED}
          sx={{ width: 20, height: 20, flexShrink: 0, mr: 1 }}
        />
        <Typography fontSize={12}>Effacer</Typography>
      </MenuItem>
    </CustomMenuPopover>
  );
}
