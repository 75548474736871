import { trim } from 'lodash';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { fr as LocalFr } from 'date-fns/locale';
import { format, isSameDay, isSameMonth } from 'date-fns';
// material
import { Paper, Box, OutlinedInput, ClickAwayListener } from '@mui/material';
//
import { v4 as uuidv4 } from 'uuid';
import { defaultTask } from './KanbanTaskAdd';

// ----------------------------------------------------------------------

export function useDatePicker({ date }) {
  const [dueDate, setDueDate] = useState([date[0], date[1]]);
  const [openPicker, setOpenPicker] = useState(false);

  const startTime = dueDate[0];
  const endTime = dueDate[1];

  const isSameDays = isSameDay(new Date(startTime), new Date(endTime));
  const isSameMonths = isSameMonth(new Date(startTime), new Date(endTime));

  const handleChangeDueDate = (newValue) => {
    setDueDate(newValue);
  };

  const handleOpenPicker = () => {
    setOpenPicker(true);
  };

  const handleClosePicker = () => {
    setOpenPicker(false);
  };

  return {
    dueDate,
    startTime,
    endTime,
    isSameDays,
    isSameMonths,
    onChangeDueDate: handleChangeDueDate,
    openPicker,
    onOpenPicker: handleOpenPicker,
    onClosePicker: handleClosePicker
  };
}

DisplayTime.propTypes = {
  isSameDays: PropTypes.bool,
  isSameMonths: PropTypes.bool,
  onOpenPicker: PropTypes.func,
  startTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  endTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)]),
  sx: PropTypes.object
};

export function DisplayTime({ startTime, endTime, isSameDays, isSameMonths, onOpenPicker, sx }) {
  const style = {
    typography: 'caption',
    cursor: 'pointer',
    '&:hover': { opacity: 0.72 }
  };

  if (isSameMonths) {
    return (
      <Box onClick={onOpenPicker} sx={{ ...style, ...sx }}>
        {isSameDays
          ? format(new Date(endTime), 'dd MMM', { locale: LocalFr })
          : `${format(new Date(startTime), 'dd', { locale: LocalFr })} - ${format(new Date(endTime), 'dd MMM', {
              locale: LocalFr
            })}`}
      </Box>
    );
  }
  return (
    <Box onClick={onOpenPicker} sx={{ ...style, ...sx }}>
      {format(new Date(startTime), 'dd MMM', { locale: LocalFr })} -{' '}
      {format(new Date(endTime), 'dd MMM', { locale: LocalFr })}
    </Box>
  );
}

ModelKanbanTaskAdd.propTypes = {
  onAddTask: PropTypes.func,
  onCloseAddTask: PropTypes.func
};

export default function ModelKanbanTaskAdd({ onAddTask, onCloseAddTask }) {
  const [name, setName] = useState('');
  // const [completed, setCompleted] = useState(false);
  const { dueDate } = useDatePicker({
    date: [null, null]
  });

  const handleKeyUpAddTask = (event) => {
    if (event.key === 'Enter' || event.keyCode === 13) {
      if (trim(name) !== '') {
        const id = uuidv4();
        onAddTask({ ...defaultTask, id, name, due: dueDate, completed: false });
        return setName('');
      }
      onCloseAddTask();
    }
  };

  const handleClickAddTask = () => {
    if (name) {
      const id = uuidv4();
      onAddTask({ ...defaultTask, id, name, due: dueDate, completed: false });
      return setName('');
    }
    onCloseAddTask();
  };

  // const handleChangeCompleted = (event) => {
  //   setCompleted(event.target.checked);
  // };

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAddTask}>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <OutlinedInput
            multiline
            size="small"
            placeholder="Titre"
            value={name}
            onChange={(event) => setName(event.target.value)}
            onKeyUp={handleKeyUpAddTask}
            sx={{
              '& input': { p: 0 },
              '& fieldset': { borderColor: 'transparent !important' }
            }}
          />
        </Paper>
      </ClickAwayListener>
    </>
  );
}
