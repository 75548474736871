import React from 'react';

export default function FolderSvg({color}) {

  return <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="100%" height="100%" focusable="false" viewBox="0 0 120 120">
            <g>
                <path 
                    fill={color === '' ? '#c7c4c4' : color}
                    d="M100.5,18.5H70.9c-3.7,0-6.9-1.4-9.7-4.2L50.5,3.7c-2.8-2.3-6-3.7-9.7-3.7H19.9C8.8,0,0,8.8,0,19.8v79.8 C0,111.2,8.8,120,19.9,120h80.1c11.1,0,19.9-8.8,19.9-19.8V38.3C120.4,27.2,111.6,18.5,100.5,18.5z" 
                />
                 <clipPath id="clip-path">
                    <path d="M100.5,18.5H70.9c-3.7,0-6.9-1.4-9.7-4.2L50.5,3.7c-2.8-2.3-6-3.7-9.7-3.7H19.9C8.8,0,0,8.8,0,19.8v79.8 C0,111.2,8.8,120,19.9,120h80.1c11.1,0,19.9-8.8,19.9-19.8V38.3C120.4,27.2,111.6,18.5,100.5,18.5z" />
                </clipPath>
                <image clipPath="url(#clip-path)"  href={`${color}`} height="100%"  />
                   
            </g>
        </svg>;
}
