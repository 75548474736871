import { Icon } from '@iconify/react';
import { alpha, ClickAwayListener, Stack, useTheme } from '@mui/material';
import { blue, grey } from '@mui/material/colors';
import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import CIconButton from 'src/components/CIconButton';
import { InputWithMention, MuiCustomInput } from 'src/components/CustomInput';
import { QuillEditor } from 'src/components/editor';
import { themeColor } from 'src/constants/color';
import { TaskType } from 'src/models/task_m';
import { dispatch } from 'src/redux/store';
import { removeHTML } from 'src/utils/removeHtmlTags';

/**
 *
 * @param {{task: TaskType}} props
 * @returns
 */
export default function TaskDescriptionInput({ task, onUpdate, placeholder = 'Ajouter une description' }) {
  const theme = useTheme();
  const [values, setValues] = React.useState({
    description: task?.description || '',
    mentions: task?.mentions || []
  });

  const handleChange = (filed, value) => {
    setValues((prev) => ({ ...prev, [filed]: value }));
  };

  const onSave = () => {
    if (!isEmpty(task?.name)) {
      dispatch(onUpdate(task, values, () => {}));
    }
  };

  return (
    <ClickAwayListener onClickAway={onSave}>
      <Stack width={1} bgcolor={alpha(themeColor.SMOKY_BLACK, 0.02)} spacing={0.5} p={0.5}>
        <DescriptionView details={task} value={values.description} handleDetailChange={handleChange} theme={theme} />
      </Stack>
    </ClickAwayListener>
  );
}

export const DescriptionView = ({ details, value, handleDetailChange, theme }) => {
  return (
    <Stack width={1} spacing={1}>
      <QuillEditor
        simple
        id="comment-compo-desc-id"
        placeholder="Description de la tâche"
        value={value}
        onChange={(e) => handleDetailChange('description', e)}
        canMention={true}
        setMensions={(mentions) => handleDetailChange('mentions', [...(details?.mentions || []), ...mentions])}
        sx={{
          backgroundColor: theme.palette.mode === 'dark' ? grey[900] : grey[50],
          color: theme.palette.mode === 'dark' ? grey[300] : grey[900],
          border: 'none',
          borderRadius: '5px',
          padding: '6px 12px',
          transition: 'all 200ms ease',
          fontSize: '13px',
          '&:hover': {
            backgroundColor: theme.palette.mode === 'dark' ? null : grey[50],
            borderColor: theme.palette.mode === 'dark' ? grey[700] : grey[400]
          },
          '&:focus': {
            outline: `2px solid ${theme.palette.mode === 'dark' ? blue[400] : blue[200]}`,
            outlineOffset: 0
          }
        }}
      />
    </Stack>
  );
};
