/**
 *
 * @param {*} data
 * @param {*} property
 * @param {*} text
 * @returns {Array}
 */
export const nameFilter = (data = [], property = '', text = '') => {
  const textTabs = text.split(' ');
  const havePropertie = data.filter((_d) => _d[property]).map((item) => ({ ...item, match: 0 }));

  const res = [...havePropertie].filter((d) => {
    textTabs.forEach((t) => {
      const isIncludes = d[property].toString().toLowerCase().includes(t.toString().toLowerCase());
      if (isIncludes) d.match += 1;
    });

    if (d?.match) return d;
    return -1;
  });

  res.sort((a, b) => b?.match - a?.match);

  return res
    .filter((one) => one.match)
    .map((r) => {
      const { match, ...rs } = r;
      return rs;
    });
};
