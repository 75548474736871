import { isEmpty, uniq } from "lodash";
import { AFFECT_STATE_VALIDATION, NOTIFICATION_TYPES } from "src/constants";
import { auth } from "src/contexts/FirebaseContext";
import { AffectationType } from "src/models/Affectation_m";
import { serverTime } from "src/utils/serverTime";
import { createNotification } from "../notifications";

/** @param {{ affect: AffectationType }} */
export function creationNotif({ affect }){
    return async (dispatch) =>{
            const { currentUser } = auth;
            const to =  [affect.responsable.id];
            const listIds = {};
            to.forEach(id => listIds[id] = true);

        const desc = `Une affectation ${affect.correspondance.reference} de type ${affect.correspondance.typeOp} ${!isEmpty(affect.correspondance.priority) ? 'de priorité '+ affect.correspondance.priority : ''} vous a été ajouté par ${currentUser.displayName}` ;
                     
        try {
            dispatch(createNotification({
                data: {
                    title:"Une nouvelle affectation à traiter",
                    description: desc,
                    returnId: affect.id,
                    canAccess: to,
                    isUnRead: listIds,
                    createdAt: serverTime(),
                    type: NOTIFICATION_TYPES.AFFECT_ADD,
                    by: {
                        id: currentUser.uid,
                        displayName: currentUser.displayName || '',
                        photoURL: currentUser.photoURL || '',
                    },
                    action:{
                        id: affect.id,
                        ref: affect.correspondance.reference,
                        userAs: 'Responsable'
                    }
                }
            }));
        } catch (error) {
            console.log(error);
        }
    }
}

function callNotif ({dispatch, title, desc, affect, to, currentUser, type=NOTIFICATION_TYPES.AFFECT_UPDATE}){
    const listIds = {};
    to.forEach(id => listIds[id] = true);

    dispatch(createNotification({
        data: {
            title:title,
            description: desc,
            returnId: affect.id,
            canAccess: to,
            isUnRead: listIds,
            createdAt: serverTime(),
            type: type,
            by: {
                id: currentUser.uid,
                displayName: currentUser.displayName || '',
                photoURL: currentUser.photoURL || '',
            },
            action:{
                id: affect.id,
                ref: affect.correspondance.reference,
            }
        }
    }));
}


/** @param {{ affect: AffectationType, change: Array, oldAffect: AffectationType }} */
export function updateNotif({ affect, oldAffect, change }){
    return async (dispatch) =>{
            const { currentUser } = auth;
         
            const to = [
                ...affect.assigne.responsable,
                ...affect.assigne.collaborateur,
                affect.responsable
            ].map(_one => _one.id);
            const listIds = {};
            to.forEach(id => listIds[id] = true);

            let desc = `L'affectation ${affect.correspondance.reference} de type ${affect.correspondance.typeOp} ${!isEmpty(affect.correspondance.priority) ? 'de priorité '+ affect.correspondance.priority : ''} a été mise à jour par ${currentUser.displayName}` ;
            let title = `Mise à jour de l'affectation ${affect.correspondance.reference}`;


            if (change.length) {
                const canPrint = change.filter((_one) => _one?.canPrint);

                if(canPrint.length){
                    title="Demande d'impression";
                    desc=`Vous avez une demande d'impression de la part de ${currentUser.displayName}`;
                    callNotif({ dispatch, affect, currentUser, desc, title, to: [affect.canPrint?.id], type: NOTIFICATION_TYPES.AFFECT_PRINT });
                    return;
                } 

                const state = change.filter((_one) =>_one?.state);

                if(state.length && state[0]?.state === AFFECT_STATE_VALIDATION.DONE){
                    title = `Affectation ${affect.correspondance.reference}, terminée`;
                    desc = `Affectation ${affect.correspondance.reference} a été terminée par ${currentUser.displayName}`;
                    
                    let sendTo = [];
                    if(!affect.assigne.responsable.find(_one => _one.id === currentUser.uid)){
                        sendTo = [ ...affect.assigne.responsable].map(_one => _one.id);
                    }

                    callNotif({ dispatch, affect, currentUser, desc, title, to: sendTo, type: NOTIFICATION_TYPES.AFFECT_DONE });
                    return;
                }

                if(state.length && state[0]?.state === AFFECT_STATE_VALIDATION.ONVALIDATE){
                    title = `Affectation ${affect.correspondance.reference}, validation`;
                    desc = `Une demande de validation  pour l'affectation ${affect.correspondance.reference} vous a été envoyée par ${currentUser.displayName}`;

                    callNotif({ dispatch, affect, currentUser, desc, title, to: [affect.responsable?.id], type: NOTIFICATION_TYPES.AFFECT_VALIDATE });
                    return ;
                }

                if(state.length && state[0]?.state === AFFECT_STATE_VALIDATION.REJECTED){
                    title = `Affectation ${affect.correspondance.reference}, rejet`;
                    desc = `La demande de validation de l'affectation ${affect.correspondance.reference} a été rejeté par ${currentUser.displayName}`;

                    callNotif({ dispatch, affect, currentUser, desc, title, to: to, type: NOTIFICATION_TYPES.AFFECT_REJECT });
                    return ;
                }
            } 

            callNotif({ dispatch, affect, currentUser, desc, title, to});

        try {
           
        } catch (error) {
            console.log(error);
        }
    }
}


/** @param {{ affect: AffectationType }} */
export function commentNotif({ affect }){
    return async (dispatch) =>{
            const { currentUser } = auth;
            const to = uniq([
                affect.responsable, 
                ...affect.assigne.collaborateur,
                ...affect.assigne.responsable
            ].map((_one) => _one.id));

            const listIds = {};
            to.forEach(id => listIds[id] = true);

        const desc  = `Un nouveau commentaire à été ajouté sur l'affectation  ${affect.correspondance.reference} 
        par ${currentUser.displayName} `

        try {
            dispatch(createNotification({
                data: {
                    title:"Commentaire sur affectation",
                    description: desc,
                    returnId: affect.id,
                    canAccess: to,
                    isUnRead: listIds,
                    createdAt: serverTime(),
                    type: NOTIFICATION_TYPES.AFFECT_COMMENT,
                    by: {
                        id: currentUser.uid,
                        displayName: currentUser.displayName || '',
                        photoURL: currentUser.photoURL || '',
                    },
                    action:{
                        id: affect.id,
                        ref: affect.correspondance.reference,
                    }
                }
            }));
        } catch (error) {
            console.log(error);
        }
    }
}

