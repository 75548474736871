import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'references',
  initialState: {
    oldTaskNumber: null,
    loading: false,
    error: null
  },
  reducers: {
    startLoading(state) {
      state.loading = true;
    },

    hasError(state, action) {
      state.loading = false;
      state.error = action.payload;
      console.error(action.payload);
    },

    gotOldTaskNumber(state, action) {
      state.loading = false;
      state.error = null;
      state.oldTaskNumber = action.payload;
    },

    resetOldTaskNumber(state){
      state.loading = false;
      state.error = null;
      state.oldTaskNumber = null;
    }
  }
});

export const { hasError, gotOldTaskNumber, startLoading,resetOldTaskNumber } = slice.actions;

const referencesReducer = slice.reducer;

export default referencesReducer;
