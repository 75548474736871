import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
// material
import { styled, alpha } from '@mui/material/styles';

import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// hooks
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// components
import { MHidden } from '../../components/@material-extend';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import Forward from './Forward';
import { HideOnScroll } from '../../components/HideOnScroll';
import { useDashbord } from './index';
import { isEmpty } from 'lodash';
// import Searchbar from './Searchbar';
import UserSearchbar from './UserSearch';
import { APP_INFO, NAVBAR } from 'src/config';
import ChangeCurrentProjectBox from 'src/section/project/ChangeCurrentProjectBox';
import { useHomeContext } from 'src/contexts/HomeContext';
import Header from 'src/section/home/Header';
// ----------------------------------------------------------------------

const COLLAPSE_WIDTH = 57;

const APPBAR_MOBILE = 55;
const APPBAR_DESKTOP = 55;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  borderBottom: `1px solid ${theme.palette.divider}`,
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const { title, openUserSearch } = useDashbord();
  const { isHome } = useHomeContext();

  return (
    <div style={{ backgroundColor: 'red', opacity: 1 }}>
      <HideOnScroll>
        <RootStyle
          sx={{
            ...(isCollapse && {
              width: { lg: `calc(100% - ${COLLAPSE_WIDTH}px)` }
            })
          }}
        >
          <ToolbarStyle>
            <MHidden width="lgUp">
              <IconButton size="small" color="inherit" onClick={onOpenSidebar} sx={{ mr: 1 }}>
                <Icon color="#ADAEB2" icon={menu2Fill} />
              </IconButton>
            </MHidden>

            {isCollapse && <Forward hasTitle={isEmpty(title)} />}
            <Box width={20} />
            {isHome && <Header />}

            <Box sx={{ ml: 2 }} maxWidth={{ xs: 150, md: 300 }}>
              <ChangeCurrentProjectBox />
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <Typography pt={1} color="black" variant="h5">
                {APP_INFO.name}
              </Typography>
            </Box>

            <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
              {/*<InvitationBtn />*/}
              {/* <Searchbar /> */}
              {openUserSearch && <UserSearchbar />}
              <NotificationsPopover hasTitle={isEmpty(title)} />
              <AccountPopover />
            </Stack>
          </ToolbarStyle>
        </RootStyle>
      </HideOnScroll>
    </div>
  );
}
