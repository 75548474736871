import React, {useMemo, useRef} from 'react'
import {isEmpty} from 'lodash';
import MenuPopover from 'src/components/MenuPopover'
import {MenuLine} from 'src/components/MenuLine'
import {TaskLinePriority, TaskListVoiceNote} from './TaskListViewComponent';
import {useTheme} from "@mui/material/styles";
import {TASK_VISIBILITY} from "src/constants";
import useProject from "src/hooks/useProject";
import useAuth from "src/hooks/useAuth";
import {gProjectId} from "src/helpers/task";
import {BudgetModal} from "src/section/tasks/kanban/KanbanTaskDetailsInfo";
import {useToggleV2} from "src/hooks/useToggle";

export default function MoreTaskOPtion({
                                           open,
                                           onClose,
                                           rights,
                                           edit,
                                           del,
                                           dependence,
                                           anchorEl,
                                           task,
                                           recordingState,
                                           openTaskDetails,
                                           onToggleVisibility,
                                           onBudget,
                                           handleBudgetChange
                                       }) {
    const theme = useTheme()
    const isPrivate = useMemo(() => {
        return task?.visibility === TASK_VISIBILITY.PRIVATE;
    }, [task?.visibility])

    const {user} = useAuth();
    const {projects} = useProject({id: user.id, status: ['open']});

    const currentProject = useMemo(() => projects?.find(el => el?.id === gProjectId(task)), [projects, task])
    const hasBudget = useMemo(() => Boolean(currentProject?.budget?.estimated > 0), [currentProject?.budget?.estimated])
    const [openBudget, onBudgetOpen, onCloseBudget] = useToggleV2();
    const ref = useRef();

    const onEdit = () => {
        onClose();
        edit();
    }

    const onDelete = () => {
        onClose();
        del();
    }

    const onOpen = () => {
        onClose();
        openTaskDetails();
    }

    const onOpenDependence = () => {
        onClose();
        dependence();
    }

    const handleToggole = () => {
        onClose();
        if (onToggleVisibility) onToggleVisibility()
    }

    return (
        <>
            <MenuPopover open={open} onClose={onClose} anchorEl={anchorEl} disabledArrow horizontal='right'
                         transformOrigin={{vertical: 'top', horizontal: 'left'}}>
                <MenuLine icon='clarity:play-line' title='Ouvrir' onClick={onOpen}/>
                {rights.update && <MenuLine icon='clarity:note-edit-line' title='Editer' onClick={onEdit}/>}
                {rights.update && <TaskListVoiceNote task={task} recordingState={recordingState}/>}
                {rights.update && isEmpty(task?.priority) && <TaskLinePriority task={task}/>}
                {rights.update && <MenuLine icon='tabler:subtask' title='Dépendances' onClick={onOpenDependence}/>}
                {rights.update && <MenuLine
                    icon={`bi:shield-lock${isPrivate ? "-fill" : ""}`}
                    color={isPrivate ? theme.palette.warning.main : null}
                    title={`Rendre ${isPrivate ? "Public" : "privée"} `}
                    onClick={handleToggole}
                />}
                {
                    hasBudget && (
                        <MenuLine icon='emojione-monotone:money-bag' title={`Dépense: ${task?.budget || 0} CFA`}
                                  onClick={onBudgetOpen}
                        />
                    )
                }
                <div ref={ref}/>
                {rights.delete &&
                    <MenuLine icon='fluent:delete-20-regular' title='Supprimer' onClick={onDelete} color='red'/>}

            </MenuPopover>

            {
                openBudget && (
                    <BudgetModal
                        open
                        secondPos
                        onClose={onCloseBudget}
                        onChange={handleBudgetChange}
                        anchor={ref}
                        amount={task?.budget}
                        projectBudget={currentProject?.budget || {}}
                        symbol={'CFA'}
                    />

                )
            }
        </>
    )
}
