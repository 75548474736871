import { useRef, useState, useEffect, createContext, useContext, useMemo } from 'react';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import chevronDown from '@iconify/icons-eva/chevron-down-outline';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { Stack, Button, IconButton, MenuItem, Typography, Badge } from '@mui/material';
import { baseColors } from 'src/constants/color';
import useToggle, { useToggleV2 } from 'src/hooks/useToggle';
import { fr as LocalFr } from 'date-fns/locale';
import {
  BaseLine,
  SubTaskLineSkeleton,
  SubTaskOneSibLine,
  TaskLineSkeleton,
  TaskLineStateView,
  TaskLineTitle,
  TaskListAssigneView,
  TaskListEcheanceView,
  TaskListNewTask,
  TaskListResponsableView,
  TaskListRowSection
} from './TaskListViewComponent';
import { MobileDateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ActionModal from 'src/components/ActionModal';
import { MuiCustomInput } from 'src/components/CustomInput';
import { useProjectContext } from 'src/contexts/ProjectContext';
import { COLOR_OPTIONS } from '../project/ProjectCardSimple';
import ColorSinglePicker from 'src/components/ColorSinglePicker';
import { isEmpty } from 'lodash';
import useSubTask from 'src/hooks/useSubTask';
import { useLocation, useNavigate } from 'react-router-dom';
import TaskListDnD from './TaskListDnD';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TASK_STATE_VALIDATION } from 'src/constants';
import MenuPopover from 'src/components/MenuPopover';
import { SelectCollaborates } from '../project/ProjectCardOPtion';
import { useDatePicker } from './kanban/KanbanTaskAdd';
import { dateConverter } from 'src/helpers/dueDateConverter';
import { gDate } from 'src/utils/formatTime';
import { getTaskRights } from '../../utils/task_rights';

const TaskLineProvider = createContext({
  assigne: [],
  taskState: '',
  limiteDate: [null, null],
  board: {
    data: {},
    columns: {},
    columnOrder: []
  },
  setTaskState: () => {},
  setAssigne: () => {},
  setLimiteDate: () => {}
});

export const useTaskLineContext = () => {
  return useContext(TaskLineProvider);
};

const STATE = {
  ['ALL']: 'Toutes les Tâches',
  ['OPEN']: 'Tâches ouvertes',
  [TASK_STATE_VALIDATION.INPROGRESS]: 'Tâches en cours',
  [TASK_STATE_VALIDATION.DONE]: 'Tâches terminées',
  [TASK_STATE_VALIDATION.REJECTED]: 'Tâches rejetées',
  [TASK_STATE_VALIDATION.ACCEPTED]: 'Tâches acceptées',
  [TASK_STATE_VALIDATION.PENDING]: 'Tâches non démarrées'
};

const taskFilter = (task = [], state, assigne = [], dueDate = []) => {
  let result = task;

  if (!isEmpty(state)) {
    result = task.filter((_one) => _one?.state === state);

    if (state === 'ALL') {
      result = task;
    }

    if (state === TASK_STATE_VALIDATION.PENDING) {
      result = task.filter((_one) => !_one?.state || _one?.state === state);
    }
    if (state === 'OPEN') {
      result = task.filter((_one) => _one?.state !== TASK_STATE_VALIDATION.ACCEPTED);
    }
  }

  if (!isEmpty(assigne)) {
    result = result.filter((_task) => assigne.some((one) => _task?.assignee.find((_one) => _one.id === one.id)));
  }

  if (dueDate.at(0)) {
    result = result.filter((_task) => {
      return (
        gDate(dueDate.at(0))?.getTime() <= gDate(_task.due.at(1))?.getTime() &&
        gDate(_task.due.at(1))?.getTime() <= gDate(dueDate.at(1))?.getTime()
      );
    });
  }

  return result;
};

const AssigneFilter = () => {
  const { assigne, setAssigne } = useTaskLineContext();
  const anchorRef = useRef();
  const [show, onShow, endShow] = useToggleV2();

  return (
    <>
      <IconButton sx={{ p: 0 }} ref={anchorRef} onClick={onShow}>
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          sx={{ p: 0 }}
          color="primary"
          badgeContent={assigne.length}
        >
          <Icon icon="clarity:filter-line" height={15} />
        </Badge>
      </IconButton>
      <MenuPopover disabledArrow width="max-content" open={show} onClose={endShow} anchorEl={anchorRef.current}>
        <SelectCollaborates
          preTitle="Filtrer par"
          title="assigné"
          actionTitle="Filtrer"
          currents={assigne}
          onClose={endShow}
          onChange={(_, res) => setAssigne(res)}
        />
      </MenuPopover>
    </>
  );
};

const LimiteDateFilter = () => {
  const { limiteDate, setLimiteDate } = useTaskLineContext();
  const anchorEl = useRef();
  const [hover, onHover, endHover] = useToggleV2();

  const { dueDate, onChangeDueDate, openPicker, onOpenPicker, onClosePicker } = useDatePicker({
    date: dateConverter(limiteDate)
  });

  const onDueChange = (val) => {
    setLimiteDate(val);
    onChangeDueDate(val);
  };

  const cleanDateFilter = () => {
    onDueChange([null, null]);
    endHover();
  };

  return (
    <>
      <IconButton ref={anchorEl} sx={{ p: 0 }} onClick={onHover} onMouseEnter={onHover}>
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          variant={limiteDate.at(0) ? 'dot' : 'standard'}
          sx={{ p: 0 }}
          color="info"
        >
          <Icon icon="flat-color-icons:calendar" height={15} />
        </Badge>
      </IconButton>
      {hover && (
        <MenuPopover
          open={hover}
          disableRestoreFocus
          onClose={endHover}
          anchorEl={anchorEl.current}
          width="max-content"
        >
          <Stack direction="row" spacing={2}>
            <IconButton onClick={onOpenPicker}>
              <Icon icon="flat-color-icons:calendar" />
            </IconButton>
            <IconButton onClick={cleanDateFilter}>
              <Icon icon="fluent:delete-32-regular" />
            </IconButton>
          </Stack>
        </MenuPopover>
      )}
      {openPicker && (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={LocalFr}>
          <MobileDateRangePicker
            value={dueDate}
            open={openPicker}
            toolbarTitle="Sélectionnez la plage de date"
            startText="Du"
            endText="au"
            inputFormat="dd/MM/yyyy"
            todayText="Aujourd'hui"
            onClose={onClosePicker}
            onOpen={onOpenPicker}
            onChange={onDueChange}
            renderInput={() => {}}
          />
        </LocalizationProvider>
      )}
    </>
  );
};

const ProgressionFilter = () => {
  const { taskState, setTaskState } = useTaskLineContext();
  const stateRef = useRef();
  const [open, onOpen, onClose] = useToggleV2();

  return (
    <>
      <IconButton ref={stateRef} sx={{ p: 0 }} onClick={onOpen}>
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          variant={taskState !== 'ALL' ? 'dot' : 'standard'}
          sx={{ p: 0 }}
          color="info"
        >
          <Icon icon="bi:filter-right" height={15} />
        </Badge>
      </IconButton>
      <MenuPopover
        width="max-content"
        disabledArrow
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={open}
        onClose={onClose}
        anchorEl={stateRef.current}
      >
        {Object.entries(STATE).map(([key, value]) => (
          <MenuItem selected={taskState === key} key={key} sx={{ py: 1, px: 2.5 }} onClick={() => setTaskState(key)}>
            <Typography variant="caption"> {value} </Typography>
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
};

const OneTaskViewOperation = ({ columns, colId, data, index, taskState, assigne, limiteDate, lineWidth }) => {
  const result = useMemo(() => {
    const col = columns[colId];
    const cardIds = col?.cardIds || [];
    const mcards = [];

    [...cardIds].forEach((_one) => {
      if (data[_one]) {
        mcards.push(data[_one]);
      }
    });

    return { mcards, col, cardIds };
  }, [columns, colId, data]);

  return (
    <TaskListView
      width={lineWidth - 700}
      column={result.col}
      tasks={taskFilter(result.mcards, taskState, assigne, limiteDate)}
      index={index}
    />
  );
};

export default function TaskProjectList({ data, columns, columnOrder, project, loading }) {
  const [taskState, setTaskState] = useState('OPEN');
  const [assigne, setAssigne] = useState([]);
  const [limiteDate, setLimiteDate] = useState([null, null]);
  const stateRef = useRef();
  const [openState, onOpenState, onCloseState] = useToggleV2();

  const [lineWidth, setLineWidth] = useState(0);
  const widthRef = useRef();
  const { open, handleClose, handleOpen } = useToggle();
  const { addColumn } = useProjectContext();
  const [title, setTitle] = useState('');
  const [color, setColor] = useState('');
  const [loadingNew, setLoadingNew] = useState(true);

  const navigate = useNavigate();
  const { state, pathname } = useLocation();
  const { onOpen, projectChanging } = useProjectContext();

  useEffect(() => {
    if (state?.detailId) {
      const exist = data[state?.detailId];
      if (exist) {
        navigate(pathname, { replace: true, state: {} });
        onOpen(exist);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.detailId]);

  const handleChangeColor = (event) => {
    setColor(event.target.value);
  };

  const onCancel = () => {
    handleClose();
  };

  const onAddOperation = () => {
    onCancel();
    if (!isEmpty(title)) {
      addColumn(title, color, () => {
        setTitle('');
        setColor('');
      });
    }
  };

  useEffect(() => {
    if (widthRef?.current) {
      setLineWidth(widthRef?.current?.clientWidth);
    }
  }, [widthRef?.current?.clientWidth]);

  useEffect(() => {
    setTimeout(() => setLoadingNew(false), 700);
  }, [project]);

  const stateChange = (_state) => {
    onCloseState();
    setTaskState(_state);
  };

  const store = {
    board: {
      data,
      columns,
      columnOrder
    },
    assigne,
    taskState,
    limiteDate,
    setAssigne,
    setTaskState,
    setLimiteDate
  };

  return (
    <TaskLineProvider.Provider value={store}>
      <TaskListDnD projectKey={project?.id}>
        <Stack spacing={2} width={1}>
          <Stack ref={widthRef} width={1} direction="row" justifyContent="flex-end" spacing={3} pl={{ md: 5 }}>
            <Button
              size="small"
              startIcon={<Icon icon="fa6-solid:sort" height={10} />}
              endIcon={<Icon icon="el:chevron-down" height={10} />}
              color="inherit"
              sx={{ border: (t) => `1px solid ${t.palette.divider}`, px: 1 }}
              ref={stateRef}
              onClick={onOpenState}
            >
              {STATE[taskState]}
            </Button>

            <Button
              size="small"
              sx={{
                bgcolor: '#000',
                color: '#FFF',
                ':hover': {
                  bgcolor: '#000',
                  color: '#FFF'
                },
                fontSize: 10
              }}
              startIcon={<Icon icon={plusFill} />}
              onClick={handleOpen}
            >
              NOUVELLE OPÉRATION
            </Button>
          </Stack>

          {loadingNew || loading || projectChanging
            ? TaskLineSkeleton
            : columnOrder &&
              columnOrder.map(
                (colId, idx) =>
                  columns[colId] && (
                    <OneTaskViewOperation
                      key={colId + idx}
                      colId={colId}
                      columns={columns}
                      data={data}
                      index={idx}
                      assigne={assigne}
                      limiteDate={limiteDate}
                      taskState={taskState}
                      lineWidth={lineWidth}
                    />
                  )
              )}
          {open && (
            <ActionModal
              open={open}
              onClose={onCancel}
              title="Nouvelle opération"
              action={<Button onClick={onAddOperation}>Ajouter</Button>}
              moreAction={
                <Stack spacing={1}>
                  <MuiCustomInput
                    fullWidth
                    autoFocus
                    value={title}
                    onChange={(e) => setTitle(e.currentTarget.value)}
                    placeholder="Titre de l'opération"
                  />
                  <ColorSinglePicker onChange={handleChangeColor} colors={COLOR_OPTIONS} />
                </Stack>
              }
            />
          )}
        </Stack>
        {openState && (
          <MenuPopover
            width="max-content"
            disabledArrow
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={openState}
            onClose={onCloseState}
            anchorEl={stateRef.current}
          >
            {Object.entries(STATE).map(([key, value]) => (
              <MenuItem selected={taskState === key} key={key} sx={{ py: 1, px: 2.5 }} onClick={() => stateChange(key)}>
                <Typography variant="caption"> {value} </Typography>
              </MenuItem>
            ))}
          </MenuPopover>
        )}
      </TaskListDnD>
    </TaskLineProvider.Provider>
  );
}

const TaskListHeader = ({ column, isCollapse, handler, handleSwitch }) => {
  return (
    <BaseLine
      icon={
        <IconButton
          {...handler}
          onClick={handleSwitch}
          size="small"
          sx={{
            bgcolor: column?.color || baseColors?.WARINIG,
            p: 0.1,
            color: 'white'
          }}
        >
          <Icon icon={chevronDown} style={{ ...(isCollapse && { transform: 'rotate(-90deg)' }) }} />
        </IconButton>
      }
      isCollapse={isCollapse}
      title={
        <TaskListRowSection
          isCollapse={isCollapse}
          isHeader
          title={column?.name}
          col={column}
          isColName
          isFirst
          color={column?.color || baseColors?.WARINIG}
          style={{
            color: 'white'
          }}
        />
      }
      grids={[
        {
          element: <TaskListRowSection col={column} isHeader title="SUPERVISEUR" />
        },
        {
          element: <TaskListRowSection col={column} isHeader title="ASSIGNÉ" icon={<AssigneFilter />} />
        },
        {
          element: <TaskListRowSection col={column} isHeader title="ÉCHÉANCE" icon={<LimiteDateFilter />} />
        },
        {
          element: <TaskListRowSection col={column} isHeader title="État" icon={<ProgressionFilter />} />
        }
      ]}
    />
  );
};

export const TaskListView = ({ column, tasks, width, index }) => {
  const [initialState, setInitState] = useLocalStorage(`col-open-state-${column?.id}`, false);
  const [isCollapse, _, __, lhandleSwitch] = useToggleV2(initialState);

  const handleSwitch = () => {
    setInitState(!isCollapse);
    lhandleSwitch();
  };

  return (
    <Draggable draggableId={column?.id} index={index}>
      {(provided) => (
        <Stack {...provided.draggableProps} ref={provided.innerRef} sx={{ transition: 'max-height 2s ease' }}>
          <TaskListHeader
            handleSwitch={handleSwitch}
            isCollapse={isCollapse}
            column={column}
            handler={provided.dragHandleProps}
          />
          <Droppable droppableId={column?.id} type="task">
            {(provided, snapshot) => (
              <Stack width={1} ref={provided.innerRef} {...provided.droppableProps}>
                {!isCollapse &&
                  tasks?.map((_task, idx) => (
                    <TaskListRowView
                      key={`task-line-${_task?.id}-${idx}`}
                      column={column}
                      task={_task}
                      width={width}
                      index={idx}
                      isDraggingOver={snapshot.isDraggingOver}
                    />
                  ))}
                {provided.placeholder}
                {!isCollapse && <TaskListNewTask columnId={column?.id} />}
              </Stack>
            )}
          </Droppable>
        </Stack>
      )}
    </Draggable>
  );
};

export const TaskListRowView = ({ column, task, index, width, isDraggingOver, canDrag = true }) => {
  const [isCollapse, _, __, handleSwitch] = useToggleV2(true);
  const [hover, onHover, onCloseHover] = useToggleV2();

  const onClose = () => {
    if (!isDraggingOver) {
      onCloseHover();
    }
  };

  const onOpen = () => {
    if (!isDraggingOver) {
      onHover();
    }
  };

  return (
    <Stack width={1} maxWidth={1}>
      {canDrag ? (
        <Draggable draggableId={task.id} index={index}>
          {(provided) => {
            const rights = getTaskRights(task);

            return (
              <Stack
                width={1}
                ref={provided.innerRef}
                {...provided.draggableProps}
                onMouseEnter={onOpen}
                onMouseLeave={onClose}
              >
                <BaseLine
                  icon={
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      {...provided.dragHandleProps}
                      pt={1}
                      hidden={!rights.update}
                    >
                      {hover && <Icon icon="radix-icons:drag-handle-dots-2" />}
                    </Stack>
                  }
                  title={
                    <TaskListRowSection
                      title={column?.name}
                      col={column}
                      isColName
                      row={
                        <TaskLineTitle
                          width={width - 100}
                          task={task}
                          openSubTask={isCollapse}
                          swichSubTask={handleSwitch}
                        />
                      }
                    />
                  }
                  grids={[
                    {
                      element: <TaskListRowSection col={column} row={<TaskListResponsableView task={task} />} />
                    },
                    {
                      element: <TaskListRowSection col={column} row={<TaskListAssigneView task={task} />} />
                    },
                    {
                      element: <TaskListRowSection col={column} row={<TaskListEcheanceView row={task} />} />
                    },
                    {
                      element: <TaskListRowSection col={column} row={<TaskLineStateView row={task} />} />
                    }
                  ]}
                />
              </Stack>
            );
          }}
        </Draggable>
      ) : (
        <Stack width={1}>
          <BaseLine
            title={
              <TaskListRowSection
                title={column?.name}
                col={column}
                isColName
                row={
                  <TaskLineTitle width={width - 100} task={task} openSubTask={isCollapse} swichSubTask={handleSwitch} />
                }
              />
            }
            grids={[
              {
                element: <TaskListRowSection col={column} row={<TaskListResponsableView task={task} />} />
              },
              {
                element: <TaskListRowSection col={column} row={<TaskListAssigneView task={task} />} />
              },
              {
                element: <TaskListRowSection col={column} row={<TaskListEcheanceView row={task} />} />
              },
              {
                element: <TaskListRowSection col={column} row={<TaskLineStateView row={task} />} />
              }
            ]}
          />
        </Stack>
      )}
      {!isCollapse && <SubTaskView task={task} width={width - 80} />}
    </Stack>
  );
};

const SubTaskView = ({ task, width, isUnderSub = false }) => {
  const { loading, subTasks } = useSubTask({ taskId: task?.id });

  return (
    <Stack direction="row" width={1}>
      {loading ? (
        SubTaskLineSkeleton
      ) : (
        <Stack width={1} spacing={0.1}>
          {subTasks &&
            subTasks.map((_sub, index) => (
              <SubTaskOneSibLine
                isBotton={index === subTasks.length - 1}
                key={`sub-task-line-${_sub?.id}`}
                isUnderSub={isUnderSub}
                line={<SubTaskLineOne key={`sub-task-${_sub?.id}`} subtask={_sub} width={width - 50} task={task} />}
              />
            ))}
          <Stack width={1} pl={4}>
            <TaskListNewTask isSub parent={task} />
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

const SubTaskLineOne = ({ subtask, width, task }) => {
  const [isCollapse, _, __, handleSwitch] = useToggleV2(true);
  return (
    <Stack width={1} maxWidth={1}>
      <BaseLine
        isSub
        title={
          <TaskListRowSection
            row={
              <TaskLineTitle
                width={width}
                task={subtask}
                isSub
                parent={task}
                openSubTask={isCollapse}
                swichSubTask={handleSwitch}
              />
            }
          />
        }
        grids={[
          {
            element: <TaskListRowSection row={<TaskListResponsableView task={subtask} />} />
          },
          {
            element: <TaskListRowSection row={<TaskListAssigneView task={subtask} />} />
          },
          {
            element: <TaskListRowSection row={<TaskListEcheanceView row={subtask} />} />
          },
          {
            element: <TaskListRowSection row={<TaskLineStateView row={subtask} />} />
          }
        ]}
      />
      {!isCollapse && <SubTaskView task={subtask} width={width} isUnderSub />}
    </Stack>
  );
};
