import {createSlice} from '@reduxjs/toolkit';
import { firebasedb } from "src/contexts/FirebaseContext"; 

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: false,
    currentUser: null,
    participants: {},
};

const slice = createSlice({
    name: 'video',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        setUser(state, action){
         
            state.currentUser = action.payload;
        },

        addParticipant(state, action){
            const { participant, userId } = action.payload;

           const participantId = Object.keys(participant)[0];

           if(userId === participantId){
            participant[participantId].currentUser = true;
           }

           let participants = { ...state.participants, ...participant};
           state.participants = participants;
        },

        removeParticipant(state, action){
            const { payload } = action;
            let participants = {...state.participants};
            delete participants[payload];
            state.participants = participants;
        }
    }
});

// Reducer
export default slice.reducer;

export const {
    setUser,
    addParticipant,
    removeParticipant
}= slice.actions;



// ----------------------------------------------------------------------

export function addUser(roomId, userId, user) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {

            const participantsRef = firebasedb.ref().child(roomId).child('participants');

            const defaultPreferences = {
                audio: true,
                video: false,
                screen: false
            };
            
            firebasedb.ref(`${roomId}/participants/${userId}`).once("value", snapshot => {
                if (snapshot.exists()){
                  
                   const { username, preferences } = snapshot.val();
                   dispatch(slice.actions.setUser({
                        [snapshot.key] : {
                            username,
                            ...preferences
                        }
                    }));
                    const userRef =  participantsRef.child(userId);
                    userRef.onDisconnect().remove();
                }else{
                    const userRef =  participantsRef.child(userId);
                    userRef.set({
                        username: user,
                        preferences: defaultPreferences
                    })
                    dispatch(slice.actions.setUser({
                        [userRef.key] : {
                            username: user,
                            ...defaultPreferences
                        }
                    }));

                    userRef.onDisconnect().remove();
                }
             });


            participantsRef.on("child_added", (snap)=>{
                const { username, preferences } = snap.val();
                dispatch(slice.actions.addParticipant({
                    participant:{
                        [snap.key]:{
                        username,
                        ...preferences
                    }},
                    userId: userId
                }))
            });
    
            participantsRef.on("child_removed", (snap)=>{
                dispatch(slice.actions.removeParticipant(snap.key));
            });
        
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

// // ----------------------------------------------------------------------

export function listenParticipants(roomId) {
    return async (dispatch) => {
        dispatch(slice.actions.startLoading());
        try {
            const participantsRef = firebasedb.ref().child(roomId).child('participants');
            participantsRef.on("child_added", (snap)=>{
                const { username, preferences } = snap.val();
                dispatch(slice.actions.addParticipant({
                    [snap.key]:{
                        username,
                        ...preferences
                    }
                }))
            });
    
         participantsRef.on("child_removed", (snap)=>{
             dispatch(slice.actions.removeParticipant(snap.key));
         });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
