import {useState, useEffect} from "react";
import useAuth from "src/hooks/useAuth";
import {useDispatch} from "src/redux/store";
import {useSnackbar} from "notistack";

import Modal from "src/components/Modal";
import {Box, LinearProgress, Stack} from "@mui/material";
import LoadingView from "src/components/LoadingView";
import CustomCommentList from "src/components/CustomCommentList";
import KanbanTaskCommentInput from "src/components/CommentInput";
import {addCommentToTask, deleteCommentFromTask} from "src/redux/slices/kanban";
import {nanoid} from "@reduxjs/toolkit";
import {deleteDocComment, emmitDocComment} from "src/redux/slices/document";
import useDocComment from "src/hooks/useDocComment";


export default function DocComments({element, open, onClose}) {
    const {user} = useAuth();
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const [submitting, setSubmitting] = useState(false);
    const [comments, setComments] = useState([]);
    const {comments: netWork, loading: netLoading} = useDocComment(element?.id)


    useEffect(() => {
        setComments(netWork)
    }, [netWork])

    const handleDelete = ({id}) => {
        dispatch(deleteDocComment({
            docId: element?.id, commentId: id, callback: () => {
                enqueueSnackbar('Commentaire supprimé avec succès', {variant: 'warning'});
            }
        }))
    }
    const handleSubmit = (comment) => {

        setSubmitting(true);
        const docId = nanoid();

        const commentObject = {
            ...comment,
            read: 0,
            readState: []
        }


        comments.push({...commentObject, id: docId});
        dispatch(emmitDocComment({
            docId: element?.id, comment,
            callback: () => {
                setSubmitting(false);
                enqueueSnackbar('Commentaire ajouté', {variant: 'info'});
            }
        }));
    }

    return (
        <>
            <Modal open={open} onClose={onClose} title={`Commentaire sur ${element?.name}`}>
                <Stack sx={{height: '80vh', maxHeight: '80vh'}}>
                    {netLoading && <LoadingView size={{height: 150, width: 150}}/>}
                    <Box sx={{flexGrow: 1, display: 'flex', overflow: 'hidden'}}>
                        <Stack sx={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}
                               justifyContent={'space-between'} height={1}>

                            <CustomCommentList
                                user={user}
                                list={comments}
                                details={element}
                                onDeleteComment={handleDelete}
                            />

                            {submitting && <Stack width={1}> <LinearProgress/> </Stack>}

                            <KanbanTaskCommentInput idQ={'DocComments'} onChange={handleSubmit}/>
                        </Stack>
                    </Box>
                </Stack>
            </Modal>
        </>
    )
}