import { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'src/redux/store';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';


export default function useSubTask({taskId}) {
    const [loading, setLoading] = useState();
    const getSubTask = useSelector(state => state.firestore.ordered[`subTask_${taskId}`]);
    const subTasks = useMemo(() => isEmpty(getSubTask) ? [] : getSubTask, [getSubTask]);

    useFirestoreConnect(() => [
        {
            collection: "tasks", 
            where: [['parentId', '==', taskId]],
            orderBy:[['createdAt', 'asc']],
            storeAs: `subTask_${taskId}`
        }
    ]);

    useEffect(()=> {
        setLoading(!isLoaded(getSubTask));
    },[getSubTask]);

  return {loading, subTasks};
}
