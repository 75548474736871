import { createSlice } from '@reduxjs/toolkit';
import { NOTIFICATION_TYPES } from 'src/constants';
import { auth, firestore } from 'src/contexts/FirebaseContext';
import { getCurrentUserAccess } from 'src/helpers/user';
import { serverTime } from 'src/utils/serverTime';
import { createNotification } from 'src/redux/slices/notifications';
import { changeOnObject, compareArraysOfObjects } from 'src/utils/changeOnObject';
import { Affectation_v4_Type } from 'src/models/Affectation_v4_type';
import { RAPPEL_TYPE } from 'src/constants/rappel';
import { getOnlyDate } from 'src/utils/formatTime';

const initialState = {
    error: false,
    isLoading: false,
    affectation: [],
    loadingSetting: false,
    settings: null
};

const slice = createSlice({
    name: 'affectation_v4',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },
        settingLoading(state, action) {
            state.loadingSetting = action.payload;
        },

        getSettings(state, action) {
            state.settings = action.payload;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        createdSuccess(state, action) {
            state.isLoading = false;
        }
    }
});

export default slice.reducer;

// Keys used on firestore connector with the property [saveAs]
const courielkeys = {
    todo: 'couriel_todo',
    reviews: 'couriel_reviews'
};

const courielCollection = firestore.collection('affectation');

export function getAffectationSetting(settings, loading) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.settingLoading(loading));
            dispatch(slice.actions.getSettings(settings));
        } catch (error) {
            console.log({ error });
        }
    };
}

export function insertAffectationV4Settings({ data, onResolve, onReject }) {
    return async () => {
        try {
            await courielCollection.doc('affectation_settings').set({ ...data }, { merge: true });
            if (onResolve) onResolve();
        } catch (e) {
            console.log(e);
            if (onReject) onReject();
        }
    };
}

export function generateRef(setval) {
    return async (dispatch) => {
        try {
            const numberDoc = await firestore.collection('references').doc('affectation_v4').get();
            if (numberDoc.exists) {
                const val = numberDoc.data()?.number;
                setval(val);
                numberDoc.ref.update({ number: val + 1 });
                return;
            }
            setval(1);
            numberDoc.ref.set({ number: 2 });
        } catch (error) {
            console.log(error);
        }
    };
}

export const getCourielById = (id, resolve, reject) => {
    return async (dispatch, getStore) => {
        try {
            console.log('id', id);
      
            const snap = await courielCollection.doc(id).get();

 
            if (snap.exists) {
                resolve({ ...snap.data(), id: snap.id });
            } else {
                reject();
            }
        } catch (error) {
            if (reject) reject();
        }
    };
};

/**
 * @param {{
 *  affectation: Affectation_v4_Type,
 * callback?:() => void
 * onError?:() =>
 * }}
 */
export function createCouriel({ affectation, callback = null, onError = null }) {
    return async (dispatch) => {
        try {
            dispatch(slice.actions.startLoading());

            const addRef = await courielCollection.add(affectation);

            dispatch(slice.actions.createdSuccess());

            dispatch(courielCreationNotification({ couriel: { ...affectation, id: addRef.id } }));

            if (callback) callback(addRef.id);
        } catch (error) {
            console.log(error);
            if (onError) onError();
            dispatch(slice.actions.hasError(error));
        }
    };
}

/**
 *
 * @param {{
 *  newAffectation: Affectation_v4_Type,
 *  oldAffectation: Affectation_v4_Type,
 *  callback: ()=> void,
 *  onError: () => void
 * }} props
 * @returns
 */
export function updateAffectation({ newAffectation, oldAffectation, callback, onError }) {
    return async (dispatch, getState) => {
        dispatch(slice.actions.startLoading());
        try {
            const { id, ...rest } = newAffectation;

            const { added } = compareArraysOfObjects(oldAffectation.toDoIds, newAffectation.toDoIds);

            const docRef = courielCollection.doc(id);

            await docRef.set({ ...rest }, { merge: true });

            callback && callback();

            dispatch(courielAssignNotification({ couriel: newAffectation, targetIds: added }));

            if (newAffectation?.validation?.state && !oldAffectation?.validation?.state) {
                dispatch(courielValidateNotification({ couriel: newAffectation, targetIds: added }));
            }

        } catch (error) {
            console.log(error);
        }
    };
}

//coReceiver defaultReceiver assignationOrganigramme
export function initiateRappel({ newAffectation, oldAffectation }) {
    return async (dispatch, getState) => {

        try {


            const rappelCollection = firestore.collection('rappel')

            if (!newAffectation?.rappel?.date && !oldAffectation?.rappel?.date) return;

            const result = await rappelCollection
                .where("type", "==", RAPPEL_TYPE.AFFECTATION)
                .where("docId", "==", newAffectation?.id)
                .limit(1)
                .get()

            const rappel = result.docs.length != 0 ? result.docs.at(0) : null

            if (!newAffectation?.rappel?.date && oldAffectation?.rappel?.date && rappel || newAffectation?.completed) {

                await rappelCollection.doc(rappel?.id).delete()

                return;
            }


            const runAt = getOnlyDate(newAffectation?.rappel?.date)
            const userIds = [...newAffectation?.toDoIds, ...newAffectation?.toReviewIds]


            const allTokens = getState().notifications.tokens;

            const tokensWithoutSender = allTokens.filter((one) => one.id !== auth.currentUser.uid);

            const canReceivNotification = [...userIds].map((id) => {
                return [...tokensWithoutSender].find((tk) => tk.id === id);
            });

            const web = [];
            const mobile = [];

            canReceivNotification.forEach((us) => {
                if (us?.token) {
                    web.push(us.token);
                }
                if (us?.mobileToken) {
                    mobile.push(us?.mobileToken);
                }
            });

            const fcm = {
                web, mobile
            }

            await rappelCollection.doc(rappel?.id).set({
                type: RAPPEL_TYPE.AFFECTATION,
                docId: newAffectation?.id,
                runAt,
                nextRunAt: null,
                updatedAt: new Date(),
                userIds,
                fcm,
                metadata: {
                    object: newAffectation?.courriel_object,
                    ref: newAffectation?.courriel_reference,
                }
            }, { merge: true })


        } catch (error) {
            console.log(error)
        }


    }
}

export function deleteCouriel({ affect, callback }) {
    return async (dispatch) => {
        try {
            await courielCollection.doc(affect.id).set({ isDelete: true }, { merge: true });
            callback && callback();
        } catch (error) {
            console.log(error);
        }
    };
}

export function addCourielComment({ commentObject, generateId, affect, callback }) {
    return async (dispatch) => {
        try {
            await courielCollection.doc(affect.id).collection('comments').doc(generateId).set(commentObject);

            callback && callback();

            // dispatch(commentNotif({ affect: affect}));
        } catch (error) {
            console.log(error);
        }
    };
}

export function removeCourielComment({ id, callback }) {
    return async (dispatch) => {
        try {
            await courielCollection.doc(id).collection('comments').doc(id).delete();
            callback && callback();
        } catch (error) {
            console.log(error);
        }
    };
}

export function courielCreationNotification({ couriel, callback }) {
    return async (dispatch) => {
        try {
            const description = `Objet: ${couriel?.courriel_object}`;
            const descriptionHtml = `Objet: <strong>${couriel?.courriel_object}</strong>`;

            const baseNotData = {
                description,
                createdAt: serverTime(),
                type: NOTIFICATION_TYPES.COURIEL_ADD,
                returnId: couriel?.id,
                // cardTitle: project?.name,
                by: {
                    id: auth.currentUser.uid,
                    displayName: auth.currentUser.displayName || '',
                    photoURL: auth.currentUser.photoURL || ''
                },
                action: {
                    id: couriel?.id,
                    object: couriel?.courriel_object,
                    courriel_reference: couriel?.courriel_reference,
                    save_reference: couriel?.save_reference,
                    operation_type: couriel?.operation_type
                },
                html: {
                    description: descriptionHtml
                }
            };

            if (couriel?.defaultReceiver?.length !== 0) {
                dispatch(
                    createNotification({
                        data: {
                            title: `Une nouvelle affectation ${couriel?.save_reference} `,
                            ...baseNotData,
                            canAccess: [...couriel?.defaultReceiver],
                            isUnRead: transformIdToUnreadFormat([...couriel?.defaultReceiver]),
                            html: {
                                ...baseNotData?.html,
                                title: `Une nouvelle affectation <strong>${couriel?.save_reference}</strong>`
                            }
                        }
                    })
                );
            }

            if (couriel?.coReceiver?.length !== 0) {
                dispatch(
                    createNotification({
                        data: {
                            title: `Vous avez été designer comme intérimaire sur l'affectation ${couriel?.save_reference} `,
                            ...baseNotData,
                            canAccess: [...couriel?.coReceiver],
                            isUnRead: transformIdToUnreadFormat([...couriel?.coReceiver]),
                            html: {
                                ...baseNotData?.html,
                                title: `Vous avez été designer comme intérimaire sur l'affectation <strong>${couriel?.save_reference}</strong>`
                            }
                        }
                    })
                );
            }

            if (callback) callback(couriel?.id);
        } catch (error) {
            console.log(error);
        }
    };
}

export function courielAssignNotification({ couriel, targetIds = [], callback }) {
    return async (dispatch, getState) => {
        try {
            if (targetIds?.length === 0) return;

            const settings = getState()?.affectation_v4?.settings;
            const isMaiReceiver = settings?.defaultRecevierID?.includes(auth?.currentUser?.uid);

            const description = `Objet: ${couriel?.courriel_object}`;
            const descriptionHtml = `Objet: <strong>${couriel?.courriel_object}</strong>`;

            const baseNotData = {
                description,
                createdAt: serverTime(),
                type: NOTIFICATION_TYPES.COURIEL_ASSIGN,
                returnId: couriel?.id,
                canAccess: [...targetIds],
                isUnRead: transformIdToUnreadFormat([...targetIds]),
                by: {
                    id: auth.currentUser.uid,
                    displayName: auth.currentUser.displayName || '',
                    photoURL: auth.currentUser.photoURL || ''
                },
                action: {
                    id: couriel?.id,
                    object: couriel?.courriel_object,
                    courriel_reference: couriel?.courriel_reference,
                    save_reference: couriel?.save_reference,
                    operation_type: couriel?.operation_type
                },
                html: {
                    description: descriptionHtml
                }
            };

            dispatch(
                createNotification({
                    data: {
                        title: `Vous avez été assigné sur l'affectation ${couriel?.save_reference} `,
                        ...baseNotData,
                        html: {
                            ...baseNotData?.html,
                            title: `Vous avez été assigné sur l'affectation <strong>${couriel?.save_reference}</strong>`
                        }
                    }
                })
            );

            if (callback) callback(couriel?.id);
        } catch (error) {
            console.log(error);
        }
    };
}

export function courielValidateNotification({ couriel, targetIds = [], callback }) {
    return async (dispatch, getState) => {
        try {
            if (targetIds?.length === 0) return;

            const settings = getState()?.affectation_v4?.settings;
            const isMaiReceiver = settings?.defaultRecevierID?.includes(auth?.currentUser?.uid);

            const description = `Objet: ${couriel?.courriel_object}`;
            const descriptionHtml = `Objet: <strong>${couriel?.courriel_object}</strong>`;

            const baseNotData = {
                description,
                createdAt: serverTime(),
                type: NOTIFICATION_TYPES.COURIEL_VALIDATE,
                returnId: couriel?.id,
                canAccess: [...targetIds],
                isUnRead: transformIdToUnreadFormat([...targetIds]),
                by: {
                    id: auth.currentUser.uid,
                    displayName: auth.currentUser.displayName || '',
                    photoURL: auth.currentUser.photoURL || ''
                },
                action: {
                    id: couriel?.id,
                    object: couriel?.courriel_object,
                    courriel_reference: couriel?.courriel_reference,
                    save_reference: couriel?.save_reference,
                    operation_type: couriel?.operation_type
                },
                html: {
                    description: descriptionHtml
                }
            };

            dispatch(
                createNotification({
                    data: {
                        title: `Affectation validée `,
                        ...baseNotData,
                        html: {
                            ...baseNotData?.html,
                            title: `L'affectation <strong>${couriel?.save_reference}</strong> a été validée par <strong>${baseNotData?.by?.displayName}</strong> `
                        }
                    }
                })
            );

            if (callback) callback(couriel?.id);
        } catch (error) {
            console.log(error);
        }
    };
}

const transformIdToUnreadFormat = (list = []) => {
    let listIds = {};
    list.forEach((id) => (listIds[id] = true));
    return listIds;
};
