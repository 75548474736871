import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { createRappel, editRappel, deleteRappel } from 'src/redux/slices/rappel';

import useAuth from './useAuth';
import { useSelector } from 'src/redux/store';
import { useSnackbar } from 'notistack';


export default function useRappel({ docId }) {
    const { user } = useAuth()
    const dispatch = useDispatch()
    const { enqueueSnackbar } = useSnackbar()

    const getData = useSelector((state) => state.firestore.ordered.reminders);
    const loading = useMemo(() => !isLoaded(getData), [getData])
    const rappels = useMemo(() => isEmpty(getData) ? [] : getData, [getData]);

    useFirestoreConnect(() => [
        {
            collection: 'reminders',
            where: [['docId', '==', docId], ['userIds', "array-contains", user.id]],
            orderBy: [['createdAt', 'desc']],
            storeAs: 'reminders'
        }
    ]);

    const create = (rappel, onResolve, onReject) => {
        dispatch(createRappel(rappel, (rappel) => {
            if (onResolve) onResolve(rappel)
            enqueueSnackbar("Rappel créé avec succès", { variant: 'info' })
        }, onReject))
    }

    const edit = (rappel, onResolve, onReject) => {
        dispatch(editRappel(rappel, (rappel) => {
            if (onResolve) onResolve(rappel)
            enqueueSnackbar("Rappel modifié avec succès", { variant: 'info' })
        }, onReject))

    }

    const remove = (rappelId, onResolve, onReject) => {
        dispatch(deleteRappel(rappelId, () => {
            if (onResolve) onResolve()
            enqueueSnackbar("Suppression du rappel avec succès", { variant: 'info' })
        }, onReject))

    }

    return { rappels, loading, create, edit, remove };
}