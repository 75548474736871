import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from '../redux/store';
import {isEmpty, isLoaded, useFirestoreConnect} from 'react-redux-firebase';
import {getArchives} from '../redux/slices/archives';
import {uniqBy, isObject} from 'lodash';
import useAuth from "src/hooks/useAuth";
import {updateDisponibility} from "src/redux/slices/disponibility";
import {useSnackbar} from 'notistack'

export default function useDisponibility(email) {
    const {user} = useAuth()
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const getArc = useSelector(state => state.firestore.ordered[`dispobility_${user?.id}`]);
    const disponibility = useMemo(() => {
        return getArc ? getArc[0] : null
    }, [getArc]);

    const {enqueueSnackbar} = useSnackbar()

    const update = (data) => {
        const result = {...data, id: user?.id}
        // if (data?.toString() !== result?.toString())
            dispatch(updateDisponibility({
                data: result, onResolve: () => {
                    enqueueSnackbar('Disponibilité bien mise à jour', {variant: 'success'})
                }
            }))
    }

    useFirestoreConnect(() => [
        {
            collection: 'dispobility',
            doc: user?.id,
            storeAs: `dispobility_${user?.id}`
        }
    ]);

    useEffect(() => {
        setLoading(!isLoaded(getArc));
    }, [getArc, disponibility, dispatch]);


    return {disponibility, update, loading};
}
