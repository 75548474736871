import React, { useMemo, useState } from 'react';
import { Button, Checkbox, Divider, FormControlLabel, FormGroup, Stack, Typography } from '@mui/material';
import { MenuLine } from 'src/components/MenuLine';
import MenuPopover from 'src/components/MenuPopover';
import { baseColors } from 'src/constants/color';
import { useToggleV2 } from 'src/hooks/useToggle';
import ActionModal from 'src/components/ActionModal';
import { useDispatch } from 'src/redux/store';
import { duplicateProject, updateProject } from 'src/redux/slices/kanban';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { PROJECT_STATE_VAL } from 'src/pages/dashboard/TaskProjectMyProjects';

/**
 *
 * @param {{
 * open: Boolean,
 * onClose: Function,
 * anchorEl: any,
 * project: any,
 * role: any,
 * user: any,
 * handleEdit: Function
 * handleOpen: Function,
 * onDelete: Function
 * }} props
 */
export default function ProjectMoreOption(props) {
  const { open, onClose, anchorEl, role, project, user, handleOpen, handleEdit, onDelete, onRemove } = props;
  const [question, openQuestion, closeQuestion] = useToggleV2();
  const [notDelete, openNotDelete, closeNotDelete] = useToggleV2();
  const [visibility, openR, closeR] = useToggleV2();
  const [duplicate, setDuplicate] = useState({ gestionaire: false, intervenant: false });
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [visible, display, hide] = useToggleV2();
  const [confirmArchive, setConfirmArchive] = useState(false);

  const createEmail = useMemo(() => {
    return project?.createBy?.userEmail || project?.createBy?.email;
  }, [project]);

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const canEdit = useMemo(() => Boolean((role?.task?.edit && createEmail === user?.email) || role?.admin), [role]);
  //eslint-disable-next-line react-hooks/exhaustive-deps
  const canDelete = useMemo(() => Boolean(role?.task?.delete && createEmail === user?.email), [role]);

  const editProject = () => {
    if (canEdit) {
      handleEdit();
      onClose();
    }
  };

  const openProject = () => {
    handleOpen();
    onClose();
  };

  const handleDelete = () => {
    onDelete();
    onClose();
  };

  const onDuplicate = () => {
    openQuestion();
    onClose();
  };

  const handlechange = (e, field) => {
    setDuplicate({ ...duplicate, [field]: e.target.checked });
  };

  const handleDuplicate = () => {
    onClose();
    setPending(true);

    const callback = () => {
      enqueueSnackbar('Projet dupliqué avec succès', { variant: 'info' });
    };

    const stop = () => {
      closeQuestion();
      setPending(false);
    };

    dispatch(duplicateProject({ projectId: project?.id, duplicate, user, callback, stop }));
  };

  const isClosed = useMemo(() => {
    return project?.state === PROJECT_STATE_VAL.SUSPENDED || project?.state === PROJECT_STATE_VAL.SUSPENDED;
  }, [project?.state]);
  const nextClosedSate = useState(() => {
    const { progress = 0 } = project;

    if (progress < 100) return 'suspendu';
    if (progress >= 100) return 'clos';
  }, [project?.progress]);

  const handleArchiveChange = () => {
    let state = project?.state;
    let text = 'Projet archivé avec succès';

    if (isClosed) {
      text = 'Projet retiré des archives avec succès';
      state = PROJECT_STATE_VAL.IN_PROGRESS;
    } else {
      const { progress = 0 } = project;

      if (progress < 100) state = PROJECT_STATE_VAL.SUSPENDED;
      if (progress >= 100) state = PROJECT_STATE_VAL.CLOSED;
    }

    dispatch(
      updateProject({ ...project, state }, project, () => {
        enqueueSnackbar(text, { variant: 'info' });
      })
    );
  };
  const handleToggleArchiveState = () => {
    if (isClosed) {
      handleArchiveChange();
    } else {
      display();
    }
  };

  return (
    <>
      <MenuPopover
        width={170}
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin="bottom"
        horizontal="center"
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        arrow="top-center"
      >
        <MenuLine title="Ouvrir" fontWeight="bold" icon="bi:play-fill" onClick={openProject} />
        <MenuLine
          title="Editer"
          fontWeight="bold"
          icon="clarity:note-edit-line"
          onClick={editProject}
          disabled={!canEdit}
        />
        <MenuLine title="Dupliquer" fontWeight="bold" icon="ion:duplicate-outline" onClick={onDuplicate} />
        <MenuLine
          title={isClosed ? 'Retirer des archives' : 'Archiver'}
          fontWeight="bold"
          icon="ic:baseline-archive"
          onClick={handleToggleArchiveState}
        />

        <Divider light />
        {onRemove && <MenuLine title="Retirer" fontWeight="bold" icon="ep:remove-filled" color="red" onClick={openR} />}

        <MenuLine
          title="Supprimer"
          fontWeight="bold"
          icon="eva:trash-fill"
          color="red"
          onClick={canDelete ? handleDelete : openNotDelete}
        />
      </MenuPopover>
      {visible && (
        <ActionModal
          title="Archiver cet projet?"
          color="error"
          open={visible}
          onClose={hide}
          moreAction={
            <Stack direction="row" spacing={1} alignItems="center">
              <Checkbox
                size="small"
                color="error"
                checked={confirmArchive}
                onChange={(e) => setConfirmArchive(e.target.checked)}
              />
              <Typography fontSize={13} fontWeight={120}>
                Je comprends qu'une fois fait , cet projet sera {nextClosedSate}
              </Typography>
            </Stack>
          }
          action={
            <Button color="error" variant="contained" disabled={!confirmArchive} onClick={handleArchiveChange}>
              Appliquer
            </Button>
          }
        />
      )}

      {notDelete && (
        <ActionModal
          title="Suppression de projet"
          color="error"
          open={notDelete}
          onClose={closeNotDelete}
          desc="Vous ne pouvez pas supprimer ce projet. Verifiez si vous êtes le créateur ou si vous avez les autorisations requis"
          closeText="OK"
        />
      )}

      {visibility && (
        <ActionModal
          title="Retier ce projet?"
          color="error"
          open
          onClose={closeR}
          desc="Êtes vous, certain de vouloir retirer le projet de cet espace ?"
          action={
            <Button color="error" variant="contained" onClick={onRemove}>
              Appliquer
            </Button>
          }
        />
      )}
      {question && (
        <ActionModal
          title="Duplication de projet"
          open={question}
          onClose={closeQuestion}
          color="info"
          desc="Voulez-vous dupliquer ce projet avec ces gestionnaires et ou intervenants ?"
          moreAction={
            <Stack pl={3}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox checked={duplicate.gestionaire} onChange={(e) => handlechange(e, 'gestionaire')} />
                  }
                  label="Avec les gestionnaires"
                />
                <FormControlLabel
                  control={
                    <Checkbox checked={duplicate.intervenant} onChange={(e) => handlechange(e, 'intervenant')} />
                  }
                  label="Avec les intervenants"
                />
              </FormGroup>
            </Stack>
          }
          action={
            <LoadingButton loading={pending} variant="contained" color="info" onClick={handleDuplicate}>
              Dupliquer
            </LoadingButton>
          }
        />
      )}
    </>
  );
}
