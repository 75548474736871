import React from 'react'
import { orderBy } from 'lodash';
import { Avatar, Box, Button, IconButton, Stack, styled, Typography } from '@mui/material'
import {fr as LocalFr} from 'date-fns/locale';
import { formatDistanceToNowStrict } from 'date-fns';
import Scrollbar from './Scrollbar';
import { MessageContainer } from 'src/section/_dashboard/chat/ChatMessageItem';
import { gDate } from 'src/utils/formatTime';
import Markdown from './Markdown';
import { useToggleV2 } from 'src/hooks/useToggle';
import { Icon } from '@iconify/react';
import MenuPopover from './MenuPopover';

const RootStyle = styled('div')(({theme}) => ({
    display: 'flex',
    marginBottom: theme.spacing(1)
}));


const InfoStyle = styled(Typography)(({theme}) => ({
    display: 'flex',
    marginBottom: theme.spacing(0.75),
    fontSize: 10,
    color: theme.palette.text.secondary
}));


export default function CustomCommentList({ list=[], user, details, onDeleteComment }) {
    const scrollRef = React.useRef();

    React.useEffect(() => {
        const scrollMessagesToBottom = () => {
            if (scrollRef.current) {
                scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
            }
        };
        scrollMessagesToBottom();
    }, [list]);

  return (
    <Scrollbar
        scrollableNodeProps={{ref: scrollRef}}
        sx={{ p: 1, flexGrow: 1 }}
    >
        {list.length ? orderBy(list.map(_one => ({..._one,createdAt: gDate(_one?.createdAt) })), "createdAt").map((comment, idx) =>(
            <CommentItem key={comment.id+idx} comment={comment} details={details} user={user} onDeleteComment={onDeleteComment} />
        )) : null }
    </Scrollbar>
  )
};


const CommentItem = ({ comment, details, user, onDeleteComment }) =>{
    const deleteRef = React.useRef();
    const [hover, onHover, endHover] = useToggleV2();
    const [openD, onOpenD, closeD] = useToggleV2();
    const isMe = React.useMemo(() => comment.userId === user?.id, [comment, user?.id]);
   

    const message = React.useMemo(() => {
        return comment.message.replace('contenteditable="false"', 'suppressContentEditableWarning="true"')
    }, [comment]);

    const deleteComment = () =>{
        onDeleteComment({ id: comment.id, details});
        closeD();
    }


    return (
        <RootStyle>
            <Box
                onMouseEnter={onHover}
                onMouseLeave={endHover}
                ref={deleteRef}
                sx={{
                    display: 'flex',
                    ...(isMe && {
                        ml: 'auto',
                    })
                }}
            >
                {!isMe && (
                    <Avatar
                        alt={comment?.name || ''}
                        src={comment?.avatar || ''}
                        sx={{width: 25, height: 25}}
                    />
                )}

                <Box sx={{ ml: 2}}>
                    <InfoStyle
                        noWrap
                        variant="caption"
                        sx={{...(isMe && {justifyContent: 'flex-end'})}}
                    >
                        {!isMe && `${comment.name},`}&nbsp;
                        {comment?.createdAt && 
                        formatDistanceToNowStrict(gDate(comment?.createdAt), {
                            addSuffix: true,
                            locale: LocalFr
                        })}
                       {hover && <IconButton onClick={onOpenD} size='small' sx={{ p: 0, ml: 1}}> 
                            <Icon icon='eva:trash-fill' color='red' height={15} />
                        </IconButton>}
                    </InfoStyle>
                    {
                            <MessageContainer isMe={isMe}>
                                {
                                comment.messageType === 'audio' ?
                                <audio src={comment.message} controls>
                                <track default
                                    kind="captions"
                                    srcLang="fr"
                                    src="" />
                                </audio>:
                                <Markdown  children={message} />
                                }
                            </MessageContainer>
                    }
                    {comment.messageType === 'audio' && (<></>)}
                </Box>
 
            </Box>
            {openD  && 
                <MenuPopover  open={openD} onClose={closeD} anchorEl={deleteRef.current}  
                  horizontal='center'
                  anchorOrigin='top'
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                <Box sx={{p: 1}}>
                    <Typography fontSize={13}> Êtes-vous sûr de vouloir supprimer ce commentaire ? </Typography>
                    <Stack direction="row" justifyContent="space-between">
                        <Button color="inherit" onClick={closeD}>Annuler</Button>
                        <Button color="error" onClick={deleteComment}> Supprimer</Button>
                    </Stack>
                </Box>
            </MenuPopover> 
            }
        </RootStyle>
    );
}
