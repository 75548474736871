import { useState, useEffect, useMemo } from 'react';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';
import { useDispatch, useSelector } from 'src/redux/store';
import { addUserList, closeUserLoading, startUserLoading } from './../redux/slices/user';

/**
 *
 * @returns {{
 *  users: Array,
 * loading: Boolean
 * }}
 */
export default function useUserList() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const getUsers = useSelector((state) => state.firestore.ordered.users);

  const users = useMemo(() => {
    return isEmpty(getUsers) ? [] : getUsers;
  }, [getUsers]);

  useFirestoreConnect(() => [{ collection: 'users', where: [['delete', '==', false]], storeAs: 'users' }]);

  useEffect(() => {
    if (!isLoaded(getUsers)) {
      setLoading(true);
      dispatch(startUserLoading());
    } else {
      setLoading(false);
      dispatch(closeUserLoading());
      dispatch(addUserList(users));
    }
  }, [dispatch, getUsers, users]);

  return { users, loading };
}
