import { useState, useEffect } from "react";
import { uniqBy } from "lodash";
import { nanoid } from "@reduxjs/toolkit";
import { useSnackbar } from "notistack";
import { Box, Stack } from "@mui/material";
import CommentInput from "src/components/CommentInput";
import CustomCommentList from "src/components/CustomCommentList";
import LoadingView from "src/components/LoadingView";
import { useAffectationComment } from "src/hooks/useAffectation";
import { useDispatch } from "src/redux/store";
import { addAffectComment, removeAffectComment } from "src/redux/slices/affectations";
import { serverTime } from "src/utils/serverTime";

export const AffectComment = ({ idQ, details, user}) =>{
    const { loading, comments } = useAffectationComment({id: details?.id});
    const [commentList, setComment] = useState([]);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => setComment(uniqBy(comments, 'id')),[comments]);

    const handleSaveComment = (comment) =>{
        const generateId = nanoid();
        const callback = () =>{}
        const obj = {
            ...comment,
            userId: user.id,
            avatar: user.photoURL,
            name: user.displayName,
            email: user.email,
            createdAt: serverTime(),
        }
        setComment(c => [...c, {...obj, id: generateId}]);
        dispatch(addAffectComment({
            affect: details,
            commentObject: obj,
            generateId,
            callback
        }));
    }

    const onDeleteComment = ({id}) =>{
        const callback = () =>{
            enqueueSnackbar('Commentaire supprimé', { variant: 'warning'});
        }
        setComment(c => c.filter((_one) => _one?.id !== id));
        dispatch(removeAffectComment({ id, affect: details, callback}))
    }


    return (
        <Stack width={1} spacing={2} sx={{ height: '65vh', maxHeight: '65vh' }}>
               {loading && <LoadingView size={{ height: 150, width:150 }} />}
             <Box sx={{flexGrow: 1, display: 'flex', overflow: 'hidden'}}>
             <Box  sx={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
                {!loading && commentList.length > 0 && 
                <CustomCommentList 
                    list={commentList} user={user} 
                    affect={details} 
                    onDeleteComment={onDeleteComment} 
                /> }
           
                <CommentInput
                    idQ={idQ}
                    onChange={handleSaveComment} 
                />
             </Box>
             </Box>
        </Stack>
    );
}
