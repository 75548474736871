import React from 'react';
import { Stack } from '@mui/material';
import KanbanTaskAttachments from './KanbanTaskAttachments';

export default function KanbanDetailsAttach({
  userId,
  files,
  details,
  userName,
  userEmail,
  userAvatar,
  isUploading,
  onRemoveFile,
  onUpdateFile,
  handleUploadAttachement,
  rigths = { read: true, create: true, edit: false, delete: true }
}) {
  return (
    <Stack className="scroll-smooth-small" height={1} maxHeight="80vh" mt={0.5} spacing={2} sx={{ overflow: 'auto' }}>
      <Stack direction="row" flexWrap="wrap">
        <KanbanTaskAttachments
          attachments={files || details.attachments}
          onRemove={onRemoveFile}
          onUpdateFile={onUpdateFile}
          onChange={handleUploadAttachement}
          isUploading={isUploading}
          userEmail={userEmail}
          userId={userId}
          userName={userName}
          userAvatar={userAvatar}
          hideDelete={!rigths?.delete}
          disableUpload={!rigths?.edit || !rigths?.create}
        />
      </Stack>
    </Stack>
  );
}
