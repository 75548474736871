import { TASK_PRIORITIES } from "src/constants";
import { AFFECT_PRIORITY } from "src/constants/affectation";
import { baseColors } from "src/constants/color";

export const priorityColor = (priority) =>{
    if(priority === TASK_PRIORITIES.BASE) return baseColors.GREEN;
    if(priority === TASK_PRIORITIES.NORMAL) return baseColors.BLUE;
    if(priority === TASK_PRIORITIES.HIGH) return baseColors.WARINIG;
    if(priority === TASK_PRIORITIES.IMPORTANT) return baseColors.RED;
    if(priority === AFFECT_PRIORITY.VERY_IMPORTANT) return 'red';
    return '';
}