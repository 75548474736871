import React, { useState, createContext, useContext,} from 'react'
import { useEdgesState, useNodesState } from 'react-flow-renderer';
import useToggle from 'src/hooks/useToggle';


const WorkflowContext = createContext({
    detail: null,
    /**
     * @type {any[]}
     * */
    nodes: [],
     /**
     * @type {any[]}
     * */
    edges: [],
    open: false,
    /**
     * @param {React.SetStateAction<Node<any>[]>} value 
     */
    setNodes: (value) => {},
     /**
     * @param {React.SetStateAction<Node<any>[]>} value 
     */
    setEdges: (value) => {},
    /**
     * @type {OnChange<NodeChange>}
     */
    onNodesChange:() => {},
    /**
     * @type {OnChange<EdgeChange>}
     */
    onEdgesChange:() => {},
    openDetail: (_detail) => {},
    closeDetail: () => {},

})

export const useWorkflowContext = () =>{
    return useContext(WorkflowContext);
}

export default function WorkflowProvider({ children }) {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);

    const [detail, setDetail] = useState(null);
    const {open, handleOpen, handleClose } = useToggle();

    const openDetail = (_detail) =>{
        setDetail(_detail);
        handleOpen();
    }

    const closeDetail = () =>{
        handleClose();
        setDetail(null);
    }

    const store = { 
      detail,
      open, 
      nodes, 
      edges, 
      setEdges, 
      setNodes, 
      openDetail, 
      closeDetail,
      onNodesChange,
      onEdgesChange 
    };

  return (
    <WorkflowContext.Provider value={store} >
      {children}
    </WorkflowContext.Provider>
  )
}