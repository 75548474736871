import {createSlice} from '@reduxjs/toolkit'
import {firestore, auth} from "src/contexts/FirebaseContext";
import {hasAccountExpired} from "src/contexts/MicrosoftMailContext";
import axios from "axios";
import {MAIL_FOLDER} from "src/section/mail/MailContext";
import {gDate} from "src/utils/formatTime";
import {keys, uniq, values} from 'lodash'
import {vercelCloudMail} from "src/config";

const slice = createSlice({
    name: 'microsoft-mail',
    initialState: {
        accounts: {},
        folders: {},
        mails: {},
        loading: []
    },
    reducers: {
        setAccounts: (state, action) => {
            state.accounts = {
                ...state.accounts,
                ...action.payload
            }
        },
        setFolders: (state, action) => {
            state.folders = {
                ...state.folders,
                ...action.payload
            }
        },
        setMailsInFolders: (state, action) => {
            const email = action.payload.email;
            const folder = action.payload.folder;
            const mails = action.payload.mails;
            state.mails = {
                ...(state.mails || {}),
                [email]: {
                    ...(state.mails[email] || {}),
                    [folder]: {
                        ...((state.mails[email] || {})[folder] || {}),
                        ...mails
                    }
                }
            }
        },
        removeMailsInFolder: (state, action) => {
            const copy = {...state.mails}
            const email = action.payload.email;
            const folder = action.payload.folder;
            const mailId = action.payload.mailId;

            delete ((copy[email] || {})[folder] || {})[mailId];

            state.mails = copy;

        },
        updateMailsInFolder: (state, action) => {
            const copy = {...state.mails}
            const email = action.payload.email;
            const folder = action.payload.folder;
            const mailId = action.payload.mailId;
            const values = action.payload.values;

            const mail = ((copy[email] || {})[folder] || {})[mailId];

            if (!mail) return;

            Object.assign(mail, {...values})

            state.mails = copy;
        },
        setLoading: (state, action) => {
            state.loading = uniq([
                ...state.loading,
                ...action.payload
            ])
        },
        revokeLoading: (state, action) => {
            state.loading = state.loading?.filter(key => !action.payload.includes(key))
        },
        removeAccount: (state, action) => {
            delete state.accounts[action.payload]
            delete state.folders[action.payload]
            delete state.mails[action.payload]
        }
    }
})

export const {
    setAccounts,
    setFolders,
    setMailsInFolders,
    setLoading,
    revokeLoading,
    removeMailsInFolder,
    updateMailsInFolder,
    removeAccount
} = slice.actions;

const microsoftMailReducer = slice.reducer;

export default microsoftMailReducer;

export const persistToken = ({email, result, userId}) => {
    return async (dispatch, getStore) => {
        try {
            const accounts = getStore()?.microsoftMail?.accounts || {};
            const isExpired = hasAccountExpired(accounts[email]);
            // console.log("accounts", accounts)
            // console.log("isExpired", isExpired)

            if (isExpired) {

                await firestore.runTransaction(transaction => {
                    const docRef = firestore.collection('users').doc(userId);
                    return transaction.get(docRef).then(snap => {
                        const linkedAccount = {
                            ...(snap?.data()?.linkedAccount || {}),
                            ...result
                        }
                        // console.log('result save', linkedAccount)

                        transaction.update(docRef, {linkedAccount});

                        dispatch(setAccounts(result));
                        dispatch(getAccountFolders({
                            account: result[email], onResolve: () => {
                                const account = result[email];

                                dispatch(getMails({account, folderType: MAIL_FOLDER.INBOX}))
                                dispatch(getMails({account, folderType: MAIL_FOLDER.SENT}))
                                dispatch(getMails({account, folderType: MAIL_FOLDER.SPAM}))
                                dispatch(getMails({account, folderType: MAIL_FOLDER.TRASH}))

                            }
                        }))

                    })
                })
            }
        } catch (error) {
            console.log(error)
        }

    }
}

export const igniteMsAccount = ({accountSnap}) => {
    return (dispatch, getStore) => {
        try {
            const snap = getStore()?.microsoftMail?.accounts || {};

            console.log("igniteMsAccount ", accountSnap)

            dispatch(setAccounts(accountSnap))

            keys(accountSnap).forEach(email => {
                if (snap[email]) return;

                const account = accountSnap[email];
                dispatch(refreshToken({
                    account, callback: (account) => {
                        dispatch(getAccountFolders({
                            account, onResolve: () => {
                                dispatch(getMails({account, folderType: MAIL_FOLDER.INBOX}))
                                dispatch(getMails({account, folderType: MAIL_FOLDER.SENT}))
                                dispatch(getMails({account, folderType: MAIL_FOLDER.SPAM}))
                                dispatch(getMails({account, folderType: MAIL_FOLDER.TRASH}))

                            }
                        }))
                    }
                }))

            })

        } catch (error) {
            console.log(error)
        }
    }
}

export const updateAccountData = ({email, data, userId}) => {
    return async (dispatch) => {
        try {

            await axios.patch(`${vercelCloudMail}/account`, {
                ...data
            }, {
                headers: {
                    userid: auth.currentUser.uid,
                }
            })

            const account = {[email]: data}

            dispatch(setAccounts(account));

        } catch (e) {
            console.log(e)
        }
    }
}

export const sendMsMail = ({account = {}, email, onResolve, onReject}) => {
    return async (dispatch, getStore) => {
        try {

            const {token} = account;

            if (!token) return account;


            const snap = await axios.post("https://graph.microsoft.com/v1.0/me/sendMail", {
                "message": {
                    "subject": email?.subject,
                    "body": {
                        "contentType": "HTML",
                        "content": email?.body,
                    },
                    "toRecipients": transformStringRecipientsToArray(email?.to),
                    "ccRecipients": transformStringRecipientsToArray(email?.cc)
                },
                "saveToSentItems": "false"
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json",
                }
            })

            onResolve && onResolve()
        } catch (error) {
            console.log(error)
            onReject && onReject()
        }
    }
}

export const getAccountFolders = ({account, onResolve, onReject}) => {
    return async (disptach) => {
        const utils = new LoadingUtil(disptach, `getAccountFolders-${account?.user?.email} `)

        try {

            const {token, user} = account;

            if (!token) return account;
            utils.start()

            const snap = await axios.get("https://graph.microsoft.com/v1.0/me/mailFolders", {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json",
                }
            })

            const value = snap.data?.value || [];

            let data = {};

            value.forEach(doc => {
                if (doc?.displayName === 'Inbox' || doc?.displayName === 'Boîte de réception') {
                    data = {
                        ...data,
                        [MAIL_FOLDER.INBOX]: doc,
                    }
                }

                if (doc?.displayName === 'Outbox' || doc?.displayName === 'Boîte d\'envoi') {
                    data = {
                        ...data,
                        [MAIL_FOLDER.SENT]: doc,
                    }
                }


                if (doc?.displayName === 'Junk Email' || doc?.displayName === 'Courrier indésirable') {
                    data = {
                        ...data,
                        [MAIL_FOLDER.SPAM]: doc,
                    }
                }

                if (doc?.displayName === 'Deleted Items' || doc?.displayName === 'Éléments supprimés') {
                    data = {
                        ...data,
                        [MAIL_FOLDER.TRASH]: doc,
                    }
                }

            })


            disptach(setFolders({
                [user?.email]: data
            }))

            // console.log("snap", snap.data)
            // console.log("data", data)
            utils.end()
            onResolve && onResolve(data)
        } catch (error) {
            console.log(error)
            onReject && onReject()
            utils.end()
        }
    }
}
export const getMails = ({account = {}, folderType, onResolve, onReject}) => {
    return async (dispatch, getStore) => {
        const utils = new LoadingUtil(dispatch, `getMails-${account?.user?.email}-${folderType}`)

        try {

            const {token, user} = account;

            if (!token) return account;

            utils.start()

            const folders = getStore()?.microsoftMail?.folders || {};
            const folderId = folders[account?.user?.email][folderType]?.id

            const snap = await axios.get(`https://graph.microsoft.com/v1.0/me/mailFolders/${folderId}/messages`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json",
                }
            })

            const result = {
                folder: folderType,
                email: account?.user?.email,
                mails: listToObject((snap.data?.value || [])?.map(el => transformMsMailToCustom(el)))
            };

            // const mails
            // console.log('folderType', folderType)
            // console.log('folderId', folderId)
            // console.log('result', result)

            dispatch(setMailsInFolders(result))
            onResolve && onResolve()
            utils.end()
        } catch (error) {
            console.log(error)
            onReject && onReject()
            utils.end()
        }
    }
}

export const toggleMsMailImportance = ({account, folderType, mailId, isImportant, onResolve, onReject}) => {
    return async (dispatch) => {

        try {
            const {token} = account;

            if (!token) return account;

            const snap = await axios.patch(`https://graph.microsoft.com/v1.0/me/messages/${mailId}`, {
                importance: isImportant ? "high" : 'normal'
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json",
                }
            })

            dispatch(updateMailsInFolder({
                email: account?.user?.email,
                folder: folderType,
                mailId,
                values: {
                    isImportant
                }
            }))

            dispatch(getMails({account, folderType}))


            onResolve && onResolve()
        } catch (error) {
            onReject && onReject()
            console.log(error)
        }
    }
}

export const getMsMailAttachment = ({account, folderType, mailId, onResolve, onReject}) => {
    return async (dispatch) => {

        try {
            const {token} = account;

            if (!token) return account;

            const snap = await axios.get(`https://graph.microsoft.com/v1.0/me/messages/${mailId}/attachments`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json",
                }
            })

            // dispatch(updateMailsInFolder({
            //     email: account?.user?.email,
            //     folder: folderType,
            //     mailId,
            //     values: {
            //         isImportant
            //     }
            // }))
            //
            // dispatch(getMails({account, folderType}))

            const result = (snap.data.value || [])?.map(file => {

                const raw = window.atob(file.contentBytes);
                const rawLength = raw.length;
                let array = new Uint8Array(new ArrayBuffer(rawLength));      // pass your byte response to this constructor

                for (let i = 0; i < rawLength; i++) {
                    array[i] = raw.charCodeAt(i);
                }

                const blob = new Blob([array], {type: file.contentType});

                const url = window.URL.createObjectURL(blob);
                return {
                    ...file,
                    type: file?.contentType,
                    url,
                }
            })

            onResolve && onResolve(result)

        } catch (error) {
            onReject && onReject()
            console.log(error)
        }
    }
}
export const markMailAsRead = ({account = {}, folderType, mailId, onResolve, onReject}) => {
    return async (dispatch, getStore) => {
        try {

            const {token, user} = account;

            if (!token) return account;

            const folders = getStore()?.microsoftMail?.folders || {};
            const folderId = folders[account?.user?.email][folderType]?.id
            console.log('mark as read', mailId)
            dispatch(updateMailsInFolder({
                email: account?.user?.email,
                folder: folderType,
                mailId,
                values: {
                    isRead: true
                }
            }))

            const snap = await axios.patch(`https://graph.microsoft.com/v1.0/me/messages/${mailId}`, {
                isRead: true
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json",
                }
            })


            // Implement update of the mail
            // console.log('folderType', snap.data)
            // console.log('folderId', folderId)
            // console.log('result', result)

        } catch (error) {
            console.log(error)
        }
    }
}

export const deleteMsMail = ({account = {}, folderType, mailId, onResolve, onReject}) => {
    return async (dispatch, getStore) => {
        try {

            const {token, user} = account;

            if (!token) return account;

            const folders = getStore()?.microsoftMail?.folders || {};
            const folderId = folders[account?.user?.email][MAIL_FOLDER.TRASH]?.id


            const snap = await axios.post(`https://graph.microsoft.com/v1.0/me/messages/${mailId}/move`, {
                destinationId: folderId
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-type": "application/json",
                }
            })

            dispatch(removeMailsInFolder({
                email: account?.user?.email,
                folder: folderType,
                mailId
            }))

            dispatch(getMails({account, folderType: MAIL_FOLDER.TRASH}))

            // Implement update of the mail


            onResolve && onResolve()
        } catch (error) {
            console.log(error)
            onReject && onReject()
        }
    }
}


export const disconnectAccount = (account) => {
    return async (dispatch) => {
        const utils = new LoadingUtil(dispatch, `refreshToken`)
        try {
            utils.start()

            dispatch(removeAccount(account?.user?.email))

            await axios.delete(`${vercelCloudMail}/account?accountId=${account?.id}`, {
                headers: {
                    userid: auth.currentUser.uid,
                    accountId: account?.id
                }
            })

            utils.end()

        } catch (error) {
            console.log(error)
            utils.end()

        }
    }
}
const refreshToken = ({account, callback}) => {
    return async (dispatch) => {
        const utils = new LoadingUtil(dispatch, `refreshToken`)

        try {
            utils.start()

            if (!hasAccountExpired(account)) {
                utils.end()
                console.log('refresh ', account)
                return callback(account)
            }

            const snap = await axios.get(`${vercelCloudMail}/google_get_token.ts`, {
                headers: {
                    userid: auth.currentUser.uid,
                    accountid: account?.id
                }
            })

            const data = snap.data
            callback(data)

            dispatch(updateAccountData({email: data?.user?.email, data, userId: auth.currentUser.uid}))
            utils.end()

            console.log("refreshToken", data)
        } catch (error) {
            utils.end()
            console.log(error)
        }
    }
}
export const refreshLinkedAccount = () => {
    return async (dispatch) => {
        const utils = new LoadingUtil(dispatch, `refreshLinkedAccount`)

        try {

            utils.start()
            const snap = await axios.get(`${vercelCloudMail}/account`, {
                headers: {
                    userid: auth.currentUser.uid,
                }
            })

            let accountSnap = {};

            keys(snap.data)?.forEach(id => {
                const email = snap.data[id]?.user?.email

                accountSnap[email] = ({...snap.data[id], email})
            })

            dispatch(igniteMsAccount({accountSnap}))
            utils.end()
            console.log(accountSnap)
        } catch (error) {
            utils.end()
            console.log(error)
        }
    }
}

const transformMsMailToCustom = (msMail) => {
    return {
        id: msMail?.id,
        from: msMail?.from?.emailAddress?.address,
        senderName: msMail?.from?.emailAddress?.name || null,
        to: transformArrayRecipientsToString(msMail?.toRecipients),
        to_string: transformUsersArrayToString(msMail?.toRecipients),
        cc: transformArrayRecipientsToString(msMail?.ccRecipients),
        cc_string: transformUsersArrayToString(msMail?.ccRecipients),
        subject: msMail?.subject,
        body: msMail?.body?.content,
        sentDate: gDate(msMail?.sentDateTime),
        receivedDate: gDate(msMail?.receivedDateTime),
        isRead: msMail?.isRead,
        metaData: msMail,
        hasAttachments: msMail?.hasAttachments || false,
        // Implemter l'importance
        isImportant: msMail?.importance !== 'normal',
        //Implementer les fichiers joints
        //

    }
}
export const transformStringRecipientsToArray = (data = '') => {
    return data.split(',').map(mail => mail.trim().replaceAll(' ', '')).filter(el => el.length !== 0).map(address => ({
        emailAddress: {
            address
        }
    }))
}
export const transformArrayRecipientsToString = (data = []) => {
    let result = "";

    data.forEach(el => {
        result += `${el?.emailAddress?.address},`
    })

    return result
}

const transformUsersArrayToString = (data = []) => {
    let result = "";

    data.forEach((el, index) => {
        const name = el?.emailAddress?.name;
        const email = el?.emailAddress?.address;
        const isLast = (data.length - 1) === index;

        if (name !== email)
            result += `${name} <${email}>`
        else {
            result += `<${email}>`
        }

        if (isLast) return;

        result += ` , `

    })

    return result
}
export const listToObject = (list = []) => {
    let result = {}

    list.forEach(el => {
        result[el?.id] = el
    })

    return result
}

export const transformToMsEmail = (data) => {
    return {}
}

class LoadingUtil {
    key = null;
    dispatch = null;

    constructor(dispatch, key) {
        this.dispatch = dispatch
        this.key = key
    }

    start() {
        this.dispatch(setLoading([this.key]))
    }

    end() {
        this.dispatch(revokeLoading([this.key]))
    }
}