import React, { useMemo } from 'react';
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID,
  createTheme,
  alpha
} from '@mui/material/styles';
import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import useSettings from 'src/hooks/useSettings';
import joyThemeOption from './joyOptions';
import { useTheme } from '@emotion/react';
import ComponentsOverrides from './overrides';

const CustomJoyTheme = ({ children }) => {
  const defaultTheme = useTheme();
  const { setColor } = useSettings();

  const themeOptions = useMemo(
    () => ({
      ...defaultTheme,
      palette: {
        ...defaultTheme.palette,
        primary: setColor
      },
      customShadows: {
        ...defaultTheme.customShadows,
        primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`
      }
    }),
    [setColor, defaultTheme]
  );

  const theme = createTheme(themeOptions);
  theme.components = ComponentsOverrides(theme);

  const joyTheme = extendTheme(joyThemeOption);
  const materialTheme = materialExtendTheme(theme);

  return (
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <CssVarsProvider defaultMode="light" theme={joyTheme}>
        <CssBaseline />
        {children}
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

export default CustomJoyTheme;
