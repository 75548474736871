import {useMemo, useState} from 'react'

import {values} from 'lodash'
import {searchList} from "src/utils/array";

import {Typography, TextField, Stack, ListItemButton, List, Button, Checkbox} from '@mui/material'
import MenuPopover from "src/components/MenuPopover";
import Scrollbar from "src/components/Scrollbar";

import list from "src/assets/currency.json"

const options = values(list)?.map(el => ({
    name: el?.name,
    symbol: el?.symbol_native,
    _helper: `${el?.name} ${el?.symbol_native}`
}))

export default function CurrencyPicker({open, onClose, onPick, selected = null, anchorEl}) {
    const [chosen, setChosen] = useState([])
    const [keyword, setKeyword] = useState('')
    const filtered = useMemo(() => searchList(options, keyword), [keyword])

    const handleCleanUp = () => {
        onClose && onClose()
        setKeyword('')
        setChosen([])
    }

    const handleSelect = (value) => {

        if (chosen.find(el => el?.name === value?.name)) {
            setChosen(prevState => prevState.filter(el => el?.name !== value?.name))
        } else {
            setChosen(prevState => [value])
        }
    }

    const handleSave = () => {
        onPick && onPick(chosen[0])
        handleCleanUp()
    }


    return (
        <>
            <MenuPopover anchorEl={anchorEl} open={open} onClose={handleCleanUp} width={350} sx={{height: 480}}>
                <Stack p={2}>

                    <Stack spacing={1} mb={1}>
                        <Typography>
                            Devise
                        </Typography>
                        <TextField
                            size={'small'}
                            fullWidth
                            label={' '}
                            value={keyword}
                            onChange={e => setKeyword(e.target.value)}
                        />
                    </Stack>

                    <Stack height={320}>
                        <Scrollbar sx={{height: "100%"}}>
                            <List>
                                {
                                    filtered?.map(el => {
                                        const isSelected = Boolean(chosen?.find(val => val?.name === el?.name))

                                        return (
                                            <ListItemButton dense onClick={() => handleSelect(el)} key={el?.name}>
                                                <Checkbox checked={isSelected}/>
                                                <Stack direction={'row'} justifyContent={'space-between'} width={1}>
                                                    <Typography>
                                                        {el?.name}
                                                    </Typography>
                                                    <Typography>
                                                        {el?.symbol}
                                                    </Typography>
                                                </Stack>
                                            </ListItemButton>

                                        );
                                    })
                                }
                            </List>
                        </Scrollbar>
                    </Stack>

                    <Stack direction={'row'} justifyContent={'end'} spacing={1} mt={1}>
                        <Button color={'inherit'} onClick={handleCleanUp}>
                            Annuler
                        </Button>
                        <Button variant={'contained'} color={'inherit'} onClick={handleSave}>
                            Sélectionner
                        </Button>
                    </Stack>
                </Stack>
            </MenuPopover>
        </>
    )

}