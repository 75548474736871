import { isEmpty } from 'lodash';

/**
 *
 * @param {*} element
 * @param {Boolean} toStart
 * @param {*} offset
 * @returns
 */
export function moveCaret(element, toStart = false, offset = undefined) {
  if (element?.tagName === 'TEXTAREA') {
    element.focus();
    return;
  }
  const range = document.createRange();
  const selection = window.getSelection();

  range.selectNodeContents(element);

  if (offset) {
    range.setStart(element, offset);
    range.setEnd(element, offset);
  }

  range.collapse(toStart);

  selection.removeAllRanges();
  selection.addRange(range);
}

export function performAction(command) {
  document.execCommand(command, false, null);
}

export function editorMention(text) {
  const selection = window.getSelection();
  const startPosition = selection.getRangeAt(0).startOffset;
  console.log('text', text[startPosition - 1]);
  if (text[startPosition - 1] === '&' || text[startPosition - 1] === ' ' || !text[startPosition - 1]) {
    console.log('is OKy');
  }
}

export function underLine() {
  const selection = window.getSelection();
  const range = selection.getRangeAt(0);

  const u = document.createElement('u');
  u.classList.add('cdx-underline');
  u.appendChild(range.extractContents());

  range.insertNode(u);

  selection.removeAllRanges();
  selection.addRange(range);
}

export function getCurrentElementId() {
  const element = document.activeElement;
  const _elementId = !isEmpty(element.id) ? element.id.replace('task_editor_line-', '') : null;
  return _elementId;
}

export function keyWordInterceptTab(element) {
  element &&
    element.addEventListener('keydown', (e) => {
      if (e.key === 'Tab') {
        e.preventDefault();
        return false;
      }
    });
}

export function showEditorTaskTitleWithMention(title = '', mentions = [], first = true) {
  let text = title;

  if (mentions.length) {
    mentions.forEach((_m) => {
      let name = _m?.display?.trim() || '';
      let patern = `@[${name}](${_m?.id})`;
      text = !first
        ? text.replace(patern, `<span style="color: #4698FC">@${name}</span>`)
        : text.replace(patern, `@${name}`);
    });
  }

  return text;
}

//execCommand alternative
