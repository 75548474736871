import {createContext, useContext, useState} from 'react'
import {useToggleV2} from "src/hooks/useToggle";

import AudienceForm from "src/section/_dashboard/acceuil-v2/audience/AudienceForm";
import {getAudienceById} from "src/redux/slices/audience";
import {useDispatch} from "src/redux/store";
import {useSnackbar} from "notistack";

export const useAudienceContext = () => useContext(AudienceContext)

export default function AudienceProvider({children}) {
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();

    const [open, onOpen, onClose] = useToggleV2()
    const [selected, setSelected] = useState(null)

    const openAudience = (audience) => {
        setSelected(audience)
        onOpen()
    }

    const openAudienceById = (audienceId) => {
        if (!audienceId) return;

        dispatch(
            getAudienceById(
                audienceId,
                (value) => openAudience(value),
                () => {
                    enqueueSnackbar(`Cette audience n'existe plus`, {variant: 'warning'});
                }
            )
        );
    }

    const cleanup = () => {
        onClose()
        setSelected(null)
    }

    const store = {
        openAudience,
        openAudienceById
    }

    return (
        <AudienceContext.Provider value={store}>
            {children}

            {
                open && (
                    <AudienceForm selected={selected} open={open} onClose={cleanup}/>
                )
            }

        </AudienceContext.Provider>
    )
};


const AudienceContext = createContext({
    openAudience: audience => Promise.all(),
    openAudienceById: audienceId => Promise.all(),
})