import React from 'react';
import { Icon } from '@iconify/react';
import { Box, MenuItem, Typography } from '@mui/material';

export const MenuLine = ({
  onClick,
  icon,
  iconColor,
  title,
  color,
  divider = false,
  fontSize = null,
  iconSize = null,
  fontWeight = 400,
  disabled = false
}) => {
  return (
    <MenuItem divider={divider} onClick={disabled ? undefined : onClick} sx={{ py: 1, px: 1.5 }}>
      <Box
        {...((iconColor || color) && { color: iconColor || color })}
        component={Icon}
        icon={icon}
        sx={{ width: 15, height: 15, flexShrink: 0, mr: 1, ...iconSize }}
      />
      <Typography
        variant="caption"
        {...(fontSize && { fontSize: fontSize })}
        {...(color && { color: color })}
        fontWeight={fontWeight}
      >
        {title}
      </Typography>
    </MenuItem>
  );
};
