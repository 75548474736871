import React from 'react'
import { COLOR_OPTIONS } from 'src/section/project/ProjectCardSimple'
import ColorSinglePicker from './ColorSinglePicker'
import MenuPopover from './MenuPopover'

export default function ColorPicker({open, onClose, onChange, anchor }) {
    const handleChangeColor = (event) => {
        onChange(event.target.value);
    };
  
  return (
    <MenuPopover open={open} onClose={onClose} anchorEl={anchor}>
        <ColorSinglePicker onChange={handleChangeColor}  colors={COLOR_OPTIONS} />
    </MenuPopover>
  )
}
