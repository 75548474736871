import { v4 as uuidv4 } from 'uuid';
import { filter, indexOf, isArray, isFunction, uniq, uniqBy } from 'lodash';
import { createSlice, nanoid } from '@reduxjs/toolkit';
import firestore from '../../utils/firestore';
import storage from '../../utils/storage';
import { auth } from '../../contexts/FirebaseContext';
import { defaultDb } from 'src/contexts/FirebaseContext';
import { docDriverCollectionPaths, FileType, FolderType, ShareType } from 'src/section/doc/types';
import { serverTime } from 'src/utils/serverTime';
import { UserType } from 'src/helpers/types';

const initialState = {
  isLoading: false,
  error: false,
  documents: [],
  myFolders: [],
  myFoldersChilds: [],
  notifications: null
};

const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
      console.error(action.payload);
    },

    //AUDIENCE ADD AND UPDATE SUCCESS
    addSuccess(state, action) {
      state.isLoading = false;
      state.documents.push(action.payload);
    },

    updateSuccess(state, action) {
      state.isLoading = false;
      const toUpdate = indexOf(state.documents, (docuemnt) => docuemnt.id === action.payload);
      let newAudience = [...state.documents];
      newAudience.splice(toUpdate, 1, action.payload);
      state.documents = newAudience;
    },

    //GET AUDIENCE SUCCESS
    getDocumentSuccess(state, action) {
      state.isLoading = false;
      state.documents = action.payload;
    },

    deleteDocumentSuccess(state, action) {
      state.isLoading = false;
      state.documents = filter(state.documents, (docuemnt) => docuemnt.id !== action.payload);
    },

    getMyFolders(state, action) {
      const { folders, parentId, isToplevel } = action.payload;
      if (isToplevel) {
        state.myFolders = [...folders];
        state.myFoldersChilds = [...folders];
        return;
      }
      state.myFoldersChilds = [...state.myFoldersChilds, ...folders];
    }
  }
});

export default slice.reducer;

const getUserId = () => {
  const userId = auth.currentUser.uid;
  if (!userId) throw Error('The user is not connected! ');

  return userId;
};

//#region save on dir ------------------------------------------------------------------------------

export function saveFilesOnRootDir(files, dir, callback) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.startLoading());

      const userId = getUserId();

      let folderId = null;
      const rootDocs = await firestore
        .collection('documents')
        .where('userId', 'array-contains', userId)
        .where('name', '==', dir)
        .get();

      if (rootDocs.empty) {
        const isCreate = await firestore.collection('documents').add({
          name: dir,
          parentId: userId,
          path: [],
          userId: [userId],
          createAt: new Date(),
          updateAt: new Date()
        });

        folderId = isCreate.id;
      } else {
        folderId = rootDocs.docs[0].id;
      }

      const docBatch = firestore.batch();

      isArray(files) &&
        files.forEach((file) => {
          const docRef = firestore.collection('files').doc();
          docBatch.set(docRef, {
            url: file.url,
            name: file.name,
            fileType: file.type,
            createAt: new Date(),
            folderId,
            userId: [userId]
          });
        });

      await docBatch.commit();

      isFunction(callback) && callback();
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function SaveStageDocs(files, callback = null) {
  return async (dispatch) => {
    try {
      dispatch(saveFilesOnRootDir(files, 'Stages', callback));
    } catch (error) {
      console.error(error);
    }
  };
}

export function SaveBlogDocs(files, callback = null) {
  return async (dispatch) => {
    try {
      dispatch(saveFilesOnRootDir(files, 'Blog', callback));
    } catch (error) {
      console.error(error);
    }
  };
}

export function SaveTaskDocs(files, callback = null) {
  return async (dispatch) => {
    try {
      dispatch(saveFilesOnRootDir(files, 'Task', callback));
    } catch (error) {
      console.error(error);
    }
  };
}

export function SaveAffectDocs(files, callback = null) {
  return async (dispatch) => {
    try {
      dispatch(saveFilesOnRootDir(files, 'Affectation', callback));
    } catch (error) {
      console.error(error);
    }
  };
}

export function SaveChatDocs(files, callback = null) {
  return async (dispatch) => {
    try {
      dispatch(saveFilesOnRootDir(files, 'Chat', callback));
    } catch (error) {
      console.error(error);
    }
  };
}

//#endregion

//#region SAVING EDITING AND DELETING FILE AND FOLDER

export function deleteFile(fileId, callback = null) {
  return async (dispatch) => {
    try {
      await firestore.collection('files').doc(fileId).delete();

      isFunction(callback) && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateFilename(fileId, newName, callback = null) {
  return async (dispatch) => {
    try {
      await firestore.collection('files').doc(fileId).set({ name: newName }, { merge: true });

      isFunction(callback) && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

export function deleteFolder(folderId, callback = null) {
  return async (dispatch) => {
    try {
      await firestore.collection('documents').doc(folderId).delete();

      isFunction(callback) && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

export function updateFolderName(folderId, newName, callback = null) {
  return async (dispatch) => {
    try {
      await firestore.collection('documents').doc(folderId).set({ name: newName }, { merge: true });

      isFunction(callback) && callback();
    } catch (error) {
      console.error(error);
    }
  };
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export async function multipleFilesSave(files, onSave, callback = null, setUploading = null, filesPathRoot = null) {
  try {
    const data = [];
    let totalLenght = 0;

    if (files?.length) {
      if (setUploading) {
        const startUpload = async () => {
          await asyncForEach(files, async (file) => {
            const id = file?.id || nanoid(30);
            setUploading((prevs) => [
              ...prevs,
              { id: id, progress: 0, error: false, name: file.name, url: file?.url || '', type: file?.type }
            ]);
            const uploadTask = storage
              .ref()
              .child(`${filesPathRoot || ''}${file?.id || id}/${file.name}`)
              .put(file);

            uploadTask.on(
              'state_changed',
              (snapshot) => {
                const progress = snapshot.bytesTransferred / snapshot.totalBytes;

                setUploading((prevUploadingFiles) => {
                  return prevUploadingFiles.map((uploadFile) => {
                    if (uploadFile.id === id) {
                      return { ...uploadFile, progress: progress };
                    }

                    return uploadFile;
                  });
                });
              },
              (error) => {
                setUploading((prevUploadingFiles) => {
                  return prevUploadingFiles.map((uploadFile) => {
                    if (uploadFile.id === id) {
                      return { ...uploadFile, error: true };
                    }
                    return uploadFile;
                  });
                });
              },
              () => {
                setUploading((prevUploadingFiles) => {
                  return prevUploadingFiles.filter((uploadFile) => {
                    return uploadFile.id !== id;
                  });
                });

                uploadTask.snapshot.ref.getDownloadURL().then((url) => {
                  data.push({ name: file.name, url: url, type: file.type, id: id, size: file?.size });
                  totalLenght += 1;

                  if (totalLenght === files.length) {
                    onSave(data);
                    if (callback) return callback();
                  }
                });
              }
            );
          });
        };

        startUpload();
      } else {
        await Promise.all(
          files.map(async (file) => {
            const id = nanoid();
            let fileSnap = await storage
              .ref()
              .child(`${filesPathRoot || ''}${file?.id || id}/${file.name}`)
              .put(file);
            let url = await fileSnap.ref.getDownloadURL();
            let _file_ = { name: file.name, url: url, type: file.type, id: id, size: file?.size };
            data.push(_file_);
          })
        );
        onSave(data);
        if (callback) return callback();
      }
      return;
    }

    onSave(data);
    if (callback) return callback();
  } catch (error) {
    console.log(error);
  }
}

export async function saveFile(file, onSave, callback = null, uploading = null, fileId = null) {
  try {
    let fileSnap = storage
      .ref()
      .child(`${fileId || ''}/${file.name}`)
      .put(file);
    fileSnap.on(
      'state_changed',
      (snap) => {
        var progress = (snap.bytesTransferred / snap.totalBytes) * 100;

        //eslint-disable-next-line
        switch (snap.state) {
          case defaultDb.storage.TaskState.PAUSED: // or 'paused'
            uploading && uploading({ progress, name: file?.name, state: 'En Pause' });
            break;
          case defaultDb.storage.TaskState.RUNNING: // or 'running'
            uploading && uploading({ progress, name: file?.name, state: 'En cours' });
            break;
        }
      },
      (error) => {
        uploading &&
          uploading((prev) => {
            return { progress: prev.progress, name: file?.name, state: 'error', error: error };
          });
      },
      () => {
        fileSnap.snapshot.ref.getDownloadURL().then((url) => {
          const _file = { name: file.name, url: url, type: file.type };

          isFunction(onSave) && onSave(_file);
          if (callback) return callback();
        });
      }
    );
  } catch (error) {
    console.log(error);
  }
}

//#endregion

//#region DOCRVIDER --------------------------------*****************------------------------

export function docDrivergetMyFolders({ folders, parentId, isToplevel }) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getMyFolders({ folders, parentId, isToplevel }));
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 *
 * @param {{ folder: FolderType,  user: UserType, callback: Function? }} params
 */
export function docDriverCreateMyFolder({ folder, user, callback = null }) {
  return async (dispatch) => {
    try {
      const docRef = await firestore.collection(docDriverCollectionPaths.myFolders).add({
        ...folder,
        type: 'folder',
        isDelete: false,
        access: 'private',
        createdBy: user,
        canAccessId: [user.id],
        createdAt: serverTime(),
        updatedAt: serverTime(),
        lastUpdatedBy: user
      });
      if (callback) return callback(docRef.id);
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 *
 * @param {{ files: Array<FileType>,  user: UserType, callback: Function? }} params
 */
export function docDriverCreateFile({ files, user, callback }) {
  return async (dispatch) => {
    try {
      const batch = firestore.batch();

      files.forEach((file) => {
        const docRef = firestore.collection(docDriverCollectionPaths.myFolders).doc(file.id);
        batch.set(docRef, {
          ...file,
          type: 'file',
          acess: 'private',
          isDelete: false,
          createdBy: user,
          canAccessId: [user.id],
          createdAt: serverTime(),
          updatedAt: serverTime(),
          lastUpdatedBy: user
        });
      });

      await batch.commit();

      if (callback) return callback();
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateDocDriverFileOrFolderField({ id, field, value }) {
  return async (dispatch) => {
    try {
      if (isArray(id)) {
        const batch = firestore.batch();
        id.forEach((_id) => {
          const docRef = firestore.collection(docDriverCollectionPaths.myFolders).doc(_id);
          batch.update(docRef, { [field]: value });
        });
        await batch.commit();
        return;
      }

      await firestore
        .collection(docDriverCollectionPaths.myFolders)
        .doc(id)
        .set({ [field]: value }, { merge: true });
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function emmitDocComment({ docId, comment, callback }) {
  return async (dispatch) => {
    try {
      await firestore.runTransaction((transaction) => {
        const ref = firestore.collection(docDriverCollectionPaths.myFolders).doc(docId);

        return transaction.get(ref).then((snap) => {
          if (snap.exists) {
            const commentsCount = (snap.data()?.commentsCount || 0) + 1;

            const { id, ...rest } = comment;

            transaction.set(ref, { commentsCount }, { merge: true });

            transaction.set(ref.collection('comments').doc(id), rest, { merge: true });
          }
        });
      });

      if (callback) return callback();
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteDocComment({ docId, commentId, callback }) {
  return async (dispatch) => {
    try {
      await firestore.runTransaction((transaction) => {
        const ref = firestore.collection(docDriverCollectionPaths.myFolders).doc(docId);

        return transaction.get(ref).then((snap) => {
          if (snap.exists) {
            const commentsCount = (snap.data()?.commentsCount || 0) - 1;

            transaction.set(ref, { commentsCount }, { merge: true });

            transaction.delete(ref.collection('comments').doc(commentId));
          }
        });
      });
      if (callback) return callback();
    } catch (error) {
      console.log(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

/**
 * @param {{
 * file: any,
 * members: Array,
 * callback: Function?,
 * onError: Function?
 * }}
 *  */
export function shareFileWithEmail({ file, members, callback, onError }) {
  return async () => {
    try {
      const memberIds = members.map((_one) => _one.id);

      const { uid } = auth.currentUser;

      const oldShared = file?.sharedBy || {};
      const myOldShared = oldShared[uid];

      const newSahredMembers = [...(myOldShared?.to || []), ...memberIds];

      await firestore
        .collection(docDriverCollectionPaths.myFolders)
        .doc(file?.id)
        .set(
          {
            canAccessId: uniq([...(file.canAccessId || []), ...memberIds]),
            shareWith: uniq([...(file.members || []), ...members]),
            sharedAt: serverTime(),
            sharedById: uniq([...(file.sharedById || []), uid]),
            sharedBy: {
              ...(file?.sharedBy || {}),
              [uid]: { to: uniq(newSahredMembers), at: serverTime() }
            }
          },
          { merge: true }
        );

      if (callback) {
        callback();
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
      console.log(error);
    }
  };
}

/**
 *
 * @param {{ docs: Array, callback: Function?, onError: Function?  }} params
 */
export function restoreDeleteDocDrive({ docs, callback, onError }) {
  return async () => {
    try {
      const batch = firestore.batch();

      docs.forEach((doc) => {
        const docRef = firestore.collection(docDriverCollectionPaths.myFolders).doc(doc?.id);
        batch.update(docRef, { isDelete: false });
      });

      await batch.commit();

      if (callback) {
        callback();
      }
    } catch (error) {
      if (onError) {
        onError();
      }
      console.log(error);
    }
  };
}

export function moveDocs({ docs = [], parentId, onResolve, onReject }) {
  return async () => {
    try {
      const batch = firestore.batch();

      docs?.forEach((el) => {
        const ref = firestore.collection(docDriverCollectionPaths.myFolders).doc(el?.id);
        batch.set(ref, { parentId }, { merge: true });
      });

      await batch.commit();

      onResolve && onResolve();
    } catch (e) {
      console.log(e);
      onReject && onReject(e);
    }
  };
}

//#endregion
