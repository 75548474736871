import React, { useState,useCallback } from 'react'

export default function useToggle(initialState = false) {
    const [open, setOpen] = useState(initialState);

    const handleOpen = useCallback(() =>{
        setOpen(true);
    },[])

    const handleClose =useCallback(() =>{
        setOpen(false);

    },[])

    const handleSwitch =useCallback(() =>{
        setOpen(!open);
    },[open])

    return { open, handleOpen, handleClose ,handleSwitch,setOpen};
}
/**
 * 
 * @param {Boolean} initialState 
 * @returns {[open: Boolean, handleOpen: Function, handleClose: Function, handleSwitch:Function, setOpen:React.Dispatch<any>]}
 */
export function useToggleV2(initialState = false) {
    const [open, setOpen] = useState(initialState);

    const handleOpen = useCallback(() =>{
        setOpen(true);
    },[])

    const handleClose =useCallback(() =>{
        setOpen(false);
    },[])

    const handleSwitch =useCallback(() =>{
        setOpen(!open);
    },[open])

    return  [open, handleOpen, handleClose ,handleSwitch,setOpen];
}
