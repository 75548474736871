import {baseColors} from "src/constants/color";

import {Dialog, DialogContent, DialogTitle, Stack, Typography} from "@mui/material";
import {DepsWarnig} from "src/assets/dependence";

import {Icon} from "@iconify/react/dist/iconify";

export function BudgetGuard({budget, amount, open, onClose, onAccept}) {

    return (
        <Dialog fullWidth maxWidth='sm' open={open} onClose={onClose} scroll='paper'>
            <DialogTitle>
                <Stack width={1} direction='row' justifyContent='flex-end'>
                    <Stack onClick={onClose} sx={{cursor: 'pointer'}} direction='row' alignItems='center'>
                        <Typography color={baseColors.RED}>Annuler</Typography>
                        <Icon icon='eva:close-fill' height={40} color={baseColors.GREY}/>
                    </Stack>
                </Stack>
            </DialogTitle>

            <DialogContent>
                <Stack width={1} spacing={2} alignItems='center' p={2}>
                    <DepsWarnig/>
                    <Typography color={baseColors.RED} fontSize={18}>
                        Êtes-vous sûr de cela ?
                    </Typography>
                    <Typography>
                        {/*Le nouveau budget est au dessus du budget initial pour ce projet*/}
                        Voulez-vous mettre à jour le budget ?
                     </Typography>
                    <Stack width={1} pt={2} pl={3}>
                        <ul>
                            <li>
                                Nouveau budget : {(budget?.used || 0) + (amount || 0)} {budget?.currency?.symbol}
                            </li>
                            <li>
                                Budget initial : {budget?.estimated || 0} {budget?.currency?.symbol}
                            </li>
                        </ul>
                    </Stack>

                    <Stack width={1} py={1.5} alignItems='center' borderRadius={1} bgcolor={baseColors.GREEN}
                           sx={{cursor: 'pointer'}} onClick={onAccept}>
                        <Typography color='white' fontWeight='bold'>Appliquer le nouveau budget</Typography>
                        {/*<Typography color='white' fontSize={12}>*/}
                        {/*    Le montant que vous avez saisi sera affecté à cette tâche*/}
                        {/*</Typography>*/}
                    </Stack>

                    <Stack width={1} py={0.5} alignItems='center' borderRadius={1} bgcolor={baseColors.GREY}
                           sx={{cursor: 'pointer'}} onClick={onClose}>
                        <Typography color='white' fontWeight='bold'> Annuler </Typography>
                        {/*<Typography color='white' fontSize={12}>Continer sans resoudre les tâches*/}
                        {/*    d'actions </Typography>*/}
                    </Stack>
                </Stack>
            </DialogContent>


        </Dialog>
    )
}